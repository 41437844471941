<div
  [class.hidden]="state() === 'filled'"
  (dragenter)="onDragenter($event)"
  class="relative flex justify-center px-6 py-10 border border-dashed rounded-lg border-gray-900/25"
>
  <ng-container [ngTemplateOutlet]="uploadSection"></ng-container>
</div>

<ul *ngIf="state() === 'filled'" (dragenter)="onDragenter($event)">
  <li class="flex flex-wrap items-center justify-center gap-2 px-2 py-3 border border-gray-200 rounded-t-lg">
    <ng-container [ngTemplateOutlet]="uploadSection"></ng-container>
  </li>
  <li
    *ngFor="let attachment of Array.from(idList.values()); trackBy: trackBy; let last = last"
    class="flex flex-wrap items-center justify-between gap-2 px-2 py-1 border border-gray-200 border-t-white"
    [ngClass]="{
      'rounded-b-lg': last
    }"
  >
    <div class="truncate">{{ attachment.fileName }}</div>
    <button type="button" class="btn-sm link link-primary" [disabled]="isLoading()" (click)="onClear(attachment)">
      {{ config.deleteBtnText | transloco }}
    </button>
  </li>
</ul>

<owt-spinner
  *ngIf="isLoading()"
  class="absolute p-2 bg-white rounded-lg pointer-events-none top-1 right-1"
  [size]="'xs'"
></owt-spinner>

<div
  *ngIf="dragover()"
  (drop)="onDrop($event)"
  (dragover)="onDragover($event)"
  (dragleave)="onDragleave($event)"
  [owtButtonIcon]="'add'"
  class="absolute inset-0 flex items-center justify-center p-3 font-bold text-white rounded-lg bg-tertiary-300"
>
  <div class="pointer-events-none">
    {{ config.dropText | transloco }}
  </div>
</div>

<ng-template #uploadSection>
  <div class="text-center">
    <svg class="w-12 h-12 mx-auto text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
      <path
        fill-rule="evenodd"
        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
        clip-rule="evenodd"
      />
    </svg>
    <div class="flex mt-4 text-sm leading-6 text-gray-600">
      <label
        [attr.for]="id"
        class="relative font-semibold bg-white rounded-lg cursor-pointer text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-500"
      >
        <span>{{ config.uploadBtnText | transloco }}</span>
        <input
          #inputElem
          type="file"
          class="sr-only"
          [id]="id"
          [accept]="accept"
          [attr.disabled]="isLoading() ? true : undefined"
          [attr.multiple]="multiple ? true : undefined"
          (change)="onFileChange($event)"
        />
      </label>
      <p class="pl-1">{{ config.dragAndDropText | transloco }}</p>
    </div>
    <p class="text-xs leading-5 text-gray-600">
      {{ formats.join(', ') }} {{ config.upTo | transloco }} {{ maxSize }}MB
    </p>
  </div>
</ng-template>
