<button
  (click)="isOpen.set(!isOpen())"
  type="button"
  [class]="buttonClasses"
  [disabled]="disabled"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <ng-container
    *ngIf="buttonTpl; else defaultButtonTpl"
    [ngTemplateOutlet]="buttonTpl"
    [ngTemplateOutletContext]="{ open: isOpen() }"
  >
  </ng-container>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen()"
  [cdkConnectedOverlayPositions]="positionPairs"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayWidth]="width"
  (detach)="isOpen.set(false)"
  (backdropClick)="isOpen.set(false)"
>
  <ng-container
    *ngIf="dropdownTpl; else defaultDropdownTpl"
    [ngTemplateOutlet]="dropdownTpl"
    [ngTemplateOutletContext]="{ overlay: overlay }"
  >
  </ng-container>
</ng-template>

<ng-template #defaultButtonTpl let-open="open">
  <ng-content></ng-content>
</ng-template>

<ng-template #defaultDropdownTpl>
  <div class="p-2 bg-white rounded-lg shadow-sm">Dropdown</div>
</ng-template>
