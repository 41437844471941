import { StaticProvider } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TableTranslateLoader } from '@owt/ui';
import { tableFactory } from '../factories';

export const TABLE_PROVIDER: StaticProvider = {
  provide: TableTranslateLoader,
  useFactory: tableFactory,
  deps: [TranslocoService],
};
