import { CommonModule, KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ValidationErrors } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { DynamicFormRegularErrorMessagePipe } from '../../pipes';
import { FORM_REGULAR_ERROR_IGNORE_LIST_TOKEN, FormRegular } from '../../utils';

@Component({
  selector: 'owt-dynamic-form-regular-error',
  standalone: true,
  imports: [CommonModule, DynamicFormRegularErrorMessagePipe, TranslocoModule],
  templateUrl: './dynamic-form-regular-error.component.html',
  styleUrls: ['./dynamic-form-regular-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormRegularErrorComponent implements OnInit {
  @Input({ required: true }) public errors: ValidationErrors | undefined | null = null;
  @Input() public ignoreList = inject(FORM_REGULAR_ERROR_IGNORE_LIST_TOKEN);
  @Input() public validationMessageOverrides?: FormRegular.ValidationMessageOverrides;

  private destroyRef = inject(DestroyRef);
  public trValidationMessageOverrides = signal<FormRegular.ValidationMessageOverrides | undefined>(undefined);

  constructor(private transloco: TranslocoService) {}

  public ngOnInit(): void {
    if (!this.validationMessageOverrides) return;

    this.transloco
      .selectTranslation()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const translated = Object.entries(this.validationMessageOverrides as FormRegular.ValidationMessageOverrides)
          .map(([k, v]) => ({
            [k]: this.transloco.translate(v as string),
          }))
          .reduce((acc, cur) => {
            return {
              ...acc,
              ...cur,
            };
          }, {});

        this.trValidationMessageOverrides.set(translated);
      });
  }

  public trackByFn(index: number, item: KeyValue<string, any>): string {
    return item.key;
  }
}
