import { ComponentRef, Directive, ElementRef, Input, OnDestroy, OnInit, ViewContainerRef, inject } from '@angular/core';
import { ControlContainer, FormGroupDirective, NgControl, NgForm, NgModel } from '@angular/forms';
import { BehaviorSubject, EMPTY, Subscription, fromEvent, iif, merge, skip, startWith } from 'rxjs';
import { DynamicFormRegularErrorComponent } from '../components';
import { DynamicFormRegularErrorStateMatcherService } from '../services/dynamic-form-regular-error-state-matcher.service';
import { FormRegular } from '../utils';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `
    [ngModel]:not([withoutValidationErrors]),
    [formControl]:not([withoutValidationErrors]),
    [formControlName]:not([withoutValidationErrors]),
    [formGroupName]:not([withoutValidationErrors]),
    [ngModelGroup]:not([withoutValidationErrors])
  `,
  standalone: true,
})
export class DynamicFormRegularValidatorMessageDirective implements OnInit, OnDestroy {
  private ngControl = inject(NgControl, { self: true, optional: true }) || inject(ControlContainer, { self: true });
  private elementRef = inject(ElementRef);
  private get form(): NgForm | FormGroupDirective | null {
    return this.parentContainer?.formDirective as NgForm | FormGroupDirective | null;
  }
  @Input()
  public errorStateMatcher = inject(DynamicFormRegularErrorStateMatcherService);

  @Input()
  public container = inject(ViewContainerRef);

  @Input() public validationMessageOverrides?: FormRegular.ValidationMessageOverrides;

  private componentRef: ComponentRef<DynamicFormRegularErrorComponent> | null = null;
  private errorMessageTrigger!: Subscription;
  private parentContainer = inject(ControlContainer, { optional: true });

  public triggerManually = new BehaviorSubject<void>(undefined);

  public ngOnInit(): void {
    queueMicrotask(() => {
      if (!this.ngControl.control) throw Error(`No control model for ${this.ngControl.name} control...`);
      this.errorMessageTrigger = merge(
        this.ngControl.control.statusChanges,
        fromEvent(this.elementRef.nativeElement, 'blur'),
        iif(() => !!this.form, this.form!.ngSubmit, EMPTY),
      )
        .pipe(startWith(this.ngControl.control.status), skip(this.ngControl instanceof NgModel ? 1 : 0))
        .subscribe(() => this.buildComponent());
    });
  }

  private buildComponent(): void {
    if (this.errorStateMatcher.isErrorVisible(this.ngControl.control, this.form)) {
      if (!this.componentRef) {
        this.componentRef = this.container.createComponent(DynamicFormRegularErrorComponent);
        this.componentRef.instance.ignoreList = [];
        this.componentRef.setInput('validationMessageOverrides', this.validationMessageOverrides);
        this.componentRef.changeDetectorRef.markForCheck();
      }
      this.componentRef.setInput('errors', this.ngControl.errors);
    } else {
      this.componentRef?.destroy();
      this.componentRef = null;
    }
  }

  public ngOnDestroy(): void {
    this.errorMessageTrigger.unsubscribe();
  }
}
