export type StorageType = 'local' | 'session';

export class StorageUtils {
  public static isAvailable = typeof window !== 'undefined' && typeof window.localStorage !== 'undefined';

  public static getStorageType = (storage: StorageType): Storage => {
    return storage === 'session' ? sessionStorage : localStorage;
  };

  public static getItem = (item: string, storageType: StorageType = 'local'): string | null => {
    if (this.isAvailable) {
      const storage = this.getStorageType(storageType);
      return storage.getItem(item);
    } else {
      console.warn(`${storageType}Storage setItem`, item);
      return null;
    }
  };

  public static setItem = (item: string, value: string, storageType: StorageType = 'local'): void => {
    if (this.isAvailable) {
      const storage = this.getStorageType(storageType);
      return storage.setItem(item, value);
    } else {
      console.warn(`${storageType}Storage setItem`, item, value);
    }
  };

  public static removeItem = (item: string, storageType: StorageType = 'local'): void => {
    if (this.isAvailable) {
      const storage = this.getStorageType(storageType);
      return storage.removeItem(item);
    } else {
      console.warn(`${storageType}Storage removeItem`, item);
    }
  };
}
