import { CommonModule } from '@angular/common';
import { InjectionToken, StaticProvider, inject } from '@angular/core';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { FormCondensed } from './dynamic-form-condensed.dto';

export const condensedSharedDynamicControlDeps = [CommonModule, ReactiveFormsModule, TranslocoModule];

export const condensedDynamicControlProvider: StaticProvider = {
  provide: ControlContainer,
  useFactory: () => inject(ControlContainer, { skipSelf: true }),
};

export const FORM_CONDENSED_CONTROL_DATA = new InjectionToken<FormCondensed.ControlData>('Form condensed control data');

export const FORM_CONDENSED_ERROR_MESSAGES: FormCondensed.ErrorMessages = {
  required: () => `This field is required`,
  requiredTrue: () => `This field is required`,
  email: () => `It should be a valid email`,
  pattern: () => `Wrong format`,
  minLength: ({ requiredLength }) => `The length should be at least ${requiredLength} characters`,
  maxLength: ({ requiredLength }) => `The length should be shorter or equal than ${requiredLength} characters`,
  passwordShouldMatch: () => `Password should match`,
  dateRange: () => `The end date must be greater than the start date`,
  min: ({ min }) => `Minimum value is ${min}`,
  max: ({ max }) => `Maximum value is ${max}`,
  nullValidator: () => '',
};

export const FORM_CONDENSED_ERRORS_ALERT_MESSAGES: FormCondensed.ErrorAllertMessages = {
  one: (count) => `There were ${count} error with your submission`,
  two: (count) => `There were ${count} errors with your submission`,
  three: (count) => `There were ${count} errors with your submission`,
  four: (count) => `There were ${count} errors with your submission`,
  other: (count) => `There were ${count} errors with your submission`,
};
