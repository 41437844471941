import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { ExtendedError } from '@sentry/types';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandlerService implements ErrorHandler {
  public handleError(error: ExtendedError): void {
    Sentry.captureException(error?.['originalError'] || error);
    throw error;
  }
}
