import { InjectionToken, StaticProvider, inject } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { FORM_REGULAR_ERROR_MESSAGES } from './dynamic-form-regular.constant';
import { FormRegular } from './dynamic-form-regular.dto';

export const regularDynamicControlProvider: StaticProvider = {
  provide: ControlContainer,
  useFactory: () => inject(ControlContainer, { skipSelf: true }),
};

export const FORM_REGULAR_ERROR_MESSAGES_TOKEN = new InjectionToken('Form regular validation messages', {
  providedIn: 'root',
  factory: (): Observable<FormRegular.ErrorMessages> => of(FORM_REGULAR_ERROR_MESSAGES),
});

export const FORM_REGULAR_ERROR_IGNORE_LIST_TOKEN = new InjectionToken('Form regular ignore list', {
  providedIn: 'root',
  factory: (): string[] => [],
});
