import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, InjectionToken, inject } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

export interface NotFoundItemConfig {
  title: string;
  description: string;
}

export const NOT_FOUND_ITEM_CONFIG = new InjectionToken<NotFoundItemConfig>('Not found item config');

@Component({
  selector: 'owt-not-found-item',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './not-found-item.component.html',
  styleUrls: ['./not-found-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundItemComponent {
  public config = inject(NOT_FOUND_ITEM_CONFIG);
}
