import { Injectable } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';

export interface ErrorStateMatcher {
  isErrorVisible(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DynamicFormRegularErrorStateMatcherService implements ErrorStateMatcher {
  public isErrorVisible(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return Boolean(control?.invalid && (control?.dirty || form?.submitted));
  }
}
