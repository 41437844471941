import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { HashMap, TranslocoModule } from '@ngneat/transloco';
import { ModalFooterComponent } from '../modal-footer';
import { ModalHeaderComponent, ModalType } from '../modal-header';

export interface ConfirmModalDataContent {
  key: string;
  params?: HashMap | undefined;
}

export interface ConfirmModalData {
  title: string;
  content: ConfirmModalDataContent;
  closeBtnText: string;
  confirmBtnText: string;
  modalType?: ModalType;
}

@Component({
  selector: 'owt-modal-confirm',
  standalone: true,
  imports: [CommonModule, DialogModule, ModalFooterComponent, ModalHeaderComponent, TranslocoModule],
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: ConfirmModalData,
    public dialogRef: DialogRef<boolean>,
  ) {}

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.dialogRef.close(true);
  }
}
