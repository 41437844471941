<owt-modal-header
  [title]="data.title | transloco"
  [content]="data.content.key | transloco: data.content.params"
  [modalType]="data.modalType || 'success'"
  [breakPoint]="'sm'"
></owt-modal-header>
<owt-modal-footer>
  <button
    type="button"
    class="btn btn-base"
    [ngClass]="{
      'btn-primary': !data.modalType || data.modalType === 'success',
      'btn-danger': data.modalType === 'warning'
    }"
    (click)="onSubmit()"
  >
    {{ data.confirmBtnText | transloco }}
  </button>
  <button type="button" class="btn btn-base btn-secondary" (click)="onClose()">
    {{ data.closeBtnText | transloco }}
  </button>
</owt-modal-footer>
