import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { IconComponent } from '../../../icon/components/icon.component';
import { FormRegular } from '../../utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'label[owtDynamicFormRegularLabel]',
  standalone: true,
  imports: [CommonModule, TranslocoModule, DropdownModule, IconComponent],
  templateUrl: './dynamic-form-regular-label.component.html',
  styleUrls: ['./dynamic-form-regular-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormRegularLabelComponent {
  @Input({ required: true }) public control!: FormRegular.ControlData;

  @HostBinding('class.extended')
  public get extended(): boolean {
    return Boolean(this.control.config.info);
  }
}
