import { Highlightable } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'owt-option',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionComponent<T, V> implements Highlightable {
  @HostBinding('class.selected') protected isSelected = false;
  @HostBinding('class.active') protected isActive = false;

  @Input() public value: T | null = null;
  @Input() public sourceValue: V | T | null = this.value;
  @Input() public disabledReason = '';

  @Input()
  @HostBinding('class.disabled')
  public disabled = false;

  @Output()
  public selected = new EventEmitter<OptionComponent<T, V>>();

  @HostListener('click')
  protected select(): void {
    if (!this.disabled) {
      this.highlightAsSelected();
      this.selected.emit(this);
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLElement>,
  ) {}

  public setActiveStyles(): void {
    this.isActive = true;
    this.cdr.markForCheck();
  }

  public setInactiveStyles(): void {
    this.isActive = false;
    this.cdr.markForCheck();
  }

  public scrollIntoView(options?: ScrollIntoViewOptions): void {
    this.el.nativeElement.scrollIntoView(options);
  }

  public highlightAsSelected(): void {
    this.isSelected = true;
    this.cdr.markForCheck();
  }

  public deselect(): void {
    this.isSelected = false;
    this.cdr.markForCheck();
  }
}
