import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { InputFileAttachment, ToastService } from '@owt/ui';
import * as Sentry from '@sentry/angular-ivy';
import {
  PreSignedUploadCreateGQL,
  PreSignedUploadFragment,
  PreSignedUploads,
} from 'projects/portal/src/generated/graphql';
import { InputFileUploadService } from 'projects/ui/src/lib/input/shared/services';
import { Observable, catchError, filter, forkJoin, map, switchMap } from 'rxjs';
import { ErrorsDto } from '../dto/errors.dto';
import { CustomToastService } from './custom-toast.service';

interface FileUpload {
  presignedUpload: PreSignedUploadFragment;
  file: File;
}

@Injectable({
  providedIn: 'root',
})
export class FileUploadService extends InputFileUploadService<FileUpload> {
  constructor(
    private preSignedUploadCreate: PreSignedUploadCreateGQL,
    private http: HttpClient,
    private customToast: CustomToastService,
    private toast: ToastService,
    private transloco: TranslocoService,
  ) {
    super();
  }

  public upload(files: File[]): Observable<FileUpload[]> {
    const fileInputs = files.map((file) => ({ originalFileName: file.name, fileSize: file.size }));
    return this.preSignedUploadCreate
      .mutate({
        files: fileInputs,
      })
      .pipe(
        map((res) => res.data?.preSignedUploadCreate),
        filter((res) => res?.__typename === 'PreSignedUploads'),
        map((presignedUpload) => {
          return (presignedUpload as PreSignedUploads).preSignedUploads.map((presignedUpload, i) => ({
            presignedUpload,
            file: files[i],
          }));
        }),
        switchMap((fileUpload) => {
          return forkJoin(fileUpload.map((item) => this.signPresignedRequest(item))).pipe(map(() => fileUpload));
        }),
      );
  }

  private signPresignedRequest(item: FileUpload): Observable<unknown> {
    return this.http
      .put(item.presignedUpload.url, item.file, {
        headers: {
          'Content-Type': item.file.type,
          'Content-Disposition': 'attachment; filename=' + item.file.name,
        },
        reportProgress: true,
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          Sentry.captureException(err);
          throw err;
        }),
      );
  }

  public handleSuccess(files: FileUpload[], idList: Map<string, InputFileAttachment>): void {
    for (const item of files) {
      idList.set(item.presignedUpload.id, { id: item.presignedUpload.id, fileName: item.file.name });
    }
  }

  public handleUnexpected(): void {
    this.customToast.showError(ErrorsDto.rootUnexpectedError);
  }

  public handleEmpty(): void {
    this.customToast.showError(ErrorsDto.rootUploadNoFiles);
  }

  public handleTooLarge(corruptedFiles: string[]): void {
    this.toast.show({
      type: 'danger',
      text: this.transloco.translate('notification.error.root.upload-too-large', { files: corruptedFiles.join(', ') }),
    });
  }

  public handleInvalidFormat(corruptedFiles: string[]): void {
    this.toast.show({
      type: 'danger',
      text: this.transloco.translate('notification.error.root.upload-invalid-format', {
        files: corruptedFiles.join(', '),
      }),
    });
  }
}
