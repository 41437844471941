<div role="combobox" owtDropdown [offset]="-5" [buttonClasses]="buttonClasses" [positions]="positions">
  <ng-template #button let-open="open">
    <span *ngIf="control.value">{{ control.value | translocoDate: { dateStyle: 'long' } }}</span>
    <span *ngIf="!control.value">{{
      todayAsPlaceholder
        ? (CalendarUtils.today() | translocoDate: { dateStyle: 'long' })
        : (config.placeholderText | transloco)
    }}</span>
    <owt-icon [icon]="'chevronDown'" class="ml-1"></owt-icon>
  </ng-template>
  <ng-template #dropdown let-overlay="overlay">
    <owt-dropdown-menu
      [overlay]="overlay"
      class="p-3 overflow-hidden bg-white border border-gray-300 rounded-lg shadow-2xl"
    >
      <owt-input-calendar
        [formControl]="control"
        [dayOfWeekOffset]="dayOfWeekOffset"
        [minDate]="minDate"
        [maxDate]="maxDate"
        (ngModelChange)="handleChange($event)"
      ></owt-input-calendar>
      <div class="flex flex-wrap justify-between gap-1 mt-2">
        <button type="button" class="btn btn-sm btn-primary" (click)="setToday()">
          {{ config.todayBtnText | transloco }}
        </button>
        <div class="flex flex-wrap gap-1">
          <button type="button" class="btn btn-sm btn-secondary" (click)="clear()">
            {{ config.clearBtnText | transloco }}
          </button>
          <button type="button" class="btn btn-sm btn-secondary" owtDropdownItem>
            {{ config.closeBtnText | transloco }}
          </button>
        </div>
      </div>
    </owt-dropdown-menu>
  </ng-template>
</div>
