import { Pipe, PipeTransform } from '@angular/core';
import { CalendarDto } from '../dto';

export interface CalendarHasNeighbourOptions {
  minDate?: Date;
  maxDate?: Date;
  type: 'prev' | 'next';
}

@Pipe({
  name: 'calendarHasNeighbour',
  standalone: true,
})
export class CalendarHasNeighbourPipe implements PipeTransform {
  private readonly oneDayMilliseconds = 24 * 60 * 60 * 1000;

  public transform(value: CalendarDto[], { minDate, maxDate, type }: CalendarHasNeighbourOptions): boolean {
    if (value.length !== 42) {
      throw new Error('Calendar should have 42 items.');
    }
    const currentMonth = value.filter((i) => i.isCurrentMonth);
    if (type === 'next' && maxDate) {
      const lastDayOfMonth = new Date(currentMonth[currentMonth.length - 1].date);
      const neighbour = new Date(lastDayOfMonth.getTime() + this.oneDayMilliseconds);
      const isAboveMaxDate = neighbour.getTime() > maxDate.getTime();
      return !isAboveMaxDate;
    }
    if (type === 'prev' && minDate) {
      const firstDayOfMonth = new Date(currentMonth[0].date);
      const neighbour = new Date(firstDayOfMonth.getTime() - this.oneDayMilliseconds);
      const isBelowMinDate = neighbour.getTime() < minDate.getTime();
      return !isBelowMinDate;
    }
    return true;
  }
}
