import {
  FORM_CONDENSED_ERROR_ALERT_MESSAGES_TOKEN,
  FORM_CONDENSED_ERROR_MESSAGES_TOKEN,
  FORM_REGULAR_ERROR_MESSAGES_TOKEN,
} from '@owt/ui';
import { formErrorsFactory } from '../factories';
import { formErrorsAlertFactory } from '../factories/form-error-alert.factory';

export const FORM_REGULAR_ERRORS_PROVIDER = {
  provide: FORM_REGULAR_ERROR_MESSAGES_TOKEN,
  useFactory: formErrorsFactory,
};

export const FORM_CONDENSED_ERRORS_PROVIDER = {
  provide: FORM_CONDENSED_ERROR_MESSAGES_TOKEN,
  useFactory: formErrorsFactory,
};

export const FORM_CONDENSED_ERROR_ALERT_MESSAGES_PROVIDER = {
  provide: FORM_CONDENSED_ERROR_ALERT_MESSAGES_TOKEN,
  useFactory: formErrorsAlertFactory,
};
