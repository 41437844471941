import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToDay',
  standalone: true,
})
export class DateToDayPipe implements PipeTransform {
  public transform(date: string): string {
    const day = date.split('-').pop()?.replace(/^0/, '') || '';
    const parseDay = parseInt(day);
    if (isNaN(parseDay)) {
      return '';
    }
    return day;
  }
}
