import {
  Directive,
  ElementRef,
  Host,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DropdownContentComponent } from '../components';
import { DropdownMenuComponent } from '../components/dropdown-menu/dropdown-menu.component';

@Directive({
  selector: '[owtDropdownItem]',
})
export class DropdownItemDirective implements OnChanges {
  @HostBinding('attr.role') public role = 'menuitem';
  @Input() public disabledForPermissions = false;
  public disableClose = false;

  constructor(
    private el: ElementRef<HTMLElement>,
    private transloco: TranslocoService,
    @Host() @Optional() private dropdownMenu?: DropdownMenuComponent,
    @Host() @Optional() private dropdownContent?: DropdownContentComponent,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabledForPermissions']) {
      if (this.disabledForPermissions) {
        this.el.nativeElement.setAttribute('disabled', 'disabled');
        // this is kind of buggy...
        // translations should be moved outside ui
        // you have to also change attribute when language is changed
        this.el.nativeElement.setAttribute(
          'title',
          this.transloco.translate('notification.error.root.ticket-last-comment-disabled'),
        );
      }
    }
  }

  @HostListener('click')
  public onClick(): void {
    this.close();
  }

  public close(force?: boolean): void {
    if (this.disableClose && !force) {
      return;
    }
    if (this.dropdownMenu) {
      this.dropdownMenu.overlay.overlayRef.detach();
    }
    if (this.dropdownContent) {
      this.dropdownContent.overlay.overlayRef.detach();
    }
  }
}
