import { CommonModule } from '@angular/common';
import { InjectionToken } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { DynamicFormRegularLabelComponent } from '../components';
import { DynamicFormRegularValidatorMessageDirective } from '../directives';
import { FormRegular } from './dynamic-form-regular.dto';

export const FORM_REGULAR_CONTROL_DATA = new InjectionToken<FormRegular.ControlData>('Form regular control data');
export const FORMS_REGULAR_FORM = new InjectionToken<FormRegular.DynamicForm>(`Form regular dynamic form`);

export const regularSharedDynamicControlDeps = [
  CommonModule,
  ReactiveFormsModule,
  TranslocoModule,
  DynamicFormRegularLabelComponent,
  DynamicFormRegularValidatorMessageDirective,
];

export const FORM_REGULAR_ERROR_MESSAGES: FormRegular.ErrorMessages = {
  required: () => `This field is required`,
  requiredTrue: () => `This field is required`,
  email: () => `It should be a valid email`,
  pattern: () => `Wrong format`,
  minLength: ({ requiredLength }) => `The length should be at least ${requiredLength} characters`,
  maxLength: ({ requiredLength }) => `The length should be shorter or equal than ${requiredLength} characters`,
  passwordShouldMatch: () => `Password should match`,
  dateRange: () => `The end date must be greater than the start date`,
  min: ({ min }) => `Minimum value is ${min}`,
  max: ({ max }) => `Maximum value is ${max}`,
  nullValidator: () => '',
};
