import { Injectable } from '@angular/core';
import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { UpdateTranslateLoader } from '@owt/core';

@Injectable({
  providedIn: 'root',
})
export class UpdateLoader extends UpdateTranslateLoader {
  constructor() {
    super();

    this.title = _('modal.confirm.new-version-available.title');
    this.content = _('modal.confirm.new-version-available.content');
    this.closeBtnText = _('uni.close');
    this.confirmBtnText = _('uni.update');
  }
}
