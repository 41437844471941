import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  InjectionToken,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonIconDirective } from 'projects/ui/src/lib/icon/directives/button-icon.directive';
import { finalize } from 'rxjs';
import { SpinnerComponent } from '../../spinner';
import { AbstractInputComponent, InputFileFormats, ValidationKey, ValidationMessage } from '../shared';
import { InputFileUploadService } from '../shared/services';
import { FileUtils } from '../shared/utils';

export type InputFileState = 'empty' | 'filled';

export interface InputFileConfig {
  dropText: string;
  uploadBtnText: string;
  dragAndDropText: string;
  upTo: string;
  deleteBtnText: string;
}

export interface InputFileAttachment {
  id: string;
  fileName?: string;
}

export const INPUT_FILE_CONFIG = new InjectionToken<InputFileConfig>('Input file config');

@Component({
  selector: 'owt-input-file',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule, TranslocoModule, SpinnerComponent, ButtonIconDirective],
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputFileComponent,
      multi: true,
    },
  ],
})
export class InputFileComponent
  extends AbstractInputComponent<Array<InputFileAttachment>>
  implements OnChanges, OnInit
{
  public static nextId = 0;
  public readonly id = `${InputFileComponent.nextId++}`;

  @Input() public formats: InputFileFormats[] = ['png', 'jpg', 'gif'];
  @Input() public maxSize = 10;
  @Input() public multiple = true;

  public state = signal<InputFileState>('empty');
  public accept = signal<string | undefined>(undefined);
  public acceptAsArray = signal<string[]>([]);
  public dragover = signal(false);
  private destroyRef = inject(DestroyRef);
  public config = inject(INPUT_FILE_CONFIG);
  public readonly Array = Array;
  public idList = new Map<string, InputFileAttachment>();
  public isLoading = signal(false);

  constructor(
    private uploadService: InputFileUploadService<unknown>,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public override writeValue(value: Array<InputFileAttachment>): void {
    this.idList.clear();
    if (value?.length) {
      for (const item of value) {
        this.idList.set(item.id, { id: item.id, fileName: item.fileName });
      }
    }

    this.state.set(this.idList.size ? 'filled' : 'empty');
  }

  public ngOnInit(): void {
    this.refreshAccept();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['formats']) {
      this.refreshAccept();
    }
  }

  private refreshAccept(): void {
    const acceptAsArray = this.formats.map((format) => FileUtils.mimeTypes(format));
    this.acceptAsArray.set(acceptAsArray);
    this.accept.set(acceptAsArray.join(','));
  }

  public validate(_: FormControl): ValidationErrors | null {
    return _.valid ? null : { [ValidationKey.InputFile]: { valid: false, message: ValidationMessage.InputFile } };
  }

  public onDragenter(event: Event): void {
    event.preventDefault();
    this.dragover.set(true);
  }

  public onDragleave(event: Event): void {
    event.preventDefault();
    this.dragover.set(false);
  }

  public onDragover(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    this.dragover.set(false);
    const filesToProcess = FileUtils.dragEventToFile(event);
    this.processFile(filesToProcess);
  }

  public onFileChange(event: any): void {
    const filesToProcess = FileUtils.changeEventToFile(event);
    this.processFile(filesToProcess);
    event.target.value = null;
  }

  private processFile(files: File[]): void {
    this.isLoading.set(true);

    if (!files.length) {
      this.uploadService.handleEmpty();
      this.state.set('empty');
      this.isLoading.set(false);
      return;
    }

    if (files.some((file) => FileUtils.fileSizeInMbytes(file) > this.maxSize)) {
      const corruptedFiles = files
        .filter((file) => FileUtils.fileSizeInMbytes(file) > this.maxSize)
        .map((file) => file.name);
      this.uploadService.handleTooLarge(corruptedFiles);
      this.isLoading.set(false);
      return;
    }

    if (files.some((file) => !this.acceptAsArray().includes(file.type))) {
      const corruptedFiles = files.filter((file) => !this.acceptAsArray().includes(file.type)).map((file) => file.name);
      this.uploadService.handleTooLarge(corruptedFiles);
      this.isLoading.set(false);
      return;
    }

    this.uploadService
      .upload(files)
      .pipe(
        finalize(() => this.isLoading.set(false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (res) => {
          this.uploadService.handleSuccess(res, this.idList);
          this.state.set(this.idList.size ? 'filled' : 'empty');
          this.onTouched();
          this.onChange(Array.from(this.idList.values()));
          this.cdr.markForCheck();
          this.isLoading.set(false);
        },
        error: (err) => {
          this.uploadService.handleUnexpected();
          throw new Error(err);
        },
      });
  }

  public trackBy(index: number, target: InputFileAttachment): string {
    return target.id;
  }

  public onClear(attachment: InputFileAttachment): void {
    if (this.idList.has(attachment.id)) {
      this.idList.delete(attachment.id);
    }
    this.state.set(this.idList.size ? 'filled' : 'empty');
    this.onTouched();
    this.onChange(Array.from(this.idList.values()));
    this.cdr.markForCheck();
  }
}
