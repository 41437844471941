import { InputFileFormats } from '../dto/file.dto';

export class FileUtils {
  public static fileSizeInMbytes(file: File): number {
    return file ? Math.round(file.size / 1024 / 1024) : 0;
  }

  public static mimeTypes(format: InputFileFormats): string {
    switch (format) {
      case 'svg':
        return 'image/svg+xml';
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'webp':
        return 'image/webp';
      default:
        return '';
    }
  }

  public static dragEventToFile(event: DragEvent): File[] {
    const dt = event.dataTransfer;
    let filesToProcess = new Array<any>();

    // DataTransfer API
    if (dt?.items) {
      filesToProcess = Array.from(dt.items)
        .map((it) => it.getAsFile())
        .filter((it) => it !== null);
    } else if (dt?.files) {
      // FileList API
      filesToProcess = Array.from(dt.files);
    }

    return filesToProcess;
  }

  public static changeEventToFile(event: any): File[] {
    const files: FileList = event?.target?.files || new FileList();
    return Array.from(files);
  }
}
