import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastTranslateLoader } from '@owt/ui';

@Injectable({
  providedIn: 'root',
})
export class ToastLoader extends ToastTranslateLoader {
  constructor(private transloco: TranslocoService) {
    super();
    this.transloco.selectTranslate('uni.close').subscribe(() => {
      this.closeTooltip = this.transloco.translate('uni.close');
    });
  }
}
