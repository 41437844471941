import { UserLanguagesChoices } from 'projects/portal/src/generated/graphql';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../constants/language.constant';
import { Language } from '../dto/language.dto';

export class LangUtils {
  public static getTranslocoLang(language: UserLanguagesChoices): string {
    if (AVAILABLE_LANGUAGES.includes(language.toLowerCase() as Language)) {
      return language.toLowerCase();
    }
    return DEFAULT_LANGUAGE;
  }

  public static getBackendLang(language: string): UserLanguagesChoices {
    const activeLang = language.toUpperCase() as UserLanguagesChoices;
    if (Object.values(UserLanguagesChoices).includes(activeLang)) {
      return activeLang;
    }
    return DEFAULT_LANGUAGE.toUpperCase() as UserLanguagesChoices;
  }
}
