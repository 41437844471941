import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { UpdateDirective } from '@owt/core';
import { ModalConfirmComponent } from '@owt/ui';
import { NgxAppVersionDirective } from 'ngx-app-version';
import { HtmlLangDirective } from './shared/directives/html-lang.directive';
import { RevealTranslationsDirective } from './shared/directives/reveal-translations.directive';

@Component({
  selector: 'owt-root',
  standalone: true,
  imports: [CommonModule, TranslocoModule, RouterOutlet, ModalConfirmComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NgxAppVersionDirective, UpdateDirective, HtmlLangDirective, RevealTranslationsDirective],
})
export class AppComponent {
  public title = 'portal';
}
