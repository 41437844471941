<ng-container *ngIf="!control.config.info">
  {{ control.config.label | transloco }}
  <ng-container *ngIf="control.config.validators?.required || control.config.validators?.requiredTrue"
    >*</ng-container
  ></ng-container
>

<ng-container *ngIf="control.config.info">
  <span
    >{{ control.config.label | transloco }}
    <ng-container *ngIf="control.config.validators?.required || control.config.validators?.requiredTrue"
      >*</ng-container
    >
  </span>
  <div owtDropdown [buttonClasses]="'px-1 link link-primary w-5 h-5'">
    <ng-template #button let-open="open">
      <owt-icon [icon]="'eye'"></owt-icon>
    </ng-template>
    <ng-template #dropdown let-overlay="overlay">
      <owt-dropdown-menu
        [overlay]="overlay"
        class="flex flex-col max-w-xs gap-2 p-2 overflow-hidden bg-white border border-gray-300 rounded-lg shadow-2xl"
      >
        <div class="text-sm">{{ control.config.info | transloco }}</div>
      </owt-dropdown-menu>
    </ng-template>
  </div>
</ng-container>
