import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ModalConfirmComponent } from '@owt/ui';
import { UpdateService } from './update.service';
import { UPDATE_CONFIG_TOKEN, UpdateConfig, defaultUpdateConfig } from './update.utils';

@NgModule({
  imports: [CommonModule, DialogModule, ModalConfirmComponent],
  providers: [UpdateService],
})
export class UpdateModule {
  public static forRoot(config: Partial<UpdateConfig>): ModuleWithProviders<UpdateModule> {
    return {
      ngModule: UpdateModule,
      providers: [
        {
          provide: UPDATE_CONFIG_TOKEN,
          useValue: { ...defaultUpdateConfig, ...config },
        },
      ],
    };
  }
}
