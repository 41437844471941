import { LanguageSwitchLanguage } from '@owt/ui';
import { Language, LanguageDto } from '../dto/language.dto';

export const DEFAULT_LANGUAGE: Language = 'cs';
export const AVAILABLE_LANGUAGES: Language[] = Object.values(LanguageDto);

const UNDERSCORE: Record<LanguageDto, string> = {
  cs: 'cs_CZ',
  en: 'en_US',
};

const DASH: Record<LanguageDto, string> = {
  cs: 'cs-CZ',
  en: 'en-US',
};

export const LOCALES = {
  UNDERSCORE,
  DASH,
};

export const LANGUAGE_SWITCH_CONFIG: LanguageSwitchLanguage[] = [
  {
    code: 'cs',
    label: 'Česky',
    abbreviation: 'CS',
    flagPath: '/assets/flags/256x256/cze.svg',
  },
  {
    code: 'en',
    label: 'English',
    abbreviation: 'EN',
    flagPath: '/assets/flags/256x256/gbr.svg',
  },
];

export const DAY_OF_WEEK_OFFSET: Record<LanguageDto, number> = {
  cs: 1,
  en: 1,
};
