import { ConnectionPositionPair } from '@angular/cdk/overlay';

export type DropdownPosition = 'topCenter' | 'topRight' | 'topLeft' | 'bottomCenter' | 'bottomRight' | 'bottomLeft';

export type DropdownPositions<T extends string> = { [key in T]: (offset: number) => ConnectionPositionPair };

export const DROPDOWN_POSITIONS: DropdownPositions<DropdownPosition> = {
  bottomCenter: (offset: number) => ({
    offsetX: 0,
    offsetY: offset,
    originX: 'center', // right corner of the button
    originY: 'bottom', // bottom corner of the button
    overlayX: 'center', // right corner of the overlay to the origin
    overlayY: 'top', // top corner of the overlay to the origin
  }),
  bottomLeft: (offset: number) => ({
    offsetX: 0,
    offsetY: offset,
    originX: 'start', // left corner of the button
    originY: 'top', // top corner of the button
    overlayX: 'start', // left corner of the overlay to the origin
    overlayY: 'bottom', // bottom corner of the overlay to the origin
  }),
  bottomRight: (offset: number) => ({
    offsetX: 0,
    offsetY: offset,
    originX: 'end', // right corner of the button
    originY: 'top', // top corner of the button
    overlayX: 'end', // right corner of the overlay to the origin
    overlayY: 'bottom', // top corner of the overlay to the origin
  }),
  topCenter: (offset: number) => ({
    offsetX: 0,
    offsetY: -1 * offset,
    originX: 'center', // right corner of the button
    originY: 'top', // top corner of the button
    overlayX: 'center', // right corner of the overlay to the origin
    overlayY: 'bottom', // top corner of the overlay to the origin
  }),
  topLeft: (offset: number) => ({
    offsetX: 0,
    offsetY: -1 * offset,
    originX: 'start', // right corner of the button
    originY: 'bottom', // bottom corner of the button
    overlayX: 'start', // right corner of the overlay to the origin
    overlayY: 'top', // top corner of the overlay to the origin
  }),
  topRight: (offset: number) => ({
    offsetX: 0,
    offsetY: -1 * offset,
    originX: 'end', // right corner of the button
    originY: 'bottom', // bottom corner of the button
    overlayX: 'end', // right corner of the overlay to the origin
    overlayY: 'top', // top corner of the overlay to the origin
  }),
};

export const DEFAULT_DROPDOWN_POSITIONS: DropdownPosition[] = [
  'topCenter',
  'topRight',
  'topLeft',
  'bottomCenter',
  'bottomRight',
  'bottomLeft',
];

export const createPositionPairs = (
  offset: number,
  positions: DropdownPosition[] = DEFAULT_DROPDOWN_POSITIONS
): ConnectionPositionPair[] => {
  return positions.map((p) => DROPDOWN_POSITIONS[p](offset));
};
