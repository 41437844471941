import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  InjectionToken,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationSkipped, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { distinctUntilChanged, filter, map } from 'rxjs';
import { DropdownModule } from '../dropdown';

export interface TabsTranslations {
  notFound: string;
}

export const TABS_TRANSLATIONS = new InjectionToken<TabsTranslations>('Tabs translations');

export interface TabItem {
  url: string[];
  label: string;
}

@Component({
  selector: 'owt-tabs',
  standalone: true,
  imports: [CommonModule, RouterModule, DropdownModule, TranslocoModule],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);
  @Input({ required: true }) public tabs: TabItem[] = [];
  @Input({ required: true }) public ariaLabelSelect!: string;
  @Input({ required: true }) public ariaLabelTabs!: string;
  public actualTab = signal<TabItem[]>([]);
  public tabsTranslations = inject(TABS_TRANSLATIONS);

  constructor(private router: Router) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabs']) {
      this.checkActive();
    }
  }

  public ngOnInit(): void {
    this.watchActive();
  }

  private checkActive(): void {
    const tab = this.tabs.filter((tab) => {
      return this.router.isActive(tab.url.join('/'), false);
    });
    this.actualTab.set(tab);
  }

  private watchActive(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd || event instanceof NavigationSkipped),
        map((e: any) => e.url),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.checkActive();
      });
  }

  public trackBy(index: number, target: TabItem): string {
    return target.url.join('/');
  }
}
