import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, InjectionToken, Input, inject } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { SpinnerComponent } from '../spinner';

export interface ProgressButtonConfig {
  saving: string;
  loading: string;
  removing: string;
  sending: string;
}

export const PROGRESS_BUTTON_CONFIG = new InjectionToken<ProgressButtonConfig>('Save button config');

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[owtProgressButton]',
  standalone: true,
  imports: [CommonModule, SpinnerComponent, TranslocoModule],
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressButtonComponent {
  @Input({ required: true, alias: 'owtProgressButton' }) public loading = false;
  @Input({ required: true }) public progressType!: keyof ProgressButtonConfig;
  public config = inject(PROGRESS_BUTTON_CONFIG);
}
