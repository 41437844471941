import { Component, Input, booleanAttribute } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  template: '',
})
export abstract class AbstractInputComponent<T> implements ControlValueAccessor {
  public onChange: (_: T) => void = () => {};
  public onTouched: () => void = () => {};

  @Input({ transform: booleanAttribute }) public required!: boolean;
  @Input({ transform: booleanAttribute }) public disabled!: boolean;

  public abstract writeValue(value: T): void;

  public registerOnChange(fn: (_: T) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
