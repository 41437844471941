import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { RouteDefinitionDto } from '../dto/route-definition.dto';

const ADMIN: RouteDefinitionDto<string>['ADMIN'] = {
  SETTINGS: _('uni.settings'),
  CUSTOMERS: _('titles.admin.users'),
  MANUFACTURERS: _('titles.admin.manufacturers'),
  INSTALLATION_COMPANIES: _('titles.admin.installation-companies'),
  TRANSLATOR: _('titles.admin.translator'),
};

const MANUFACTURER: RouteDefinitionDto<string>['MANUFACTURER'] = {
  DEVICES: _('uni.devices'),
  EVENTS: _('uni.events'),
  REGISTER: _('titles.manufacturer.register'),
  SETTINGS: _('uni.settings'),
  TICKETS: _('uni.tickets'),
  TYPES_OF_DEVICES: _('titles.manufacturer.types-of-devices'),
};

const CUSTOMER: RouteDefinitionDto<string>['CUSTOMER'] = {
  DASHBOARD: _('uni.dashboard'),
  DEVICES: _('uni.devices'),
  EVENTS: _('uni.events'),
  REGISTER: _('titles.customer.register'),
  SETTINGS: _('uni.settings'),
  TICKETS: _('uni.tickets'),
  COMPARE: _('titles.customer.compare'),
};

const INSTALLATION_COMPANY: RouteDefinitionDto<string>['INSTALLATION_COMPANY'] = {
  DASHBOARD: _('uni.dashboard'),
  DEVICES: _('uni.devices'),
  EVENTS: _('uni.events'),
  REGISTER: _('titles.installation-company.register'),
  SETTINGS: _('uni.settings'),
  TICKETS: _('uni.tickets'),
  CUSTOMERS: _('titles.installation-company.customers'),
};

const ROOT: Partial<RouteDefinitionDto<string>['APP']> = {
  LOGIN: _('titles.app.login'),
  FORGOTTEN_PASSWORD: _('titles.app.forgotten-password'),
  ACTIVATE_ACCOUNT: _('titles.app.activate-account'),
  RESET_PASSWORD: _('titles.app.reset-password'),
  USER_INVITATION: _('titles.app.user-invitation'),
  INSTALLATION_CUSTOMER_INVITATION: _('titles.app.installation-customer-invitation'),
};

export const TITLES = {
  ROOT,
  MANUFACTURER,
  INSTALLATION_COMPANY,
  CUSTOMER,
  ADMIN,
};
