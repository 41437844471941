import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { IconComponent } from 'projects/ui/src/lib/icon/components/icon.component';
import { ICONS } from 'projects/ui/src/lib/icon/icon.constants';

@Directive({
  selector: '[owtButtonIcon]',
  standalone: true,
})
export class ButtonIconDirective implements OnChanges, OnInit {
  @Input({ required: true }) public owtButtonIcon!: keyof typeof ICONS;
  @Input() public owtButtonIconPosition: 'left' | 'right' | 'standalone' = 'left';
  @Input() public owtButtonIconClasses = '';

  private componentRef: ComponentRef<IconComponent> | undefined;

  constructor(
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    const host: Element = this.element.nativeElement;

    if (!host.getAttribute('type')) {
      host.setAttribute('type', 'button');
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.componentRef) {
      this.componentRef = this.createComponent();
      this.bindComponentRef();
    } else {
      if (changes['owtButtonIcon']) {
        this.componentRef.instance.icon = this.owtButtonIcon;
      }
      if (changes['owtButtonIconPosition']) {
        this.componentRef.instance.position = this.owtButtonIconPosition;
      }
      if (changes['owtButtonIconClasses']) {
        this.componentRef.instance.classes = this.owtButtonIconClasses;
      }
    }
  }

  private bindComponentRef(): void {
    const host: Element = this.element.nativeElement;
    if (this.owtButtonIconPosition === 'right') {
      this.renderer.appendChild(host, this.componentRef!.location.nativeElement);
    } else {
      this.renderer.insertBefore(host, this.componentRef!.location.nativeElement, host.firstChild);
    }
  }

  private createComponent(): ComponentRef<IconComponent> {
    const componentRef = this.viewContainerRef.createComponent(IconComponent);
    componentRef.instance.icon = this.owtButtonIcon;
    componentRef.instance.classes = this.owtButtonIconClasses;
    componentRef.instance.position = this.owtButtonIconPosition;
    return componentRef;
  }
}
