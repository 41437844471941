import { Routes } from '@angular/router';
import { ROUTE_DEFINITION } from './shared/constants/route-definition.constant';
import { NotLoggedGuard } from './shared/guards/not-logged.guard';
import { TITLES } from './shared/i18n/title.i18n';

export const routes: Routes = [
  {
    path: ROUTE_DEFINITION.APP.LOGIN,
    title: TITLES.ROOT.LOGIN,
    canActivate: [NotLoggedGuard.notLogged],
    loadComponent: () => import('./layout/layout-public').then((m) => m.LayoutPublicComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./root/login').then((m) => m.LoginComponent),
      },
    ],
  },
  {
    path: ROUTE_DEFINITION.APP.ACTIVATE_ACCOUNT,
    title: TITLES.ROOT.ACTIVATE_ACCOUNT,
    canActivate: [NotLoggedGuard.notLogged],
    loadComponent: () => import('./layout/layout-public').then((m) => m.LayoutPublicComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./root/activate-account').then((m) => m.ActivateAccountComponent),
      },
    ],
  },
  {
    path: ROUTE_DEFINITION.APP.FORGOTTEN_PASSWORD,
    title: TITLES.ROOT.FORGOTTEN_PASSWORD,
    canActivate: [NotLoggedGuard.notLogged],
    loadComponent: () => import('./layout/layout-public').then((m) => m.LayoutPublicComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./root/forgotten-password').then((m) => m.ForgottenPasswordComponent),
      },
    ],
  },
  {
    path: ROUTE_DEFINITION.APP.RESET_PASSWORD,
    title: TITLES.ROOT.RESET_PASSWORD,
    canActivate: [NotLoggedGuard.notLogged],
    loadComponent: () => import('./layout/layout-public').then((m) => m.LayoutPublicComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./root/reset-password').then((m) => m.ResetPasswordComponent),
      },
    ],
  },
  {
    path: ROUTE_DEFINITION.APP.USER_INVITATION,
    title: TITLES.ROOT.USER_INVITATION,
    loadComponent: () => import('./layout/layout-public').then((m) => m.LayoutPublicComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./root/user-invitation').then((m) => m.UserInvitationComponent),
      },
    ],
  },
  {
    path: ROUTE_DEFINITION.APP.ADMIN,
    loadChildren: () => import('./admin/admin').then((m) => m.AdminModule),
  },
  {
    path: ROUTE_DEFINITION.APP.MANUFACTURER,
    loadChildren: () => import('./manufacturer/manufacturer').then((m) => m.ManufacturerModule),
  },
  {
    path: ROUTE_DEFINITION.APP.CUSTOMER,
    loadChildren: () => import('./customer/customer').then((m) => m.CustomerModule),
  },
  {
    path: ROUTE_DEFINITION.APP.INSTALLATION_COMPANY,
    loadChildren: () => import('./installation-company/installation-company').then((m) => m.InstallationCompanyModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTE_DEFINITION.APP.LOGIN,
  },
  {
    path: '**',
    loadComponent: () => import('./not-found').then((m) => m.NotFoundComponent),
  },
];
