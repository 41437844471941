import { OverlayModule } from '@angular/cdk/overlay';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastComponent } from './components/toast/toast.component';
import { TOAST_CONFIG_TOKEN, defaultToastConfig } from './utils/toast-config';
import { ToastTranslateLoader } from './utils/toast-translate-loader';

@NgModule({
  declarations: [ToastComponent],
  imports: [OverlayModule, BrowserAnimationsModule],
  providers: [ToastTranslateLoader],
})
export class ToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        },
      ],
    };
  }
}
