import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { EMPTY, Observable, mergeMap, of } from 'rxjs';
import { OnDemandPreloadService } from './on-demand-preload.service';

@Injectable({
  providedIn: 'root',
})
export class CustomPreloadStrategyService implements PreloadingStrategy {
  constructor(private onDemandPreload: OnDemandPreloadService) {}

  public preload(route: Route, fn: () => Observable<any>): Observable<Observable<any> | never> {
    return this.onDemandPreload.state.pipe(
      mergeMap((value) => {
        return of(route.title === value.routePath ? fn() : EMPTY);
      })
    );
  }
}
