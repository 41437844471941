import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconComponent } from '../../icon/components/icon.component';

export type ModalType = 'warning' | 'success';

@Component({
  selector: 'owt-modal-header',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
  @Input({ required: true }) public modalType!: ModalType;
  @Input({ required: true }) public title!: string;
  @HostBinding('class')
  @Input({ required: true })
  public breakPoint!: 'sm' | 'none';
  @Input() public content?: string;
  @Input() public displayIcon = true;
}
