import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'owt-dropdown-content,ul[owtDropdownContent]',
  templateUrl: './dropdown-content.component.html',
  styleUrls: ['./dropdown-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownContentComponent {
  @HostBinding('attr.role') public role = 'menu';
  @ContentChildren('itemRef') public itemsTpl?: QueryList<TemplateRef<void>>;
  @Input({ required: true, alias: 'owtDropdownContent' }) public overlay!: CdkConnectedOverlay;
}
