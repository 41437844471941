import { inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { FormRegular } from '@owt/ui';
import { ErrorCodeEnum } from 'projects/portal/src/generated/graphql';
import { Observable, map } from 'rxjs';
import { CustomErrorDto } from '../dto/custom-error.dto';
import { FormErrorsDto } from '../dto/errors.dto';

type CustomErrors = Record<CustomErrorDto, (args?: any) => string>;

export const formErrorsFactory = (): Observable<CustomErrors> => {
  const transloco = inject(TranslocoService);
  return transloco.selectTranslation().pipe(
    map(() => {
      const beErrors: Record<ErrorCodeEnum, (args?: any) => string> = {
        [ErrorCodeEnum.DeviceTypeApiTypeMappingMissmatchError]: () =>
          transloco.translate(_('validation.be.device-type-api-type-mapping-missmatch_error')),
        [ErrorCodeEnum.DeviceTypeMappingValidationError]: () =>
          transloco.translate(_('validation.be.device-type-mapping-validation-error')),
        [ErrorCodeEnum.Invalid]: () => transloco.translate(_('validation.be.invalid')),
        [ErrorCodeEnum.ItemInvalid]: () => transloco.translate(_('validation.be.item-invalid')),
        [ErrorCodeEnum.MaxDigits]: () => transloco.translate(_('validation.be.max-digits')),
        [ErrorCodeEnum.MaxLength]: () => transloco.translate(_('validation.be.max-length')),
        [ErrorCodeEnum.MaxWholeDigits]: () => transloco.translate(_('validation.be.max-whole-digits')),
        [ErrorCodeEnum.MinLength]: () => transloco.translate(_('validation.be.min-length')),
        [ErrorCodeEnum.MinValue]: () => transloco.translate(_('validation.be.min-value')),
        [ErrorCodeEnum.PasswordEntirelyNumeric]: () =>
          transloco.translate(_('validation.be.password-entirely-numeric')),
        [ErrorCodeEnum.PasswordTooCommon]: () => transloco.translate(_('validation.be.password-too-common')),
        [ErrorCodeEnum.PasswordTooShort]: () => transloco.translate(_('validation.be.password-too-short')),
        [ErrorCodeEnum.PasswordTooSimilar]: () => transloco.translate(_('validation.be.password-too-similar')),
        [ErrorCodeEnum.Required]: () => transloco.translate(_('validation.fe.required')),
        [ErrorCodeEnum.SerialNumberFormatValidationError]: () =>
          transloco.translate(_('validation.be.serial-number-format-validation-error')),
        [ErrorCodeEnum.Unique]: () => transloco.translate(_('validation.be.unique')),
        [ErrorCodeEnum.PasswordIncorrect]: () => transloco.translate(_('validation.be.password-incorrect')),
        [ErrorCodeEnum.PasswordMismatch]: () => transloco.translate(_('validation.be.password-mismatch')),
        [ErrorCodeEnum.DeviceEventTypeUidExistsError]: () =>
          transloco.translate(_('validation.be.device-event-type-uid-exists')),
        [ErrorCodeEnum.MaxValue]: () => transloco.translate(_('validation.be.max-value')),
        [ErrorCodeEnum.InvalidChoice]: () => transloco.translate(_('validation.be.invalid-choice')),
        [ErrorCodeEnum.InstallationCompanyDeviceInstallDeviceAlreadyRegisteredValidationError]: () =>
          transloco.translate(_('validation.be.installation-company-device-install-device-already-registered')),
        [ErrorCodeEnum.CustomerDeviceRegisterDeviceAlreadyRegisteredValidationError]: () =>
          transloco.translate(_('validation.be.customer-device-register-device-already-registered')),
        [ErrorCodeEnum.CustomerDeviceRegisterTechnologyAlreadyRegisteredValidationError]: () =>
          transloco.translate(_('validation.be.customer-device-register-technology-already-registered')),
        [ErrorCodeEnum.DeviceRegisterRegistrationTokenValidationError]: () =>
          transloco.translate(_('validation.be.device-register-registration-token')),
        [ErrorCodeEnum.DeviceRegisterSerialNumberValidationError]: () =>
          transloco.translate(_('validation.be.device-register-serial-number')),
        [ErrorCodeEnum.CustomerTicketCustomerInstallationCompanyMismatchValidationError]: () =>
          transloco.translate(_('validation.be.installation-company-mismatch')),
        [ErrorCodeEnum.CustomerTicketDeviceMismatchValidationError]: () =>
          transloco.translate(_('validation.be.customer-ticket-mismatch')),
        [ErrorCodeEnum.CustomerTicketEventMismatchValidationError]: () =>
          transloco.translate(_('validation.be.customer-event-mismatch')),
        [ErrorCodeEnum.InstallationCompanyDeviceInstallDeviceTechnologyAlreadyRegisteredValidationError]: () =>
          transloco.translate(_('validation.be.installation-company-device-technology-already-registered')),
        [ErrorCodeEnum.UniqueTogether]: () => transloco.translate(_('validation.be.unique-together')),
        [ErrorCodeEnum.DeviceTypeMappingUnknownMetricFieldError]: (err: string[]) => {
          return transloco.translate(_('validation.be.mapping-unknown-field'), { fields: err.join(', ') });
        },
        [ErrorCodeEnum.RelatedInstallationCompanyTicketNotAllowedValidationError]: () =>
          transloco.translate(_('validation.be.tickets-not-allowed')),
      };

      const globalErrors: { [key in FormErrorsDto]: (args?: any) => string } = {
        [FormErrorsDto.ExistingDevices]: () => transloco.translate(_('validation.global.be.existing-devices')),
        [FormErrorsDto.InvalidCredentials]: () => transloco.translate(_('validation.global.be.invalid-credentials')),
        [FormErrorsDto.InvalidRefreshToken]: () => transloco.translate(_('validation.global.be.invalid-refresh-token')),
        [FormErrorsDto.ObjectDoesNotExist]: () => transloco.translate(_('validation.global.be.object-does-not-exist')),
        [FormErrorsDto.InvalidPassword]: () => transloco.translate(_('validation.global.be.invalid-password')),
        [FormErrorsDto.TokenExpired]: () => transloco.translate(_('validation.global.be.token-expired')),
        [FormErrorsDto.UserDisabled]: () => transloco.translate(_('validation.global.be.user-disabled')),
        [FormErrorsDto.TariffRestriction]: () => transloco.translate(_('validation.global.be.tariff-restriction')),
      };

      const buitInErrors: { [key in FormRegular.ValidatorKeys]: (args?: any) => string } = {
        required: () => transloco.translate(_('validation.fe.required')),
        requiredTrue: () => transloco.translate(_('validation.fe.required-true')),
        email: () => transloco.translate(_('validation.fe.email')),
        pattern: () => transloco.translate(_('validation.fe.pattern')),
        minLength: (res: any) =>
          transloco.translate(_('validation.fe.min-length'), { requiredLength: res.requiredLength }),
        maxLength: (res: any) =>
          transloco.translate(_('validation.fe.max-length'), { requiredLength: res.requiredLength }),
        passwordShouldMatch: () => transloco.translate(_('validation.fe.password-should-match')),
        dateRange: () => transloco.translate(_('validation.fe.date-range')),
        max: (res: any) => transloco.translate(_('validation.fe.max'), { max: res.max }),
        min: (res: any) => transloco.translate(_('validation.fe.min'), { min: res.min }),
        nullValidator: () => '',
        missingProperty: (property: string) => {
          return transloco.translate(_('validation.fe.missing-property'), { property });
        },
        schema: () => {
          return transloco.translate(_('validation.fe.invalid-schema'));
        },
      };

      return {
        // BE errors
        ...beErrors,

        // global errors
        ...globalErrors,

        // built-in errors
        ...buitInErrors,
      };
    }),
  );
};
