import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { isPlatformBrowser } from '@angular/common';
import { ApplicationRef, Inject, Injectable, PLATFORM_ID, isDevMode } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ConfirmModalData, ModalConfirmComponent } from '@owt/ui';
import { concat, filter, first, interval } from 'rxjs';
import { UpdateTranslateLoader } from './update-translate-loader';
import { UPDATE_CONFIG_TOKEN, UpdateConfig } from './update.utils';

@Injectable()
export class UpdateService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(UPDATE_CONFIG_TOKEN) private config: UpdateConfig,
    private appRef: ApplicationRef,
    private translateLoader: UpdateTranslateLoader,
    private updates: SwUpdate,
    private dialog: Dialog,
    private overlay: Overlay,
  ) {}

  public checkForUpdates(): void {
    if (this.isEnabled) {
      // make first check when app is stable (check for update after after page load is finished)
      // and then make periodical checks
      const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
      const pollInterval$ = concat(appIsStable$, interval(this.config.interval * 60 * 1000));

      pollInterval$.subscribe(() => this.updates.checkForUpdate());

      this.updates.versionUpdates.pipe(filter((e) => e.type === 'VERSION_READY')).subscribe(() => {
        this.openVersionAvailableDialog();
      });
    }
  }

  private openVersionAvailableDialog(): void {
    const positionStrategy = this.overlay.position().global().left('1rem').bottom('1rem');
    const title = this.translateLoader.title;
    const content = {
      key: this.translateLoader.content,
    };
    const closeBtnText = this.translateLoader.closeBtnText;
    const confirmBtnText = this.translateLoader.confirmBtnText;
    const dialogRef = this.dialog.open<boolean, ConfirmModalData>(ModalConfirmComponent, {
      data: { title, content, closeBtnText, confirmBtnText, modalType: 'success' },
      panelClass: 'modal-xs',
      hasBackdrop: false,
      positionStrategy,
    });
    dialogRef.closed.subscribe((reload) => {
      if (reload) {
        window.location.reload();
      }
    });
  }

  private get isEnabled(): boolean {
    return (
      typeof window !== 'undefined' &&
        'navigator' in window &&
        'serviceWorker' in navigator &&
        isPlatformBrowser(this.platformId) &&
        !isDevMode(),
      this.updates.isEnabled
    );
  }
}
