<div
  tabindex="0"
  class="w-full flex items-center justify-between gap-1 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
  #select
>
  <ng-container [ngSwitch]="searchable && isOpen">
    <input
      #input
      *ngSwitchCase="true"
      (input)="onHandleInput($event)"
      [placeholder]="config.i18n ? (searchPlaceholderText | transloco) : searchPlaceholderText"
      class="w-full -my-1.5 -ml-3 rounded-md sm:leading-6 py-1.5 focus:text-sm focus:ring-primary focus:border-primary"
    />
    <span *ngSwitchCase="false" [ngClass]="{ empty: !value }">
      <ng-container
        *ngIf="selectedTpl; else selectedDefault"
        [ngTemplateOutlet]="selectedTpl"
        [ngTemplateOutletContext]="{
          value: value,
          optionMap: optionMap,
          nothingSelectedText: config.i18n ? (nothingSelectedText | transloco) : nothingSelectedText
        }"
      ></ng-container>
      <ng-template #selectedDefault>
        {{ displayValue || (config.i18n ? (nothingSelectedText | transloco) : nothingSelectedText) }}
      </ng-template>
    </span>
  </ng-container>
  <div class="flex gap-1">
    <button
      (click)="clearSelection($event)"
      *ngIf="value && clearable"
      [owtButtonIcon]="'close'"
      [title]="config.i18n ? (config.clearText | transloco) : config.clearText"
      class="p-1 text-xs text-gray-300 hover:text-black focus-visible:outline-gray-800"
    ></button>
    <span class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
      <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path
          fill-rule="evenodd"
          d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
          clip-rule="evenodd"
        />
      </svg>
    </span>
  </div>
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="positionPairs"
  [cdkConnectedOverlayWidth]="width()"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="close()"
  (detach)="close()"
>
  <div
    class="w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
