import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  name: 'testing',
  domain: 'https://onlinewatt-test.web.app',
  sentryDsn: 'https://6ad6a0a9ca484975bb6a9423f975fef2@vortex.coex.cz/109',
  siteName: 'onlinewatt.com',
  graphql: {
    uri: 'https://test-portal.onlinewatt.com/graphql/',
    credentials: 'onlinewatt-portal_testing:onlinewatt-portal_testing',
  },
  translations: {
    uri: 'https://translations.onlinewatt.com/translations/',
    authUri: 'https://api-translate.onlinewatt.com/auth/jwt/',
    remote: true,
    reveal: true,
  },
};
