import { CALENDAR_PROVIDER } from './calendar.provider';
import { DINAMIC_FILTERS_TRANSLATIONS_PROVIDER } from './dynamic-filters.provider';
import { ERROR_HANDLER_PROVIDER } from './error-handler-provider';
import { FILE_UPLOAD_PROVIDER } from './file-upload.provider';
import {
  FORM_CONDENSED_ERRORS_PROVIDER,
  FORM_CONDENSED_ERROR_ALERT_MESSAGES_PROVIDER,
  FORM_REGULAR_ERRORS_PROVIDER,
} from './form-errors.provider';
import { FORM_CONDENSED_IGNORE_LIST_PROVIDER, FORM_REGULAR_IGNORE_LIST_PROVIDER } from './form-ignore-list.provider';
import { GOOGLE_MAPS_PROVIDER } from './google-maps.provider';
import { INPUT_DATE_PROVIDER } from './input-date.provider';
import { INPUT_FILE_PROVIDER } from './input-file.provider';
import { INPUT_SELECT_PROVIDER } from './input-select-provider';
import { LANGUAGE_SWITCH_PROVIDER } from './language-switch.provider';
import { NOT_FOUND_ITEM_PROVIDER } from './not-found-item.provider';
import { PROGRESS_BUTTON_PROVIDER } from './progress-button.provider';
import { TABLE_PROVIDER } from './table.provider';
import { TABS_PROVIDER } from './tabs.provider';
import { TITLE_STRATEGY_PROVIDER } from './title-strategy.provider';
import { TOAST_PROVIDER } from './toast.provider';
import { UPDATE_PROVIDER } from './update.provider';

export const CUSTOM_PROVIDERS = [
  CALENDAR_PROVIDER,
  DINAMIC_FILTERS_TRANSLATIONS_PROVIDER,
  ERROR_HANDLER_PROVIDER,
  FILE_UPLOAD_PROVIDER,
  FORM_CONDENSED_ERROR_ALERT_MESSAGES_PROVIDER,
  FORM_CONDENSED_ERRORS_PROVIDER,
  FORM_CONDENSED_IGNORE_LIST_PROVIDER,
  FORM_REGULAR_ERRORS_PROVIDER,
  FORM_REGULAR_IGNORE_LIST_PROVIDER,
  GOOGLE_MAPS_PROVIDER,
  INPUT_DATE_PROVIDER,
  INPUT_FILE_PROVIDER,
  INPUT_SELECT_PROVIDER,
  LANGUAGE_SWITCH_PROVIDER,
  NOT_FOUND_ITEM_PROVIDER,
  PROGRESS_BUTTON_PROVIDER,
  TABLE_PROVIDER,
  TABS_PROVIDER,
  TITLE_STRATEGY_PROVIDER,
  TOAST_PROVIDER,
  UPDATE_PROVIDER,
];
