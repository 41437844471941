<div class="container py-4 md:hidden" #container>
  <owt-dropdown
    [buttonClasses]="'items-center w-full rounded-md'"
    [positions]="['topLeft', 'bottomLeft', 'topRight', 'bottomRight']"
    [offset]="-5"
    [scrollPositionStrategy]="'reposition'"
    [width]="container.offsetWidth - 32"
    #select
  >
    <ng-template #button let-open="open">
      <button
        type="button"
        class="rounded-md relative w-full cursor-defaultbg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
      >
        <span class="block truncate">{{ (actualTab().length === 1 && actualTab()[0].label) || ariaLabelTabs }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>
    </ng-template>
    <ng-template #dropdown let-overlay="overlay">
      <ul *ngIf="tabs.length; else notFound" class="w-full bg-white border border-gray-300 rounded-lg shadow-2xl ulis">
        <li *ngFor="let tab of tabs; trackBy: trackBy" class="relative w-full">
          <a
            class="block w-full px-6 py-2 text-left hover:bg-gray-100 focus:outline-none focus-visible:bg-gray-200"
            [routerLink]="tab.url"
            routerLinkActive="font-semibold"
            (click)="overlay.overlayRef.detach()"
          >
            {{ tab.label }}
            <span class="absolute inset-y-0 right-0 items-center hidden pr-4 text-primary-600" routerLinkActive="!flex">
              <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </a>
        </li>
      </ul>
      <ng-template #notFound>
        <div class="w-full bg-white border border-gray-300 rounded-lg shadow-2xl">
          <div class="block w-full px-6 py-2 text-left">{{ tabsTranslations.notFound | transloco }}</div>
        </div>
      </ng-template>
    </ng-template>
  </owt-dropdown>
</div>

<div class="hidden md:block">
  <div class="border-b border-gray-200">
    <nav class="container flex -mb-px space-x-8" [attr.aria-label]="ariaLabelTabs">
      <a
        *ngFor="let tab of tabs; trackBy: trackBy"
        [routerLink]="tab.url"
        class="px-1 py-4 text-sm font-medium whitespace-nowrap"
        routerLinkActive="is-active"
        >{{ tab.label }}</a
      >
    </nav>
  </div>
</div>
