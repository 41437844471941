import { InjectionToken } from '@angular/core';
import { DynamicFilterData } from './dynamic-filters.dto';

export const DYNAMIC_FILTERS_CONFIG = new InjectionToken<DynamicFilterData<any>>('Dynamic filters config');

export const DYNAMIC_FILTERS_DEFAULTS = {
  DEBOUNCE_TIME: 0,
  SEPARATOR: ',',
};

export interface DynamicFIltersTranslations {
  notFound: string;
}

export const DYNAMIC_FILTERS_TRANSLATIONS = new InjectionToken<DynamicFIltersTranslations>(
  'Dynamic filters translations',
);
