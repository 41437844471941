import { RouteDefinitionDto } from '../dto/route-definition.dto';

export const ROUTE_DEFINITION: RouteDefinitionDto<string> = {
  APP: {
    ADMIN: 'admin',
    MANUFACTURER: 'manufacturer',
    CUSTOMER: 'customer',
    LOGIN: 'login',
    ACTIVATE_ACCOUNT: 'activate-account',
    FORGOTTEN_PASSWORD: 'forgotten-password',
    INSTALLATION_COMPANY: 'installation-company',
    RESET_PASSWORD: 'reset-password',
    USER_INVITATION: 'user-invitation',
    INSTALLATION_CUSTOMER_INVITATION: 'installation-customer-invitation',
  },
  ROOT: {
    // unclassified
    EDIT: 'edit',
    NEW: 'new',
    SUBSCRIPTION_HISTORY: 'subscription-history',
    TARIFFS: 'tariffs',
    USER_MANAGEMENT: 'user-management',
    ORGANIZATION: 'organization',
    CONFIGURATION: 'configuration',
    DASHBOARD: 'dashboard',
    EVENTS: 'events',
    FVE: 'fve',
    TC: 'tc',
    TICKETS: 'tickets',
    WITH_CUSTOMER: 'customer',
    WITH_MANUFACTURER: 'manufacturer',
    MY_TICKETS: 'my',
    INSTRUCTIONS: 'instructions',
    EVENT_TYPE: 'event-type',
    SITES: 'sites',
    ATTACHMENTS: 'attachments',
    NOTES: 'notes',
    MY_DEVICES: 'my-devices',
  },
  ADMIN: {
    SETTINGS: 'settings',
    CUSTOMERS: 'customers',
    MANUFACTURERS: 'manufacturers',
    INSTALLATION_COMPANIES: 'installation-companies',
    TRANSLATOR: 'translator',
  },
  MANUFACTURER: {
    DEVICES: 'devices',
    EVENTS: 'events',
    REGISTER: 'register',
    SETTINGS: 'settings',
    TICKETS: 'tickets',
    TYPES_OF_DEVICES: 'types-of-devices',
  },
  CUSTOMER: {
    COMPARE: 'compare',
    DASHBOARD: 'dashboard',
    DEVICES: 'devices',
    EVENTS: 'events',
    REGISTER: 'register',
    SETTINGS: 'settings',
    TICKETS: 'tickets',
  },
  INSTALLATION_COMPANY: {
    REGISTER: 'register',
    DASHBOARD: 'dashboard',
    CUSTOMERS: 'customers',
    DEVICES: 'devices',
    EVENTS: 'events',
    SETTINGS: 'settings',
    TICKETS: 'tickets',
  },
};
