import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DEFAULT_LANGUAGE } from '../constants/language.constant';

@Injectable({
  providedIn: 'root',
})
export class NgxTranslateRevealService implements OnDestroy {
  private keyPressEvent = fromEvent<KeyboardEvent>(this.document, 'keydown').pipe(
    filter((e: KeyboardEvent) => e.altKey && e.ctrlKey),
  );
  private isEnabled = false;
  private keyPressSub: Subscription | null = null;

  constructor(
    private translate: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public getEnabledStatus(): boolean {
    return this.isEnabled;
  }

  public enable(): void {
    this.dispose();
    this.keyPressSub = this.keyPressEvent.subscribe(() => {
      if (!this.isEnabled) {
        this.translate.setActiveLang('');
      } else {
        this.translate.setActiveLang(DEFAULT_LANGUAGE);
      }
      this.isEnabled = !this.isEnabled;
    });
  }

  public disable(): void {
    if (this.isEnabled) {
      this.translate.setActiveLang(DEFAULT_LANGUAGE);
    }
    this.dispose();
  }

  public ngOnDestroy(): void {
    this.dispose();
  }

  private dispose(): void {
    this.keyPressSub?.unsubscribe();
  }
}
