import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { ICONS } from '../icon.constants';

@Component({
  selector: 'owt-icon',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges, OnInit {
  public iconClass: IconProp = null as unknown as IconProp;
  @Input({ required: true }) public icon!: keyof typeof ICONS;
  @Input() public position: 'left' | 'right' | 'standalone' = 'left';
  @Input() public classes = '';
  @Input() public size: SizeProp | undefined = undefined;

  public ngOnInit(): void {
    this.setupIcon();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['icon'] && !changes['icon'].firstChange) {
      this.setupIcon();
    }
  }

  private setupIcon(): void {
    if (this.icon in ICONS) {
      this.iconClass = ICONS[this.icon];
    } else {
      throw new Error('Missing icon.');
    }
  }
}
