export enum ErrorsDto {
  customerDeviceUnregister = 'customerDeviceUnregister',
  customerSiteRemove = 'customerSiteRemove',
  customerSiteDelete = 'customerSiteDelete',
  customerCustomerSiteSetDefault = 'customerCustomerSiteSetDefault',
  customerStripeSubscriptionUpdate = 'customerStripeSubscriptionUpdate',
  customerSetTemperature = 'customerSetTemperature',
  installationCompanyCustomerSiteDelete = 'installationCompanyCustomerSiteDelete',
  installationCompanyCustomerDelete = 'installationCompanyCustomerDelete',
  installationCompanyNoteRemove = 'installationCompanyNoteRemove',
  installationCompanyNoteAttachmentRemove = 'installationCompanyNoteAttachmentRemove',
  installationCompanyAttachmentDelete = 'installationCompanyAttachmentDelete',
  installationCompanyStripeSubscriptionUpdate = 'installationCompanyStripeSubscriptionUpdate',
  installationCompanyCustomerInvitation = 'installationCompanyCustomerInvitation',
  manufacturerDeviceRemove = 'manufacturerDeviceRemove',
  manufacturerProfileUpdate = 'manufacturerProfileUpdate',
  manufacturerTypeOfDeviceRemove = 'manufacturerTypeOfDeviceRemove',
  manufacturerUsersRemove = 'manufacturerUsersRemove',
  manufacturerDeviceAdd = 'manufacturerDeviceAdd',
  rootActivationError = 'rootActivationError',
  rootChangePassword = 'rootChangePassword',
  rootForgottenPassword = 'rootForgottenPassword',
  rootLogout = 'rootLogout',
  rootObjectDoesNotExist = 'rootObjectDoesNotExist',
  rootUnexpectedError = 'rootUnexpectedError',
  rootUserToggleActivation = 'rootUserToggleActivation',
  rootUserToggleDectivation = 'rootUserToggleDectivation',
  rootTicketRemove = 'rootTicketRemove',
  rootTicketClose = 'rootTicketClose',
  rootTicketManufacturerAssign = 'rootTicketManufacturerAssign',
  rootTicketCustomerAssign = 'rootTicketCustomerAssign',
  rootNotImplemented = 'rootNotImplemented',
  rootEventMarkedAsUnread = 'rootEventMarkedAsUnread',
  rootEventMarkedAsRead = 'rootEventMarkedAsRead',
  rootUploadNoFiles = 'rootUploadNoFiles',
  rootTicketCommentRemove = 'rootTicketCommentRemove',
  rootTicketDeleteLastComment = 'rootTicketDeleteLastComment',
  rootTicketUpdate = 'rootTicketUpdate',
  rootAttachmentDeleted = 'rootAttachmentDeleted',
  rootTokenExpired = 'rootTokenExpired',
  rootCheckoutSessionCreate = 'rootCheckoutSessionCreate',
  rootStripeCheckoutSession = 'rootStripeCheckoutSession',
  rootSubscriptionCancel = 'rootSubscriptionCancel',
  rootExportMetrics = 'rootExportMetrics',
  rootTariffRestriction = 'rootTariffRestriction',
  rootAssignTechnician = 'rootAssignTechnician',
}

export enum FormErrorsDto {
  ExistingDevices = 'existingDevices',
  InvalidCredentials = 'invalidCredentials',
  InvalidPassword = 'invalidPassword',
  InvalidRefreshToken = 'invalidRefreshToken',
  ObjectDoesNotExist = 'objectDoesNotExist',
  TariffRestriction = 'tariffRestriction',
  TokenExpired = 'tokenExpired',
  UserDisabled = 'userDisabled',
}
