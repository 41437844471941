import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../environments/environment';

@Injectable()
export class CustomTitleStrategyService extends TitleStrategy {
  private readonly siteName = environment.siteName;

  constructor(private title: Title, private transloco: TranslocoService) {
    super();
  }

  public updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title) {
      this.transloco.selectTranslate(title).subscribe((translatedTitle) => {
        this.title.setTitle(`${translatedTitle} - ${this.siteName}`);
      });
    } else {
      this.title.setTitle(this.siteName);
    }
  }
}
