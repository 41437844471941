import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  InjectionToken,
  Input,
  OnChanges,
  SimpleChanges,
  effect,
  forwardRef,
  inject,
  signal,
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { AbstractInputComponent, CalendarDayOfWeek, CalendarDto, ValidationKey, ValidationMessage } from '../shared';
import { CalendarHasNeighbourPipe, CalendarSelectedMonthPipe, DateToDayPipe } from '../shared/pipes';
import { CalendarUtils } from '../shared/utils';

export interface CalendarTranslations {
  dayoOfWeekShort: Record<CalendarDayOfWeek, string>;
  dayoOfWeekLong: Record<CalendarDayOfWeek, string>;
  previousMonth: string;
  nextMonth: string;
}

export const CALENDAR_TRANSLATIONS = new InjectionToken<CalendarTranslations>('Calendar translations');

@Component({
  selector: 'owt-input-calendar',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DateToDayPipe,
    CalendarSelectedMonthPipe,
    CalendarHasNeighbourPipe,
    TranslocoLocaleModule,
    TranslocoModule,
  ],
  templateUrl: './input-calendar.component.html',
  styleUrls: ['./input-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCalendarComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputCalendarComponent,
      multi: true,
    },
  ],
})
export class InputCalendarComponent extends AbstractInputComponent<string> implements OnChanges {
  @Input() public dayOfWeekOffset: number = 0; // 0 ... sunday, 1 ... monday
  @Input({ transform: (value: string | undefined): Date | undefined => (value ? new Date(value) : undefined) })
  public minDate?: Date;
  @Input({ transform: (value: string | undefined): Date | undefined => (value ? new Date(value) : undefined) })
  public maxDate?: Date;

  public control = new FormControl();
  public monthOffset = signal(0);
  public days: CalendarDto[] = [];
  public daysOfWeek = signal(CalendarUtils.generateDaysOfWeek(this.dayOfWeekOffset));
  public calendarTranslations = inject(CALENDAR_TRANSLATIONS);

  constructor(private cdr: ChangeDetectorRef) {
    super();
    effect(() => {
      this.days = CalendarUtils.generateCalendar(
        this.control.value,
        this.minDate,
        this.maxDate,
        this.monthOffset(),
        this.dayOfWeekOffset,
      );
    });
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['dayOfWeekOffset']) {
      this.daysOfWeek.set(CalendarUtils.generateDaysOfWeek(this.dayOfWeekOffset));
    }
  }

  public override writeValue(value: string): void {
    this.control.setValue(value);
    this.days = this.setDays(value);
    this.cdr.markForCheck();
  }

  private setDays(value?: string, monthOffset: number = 0): CalendarDto[] {
    return CalendarUtils.generateCalendar(value, this.minDate, this.maxDate, monthOffset, this.dayOfWeekOffset);
  }

  public setDay(day: CalendarDto): void {
    this.days.forEach((i) => (i.isSelected = i.date === day.date ? true : undefined));
    this.control.setValue(day.date);
    this.onChange(this.control.value);
    this.onTouched();
  }

  public setMonth(monthOffset: number): void {
    this.monthOffset.update((value) => value + monthOffset);
  }

  public validate(_: FormControl): ValidationErrors | null {
    return _.valid
      ? null
      : { [ValidationKey.InputCalendar]: { valid: false, message: ValidationMessage.InputCalendar } };
  }

  public trackBy(index: number, target: CalendarDto): string {
    return target.date;
  }
}
