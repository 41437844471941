import { AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ToastAnimationState, toastAnimations } from '../../utils/toast-animation';
import { TOAST_CONFIG_TOKEN, ToastConfig, ToastData, defaultToastConfig } from '../../utils/toast-config';
import { ToastRef } from '../../utils/toast-ref';
import { ToastTranslateLoader } from '../../utils/toast-translate-loader';

@Component({
  selector: 'owt-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toastAnimations.fadeToast],
})
export class ToastComponent implements OnInit, OnDestroy {
  public animationState: ToastAnimationState = 'default';
  public iconType: string;
  private intervalId!: any;

  constructor(
    public readonly data: ToastData,
    private readonly ref: ToastRef,
    private cdr: ChangeDetectorRef,
    public translate: ToastTranslateLoader,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig = defaultToastConfig
  ) {
    this.iconType = data.type;
  }

  public ngOnInit(): void {
    this.intervalId = setTimeout(() => {
      this.animationState = 'closing';
      this.cdr.markForCheck();
    }, this.toastConfig.duration);
  }

  public ngOnDestroy(): void {
    clearTimeout(this.intervalId);
  }

  public close(): void {
    this.ref.close();
  }

  public onFadeFinished(event: AnimationEvent): void {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';
    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}
