import { DOCUMENT } from '@angular/common';
import { DestroyRef, Directive, Inject, LOCALE_ID, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Meta } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { AVAILABLE_LANGUAGES, LOCALES } from '../constants/language.constant';
import { Language } from '../dto/language.dto';

@Directive({
  selector: '[owtHtmlLang]',
  standalone: true,
})
export class HtmlLangDirective implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private transloco: TranslocoService,
    private translocoLocale: TranslocoLocaleService,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  public ngOnInit(): void {
    this.transloco.langChanges$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((lang) => {
      const language = lang as Language;
      this.setOgTags(language);
      this.setHtmlLang(language);
      this.setLocale(language);
    });
  }

  private setOgTags(language: Language): void {
    this.meta.removeTag(`content='${LOCALES.UNDERSCORE[language]}'`);
    this.meta.updateTag({ property: 'og:locale', content: LOCALES.UNDERSCORE[language] });
    this.meta.addTags(
      AVAILABLE_LANGUAGES.filter((lang) => lang !== language).map((lang) => ({
        property: 'og:locale:alternate',
        content: LOCALES.UNDERSCORE[lang],
      }))
    );
  }

  private setHtmlLang(language: Language): void {
    if (this?.document?.documentElement) {
      this.document.documentElement.lang = language;
    }
  }

  private setLocale(language: Language): void {
    this.locale = LOCALES.DASH[language];
    this.translocoLocale.setLocale(LOCALES.DASH[language]);
  }
}
