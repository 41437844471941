import { INPUT_SELECT_CONFIG } from '@owt/ui';
import { INPUT_SELECT_I18N } from '../i18n/input-select.i18n';

export const INPUT_SELECT_PROVIDER = {
  provide: INPUT_SELECT_CONFIG,
  useValue: {
    i18n: true,
    ...INPUT_SELECT_I18N,
  },
};
