import { FORM_CONDENSED_ERROR_IGNORE_LIST_TOKEN, FORM_REGULAR_ERROR_IGNORE_LIST_TOKEN } from '@owt/ui';
import { CustomErrorDto } from '../dto/custom-error.dto';

export const FORM_REGULAR_IGNORE_LIST_PROVIDER = {
  provide: FORM_REGULAR_ERROR_IGNORE_LIST_TOKEN,
  useFactory: (): CustomErrorDto[] => ['passwordShouldMatch'],
};

export const FORM_CONDENSED_IGNORE_LIST_PROVIDER = {
  provide: FORM_CONDENSED_ERROR_IGNORE_LIST_TOKEN,
  useFactory: (): CustomErrorDto[] => ['passwordShouldMatch'],
};
