import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable()
export class TableTranslateLoader {
  public loading = 'Loading…';
  public zero = 'No items found';
  public one = 'Displaying 1 item';
  public two = 'Displaying 2 items';
  public three = 'Displaying 3 items';
  public four = 'Displaying 4 items';
  public paginationAriaLabel = 'Pagination';
  public other = (totalCount: number): string => `Displaying ${totalCount} items`;
  public many = (meta: { totalCount: number; currentPage: number; pagesCount: number; pageSize: number }): string =>
    `Displaying ${meta.currentPage * meta.pageSize - meta.pageSize + 1} - ${Math.min(
      meta.currentPage * meta.pageSize,
      meta.totalCount
    )} of ${meta.totalCount} items`;
  public language = signal('en');
  public setLanguage(language: string): void {
    this.language.set(language);
  }
  public language$ = toObservable(this.language);
}
