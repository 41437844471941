import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastService } from '@owt/ui';
import * as Sentry from '@sentry/angular-ivy';
import { ErrorsDto } from '../dto/errors.dto';
import { SuccessDto } from '../dto/success.dto';
import { WarningDto } from '../dto/warning.dto';
import { ERRORS_I18N } from '../i18n/errors.i18n';
import { SUCCESS_I18N } from '../i18n/success.i18n';
import { WARNING_I18N } from '../i18n/warning.i18n';

@Injectable({
  providedIn: 'root',
})
export class CustomToastService {
  constructor(
    private toast: ToastService,
    private transloco: TranslocoService,
  ) {}

  public showError(type: ErrorsDto, error?: any): void {
    this.toast.show({
      type: 'danger',
      text: this.transloco.translate(ERRORS_I18N[type]),
    });
    if (error) throw new Error(error);
  }

  public showWarning(type: WarningDto): void {
    switch (type) {
      case WarningDto.rootUnhandledResponse:
        Sentry.captureMessage(`Unhandled response. Update your graphql request.`, { level: 'warning' });
        break;
      default:
        break;
    }
    this.toast.show({
      type: 'warning',
      text: this.transloco.translate(WARNING_I18N[type]),
    });
  }

  public showSuccess(type: SuccessDto): void {
    this.toast.show({
      type: 'success',
      text: this.transloco.translate(SUCCESS_I18N[type]),
    });
  }
}
