import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { CalendarDayOfWeek, CalendarTranslations } from '@owt/ui';

export const CALENDAR_I18N: CalendarTranslations = {
  dayoOfWeekShort: {
    [CalendarDayOfWeek.Sunday]: _('uni.day-of-week.sunday.short'),
    [CalendarDayOfWeek.Monday]: _('uni.day-of-week.monday.short'),
    [CalendarDayOfWeek.Tuesday]: _('uni.day-of-week.tuesday.short'),
    [CalendarDayOfWeek.Wednesday]: _('uni.day-of-week.wednesday.short'),
    [CalendarDayOfWeek.Thursday]: _('uni.day-of-week.thursday.short'),
    [CalendarDayOfWeek.Friday]: _('uni.day-of-week.friday.short'),
    [CalendarDayOfWeek.Saturday]: _('uni.day-of-week.saturday.short'),
  },
  dayoOfWeekLong: {
    [CalendarDayOfWeek.Sunday]: _('uni.day-of-week.sunday.long'),
    [CalendarDayOfWeek.Monday]: _('uni.day-of-week.monday.long'),
    [CalendarDayOfWeek.Tuesday]: _('uni.day-of-week.tuesday.long'),
    [CalendarDayOfWeek.Wednesday]: _('uni.day-of-week.wednesday.long'),
    [CalendarDayOfWeek.Thursday]: _('uni.day-of-week.thursday.long'),
    [CalendarDayOfWeek.Friday]: _('uni.day-of-week.friday.long'),
    [CalendarDayOfWeek.Saturday]: _('uni.day-of-week.saturday.long'),
  },
  previousMonth: _('uni.previous-month'),
  nextMonth: _('uni.next-month'),
};
