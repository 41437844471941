import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageSwitchLanguage, LanguageSwitchService } from '@owt/ui';
import { MeGQL, UserSetLanguageGQL } from 'projects/portal/src/generated/graphql';
import { Observable, first, map, of, switchMap, tap } from 'rxjs';
import { ErrorsDto } from '../dto/errors.dto';
import { LangUtils } from '../utils/lang.utils';
import { CustomToastService } from './custom-toast.service';

@Injectable({
  providedIn: 'root',
})
export class TranslocoLanguageSwitchService extends LanguageSwitchService {
  constructor(
    private transloco: TranslocoService,
    private userSetLanguage: UserSetLanguageGQL,
    private customToast: CustomToastService,
    private me: MeGQL,
  ) {
    super();
  }

  public override setLanguage(language: LanguageSwitchLanguage): void {
    const setBeLanguage$ = this.userSetLanguage
      .mutate(
        {
          languageCode: LangUtils.getBackendLang(language.code),
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(
        map((res) => res.data?.userSetLanguage?.user),
        tap({
          next: (user) => {
            if (user) {
              // language switch is handled in layout private
            } else {
              this.customToast.showError(ErrorsDto.rootUnexpectedError);
            }
          },
          error: (err) => {
            this.customToast.showError(ErrorsDto.rootUnexpectedError);
            throw new Error(err);
          },
        }),
      );

    this.me
      .fetch(undefined, { fetchPolicy: 'cache-first' })
      .pipe(
        first(),
        map((res) => res.data.me),
        switchMap((me) => {
          if (me) {
            return setBeLanguage$;
          }
          this.transloco.setActiveLang(language.code);
          return of(null);
        }),
      )
      .subscribe();
  }

  public override get currentLang$(): Observable<string> {
    return this.transloco.langChanges$;
  }
}
