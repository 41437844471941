import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TableTranslateLoader } from '@owt/ui';
import { PaginationMetaFragment } from 'projects/portal/src/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class TableLoader extends TableTranslateLoader {
  constructor(private transloco: TranslocoService) {
    super();
    this.transloco.selectTranslation().subscribe(() => {
      this.loading = this.transloco.translate('uni.loading');
      this.zero = this.transloco.translate('table-footer.info.zero');
      this.one = this.transloco.translate('table-footer.info.one');
      this.two = this.transloco.translate('table-footer.info.two');
      this.three = this.transloco.translate('table-footer.info.three');
      this.four = this.transloco.translate('table-footer.info.four');
      this.other = (totalCount: number): string => this.transloco.translate('table-footer.info.other', { totalCount });
      this.many = (meta: PaginationMetaFragment): string =>
        this.transloco.translate('table-footer.info.many', {
          currentPage: meta.currentPage * meta.pageSize - meta.pageSize + 1,
          pagesCount: Math.min(meta.currentPage * meta.pageSize, meta.totalCount),
          totalCount: meta.totalCount,
        });
      this.paginationAriaLabel = this.transloco.translate('table-footer.pagination.aria-label');
      this.setLanguage(this.transloco.getActiveLang());
    });
  }
}
