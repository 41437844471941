import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InputFileAttachment } from '../../input-file';

@Injectable({
  providedIn: 'root',
})
export abstract class InputFileUploadService<T> {
  public abstract upload(files: File[]): Observable<T[]>;

  public abstract handleUnexpected(): void;

  public abstract handleEmpty(): void;

  public abstract handleTooLarge(corruptedFiles: string[]): void;

  public abstract handleInvalidFormat(corruptedFiles: string[]): void;

  public abstract handleSuccess(files: T[], idList: Map<string, InputFileAttachment>): void;
}
