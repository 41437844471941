import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache, defaultDataIdFromObject } from '@apollo/client/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { ApolloUtils } from './shared/utils/apollo.utils';
import { AuthUtils } from './shared/utils/auth.utils';

/**
 * @description all models that do not have "id" should be here
 */
const dataIdFromObject = (object: any): string | undefined => {
  switch (object.__typename) {
    default:
      return defaultDataIdFromObject(object);
  }
};

function createApollo(httpLink: HttpLink, transloco: TranslocoService): ApolloClientOptions<any> {
  return {
    link: ApolloUtils.tokenRefreshLink
      .concat(ApolloUtils.authLink(transloco))
      .concat(ApolloUtils.sentryLink)
      .concat(ApolloUtils.retryLink)
      .concat(ApolloUtils.errorLink)
      .concat(
        httpLink.create({
          uri: environment.graphql.uri,
          withCredentials: true,
          headers: new HttpHeaders({
            Authorization: AuthUtils.basicAuthHeader,
          }),
        })
      ),
    cache: new InMemoryCache({
      dataIdFromObject: dataIdFromObject,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  };
}

@NgModule({
  imports: [TranslocoModule],
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, TranslocoService],
    },
  ],
})
export class GraphQLModule {}
