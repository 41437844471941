<div class="flex items-center">
  <h2 class="flex-auto text-sm font-semibold text-gray-900">
    {{ days | calendarSelectedMonth | translocoDate: { year: 'numeric', month: 'long' } }}
  </h2>
  <button
    type="button"
    class="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:not:disabled:text-gray-500"
    [disabled]="!(days | calendarHasNeighbour: { minDate, maxDate, type: 'prev' })"
    (click)="setMonth(-1)"
  >
    <span class="sr-only">{{ calendarTranslations.previousMonth | transloco }}</span>
    <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path
        fill-rule="evenodd"
        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
  <button
    type="button"
    class="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:not:disabled:text-gray-500"
    [disabled]="!(days | calendarHasNeighbour: { minDate, maxDate, type: 'next' })"
    (click)="setMonth(1)"
  >
    <span class="sr-only">{{ calendarTranslations.nextMonth | transloco }}</span>
    <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path
        fill-rule="evenodd"
        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
</div>
<div class="grid grid-cols-7 mt-4 text-xs leading-6 text-center text-gray-500">
  <div *ngFor="let dayOfWeek of daysOfWeek()">{{ calendarTranslations.dayoOfWeekShort[dayOfWeek] | transloco }}</div>
</div>
<div class="grid grid-cols-7 mt-2 text-sm">
  <div *ngFor="let day of days; trackBy: trackBy" class="p-1">
    <button
      type="button"
      class="flex items-center justify-center mx-auto rounded-full w-7 sm:w-8 h-7 sm:h-8"
      [ngClass]="{
        'text-white': day.isSelected,
        'text-primary-600': !day.isSelected && day.isToday,
        'text-gray-900': !day.isSelected && !day.isToday && day.isCurrentMonth,
        'text-gray-400': !day.isSelected && !day.isToday && !day.isCurrentMonth,
        'bg-primary-600': day.isSelected && day.isToday,
        'bg-gray-900': day.isSelected && !day.isToday,
        'hover:bg-gray-200': !day.isSelected,
        'font-semibold': day.isSelected || day.isToday,
        '!text-gray-400 pointer-events-none': day?.isAboveMaxDate || day?.isBelowMinDate
      }"
      (click)="setDay(day)"
    >
      <time [attr.datetime]="day.date">{{ day.date | dateToDay }}</time>
    </button>
  </div>
</div>
