import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  LANGUAGE_SWITCH_CONFIG_TOKEN,
  LanguageSwitchConfig,
  LanguageSwitchLanguage,
  defaultLanguageSwitchConfig,
} from '../utils/language-switch-config';

@Injectable({
  providedIn: 'root',
})
export class LanguageSwitchService {
  constructor(
    @Inject(LANGUAGE_SWITCH_CONFIG_TOKEN)
    public config: LanguageSwitchConfig = defaultLanguageSwitchConfig
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setLanguage(language: LanguageSwitchLanguage): void {
    throw new Error('setLanguage should be overriden.');
  }

  public get currentLang$(): Observable<string> {
    throw new Error('currentLang$ should be overriden.');
  }

  public setNextLanguage(currentLang: LanguageSwitchLanguage): void {
    this.setLanguage(this.getNextLang(currentLang));
  }

  private getNextLang(currentLang: LanguageSwitchLanguage): LanguageSwitchLanguage {
    const newIndex = this.getNextIndex(currentLang);
    return this.config.languages[newIndex];
  }

  private getNextIndex(currentLang: LanguageSwitchLanguage): number {
    const currentIndex = this.config.languages.findIndex((l) => l.code === currentLang.code);

    let newIndex = currentIndex;
    if (currentIndex < this.config.languages.length - 1) {
      newIndex++;
    } else {
      newIndex = 0;
    }

    return newIndex;
  }
}
