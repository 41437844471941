import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class OnDemandPreloadOptions {
  constructor(public routePath: string) {}
}

@Injectable({
  providedIn: 'root',
})
export class OnDemandPreloadService {
  private subject = new Subject<OnDemandPreloadOptions>();
  public state = this.subject.asObservable();

  public startPreload(routePath: string): void {
    const message = new OnDemandPreloadOptions(routePath);
    this.subject.next(message);
  }
}
