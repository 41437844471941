import { inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { FormCondensed } from '@owt/ui';
import { Observable, map } from 'rxjs';

export const formErrorsAlertFactory = (): Observable<FormCondensed.ErrorAllertMessages> => {
  const transloco = inject(TranslocoService);

  return transloco.selectTranslation().pipe(
    map(() => {
      return {
        one: (count) => transloco.translate(_('form-error-alert.one'), { count }),
        two: (count) => transloco.translate(_('form-error-alert.two'), { count }),
        three: (count) => transloco.translate(_('form-error-alert.three'), { count }),
        four: (count) => transloco.translate(_('form-error-alert.four'), { count }),
        other: (count) => transloco.translate(_('form-error-alert.other'), { count }),
      };
    }),
  );
};
