import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownComponent, DropdownContentComponent } from './components';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { DropdownItemDirective } from './directives';

@NgModule({
  declarations: [DropdownContentComponent, DropdownComponent, DropdownMenuComponent, DropdownItemDirective],
  imports: [CommonModule, OverlayModule],
  exports: [DropdownContentComponent, DropdownComponent, DropdownMenuComponent, DropdownItemDirective],
})
export class DropdownModule {}
