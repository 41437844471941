import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, InjectionToken, Input, forwardRef, inject } from '@angular/core';
import {
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { DropdownModule, DropdownPosition } from '../../dropdown';
import { IconComponent } from '../../icon/components/icon.component';
import { InputCalendarComponent } from '../input-calendar';
import { AbstractInputComponent, ValidationKey, ValidationMessage } from '../shared';
import { CalendarUtils } from '../shared/utils';

export interface InputDateConfig {
  placeholderText: string;
  clearBtnText: string;
  closeBtnText: string;
  todayBtnText: string;
}

export const INPUT_DATE_CONFIG = new InjectionToken<InputDateConfig>('Input date config');

@Component({
  selector: 'owt-input-date',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IconComponent,
    DropdownModule,
    InputCalendarComponent,
    TranslocoLocaleModule,
    TranslocoModule,
  ],
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputDateComponent,
      multi: true,
    },
  ],
})
export class InputDateComponent extends AbstractInputComponent<string> {
  @Input() public dayOfWeekOffset: number = 0; // 0 ... sunday, 1 ... monday
  @Input() public minDate?: string;
  @Input() public maxDate?: string;
  @Input() public buttonClasses = 'flex items-center gap-1 btn btn-base btn-secondary';
  @Input() public positions: DropdownPosition[] = ['topRight', 'bottomRight', 'topLeft', 'bottomLeft'];
  @Input() public todayAsPlaceholder = false;

  public readonly CalendarUtils = CalendarUtils;

  public control = new FormControl();

  public config: InputDateConfig = inject(INPUT_DATE_CONFIG);

  public override writeValue(value: string): void {
    this.control.setValue(value);
  }

  public validate(_: FormControl): ValidationErrors | null {
    return _.valid ? null : { [ValidationKey.InputDate]: { valid: false, message: ValidationMessage.InputDate } };
  }

  public handleChange(value: string): void {
    this.onChange(value);
    this.onTouched();
  }

  public clear(): void {
    this.control.setValue(null);
  }

  public setToday(): void {
    this.control.setValue(CalendarUtils.today());
  }
}
