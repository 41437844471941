import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoogleMapsLoader } from 'projects/ui/src/lib/google-maps/google-maps.loader';
import { catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService extends GoogleMapsLoader {
  constructor(private http: HttpClient) {
    super();
    this.loadGoogleMaps();
  }

  private loadGoogleMaps(): void {
    // see: https://github.com/angular/components/tree/main/src/google-maps#readme
    this.http
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyC-HNfCpdkX4eIY7ieD4ypiVVsXgJ6QYCo&libraries=places',
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      )
      .subscribe({
        next: (res) => this.apiLoaded.set(res),
      });
  }
}
