export enum SuccessDto {
  adminManufacturerAdd = 'adminManufacturerAdd',
  adminUsersAdd = 'adminUsersAdd',
  adminUsersEdit = 'adminUsersEdit',
  adminManufacturerUpdate = 'adminManufacturerUpdate',
  customerDeviceRegister = 'customerDeviceRegister',
  customerDeviceUnregister = 'customerDeviceUnregister',
  customerProfileUpdate = 'customerProfileUpdate',
  customerRegister = 'customerRegister',
  customerSiteAdd = 'customerSiteAdd',
  customerSiteEdit = 'customerSiteEdit',
  customerSiteRemove = 'customerSiteRemove',
  customerUsersAdd = 'customerUsersAdd',
  customerUsersEdit = 'customerUsersEdit',
  customerDeviceMove = 'customerDeviceMove',
  customerCustomerTicketCreate = 'customerCustomerTicketCreate',
  customerCustomerSiteSetDefault = 'customerCustomerSiteSetDefault',
  customerStripeSubscriptionUpdate = 'customerStripeSubscriptionUpdate',
  installationCompanyCustomerUpdate = 'installationCompanyCustomerUpdate',
  installationCompanyCustomerDelete = 'installationCompanyCustomerDelete',
  installationCompanyDeviceNewExisting = 'installationCompanyDeviceNewExisting',
  installationCompanyDeviceNewNonExisting = 'installationCompanyDeviceNewNonExisting',
  installationCompanyProfileUpdate = 'installationCompanyProfileUpdate',
  installationCompanyRegister = 'installationCompanyRegister',
  installationCompanyUsersAdd = 'installationCompanyUsersAdd',
  installationUsersEdit = 'installationUsersEdit',
  installationCompanyCustomerSiteDelete = 'installationCompanyCustomerSiteDelete',
  installationCompanyCustomerSiteUpdate = 'installationCompanyCustomerSiteUpdate',
  installationCompanyDeviceUnregister = 'installationCompanyDeviceUnregister',
  installationCompanyNoteCreate = 'installationCompanyNoteCreate',
  installationCompanyNoteRemove = 'installationCompanyNoteRemove',
  installationCompanyNoteEdit = 'installationCompanyNoteEdit',
  installationCompanyNoteAttachmentRemove = 'installationCompanyNoteAttachmentRemove',
  installationCompanyAttachmentCreate = 'installationCompanyAttachmentCreate',
  installationCompanyAttachmentEdit = 'installationCompanyAttachmentEdit',
  installationCompanyAttachmentDelete = 'installationCompanyAttachmentDelete',
  installationCompanyStripeSubscriptionUpdate = 'installationCompanyStripeSubscriptionUpdate',
  installationCompanyCustomerInvitation = 'installationCompanyCustomerInvitation',
  installationCompanyConfigurationUpdated = 'installationCompanyConfigurationUpdated',
  installationCompanyInstructionAdded = 'installationCompanyInstructionAdded',
  manufacturerDeviceAdd = 'manufacturerDeviceAdd',
  manufacturerDeviceEdit = 'manufacturerDeviceEdit',
  manufacturerDeviceRemove = 'manufacturerDeviceRemove',
  manufacturerProfileUpdate = 'manufacturerProfileUpdate',
  manufacturerRegister = 'manufacturerRegister',
  manufacturerTypeOfDeviceAdd = 'manufacturerTypeOfDeviceAdd',
  manufacturerTypeOfDeviceEdit = 'manufacturerTypeOfDeviceEdit',
  manufacturerTypeOfDeviceEventTypeAdd = 'manufacturerTypeOfDeviceEventTypeAdd',
  manufacturerTypeOfDeviceEventTypeEdit = 'manufacturerTypeOfDeviceEventTypeEdit',
  manufacturerTypeOfDeviceEventTypeRemove = 'manufacturerTypeOfDeviceEventTypeRemove',
  manufacturerTypeOfDeviceRemove = 'manufacturerTypeOfDeviceRemove',
  manufacturerUsersAdd = 'manufacturerUsersAdd',
  manufacturerUsersEdit = 'manufacturerUsersEdit',
  manufacturerManufacturerTicketCreate = 'manufacturerManufacturerTicketCreate',
  manufacturerTicketUpdate = 'manufacturerTicketUpdate',
  rootActivated = 'rootActivated',
  rootForgottenPassword = 'rootForgottenPassword',
  rootLoggedOut = 'rootLoggedOut',
  rootPasswordChanged = 'rootPasswordChanged',
  rootUserActivated = 'rootUserActivated',
  rootUserDeactivated = 'rootUserDeactivated',
  rootTicketRemove = 'rootTicketRemove',
  rootTicketClose = 'rootTicketClose',
  rootTicketManufacturerAssign = 'rootTicketManufacturerAssign',
  rootTicketCustomerAssign = 'rootTicketCustomerAssign',
  rootAdminUserUpdate = 'rootAdminUserUpdate',
  rootEventMarkedAsUnread = 'rootEventMarkedAsUnread',
  rootEventMarkedAsRead = 'rootEventMarkedAsRead',
  rootTicketCommentRemove = 'rootTicketCommentRemove',
  rootAttachmentDeleted = 'rootAttachmentDeleted',
  rootTicketUpdate = 'rootTicketUpdate',
  rootSubscriptionCancel = 'rootSubscriptionCancel',
  rootRegister = 'rootRegister',
  rootAssignTechnician = 'rootAssignTechnician',
}
