import { CalendarDayOfWeek, CalendarDto } from '../dto';

export class CalendarUtils {
  public static generateCalendar(
    inputDate?: string,
    minDate?: Date,
    maxDate?: Date,
    monthOffset: number = 0,
    dayOfWeekOffset: number = 0,
  ): CalendarDto[] {
    const days: CalendarDto[] = [];

    const startDate = inputDate ? new Date(inputDate) : new Date();
    startDate.setMonth(startDate.getMonth() + monthOffset);

    const firstDayOfMonth = new Date(startDate);
    firstDayOfMonth.setDate(1);

    const firstDayOfCalendar = new Date(firstDayOfMonth);
    firstDayOfCalendar.setDate(firstDayOfMonth.getDate() - ((firstDayOfMonth.getDay() + 7 - dayOfWeekOffset) % 7));

    for (let i = 0; i < 42; i++) {
      const currentDate = new Date(firstDayOfCalendar);
      currentDate.setDate(currentDate.getDate() + i);
      const dateString = currentDate.toISOString().slice(0, 10);

      const isCurrentMonth = currentDate.getMonth() === startDate.getMonth();
      const isSelected = inputDate ? dateString === inputDate : false;
      const isToday = this.isToday(dateString);
      const isBelowMinDate =
        minDate && currentDate.getTime() < minDate.getTime() && dateString !== minDate.toISOString().slice(0, 10);

      const isAboveMaxDate =
        maxDate && currentDate.getTime() > maxDate.getTime() && dateString !== maxDate.toISOString().slice(0, 10);

      days.push({
        date: dateString,
        isCurrentMonth,
        isSelected,
        isToday,
        isBelowMinDate,
        isAboveMaxDate,
      });
    }

    return days;
  }

  public static isToday(dateString: string): boolean {
    return dateString === new Date().toISOString().slice(0, 10);
  }

  public static generateDaysOfWeek(offset: number): CalendarDayOfWeek[] {
    const startingDayIndex = offset % 7;
    const daysOfWeek = Object.values(CalendarDayOfWeek);
    const generatedDays = [];

    for (let i = 0; i < 7; i++) {
      const dayIndex = (startingDayIndex + i) % 7;
      generatedDays.push(daysOfWeek[dayIndex]);
    }

    return generatedDays;
  }

  public static calculateMonthOffset(inputDate: string): number {
    if (!inputDate) {
      return 0;
    }

    const currentDate = new Date();
    const targetDate = new Date(inputDate);

    const currentYear = currentDate.getFullYear();
    const targetYear = targetDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const targetMonth = targetDate.getMonth();

    const yearDiff = targetYear - currentYear;
    const monthDiff = targetMonth - currentMonth;

    const monthOffset = yearDiff * 12 + monthDiff;

    return monthOffset;
  }

  public static today(): string {
    const today = new Date();
    return today.toISOString().slice(0, 10);
  }
}
