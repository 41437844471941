import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'projects/portal/src/environments/environment';
import { UserTypeChoices } from 'projects/portal/src/generated/graphql';
import { LOCAL_STORAGE } from '../constants/local-storage.constant';
import { ROUTE_DEFINITION } from '../constants/route-definition.constant';
import { StorageUtils } from './storage.utils';

type JwtToken = {
  aud: string;
  email: string;
  exp: number;
  iss: string;
  origIat: number;
};

type RefreshResponse = {
  data: {
    tokenRefresh: {
      accessToken?: string;
      ok?: boolean;
    };
  };
  errors: Error[];
};

export type CustomHandleResponse = { access_token: string } | void;
export type CustomHandleResponseFn = (response: RefreshResponse) => CustomHandleResponse;

const REVOKE_TOKEN_OFFSET = 30;

export class AuthUtils {
  public static basicAuthHeader = environment.graphql.credentials
    ? `Basic ${btoa(environment.graphql.credentials)}`
    : '';

  public static authHeader(accessToken: string | null): string {
    return accessToken ? `Bearer ${accessToken}` : '';
  }

  public static decodeToken(accessTokenCurrent: string): JwtToken | null {
    try {
      return jwtDecode(accessTokenCurrent);
    } catch (e) {
      console.error('Error jwtDecode', e);
      return null;
    }
  }

  public static handleResponse = (): CustomHandleResponseFn => {
    return (response: RefreshResponse): CustomHandleResponse => {
      if (response.data.tokenRefresh?.accessToken) {
        return { access_token: response.data.tokenRefresh.accessToken };
      } else {
        return;
      }
    };
  };

  public static async logout(): Promise<void> {
    await fetch(environment.graphql.uri, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthUtils.basicAuthHeader,
      },
      body: JSON.stringify({
        operationName: 'TokenRevoke',
        query: 'mutation TokenRevoke { tokenRevoke { ok }}',
      }),
    });
  }

  public static async getRefreshedToken(): Promise<Response> {
    const resp = await fetch(environment.graphql.uri, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthUtils.basicAuthHeader,
      },
      body: JSON.stringify({
        operationName: 'TokenRefresh',
        query:
          'mutation TokenRefresh { tokenRefresh { ... on TokenResponseSuccess { accessToken } ... on InvalidRefreshToken { ok } }}',
      }),
    });
    return resp.json();
  }

  public static setAccessToken(token: string): void {
    // console.log('TOKEN_REFRESH', token);
    StorageUtils.setItem(LOCAL_STORAGE.ACCESS_TOKEN, token);
  }

  public static getNowTimestamp(): number {
    return Math.floor(new Date().getTime() / 1000);
  }

  public static isStorageTokenValidOrUndefined(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const accessToken = StorageUtils.getItem(LOCAL_STORAGE.ACCESS_TOKEN);

      const isAccessToken = accessToken && typeof accessToken === 'string';
      let tokenValidOrUndefined: boolean;
      // If accessToken exists
      if (isAccessToken) {
        const decodedToken = AuthUtils.decodeToken(accessToken);
        // Check expiration of accessToken - nowTimestamp is timestamp from current date
        const nowTimestamp: number = AuthUtils.getNowTimestamp();
        if (decodedToken?.exp) {
          tokenValidOrUndefined = decodedToken.exp - nowTimestamp > REVOKE_TOKEN_OFFSET;
        } else {
          // Token is malformed
          tokenValidOrUndefined = false;
        }
      } else {
        // Token does not exist
        tokenValidOrUndefined = false;
      }
      // Log. To remove
      if (!tokenValidOrUndefined) {
        console.warn('Token expired or malformed');
      }
      resolve(tokenValidOrUndefined);
    });
  }

  public static redirect(userType: UserTypeChoices, router: Router): void {
    switch (userType) {
      case UserTypeChoices.Customer:
        router.navigate(['/', ROUTE_DEFINITION.APP.CUSTOMER]);
        break;
      case UserTypeChoices.InstallationCompany:
        router.navigate(['/', ROUTE_DEFINITION.APP.INSTALLATION_COMPANY]);
        break;
      case UserTypeChoices.Manufacturer:
        router.navigate(['/', ROUTE_DEFINITION.APP.MANUFACTURER]);
        break;
      case UserTypeChoices.SystemAdmin:
        router.navigate(['/', ROUTE_DEFINITION.APP.ADMIN]);
        break;
      default:
        break;
    }
  }

  public static processToken(accessToken: string, userType: UserTypeChoices, router: Router, apollo: Apollo): void {
    AuthUtils.setAccessToken(accessToken);
    apollo.client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'me',
    });
    AuthUtils.redirect(userType, router);
  }
}
