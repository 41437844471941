export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'owt-access-token',
  LOGIN_STATUS: 'owt-login-status',
  FVE_CHART_TYPE: 'owt-fve-chart-type',
  FVE_CHART_LINE_FILTERS: 'owt-fve-chart-line-filters',
  FVE_CHART_BAR_FILTERS: 'owt-fve-chart-bar-filters',
  HEAT_PUMP_CHART_TYPE: 'owt-heat-chart-pump-type',
  HEAT_PUMP_CHART_LINE_FILTERS: 'owt-heat-pump-chart-line-filters',
  HEAT_PUMP_CHART_BAR_FILTERS: 'owt-heat-pump-chart-bar-filters',
};
