import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { environment } from 'projects/portal/src/environments/environment';
import { VERSION } from 'projects/portal/src/environments/version';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  private pathI18n(lang: string): string {
    if (lang.length) {
      return environment.translations.remote
        ? `${environment.translations.uri}${
            environment.name === 'prod' ? 'production' : environment.name
          }/front/${lang}/translations.json`
        : `assets/i18n/${lang}.json`;
    } else {
      // This is for ngx-reveal-translations
      return `assets/i18n/empty.json`;
    }
  }

  public getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`${this.pathI18n(lang)}?v=${VERSION.version}`, {
      headers: { 'Cache-Control': 'no-cache' },
    });
  }
}
