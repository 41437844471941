import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { ErrorsDto } from '../dto/errors.dto';

export const ERRORS_I18N: Record<ErrorsDto, string> = {
  [ErrorsDto.customerDeviceUnregister]: _('notification.error.customer.device-unregister'),
  [ErrorsDto.customerSiteRemove]: _('notification.error.customer.site-remove'),
  [ErrorsDto.customerSiteDelete]: _('notification.error.customer.customer-site-delete'),
  [ErrorsDto.customerCustomerSiteSetDefault]: _('notification.error.customer.customer-site-set-default'),
  [ErrorsDto.customerStripeSubscriptionUpdate]: _('notification.error.customer.stripe-subscription-update'),
  [ErrorsDto.customerSetTemperature]: _('notification.error.customer.set-temperature'),
  [ErrorsDto.installationCompanyCustomerDelete]: _('notification.error.installation-company.customer-delete'),
  [ErrorsDto.installationCompanyNoteRemove]: _('notification.error.installation-company.note-remove'),
  [ErrorsDto.installationCompanyNoteAttachmentRemove]: _(
    'notification.error.installation-company.note-attachment-removed',
  ),
  [ErrorsDto.installationCompanyAttachmentDelete]: _('notification.error.installation-company.attachment-delete'),
  [ErrorsDto.installationCompanyCustomerSiteDelete]: _('notification.error.installation-company.customer-site-delete'),
  [ErrorsDto.installationCompanyStripeSubscriptionUpdate]: _(
    'notification.error.installation-company.stripe-subscription-update',
  ),
  [ErrorsDto.installationCompanyCustomerInvitation]: _('notification.error.installation-company.customer-invitation'),
  [ErrorsDto.manufacturerDeviceRemove]: _('notification.error.manufacturer.device-remove'),
  [ErrorsDto.manufacturerProfileUpdate]: _('notification.error.manufacturer.profile-update'),
  [ErrorsDto.manufacturerTypeOfDeviceRemove]: _('notification.error.manufacturer.type-of-device-remove'),
  [ErrorsDto.manufacturerUsersRemove]: _('notification.error.manufacturer.user-remove'),
  [ErrorsDto.manufacturerDeviceAdd]: _('notification.error.manufacturer.device-add'),
  [ErrorsDto.rootActivationError]: _('notification.error.root.activation-error'),
  [ErrorsDto.rootChangePassword]: _('notification.error.root.change-password'),
  [ErrorsDto.rootForgottenPassword]: _('notification.error.root.forgotten-password'),
  [ErrorsDto.rootLogout]: _('notification.error.root.logout'),
  [ErrorsDto.rootObjectDoesNotExist]: _('notification.error.root.object-does-not-exist'),
  [ErrorsDto.rootUnexpectedError]: _('notification.error.root.unexpected-exception'),
  [ErrorsDto.rootUserToggleActivation]: _('notification.error.root.root-user-toggle-activation'),
  [ErrorsDto.rootUserToggleDectivation]: _('notification.error.root.root-user-toggle-deactivation'),
  [ErrorsDto.rootTicketRemove]: _('notification.error.root.ticket-remove'),
  [ErrorsDto.rootTicketClose]: _('notification.error.root.ticket-close'),
  [ErrorsDto.rootTicketManufacturerAssign]: _('notification.error.root.ticket-manufacturer-assign'),
  [ErrorsDto.rootTicketCustomerAssign]: _('notification.error.root.ticket-customer-assign'),
  [ErrorsDto.rootNotImplemented]: _('notification.error.root.not-implemented'),
  [ErrorsDto.rootEventMarkedAsUnread]: _('notification.error.root.event-marked-as-unread'),
  [ErrorsDto.rootEventMarkedAsRead]: _('notification.error.root.event-marked-as-read'),
  [ErrorsDto.rootUploadNoFiles]: _('notification.error.root.upload-no-files'),
  [ErrorsDto.rootTokenExpired]: _('notification.error.root.token-expired'),
  [ErrorsDto.rootTicketCommentRemove]: _('notification.error.root.ticket-comment-remove'),
  [ErrorsDto.rootTicketDeleteLastComment]: _('notification.error.root.ticket-last-comment-disabled'),
  [ErrorsDto.rootTicketUpdate]: _('notification.error.root.ticket-update'),
  [ErrorsDto.rootAttachmentDeleted]: _('notification.error.root.attachment-deleted'),
  [ErrorsDto.rootCheckoutSessionCreate]: _('notification.error.root.checkout-session-create'),
  [ErrorsDto.rootStripeCheckoutSession]: _('notification.error.root.stripe-checkout-session'),
  [ErrorsDto.rootSubscriptionCancel]: _('notification.error.root.subscription-cancel'),
  [ErrorsDto.rootExportMetrics]: _('notification.error.root.export-metrics'),
  [ErrorsDto.rootTariffRestriction]: _('notification.error.root.tariff-restriction'),
  [ErrorsDto.rootAssignTechnician]: _('notification.error.root.assign-technician'),
};

export const CUSTOM_FORM_OVERRIDE_ERRORS_I18N = {
  emailAlreadyInUse: _('validation.overrides.email-already-in-use'),
  customerEmailAlreadyInUse: _('validation.overrides.customer-email-already-in-use'),
  serialNumberAlreadyExists: _('validation.overrides.serial-number-already-exists'),
};
