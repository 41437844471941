import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  forwardRef,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { Subject, combineLatest, debounceTime, fromEvent, merge, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { ButtonIconDirective } from '../../icon/directives/button-icon.directive';
import { OptionComponent } from '../../option';
import { AbstractInputComponent, ValidationKey, ValidationMessage } from '../shared';

export type SelectValue<T> = T | T[] | null;

export interface InputSelectConfig {
  nothingSelectedText: string;
  searchPlaceholderText: string;
  clearText: string;
  i18n: boolean;
}

export const INPUT_SELECT_CONFIG = new InjectionToken<InputSelectConfig>('Input select config');

@Component({
  selector: 'owt-input-select',
  standalone: true,
  imports: [CommonModule, OverlayModule, TranslocoModule, ButtonIconDirective],
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputSelectComponent,
      multi: true,
    },
  ],
})
export class InputSelectComponent<T, V>
  extends AbstractInputComponent<SelectValue<T>>
  implements OnDestroy, OnChanges, AfterContentInit, AfterViewInit
{
  public optionMap = new Map<T | null, OptionComponent<T, V>>();
  private unsubscribe$ = new Subject<void>();
  private listKeyManager!: ActiveDescendantKeyManager<OptionComponent<T, V>>;
  private selectionModel = new SelectionModel<T>(coerceBooleanProperty(false));

  public positionPairs: ConnectionPositionPair[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ];

  public config: InputSelectConfig = inject(INPUT_SELECT_CONFIG);

  @Output()
  public readonly opened = new EventEmitter<void>();

  @Output()
  public readonly selectionChanged = new EventEmitter<SelectValue<T>>();

  @Output()
  public readonly closed = new EventEmitter<void>();

  @Output()
  public readonly searchChanged = new EventEmitter<string>();

  @ContentChildren(OptionComponent, { descendants: true }) public options!: QueryList<OptionComponent<T, V>>;

  @ContentChild('selectedRef') public selectedTpl?: TemplateRef<{
    value: SelectValue<T>;
    optionMap: Map<T | null, OptionComponent<T, V>>;
    nothingSelectedText: string;
  }>;

  @ViewChild('select', { read: ElementRef }) public selectEl!: ElementRef;
  @ViewChild('input') public searchInputEl!: ElementRef<HTMLInputElement>;

  @HostBinding('class.select-panel-open')
  public isOpen = false;

  @Input()
  public searchable = false;

  @Input() public multiple = false;

  @Input() public clearable = true;

  @Input() public nothingSelectedText = this.config.nothingSelectedText;

  @Input() public searchPlaceholderText = this.config.searchPlaceholderText;

  @Input()
  public displayWith: ((value: T) => string | number) | null = null;

  @Input()
  public compareWith: (v1: T | null, v2: T | null) => boolean = (v1, v2) => v1 === v2;

  @Input()
  public set value(value: SelectValue<T>) {
    this.setupValue(value);
    this.onChange(this.value);
    this.highlightSelectedOptions();
  }
  public get value(): SelectValue<T> {
    if (this.selectionModel.isEmpty()) {
      return null;
    }
    if (this.selectionModel.isMultipleSelection()) {
      return this.selectionModel.selected;
    }
    return this.selectionModel.selected[0];
  }

  @HostListener('blur')
  public markAsTouched(): void {
    if (!this.disabled && !this.isOpen) {
      this.onTouched();
      this.cdr.markForCheck();
    }
  }

  @HostListener('click')
  public open(): void {
    if (this.disabled) return;
    this.isOpen = true;
    if (this.searchable) {
      setTimeout(() => {
        this.searchInputEl.nativeElement.focus();
      }, 0);
    }
    this.cdr.markForCheck();
  }

  public close(): void {
    this.isOpen = false;
    this.onTouched();
    this.elementRef.nativeElement.focus();
    this.cdr.markForCheck();
  }

  @HostListener('keydown', ['$event'])
  protected onKeyDown(e: KeyboardEvent): void {
    if (e.key === 'ArrowDown' && !this.isOpen) {
      this.open();
      return;
    }
    if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && this.isOpen) {
      this.listKeyManager.onKeydown(e);
      return;
    }
    if (e.key === 'Enter' && this.isOpen && this.listKeyManager.activeItem) {
      this.handleSelection(this.listKeyManager.activeItem);
    }
  }

  protected get displayValue(): string | number | SelectValue<T> | (string | number)[] {
    if (this.displayWith && this.value) {
      if (Array.isArray(this.value)) {
        return this.value.map(this.displayWith);
      }
      return this.displayWith(this.value);
    }
    return this.value;
  }

  private destroyRef = inject(DestroyRef);

  public width = signal(0);

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    const divElement: HTMLElement = this.selectEl.nativeElement;
    this.width.set(divElement.offsetWidth);

    combineLatest([fromEvent(window, 'resize'), fromEvent(window, 'orientationchange')])
      .pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.width.set(divElement.offsetWidth);
      });
  }

  public writeValue(value: SelectValue<T>): void {
    this.setupValue(value);
    this.highlightSelectedOptions();
    this.cdr.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['compareWith']) {
      this.selectionModel.compareWith = changes['compareWith'].currentValue;
      this.highlightSelectedOptions();
    }
    if (changes['multiple']) {
      this.selectionModel = new SelectionModel<T>(coerceBooleanProperty(this.multiple));
    }
  }

  public ngAfterContentInit(): void {
    this.listKeyManager = new ActiveDescendantKeyManager(this.options).withWrap();
    this.listKeyManager.change.pipe(takeUntil(this.unsubscribe$)).subscribe((itemIndex) => {
      this.options.get(itemIndex)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    });
    this.selectionModel.changed.pipe(takeUntil(this.unsubscribe$)).subscribe((values) => {
      values.removed.forEach((rv) => this.optionMap.get(rv)?.deselect());
      values.added.forEach((av) => this.optionMap.get(av)?.highlightAsSelected());
    });
    this.options.changes
      .pipe(
        startWith<QueryList<OptionComponent<T, V>>>(this.options),
        tap(() => this.refreshOptionsMap()),
        tap(() => queueMicrotask(() => this.highlightSelectedOptions())),
        switchMap((options) => merge(...options.map((o) => o.selected))),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((selectedOption) => this.handleSelection(selectedOption));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public clearSelection(e?: Event): void {
    e?.stopPropagation();
    if (this.disabled) return;
    this.selectionModel.clear();
    this.selectionChanged.emit(this.value);
    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  protected onHandleInput(e: Event): void {
    this.searchChanged.emit((e.target as HTMLInputElement).value);
  }

  private setupValue(value: SelectValue<T>): void {
    this.selectionModel.clear();
    if (value) {
      if (Array.isArray(value)) {
        this.selectionModel.select(...value);
      } else {
        this.selectionModel.select(value);
      }
    }
  }

  private handleSelection(option: OptionComponent<T, V>): void {
    if (this.disabled) return;
    if (option.value) {
      this.setupValue(option.value);
      this.selectionChanged.emit(this.value);
      this.onChange(this.value);
    }
    if (!this.selectionModel.isMultipleSelection()) {
      this.close();
    }
  }

  private refreshOptionsMap(): void {
    this.optionMap.clear();
    this.options.forEach((o) => this.optionMap.set(o.value, o));
  }

  private highlightSelectedOptions(): void {
    const valuesWithUpdatedReferences = this.selectionModel.selected.map((value) => {
      const correspondingOption = this.findOptionsByValue(value);
      return correspondingOption ? correspondingOption.value! : value;
    });
    this.selectionModel.clear();
    this.selectionModel.select(...valuesWithUpdatedReferences);
  }

  private findOptionsByValue(value: T | null): OptionComponent<T, V> | undefined {
    if (this.optionMap.has(value)) {
      return this.optionMap.get(value);
    }
    return this.options && this.options.find((o) => this.compareWith(o.value, value));
  }

  public validate(_: FormControl): ValidationErrors | null {
    return _.valid ? null : { [ValidationKey.InputSelect]: { valid: false, message: ValidationMessage.InputSelect } };
  }
}
