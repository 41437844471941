import {
  BlockScrollStrategy,
  CdkConnectedOverlay,
  CloseScrollStrategy,
  ConnectionPositionPair,
  RepositionScrollStrategy,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  signal,
} from '@angular/core';
import { DropdownItemDirective } from '../..';
import { DEFAULT_DROPDOWN_POSITIONS, DropdownPosition, createPositionPairs } from '../../utils';
import { DropdownContentComponent } from '../dropdown-content/dropdown-content.component';

@Component({
  selector: 'owt-dropdown,[owtDropdown]',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnChanges {
  @ContentChild('button') public buttonTpl?: TemplateRef<{ open: boolean }>;
  @ContentChild('dropdown') public dropdownTpl?: TemplateRef<{ overlay: CdkConnectedOverlay }>;
  @ContentChild(DropdownContentComponent) public dropdownContent?: DropdownContentComponent;
  @Input() public buttonClasses?: string;
  @Input() public positions: DropdownPosition[] = DEFAULT_DROPDOWN_POSITIONS;
  @Input() public offset = 0;
  @Input() public scrollPositionStrategy: 'reposition' | 'close' | 'block' = 'reposition';
  @Input() public width: number | string = '';
  @Input() public disabled = false;

  @ViewChildren(DropdownItemDirective) public menuItems?: QueryList<DropdownItemDirective>;

  @ViewChild(CdkConnectedOverlay) public overlay!: CdkConnectedOverlay;
  @ViewChild(DropdownContentComponent) public menu?: DropdownContentComponent;

  public readonly delay = 250; // close with delay
  public isOpen = signal(false);
  public positionPairs: ConnectionPositionPair[] = createPositionPairs(this.offset, this.positions);
  public scrollStrategy = this.selectStrategy(this.scrollPositionStrategy);

  constructor(
    private sso: ScrollStrategyOptions,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['positions'] || changes['offset']) {
      this.positionPairs = createPositionPairs(this.offset, this.positions);
      this.cdr.markForCheck();
    }
    if (changes['scrollPositionStrategy']) {
      this.scrollStrategy = this.selectStrategy(this.scrollPositionStrategy);
      this.cdr.markForCheck();
    }
  }

  private selectStrategy(
    strategy: 'reposition' | 'close' | 'block',
  ): RepositionScrollStrategy | BlockScrollStrategy | CloseScrollStrategy {
    switch (strategy) {
      case 'reposition':
        return this.sso.reposition();
      case 'block':
        return this.sso.block();
      default:
        return this.sso.close();
    }
  }
}
