export enum ValidationKey {
  InputRadioGroup = 'inputRadioGroup',
  InputSelect = 'inputSelect',
  InputCalendar = 'inputCalendar',
  InputDate = 'inputDate',
  InputFile = 'inputFile',
}

export enum ValidationMessage {
  InputRadioGroup = 'input.radio-group',
  InputSelect = 'input.select',
  InputCalendar = 'input.calendar',
  InputDate = 'input.date',
  InputFile = 'input.file',
}
