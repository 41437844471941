import { UserTypeBasedSettings } from '../dto/role.dto';
import { ROUTE_DEFINITION } from './route-definition.constant';

export const ROUTE_SETTINGS: UserTypeBasedSettings<{
  home: string;
  logged: string;
  notLogged: string;
  restricted?: string;
}> = {
  admin: {
    home: `/${ROUTE_DEFINITION.APP.ADMIN}`,
    logged: `/${ROUTE_DEFINITION.APP.ADMIN}/${ROUTE_DEFINITION.ADMIN.MANUFACTURERS}`,
    notLogged: `/${ROUTE_DEFINITION.APP.LOGIN}`,
  },
  customer: {
    home: `/${ROUTE_DEFINITION.APP.CUSTOMER}`,
    logged: `/${ROUTE_DEFINITION.APP.CUSTOMER}/${ROUTE_DEFINITION.CUSTOMER.DASHBOARD}`,
    notLogged: `/${ROUTE_DEFINITION.APP.LOGIN}`,
    restricted: `/${ROUTE_DEFINITION.APP.CUSTOMER}/${ROUTE_DEFINITION.CUSTOMER.SETTINGS}/${ROUTE_DEFINITION.ROOT.TARIFFS}`,
  },
  'installation-company': {
    home: `/${ROUTE_DEFINITION.APP.INSTALLATION_COMPANY}`,
    logged: `/${ROUTE_DEFINITION.APP.INSTALLATION_COMPANY}/${ROUTE_DEFINITION.INSTALLATION_COMPANY.DASHBOARD}`,
    notLogged: `/${ROUTE_DEFINITION.APP.LOGIN}`,
    restricted: `/${ROUTE_DEFINITION.APP.INSTALLATION_COMPANY}/${ROUTE_DEFINITION.INSTALLATION_COMPANY.SETTINGS}/${ROUTE_DEFINITION.ROOT.TARIFFS}`,
  },
  manufacturer: {
    home: `/${ROUTE_DEFINITION.APP.MANUFACTURER}`,
    logged: `/${ROUTE_DEFINITION.APP.MANUFACTURER}/${ROUTE_DEFINITION.MANUFACTURER.DEVICES}`,
    notLogged: `/${ROUTE_DEFINITION.APP.LOGIN}`,
    restricted: `/${ROUTE_DEFINITION.APP.MANUFACTURER}/${ROUTE_DEFINITION.MANUFACTURER.SETTINGS}/${ROUTE_DEFINITION.ROOT.TARIFFS}`,
  },
};
