import { Directive } from '@angular/core';
import { environment } from 'projects/portal/src/environments/environment';
import { NgxTranslateRevealService } from '../services/ngx-translate-reveal.service';

@Directive({
  selector: '[owtRevealTranslations]',
  standalone: true,
})
export class RevealTranslationsDirective {
  constructor(private translateReveal: NgxTranslateRevealService) {
    environment.translations.reveal && this.translateReveal.enable();
  }
}
