import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { InputFileConfig } from '@owt/ui';

export const INPUT_FILE_I18N: InputFileConfig = {
  dragAndDropText: _('input-file.drag-and-drop'),
  dropText: _('input-file.drop-text'),
  uploadBtnText: _('input-file.upload-btn-text'),
  upTo: _('input-file.up-to'),
  deleteBtnText: _('uni.delete'),
};
