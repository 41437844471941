import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { SelectedLanguagePipe } from './pipes/selected-language.pipe';
import { LANGUAGE_SWITCH_CONFIG_TOKEN, defaultLanguageSwitchConfig } from './utils/language-switch-config';

@NgModule({
  declarations: [LanguageSwitchComponent, SelectedLanguagePipe],
  imports: [CommonModule, NgOptimizedImage],
  exports: [LanguageSwitchComponent],
})
export class LanguageSwitchModule {
  public static forRoot(config = defaultLanguageSwitchConfig): ModuleWithProviders<LanguageSwitchModule> {
    return {
      ngModule: LanguageSwitchModule,
      providers: [
        {
          provide: LANGUAGE_SWITCH_CONFIG_TOKEN,
          useValue: {
            ...defaultLanguageSwitchConfig,
            ...config,
          },
        },
      ],
    };
  }
}
