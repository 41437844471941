import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { SuccessDto } from '../dto/success.dto';

export const SUCCESS_I18N: Record<SuccessDto, string> = {
  [SuccessDto.adminManufacturerAdd]: _('notification.success.admin.manufacturer-add'),
  [SuccessDto.adminUsersAdd]: _('uni.user-invited'),
  [SuccessDto.adminUsersEdit]: _('uni.user-edited'),
  [SuccessDto.adminManufacturerUpdate]: _('notification.success.admin.manufacturer-update'),
  [SuccessDto.customerDeviceRegister]: _('notification.success.customer.device-register'),
  [SuccessDto.customerDeviceUnregister]: _('notification.success.customer.device-unregister'),
  [SuccessDto.customerProfileUpdate]: _('uni.profile-updated'),
  [SuccessDto.customerRegister]: _('notification.success.customer.register'),
  [SuccessDto.customerSiteAdd]: _('notification.success.customer.site-add'),
  [SuccessDto.customerSiteEdit]: _('notification.success.customer.site-edit'),
  [SuccessDto.customerSiteRemove]: _('notification.success.customer.site-remove'),
  [SuccessDto.customerUsersAdd]: _('uni.user-invited'),
  [SuccessDto.customerUsersEdit]: _('uni.user-edited'),
  [SuccessDto.customerDeviceMove]: _('notification.success.customer.device-move'),
  [SuccessDto.customerCustomerSiteSetDefault]: _('notification.success.customer.customer-site-set-default'),
  [SuccessDto.customerCustomerTicketCreate]: _('notification.success.customer.ticket-create'),
  [SuccessDto.customerStripeSubscriptionUpdate]: _('notification.success.customer.stripe-subscription-update'),
  [SuccessDto.installationCompanyRegister]: _('notification.success.installation-company.register'),
  [SuccessDto.installationCompanyUsersAdd]: _('uni.user-invited'),
  [SuccessDto.installationCompanyCustomerDelete]: _('notification.success.installation-company.customer-delete'),

  [SuccessDto.installationUsersEdit]: _('uni.user-edited'),
  [SuccessDto.installationCompanyProfileUpdate]: _('uni.profile-updated'),
  [SuccessDto.installationCompanyDeviceNewNonExisting]: _(
    'notification.success.installation-company.device-new-non-existing',
  ),
  [SuccessDto.installationCompanyDeviceNewExisting]: _('notification.success.installation-company.device-new-existing'),
  [SuccessDto.installationCompanyCustomerUpdate]: _('notification.success.installation-company.customer-update'),
  [SuccessDto.installationCompanyCustomerSiteDelete]: _(
    'notification.success.installation-company.customer-site-delete',
  ),
  [SuccessDto.installationCompanyCustomerSiteUpdate]: _(
    'notification.success.installation-company.customer-site-update',
  ),
  [SuccessDto.installationCompanyDeviceUnregister]: _('notification.success.installation-company.device-unregister'),
  [SuccessDto.installationCompanyNoteCreate]: _('notification.success.installation-company.note-create'),
  [SuccessDto.installationCompanyNoteRemove]: _('notification.success.installation-company.note-remove'),
  [SuccessDto.installationCompanyNoteEdit]: _('notification.success.installation-company.note-edit'),
  [SuccessDto.installationCompanyNoteAttachmentRemove]: _(
    'notification.success.installation-company.note-attachment-removed',
  ),
  [SuccessDto.installationCompanyAttachmentCreate]: _('notification.success.installation-company.attachment-create'),
  [SuccessDto.installationCompanyAttachmentDelete]: _('notification.success.installation-company.attachment-delete'),
  [SuccessDto.installationCompanyAttachmentEdit]: _('notification.success.installation-company.attachment-edit'),
  [SuccessDto.installationCompanyStripeSubscriptionUpdate]: _(
    'notification.success.installation-company.stripe-subscription-update',
  ),
  [SuccessDto.installationCompanyCustomerInvitation]: _(
    'notification.success.installation-company.customer-invitation',
  ),
  [SuccessDto.installationCompanyConfigurationUpdated]: _(
    'notification.success.installation-company.configuration-updated',
  ),
  [SuccessDto.installationCompanyInstructionAdded]: _('notification.success.installation-company.instruction-added'),
  [SuccessDto.manufacturerDeviceAdd]: _('notification.success.manufacturer.device-add'),
  [SuccessDto.manufacturerDeviceEdit]: _('notification.success.manufacturer.device-edit'),
  [SuccessDto.manufacturerDeviceRemove]: _('notification.success.manufacturer.device-remove'),
  [SuccessDto.manufacturerProfileUpdate]: _('uni.profile-updated'),
  [SuccessDto.manufacturerRegister]: _('notification.success.manufacturer.register'),
  [SuccessDto.manufacturerTypeOfDeviceAdd]: _('notification.success.manufacturer.type-of-device-add'),
  [SuccessDto.manufacturerTypeOfDeviceEdit]: _('notification.success.manufacturer.type-of-device-edit'),
  [SuccessDto.manufacturerTypeOfDeviceEventTypeAdd]: _(
    'notification.success.manufacturer.type-of-device-event-type-add',
  ),
  [SuccessDto.manufacturerTypeOfDeviceEventTypeEdit]: _(
    'notification.success.manufacturer.type-of-device-event-type-edit',
  ),
  [SuccessDto.manufacturerTypeOfDeviceEventTypeRemove]: _(
    'notification.success.manufacturer.type-of-device-event-type-remove',
  ),
  [SuccessDto.manufacturerTypeOfDeviceRemove]: _('notification.success.manufacturer.type-of-device-remove'),
  [SuccessDto.manufacturerUsersAdd]: _('uni.user-invited'),
  [SuccessDto.manufacturerUsersEdit]: _('notification.success.manufacturer.users-edit'),
  [SuccessDto.manufacturerManufacturerTicketCreate]: _('notification.success.manufacturer.ticket-create'),
  [SuccessDto.manufacturerTicketUpdate]: _('notification.success.manufacturer.ticket-update'),
  [SuccessDto.rootActivated]: _('notification.success.root.activated'),
  [SuccessDto.rootForgottenPassword]: _('notification.success.root.forgotten-password'),
  [SuccessDto.rootLoggedOut]: _('notification.success.root.logged-out'),
  [SuccessDto.rootPasswordChanged]: _('notification.success.root.password-changed'),
  [SuccessDto.rootUserActivated]: _('notification.success.root.user-activated'),
  [SuccessDto.rootUserDeactivated]: _('notification.success.root.user-deactivated'),
  [SuccessDto.rootTicketRemove]: _('notification.success.root.ticket-remove'),
  [SuccessDto.rootTicketClose]: _('notification.success.root.ticket-close'),
  [SuccessDto.rootTicketManufacturerAssign]: _('notification.success.root.ticket-manufacturer-assign'),
  [SuccessDto.rootTicketCustomerAssign]: _('notification.success.root.ticket-customer-assign'),
  [SuccessDto.rootAdminUserUpdate]: _('uni.profile-updated'),
  [SuccessDto.rootEventMarkedAsUnread]: _('notification.success.root.event-marked-as-unread'),
  [SuccessDto.rootEventMarkedAsRead]: _('notification.success.root.event-marked-as-read'),
  [SuccessDto.rootTicketCommentRemove]: _('notification.success.root.ticket-comment-remove'),
  [SuccessDto.rootAttachmentDeleted]: _('notification.success.root.attachment-deleted'),
  [SuccessDto.rootTicketUpdate]: _('notification.success.root.ticket-update'),
  [SuccessDto.rootSubscriptionCancel]: _('notification.success.root.subscription-cancel'),
  [SuccessDto.rootRegister]: _('notification.success.root.register'),
  [SuccessDto.rootAssignTechnician]: _('notification.success.root.assign-technician'),
};
