import { Directive, OnInit } from '@angular/core';
import { UpdateService } from './update.service';

@Directive({
  selector: '[owtUpdate]',
  standalone: true,
})
export class UpdateDirective implements OnInit {
  constructor(private update: UpdateService) {}

  public ngOnInit(): void {
    this.update.checkForUpdates();
  }
}
