import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { MeGQL } from 'projects/portal/src/generated/graphql';
import { first, map, Observable } from 'rxjs';
import { ROUTE_SETTINGS } from '../constants/route-settings.constant';
import { UserUtils } from '../utils/user.utils';

export class NotLoggedGuard {
  public static notLogged(): Observable<true | UrlTree> {
    const router = inject(Router);
    const me = inject(MeGQL);
    return me.fetch(undefined, { fetchPolicy: 'cache-first' }).pipe(
      first(),
      map((res) => {
        const userRole = UserUtils.userType(res.data.me);
        const loggedUrl = ROUTE_SETTINGS[userRole].logged;

        // This is also redirect from `login` page to its dashboard page (role specific)
        return !UserUtils.isLogged(res.data.me) || router.createUrlTree([loggedUrl]);
      })
    );
  }
}
