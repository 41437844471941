import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Inject, Injectable, Injector } from '@angular/core';
import { ToastComponent } from '../components/toast/toast.component';
import { TOAST_CONFIG_TOKEN, ToastConfig, ToastData, defaultToastConfig } from '../utils/toast-config';
import { ToastRef } from '../utils/toast-ref';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private lastToast!: ToastRef;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig = defaultToastConfig
  ) {}

  public show(data: ToastData): ToastRef {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });
    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;
    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);
    overlayRef.attach(toastPortal);
    return toastRef;
  }

  public getPositionStrategy(): GlobalPositionStrategy {
    return this.overlay
      .position()
      .global()
      .top(this.getPosition())
      .right((this.toastConfig.position?.right || 0) + 'px');
  }

  public getPosition(): string {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible ? this.lastToast.getPosition().bottom : this.toastConfig.position?.top || 0;
    return position + 'px';
  }

  public getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector): Injector {
    return Injector.create({
      providers: [
        {
          provide: ToastData,
          useValue: data,
        },
        {
          provide: ToastRef,
          useValue: toastRef,
        },
      ],
      parent: parentInjector,
      name: 'portal-injector',
    });
  }
}
