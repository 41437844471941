import { faClock, faEye } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowUpRightFromSquare,
  faCheck,
  faChevronDown,
  faCircleInfo,
  faCirclePlus,
  faCircleXmark,
  faCog,
  faDownload,
  faEllipsisVertical,
  faExclamationTriangle,
  faFileExport,
  faFilterCircleXmark,
  faHouse,
  faPaperPlane,
  faPaperclip,
  faPen,
  faPeopleArrows,
  faTrash,
  faUpDownLeftRight,
  faUserPlus,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

export const ICONS = {
  add: faCirclePlus,
  addUser: faUserPlus,
  check: faCheck,
  cross: faXmark,
  clock: faClock,
  close: faCircleXmark,
  chevronDown: faChevronDown,
  default: faHouse,
  delete: faTrash,
  download: faDownload,
  edit: faPen,
  export: faFileExport,
  eye: faEye,
  file: faPaperclip,
  filterReset: faFilterCircleXmark,
  kebab: faEllipsisVertical,
  move: faUpDownLeftRight,
  openNewTab: faArrowUpRightFromSquare,
  send: faPaperPlane,
  settings: faCog,
  warning: faExclamationTriangle,
  peopleArrows: faPeopleArrows,
  info: faCircleInfo,
};
