import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FORM_CONDENSED_ERRORS_ALERT_MESSAGES, FORM_CONDENSED_ERROR_MESSAGES } from './dynamic-form-condensed.constant';
import { FormCondensed } from './dynamic-form-condensed.dto';

export const FORM_CONDENSED_ERROR_MESSAGES_TOKEN = new InjectionToken('Form condensed validation messages', {
  providedIn: 'root',
  factory: (): Observable<FormCondensed.ErrorMessages> => of(FORM_CONDENSED_ERROR_MESSAGES),
});

export const FORM_CONDENSED_ERROR_ALERT_MESSAGES_TOKEN = new InjectionToken(
  'Form condensed validation alert messages',
  {
    providedIn: 'root',
    factory: (): Observable<FormCondensed.ErrorAllertMessages> => of(FORM_CONDENSED_ERRORS_ALERT_MESSAGES),
  },
);

export const FORM_CONDENSED_ERROR_IGNORE_LIST_TOKEN = new InjectionToken('Form condensed ignore list', {
  providedIn: 'root',
  factory: (): string[] => [],
});
