<div
  *ngIf="displayIcon"
  class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full align-center"
  [class.bg-danger-100]="modalType === 'warning'"
  [class.bg-success-100]="modalType === 'success' || !modalType"
  [ngClass]="{
    'sm:mx-0 sm:h-10 sm:w-10': breakPoint === 'sm'
  }"
>
  <owt-icon
    [icon]="modalType === 'warning' ? 'warning' : 'check'"
    [position]="'standalone'"
    [ngClass]="{
      'text-primary-600': modalType === 'success' || !modalType,
      'text-danger-600': modalType === 'warning'
    }"
  ></owt-icon>
</div>
<div
  class="text-center"
  [ngClass]="{
    'sm:text-left': breakPoint === 'sm'
  }"
>
  <h3 class="text-base font-semibold leading-6 text-gray-900">{{ title }}</h3>
  <div *ngIf="content" class="mt-2">
    <p class="text-sm text-gray-500" [innerHTML]="content"></p>
  </div>
</div>
