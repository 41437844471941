import { Pipe, PipeTransform, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FORM_REGULAR_ERROR_MESSAGES_TOKEN, FormRegular } from '../utils';

@Pipe({
  name: 'dynamicFormRegularErrorMessage',
  standalone: true,
})
export class DynamicFormRegularErrorMessagePipe implements PipeTransform {
  private errorMessages = inject(FORM_REGULAR_ERROR_MESSAGES_TOKEN);

  public transform(
    key: string,
    errValue: any,
    validationMessageOverrides?: FormRegular.ValidationMessageOverrides,
  ): Observable<string> {
    return this.errorMessages.pipe(
      map((errorMessages) => this.parseMessages(key, errValue, errorMessages, validationMessageOverrides)),
    );
  }

  private parseMessages(
    key: string,
    errValue: any,
    errorMessages: any,
    validationMessageOverrides?: FormRegular.ValidationMessageOverrides,
  ): any {
    if (!errorMessages[key]) {
      console.warn(`Missing message for ${key} validator...`);
      return '';
    }

    return validationMessageOverrides?.[key] || errorMessages[key](errValue);
  }
}
