import { UserFragment, UserRoleChoices, UserTypeChoices } from 'projects/portal/src/generated/graphql';
import { UserTypeDto } from '../dto/role.dto';

export class UserUtils {
  public static isLogged(me: Partial<UserFragment> | undefined | null): boolean {
    return Boolean(me);
  }

  public static userType(me: Pick<UserFragment, 'userType'> | undefined | null): UserTypeDto {
    switch (me?.userType) {
      case UserTypeChoices.Customer:
        return UserTypeDto.Customer;
      case UserTypeChoices.Manufacturer:
        return UserTypeDto.Manufacturer;
      case UserTypeChoices.InstallationCompany:
        return UserTypeDto.InstallationCompany;
      default:
        return UserTypeDto.Admin;
    }
  }

  public static beRole(me: Pick<UserFragment, 'role'> | undefined | null): UserRoleChoices {
    switch (me?.role) {
      case UserRoleChoices.Admin:
        return UserRoleChoices.Admin;
      default:
        return UserRoleChoices.User;
    }
  }
}
