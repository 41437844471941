import { InjectionToken } from '@angular/core';

export interface UpdateConfig {
  interval: number; // in minutes
}

export const defaultUpdateConfig: UpdateConfig = {
  interval: 5,
};

export const UPDATE_CONFIG_TOKEN = new InjectionToken<UpdateConfig>('update-config');
