import { DialogModule } from '@angular/cdk/dialog';
import { registerLocaleData } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { provideRouter, withPreloading } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideTransloco } from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { provideTranslocoPersistLang } from '@ngneat/transloco-persist-lang';
import { CustomPreloadStrategyService, UpdateModule } from '@owt/core';
import { LanguageSwitchModule, ToastModule } from '@owt/ui';
import { NgxAppVersionModule } from 'ngx-app-version';
import { VERSION } from '../environments/version';
import { routes } from './app.routes';
import { GraphQLModule } from './graphql.module';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, LANGUAGE_SWITCH_CONFIG } from './shared/constants/language.constant';
import { CUSTOM_PROVIDERS } from './shared/providers';
import { TranslocoHttpLoader } from './shared/services/transloco-http-loader';

registerLocaleData(localeCs, 'cs-CZ');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(CustomPreloadStrategyService)),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideTransloco({
      config: {
        availableLangs: AVAILABLE_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistLang({
      storage: {
        useValue: localStorage,
      },
    }),
    provideTranslocoLocale(),
    importProvidersFrom(
      HttpClientModule,
      HttpClientJsonpModule,
      BrowserModule,
      DialogModule,
      GoogleMapsModule,
      GraphQLModule,
      NgxAppVersionModule.forRoot({
        version: VERSION.version,
      }),
      ToastModule.forRoot({
        position: {
          top: 20,
          right: 20,
        },
        animation: {
          fadeOut: 600,
          fadeIn: 300,
        },
        duration: 2000,
      }),
      UpdateModule.forRoot({
        interval: 5,
      }),
      LanguageSwitchModule.forRoot({
        languages: LANGUAGE_SWITCH_CONFIG,
      }),
    ),
    ...CUSTOM_PROVIDERS,
  ],
};
