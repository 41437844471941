/* eslint-disable */
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AttachmentCreateResponse: ['BaseSuccessResponse', 'FormErrorResponse'],
    AttachmentDeleteResponse: ['BaseSuccessResponse', 'ObjectCannotBeDeletedResponse', 'ObjectDoesNotExistsResponse'],
    CheckoutSessionCreateResponse: ['BaseSuccessResponse', 'CheckoutSession', 'ObjectDoesNotExistsResponse'],
    CreateDevicesResponse: ['BaseSuccessResponse', 'FormErrorResponse', 'TariffRestrictionResponse'],
    CustomerCustomerTicketActionResponse: ['CustomerCustomerTicketResponse', 'ObjectDoesNotExistsResponse'],
    CustomerCustomerTicketCreateResponse: ['CustomerCustomerTicketResponse', 'FormErrorResponse'],
    CustomerCustomerTicketUpdateResponse: [
      'CustomerCustomerTicketResponse',
      'FormErrorResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    CustomerDeviceRegisterResponse: ['BaseSuccessResponse', 'FormErrorResponse', 'TariffRestrictionResponse'],
    CustomerDeviceUnregisterResponse: ['BaseSuccessResponse', 'ObjectDoesNotExistsResponse'],
    CustomerInviteRegistrationResponse: ['FormErrorResponse', 'TokenExpiredResponse', 'TokenResponseSuccess'],
    CustomerSetTemperatureResponse: [
      'CustomerSetTemperatureSuccessResponse',
      'InvalidDeviceResponse',
      'InvalidTemperatureOffsetResponse',
      'ObjectDoesNotExistsResponse',
    ],
    CustomerSiteCreateResponse: ['CustomerSiteCreateSuccess', 'FormErrorResponse'],
    CustomerSiteDeleteResponse: ['BaseSuccessResponse', 'ObjectCannotBeDeletedResponse', 'ObjectDoesNotExistsResponse'],
    CustomerSiteUpdateResponse: ['CustomerSiteUpdateSuccess', 'FormErrorResponse', 'ObjectDoesNotExistsResponse'],
    CustomerTicketCommentCreateResponse: ['CustomerTicketCommentResponse', 'FormErrorResponse'],
    CustomerTicketCommentDeleteResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeDeletedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    CustomerTicketCommentUpdateResponse: [
      'CustomerTicketCommentResponse',
      'FormErrorResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    CustomerTicketDeleteResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeDeletedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    CustomerUpdateResponse: ['CustomerUpdateSuccess', 'FormErrorResponse'],
    DeleteDeviceResponse: ['BaseSuccessResponse', 'ObjectCannotBeDeletedResponse', 'ObjectDoesNotExistsResponse'],
    DeviceEventTypeDeleteResponse: ['BaseSuccessResponse', 'ObjectDoesNotExistsResponse'],
    DeviceEventTypeUpsertResponse: ['DeviceEventTypeUpsertSuccess', 'FormErrorResponse', 'ObjectDoesNotExistsResponse'],
    DeviceTypeDeleteResponse: ['BaseSuccessResponse', 'ExistingDevicesResponse', 'ObjectDoesNotExistsResponse'],
    DeviceTypeUpsertResponse: [
      'DeviceTypeUpsertSuccess',
      'FormErrorResponse',
      'ObjectDoesNotExistsResponse',
      'TariffRestrictionResponse',
    ],
    InstallationAttachmentDeleteResponse: ['BaseSuccessResponse', 'ObjectDoesNotExistsResponse'],
    InstallationCompanyConfigurationResponse: [
      'FormErrorResponse',
      'InstallationCompanyConfigurationType',
      'TariffRestrictionResponse',
    ],
    InstallationCompanyCustomerDeleteResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeDeletedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyCustomerInvitationDetailResponse: [
      'InstallationCompanyCustomerInvitationType',
      'ObjectDoesNotExistsResponse',
      'TokenExpiredResponse',
    ],
    InstallationCompanyCustomerInvitationResponse: ['BaseSuccessResponse', 'ObjectDoesNotExistsResponse'],
    InstallationCompanyCustomerSiteDeleteResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeDeletedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyCustomerSiteUpdateResponse: [
      'FormErrorResponse',
      'InstallationCompanyCustomerSiteUpdateSuccess',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyCustomerTicketActionResponse: [
      'InstallationCompanyCustomerTicketResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyCustomerTicketCreateResponse: ['FormErrorResponse', 'InstallationCompanyCustomerTicketResponse'],
    InstallationCompanyCustomerTicketUpdateResponse: [
      'FormErrorResponse',
      'InstallationCompanyCustomerTicketResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyCustomerUpdateResponse: [
      'FormErrorResponse',
      'InstallationCompanyCustomerUpdateSuccess',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyDeviceRegisterResponse: [
      'BaseSuccessResponse',
      'FormErrorResponse',
      'TariffRestrictionResponse',
    ],
    InstallationCompanyDeviceUninstallResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeDeletedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyManufacturerTicketActionResponse: [
      'InstallationCompanyManufacturerTicketResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyManufacturerTicketCreateResponse: [
      'FormErrorResponse',
      'InstallationCompanyManufacturerTicketResponse',
    ],
    InstallationCompanyManufacturerTicketUpdateResponse: [
      'FormErrorResponse',
      'InstallationCompanyManufacturerTicketResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanySendCommandResponse: [
      'InstallationCompanySendCommandSuccessResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyTicketActionResponse: ['InstallationCompanyTicketResponse', 'ObjectDoesNotExistsResponse'],
    InstallationCompanyTicketCommentCreateResponse: ['FormErrorResponse', 'InstallationCompanyTicketCommentResponse'],
    InstallationCompanyTicketCommentDeleteResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeDeletedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyTicketCommentUpdateResponse: [
      'FormErrorResponse',
      'InstallationCompanyTicketCommentResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyTicketCreateResponse: ['FormErrorResponse', 'InstallationCompanyTicketResponse'],
    InstallationCompanyTicketDeleteResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeDeletedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyTicketUpdateResponse: [
      'FormErrorResponse',
      'InstallationCompanyTicketResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationCompanyUpdateResponse: ['FormErrorResponse', 'InstallationCompanyUpdateSuccess'],
    InstallationNoteAttachmentDeleteResponse: ['BaseSuccessResponse', 'ObjectDoesNotExistsResponse'],
    InstallationNoteCreateResponse: ['FormErrorResponse', 'InstallationNoteResponse'],
    InstallationNoteDeleteResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    InstallationNoteUpdateResponse: [
      'FormErrorResponse',
      'InstallationNoteResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    ManufacturerManufacturerTicketActionResponse: [
      'ManufacturerManufacturerTicketResponse',
      'ObjectDoesNotExistsResponse',
    ],
    ManufacturerManufacturerTicketCreateResponse: ['FormErrorResponse', 'ManufacturerManufacturerTicketResponse'],
    ManufacturerManufacturerTicketUpdateResponse: [
      'FormErrorResponse',
      'ManufacturerManufacturerTicketResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    ManufacturerTicketCommentCreateResponse: ['FormErrorResponse', 'ManufacturerTicketCommentResponse'],
    ManufacturerTicketCommentDeleteResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeDeletedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    ManufacturerTicketCommentUpdateResponse: [
      'FormErrorResponse',
      'ManufacturerTicketCommentResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    ManufacturerTicketDeleteResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeDeletedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    OrganizationUnionType: ['CustomerType', 'InstallationCompanyType', 'ManufacturerType'],
    PLCFVEBarChartDataResponse: ['PLCFVEBarChartDataList', 'TariffRestrictionResponse'],
    PLCFVELineChartDataResponse: ['PLCFVELineChartDataList', 'TariffRestrictionResponse'],
    PLCHeatPumpBarChartDataResponse: ['PLCHeatPumpBarChartDataList', 'TariffRestrictionResponse'],
    PLCHeatPumpLineChartDataResponse: ['PLCHeatPumpLineChartDataList', 'TariffRestrictionResponse'],
    PaymentMethodCreateResponse: ['BaseSuccessResponse', 'CheckoutSession'],
    PlcEventMarkAsReadResponse: ['BaseSuccessResponse', 'ObjectDoesNotExistsResponse'],
    PlcEventMarkAsUnreadResponse: ['BaseSuccessResponse', 'ObjectDoesNotExistsResponse'],
    PlcExportResponse: ['ObjectDoesNotExistsResponse', 'PlcExportSuccessResponse', 'TariffRestrictionResponse'],
    PreSignedUploadsResponse: ['FormErrorResponse', 'PreSignedUploads'],
    SubscriptionCancelResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    SubscriptionUpdateResponse: [
      'BaseSuccessResponse',
      'ObjectCannotBeModifiedResponse',
      'ObjectDoesNotExistsResponse',
    ],
    SystemAdminManufacturerUpdateResponse: ['FormErrorResponse', 'SystemAdminManufacturerUpdateSuccess'],
    TokenObtainResponse: ['InvalidCredentialsError', 'TokenResponseSuccess'],
    TokenRefreshResponse: ['InvalidRefreshToken', 'TokenResponseSuccess'],
    TokenVerificationResponse: ['ObjectDoesNotExistsResponse', 'TokenExpiredResponse', 'TokenVerificationSuccess'],
    UpdateDeviceResponse: ['FormErrorResponse', 'ManufacturerUpdateDeviceSuccess', 'ObjectDoesNotExistsResponse'],
    UserActivationResponse: [
      'BaseSuccessResponse',
      'TokenExpiredResponse',
      'TokenResponseSuccess',
      'UserDisabledResponse',
    ],
    UserChangePasswordResponse: ['BaseSuccessResponse', 'FormErrorResponse'],
    UserDisableResponse: ['BaseSuccessResponse', 'ObjectDoesNotExistsResponse'],
    UserEnableResponse: ['BaseSuccessResponse', 'ObjectDoesNotExistsResponse'],
    UserInvitationResponse: ['FormErrorResponse', 'TariffRestrictionResponse', 'UserInvitationSuccess'],
    UserRegistrationResponse: ['FormErrorResponse', 'UserRegistrationSuccess'],
    UserResetPasswordResponse: [
      'BaseSuccessResponse',
      'FormErrorResponse',
      'ObjectDoesNotExistsResponse',
      'TokenExpiredResponse',
      'TokenResponseSuccess',
      'UserDisabledResponse',
    ],
    UserUpdateResponse: ['FormErrorResponse', 'ObjectDoesNotExistsResponse', 'UserUpdateSuccess'],
  },
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Represents binary data as Base64-encoded strings, using the standard alphabet. */
  Base64: { input: any; output: any };
  /** Date (isoformat) */
  Date: { input: any; output: any };
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
};

export type AdminUserInvitationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  languageCode: UserLanguagesChoices;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: UserRoleChoices;
};

export type AdminUserUpdateInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: UserRoleChoices;
};

export type AttachmentCreateResponse = BaseSuccessResponse | FormErrorResponse;

export type AttachmentDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export type AttachmentInput = {
  fileUpload: Scalars['ID']['input'];
};

export type BaseDeviceType = {
  __typename?: 'BaseDeviceType';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  deviceType: DeviceTypeType;
  id: Scalars['ID']['output'];
  installationCompany?: Maybe<InstallationCompanyType>;
  lastMetricsTimestamp?: Maybe<Scalars['DateTime']['output']>;
  manufacturerNote: Scalars['String']['output'];
  modified: Scalars['DateTime']['output'];
  plcPassword: Scalars['String']['output'];
  plcUsername: Scalars['String']['output'];
  registrationToken: Scalars['String']['output'];
  serialNumber: Scalars['String']['output'];
  state: DeviceStateChoices;
};

export type BaseSuccessResponse = {
  __typename?: 'BaseSuccessResponse';
  ok: Scalars['Boolean']['output'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  publicKey: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
};

export type CheckoutSessionCreateResponse = BaseSuccessResponse | CheckoutSession | ObjectDoesNotExistsResponse;

export type CommentType = {
  __typename?: 'CommentType';
  comment: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
};

export type CountryType = {
  __typename?: 'CountryType';
  alpha3: Scalars['String']['output'];
  code: Scalars['String']['output'];
  iocCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numeric: Scalars['String']['output'];
};

export type CreateDevicesResponse = BaseSuccessResponse | FormErrorResponse | TariffRestrictionResponse;

export type CustomerCustomerTicketActionResponse = CustomerCustomerTicketResponse | ObjectDoesNotExistsResponse;

export type CustomerCustomerTicketCreateResponse = CustomerCustomerTicketResponse | FormErrorResponse;

export type CustomerCustomerTicketInput = {
  bridgePlcEvent?: InputMaybe<Scalars['ID']['input']>;
  content: Scalars['String']['input'];
  device: Scalars['ID']['input'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['input'];
};

export type CustomerCustomerTicketResponse = {
  __typename?: 'CustomerCustomerTicketResponse';
  customerTicket: CustomerCustomerTicketType;
};

export type CustomerCustomerTicketType = {
  __typename?: 'CustomerCustomerTicketType';
  assignee: CustomerTicketAssigneeChoices;
  attachments: Array<CustomerTicketAttachmentType>;
  category: TicketCategoryChoices;
  content: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  customer: CustomerType;
  device?: Maybe<CustomerDeviceType>;
  deviceEvent?: Maybe<DeviceEventType>;
  id: Scalars['ID']['output'];
  installationCompany: InstallationCompanyType;
  isClosed: Scalars['Boolean']['output'];
  lastComment?: Maybe<CommentType>;
  modified: Scalars['DateTime']['output'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['output'];
  updatedBy?: Maybe<UserSelectItemType>;
};

export type CustomerCustomerTicketUpdateResponse =
  | CustomerCustomerTicketResponse
  | FormErrorResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type CustomerDeviceMoveRegistrationInput = {
  customerSite: Scalars['ID']['input'];
  device: Scalars['ID']['input'];
};

export type CustomerDeviceRegisterInput = {
  customerSite: Scalars['ID']['input'];
  registrationToken: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type CustomerDeviceRegisterResponse = BaseSuccessResponse | FormErrorResponse | TariffRestrictionResponse;

export type CustomerDeviceType = {
  __typename?: 'CustomerDeviceType';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  customerSite?: Maybe<CustomerSiteType>;
  deviceType: DeviceTypeType;
  id: Scalars['ID']['output'];
  installationCompany?: Maybe<InstallationCompanyType>;
  lastMetricsTimestamp?: Maybe<Scalars['DateTime']['output']>;
  manufacturerNote: Scalars['String']['output'];
  modified: Scalars['DateTime']['output'];
  plcPassword: Scalars['String']['output'];
  plcUsername: Scalars['String']['output'];
  registeredAt?: Maybe<Scalars['DateTime']['output']>;
  registrationToken: Scalars['String']['output'];
  serialNumber: Scalars['String']['output'];
  state: DeviceStateChoices;
};

export type CustomerDeviceUnregisterResponse = BaseSuccessResponse | ObjectDoesNotExistsResponse;

export type CustomerInviteRegistrationResponse = FormErrorResponse | TokenExpiredResponse | TokenResponseSuccess;

export type CustomerPaginatedDeviceListType = {
  __typename?: 'CustomerPaginatedDeviceListType';
  items: Array<CustomerDeviceType>;
  meta: PaginationMetaType;
};

export type CustomerPlcEventPaginatedDeviceListType = {
  __typename?: 'CustomerPlcEventPaginatedDeviceListType';
  items: Array<CustomerPlcEventType>;
  meta: PaginationMetaType;
};

export type CustomerPlcEventType = {
  __typename?: 'CustomerPlcEventType';
  created: Scalars['DateTime']['output'];
  customerTicket?: Maybe<CustomerCustomerTicketType>;
  device: CustomerDeviceType;
  deviceId: Scalars['ID']['output'];
  eventId: Scalars['Int']['output'];
  eventType?: Maybe<DeviceEventTypeType>;
  id: Scalars['ID']['output'];
  markedAsRead: Scalars['Boolean']['output'];
};

export type CustomerRegistrationInput = {
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  street: Scalars['String']['input'];
  vatId: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type CustomerSelectItemType = {
  __typename?: 'CustomerSelectItemType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CustomerSetTemperatureResponse =
  | CustomerSetTemperatureSuccessResponse
  | InvalidDeviceResponse
  | InvalidTemperatureOffsetResponse
  | ObjectDoesNotExistsResponse;

export type CustomerSetTemperatureSuccessResponse = {
  __typename?: 'CustomerSetTemperatureSuccessResponse';
  command: DeviceCommandType;
};

export type CustomerSiteCreateResponse = CustomerSiteCreateSuccess | FormErrorResponse;

export type CustomerSiteCreateSuccess = {
  __typename?: 'CustomerSiteCreateSuccess';
  customerSite: CustomerSiteType;
};

export type CustomerSiteDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export type CustomerSiteInput = {
  name: Scalars['String']['input'];
};

export type CustomerSiteType = {
  __typename?: 'CustomerSiteType';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  customer: CustomerType;
  devices: Array<CustomerDeviceType>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  updatedBy?: Maybe<UserSelectItemType>;
};

export type CustomerSiteUpdateResponse = CustomerSiteUpdateSuccess | FormErrorResponse | ObjectDoesNotExistsResponse;

export type CustomerSiteUpdateSuccess = {
  __typename?: 'CustomerSiteUpdateSuccess';
  customerSite: CustomerSiteType;
};

export enum CustomerSitesOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type CustomerStripeSubscriptionType = {
  __typename?: 'CustomerStripeSubscriptionType';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  created: Scalars['DateTime']['output'];
  currentPeriodEnd: Scalars['DateTime']['output'];
  currentPeriodStart: Scalars['DateTime']['output'];
  customer: CustomerType;
  customerTariff: CustomerTariffType;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  organization: OrganizationUnionType;
  status: StripeSubscriptionStatusChoices;
  subscriptionCreated: Scalars['DateTime']['output'];
  tariff: StripeTariffType;
  tariffType: TariffTypeChoices;
};

export type CustomerTariffRestrictionInfoType = {
  __typename?: 'CustomerTariffRestrictionInfoType';
  actualNumberOfDevices: Scalars['Int']['output'];
  actualNumberOfUsers: Scalars['Int']['output'];
  availableNumberOfDevices?: Maybe<Scalars['Int']['output']>;
  availableNumberOfUsers?: Maybe<Scalars['Int']['output']>;
  commands: Scalars['Boolean']['output'];
  dataExportExcel: Scalars['Boolean']['output'];
  dataExportImage: Scalars['Boolean']['output'];
  devicesOverLimit: Scalars['Boolean']['output'];
  singlePhaseMetrics: Scalars['Boolean']['output'];
  usersOverLimit: Scalars['Boolean']['output'];
};

export type CustomerTariffType = {
  __typename?: 'CustomerTariffType';
  activeSubscription?: Maybe<CustomerStripeSubscriptionType>;
  barChartDataGranularity: TariffBarChartDataGranularityChoices;
  commands: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  dataExportExcel: Scalars['Boolean']['output'];
  dataExportImage: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  lineChartDataGranularity: TariffLineChartDataGranularityChoices;
  lineChartDataRetention: TariffLineChartDataRetentionChoices;
  name: Scalars['String']['output'];
  numberOfDevices?: Maybe<Scalars['Int']['output']>;
  numberOfUsers?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Float']['output'];
  priceType: TariffPriceTypeChoices;
};

export enum CustomerTicketAssigneeChoices {
  Customer = 'CUSTOMER',
  InstallationCompany = 'INSTALLATION_COMPANY',
}

export type CustomerTicketAttachmentType = {
  __typename?: 'CustomerTicketAttachmentType';
  created: Scalars['DateTime']['output'];
  file: FileType;
  fileSize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalFileName: Scalars['String']['output'];
};

export type CustomerTicketCommentAttachmentType = {
  __typename?: 'CustomerTicketCommentAttachmentType';
  created: Scalars['DateTime']['output'];
  file: FileType;
  fileSize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalFileName: Scalars['String']['output'];
};

export type CustomerTicketCommentCreateResponse = CustomerTicketCommentResponse | FormErrorResponse;

export type CustomerTicketCommentDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export type CustomerTicketCommentInput = {
  comment: Scalars['String']['input'];
  customerTicket: Scalars['ID']['input'];
};

export type CustomerTicketCommentResponse = {
  __typename?: 'CustomerTicketCommentResponse';
  customerTicketComment: CustomerTicketCommentType;
};

export type CustomerTicketCommentType = {
  __typename?: 'CustomerTicketCommentType';
  attachments: Array<CustomerTicketCommentAttachmentType>;
  comment: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
};

export type CustomerTicketCommentUpdateInput = {
  comment: Scalars['String']['input'];
};

export type CustomerTicketCommentUpdateResponse =
  | CustomerTicketCommentResponse
  | FormErrorResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type CustomerTicketDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export enum CustomerTicketOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
}

export type CustomerTicketUpdateInput = {
  content: Scalars['String']['input'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['input'];
};

export type CustomerType = {
  __typename?: 'CustomerType';
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryType>;
  created: Scalars['DateTime']['output'];
  defaultPaymentMethod?: Maybe<Scalars['String']['output']>;
  deviceCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<CustomerStripeSubscriptionType>;
  tariff?: Maybe<CustomerTariffType>;
  userCount: Scalars['Int']['output'];
  vatId?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CustomerUpdateInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  vatId: Scalars['String']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerUpdateResponse = CustomerUpdateSuccess | FormErrorResponse;

export type CustomerUpdateSuccess = {
  __typename?: 'CustomerUpdateSuccess';
  customer: CustomerType;
};

export enum CustomersOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  DeviceCountAsc = 'DEVICE_COUNT_ASC',
  DeviceCountDesc = 'DEVICE_COUNT_DESC',
  UserCountAsc = 'USER_COUNT_ASC',
  UserCountDesc = 'USER_COUNT_DESC',
}

export type DeleteDeviceResponse = BaseSuccessResponse | ObjectCannotBeDeletedResponse | ObjectDoesNotExistsResponse;

export type DeviceCommandPaginatedListType = {
  __typename?: 'DeviceCommandPaginatedListType';
  items: Array<DeviceCommandType>;
  meta: PaginationMetaType;
};

export enum DeviceCommandStatusChoices {
  Complete = 'COMPLETE',
  FailedExecution = 'FAILED_EXECUTION',
  FailedSending = 'FAILED_SENDING',
  NoResponse = 'NO_RESPONSE',
  Pending = 'PENDING',
  Sent = 'SENT',
}

export type DeviceCommandType = {
  __typename?: 'DeviceCommandType';
  command: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  device: BaseDeviceType;
  id: Scalars['ID']['output'];
  response?: Maybe<Scalars['String']['output']>;
  responseReceived?: Maybe<Scalars['DateTime']['output']>;
  sent?: Maybe<Scalars['DateTime']['output']>;
  status: DeviceCommandStatusChoices;
  uid: Scalars['String']['output'];
};

export type DeviceCreateInput = {
  deviceType: Scalars['ID']['input'];
  numberOfDevices: Scalars['Int']['input'];
};

export enum DeviceEventPriorityChoices {
  Priority_1 = 'PRIORITY_1',
  Priority_2 = 'PRIORITY_2',
  Priority_3 = 'PRIORITY_3',
  Priority_4 = 'PRIORITY_4',
  Priority_5 = 'PRIORITY_5',
}

export type DeviceEventType = {
  __typename?: 'DeviceEventType';
  created: Scalars['DateTime']['output'];
  eventId: Scalars['Int']['output'];
  eventType?: Maybe<DeviceEventTypeType>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalCreated: Scalars['DateTime']['output'];
  originalId: Scalars['ID']['output'];
};

export type DeviceEventTypeDeleteResponse = BaseSuccessResponse | ObjectDoesNotExistsResponse;

export type DeviceEventTypeInput = {
  customerVisibility: Scalars['Boolean']['input'];
  descriptionCs: Scalars['String']['input'];
  descriptionEn: Scalars['String']['input'];
  priority: DeviceEventPriorityChoices;
  uid: Scalars['Int']['input'];
};

export type DeviceEventTypeType = {
  __typename?: 'DeviceEventTypeType';
  customerVisibility: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  descriptionCs: Scalars['String']['output'];
  descriptionEn: Scalars['String']['output'];
  deviceType: DeviceTypeType;
  id: Scalars['ID']['output'];
  priority: DeviceEventPriorityChoices;
  uid: Scalars['Int']['output'];
};

export type DeviceEventTypeUpsertResponse =
  | DeviceEventTypeUpsertSuccess
  | FormErrorResponse
  | ObjectDoesNotExistsResponse;

export type DeviceEventTypeUpsertSuccess = {
  __typename?: 'DeviceEventTypeUpsertSuccess';
  deviceEventType: DeviceEventTypeType;
};

export type DeviceExportResponse = {
  __typename?: 'DeviceExportResponse';
  data: Scalars['Base64']['output'];
};

export type DeviceInput = {
  manufacturerNote: Scalars['String']['input'];
  plcPassword: Scalars['String']['input'];
  plcUsername?: InputMaybe<Scalars['String']['input']>;
  registrationToken: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  state?: DeviceStateChoices;
};

export enum DeviceOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
  SerialNumberAsc = 'SERIAL_NUMBER_ASC',
  SerialNumberDesc = 'SERIAL_NUMBER_DESC',
}

export type DeviceRegisterInput = {
  registrationToken: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export enum DeviceStateChoices {
  Attached = 'ATTACHED',
  Connected = 'CONNECTED',
  Created = 'CREATED',
  Delivered = 'DELIVERED',
  Dispatched = 'DISPATCHED',
  Material = 'MATERIAL',
  Programmed = 'PROGRAMMED',
  Tested = 'TESTED',
}

export enum DeviceTypeApiTypeChoices {
  Http = 'HTTP',
  Mqtt = 'MQTT',
}

export type DeviceTypeDeleteResponse = BaseSuccessResponse | ExistingDevicesResponse | ObjectDoesNotExistsResponse;

export type DeviceTypeInput = {
  apiType: DeviceTypeApiTypeChoices;
  hwVersion?: InputMaybe<Scalars['String']['input']>;
  mapping: Scalars['String']['input'];
  name: Scalars['String']['input'];
  note: Scalars['String']['input'];
  serialNumberFormat: Scalars['String']['input'];
  technology: DeviceTypeTechnologyChoices;
};

export enum DeviceTypeOrderingChoices {
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type DeviceTypeSelectItemType = {
  __typename?: 'DeviceTypeSelectItemType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum DeviceTypeTechnologyChoices {
  Fve = 'FVE',
  HeatPump = 'HEAT_PUMP',
}

export type DeviceTypeType = {
  __typename?: 'DeviceTypeType';
  apiType: DeviceTypeApiTypeChoices;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  devicesCount: Scalars['Int']['output'];
  eventTypesCount: Scalars['Int']['output'];
  hwVersion?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manufacturer: ManufacturerType;
  mapping: Scalars['String']['output'];
  mappingJson: Scalars['JSON']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  serialNumberFormat: Scalars['String']['output'];
  technology: DeviceTypeTechnologyChoices;
};

export type DeviceTypeUpsertResponse =
  | DeviceTypeUpsertSuccess
  | FormErrorResponse
  | ObjectDoesNotExistsResponse
  | TariffRestrictionResponse;

export type DeviceTypeUpsertSuccess = {
  __typename?: 'DeviceTypeUpsertSuccess';
  deviceType: DeviceTypeType;
};

export enum ErrorCodeEnum {
  CustomerDeviceRegisterDeviceAlreadyRegisteredValidationError = 'customer_device_register_device_already_registered_validation_error',
  CustomerDeviceRegisterTechnologyAlreadyRegisteredValidationError = 'customer_device_register_technology_already_registered_validation_error',
  CustomerTicketCustomerInstallationCompanyMismatchValidationError = 'customer_ticket_customer_installation_company_mismatch_validation_error',
  CustomerTicketDeviceMismatchValidationError = 'customer_ticket_device_mismatch_validation_error',
  CustomerTicketEventMismatchValidationError = 'customer_ticket_event_mismatch_validation_error',
  DeviceEventTypeUidExistsError = 'device_event_type_uid_exists_error',
  DeviceRegisterRegistrationTokenValidationError = 'device_register_registration_token_validation_error',
  DeviceRegisterSerialNumberValidationError = 'device_register_serial_number_validation_error',
  DeviceTypeApiTypeMappingMissmatchError = 'device_type_api_type_mapping_missmatch_error',
  DeviceTypeMappingUnknownMetricFieldError = 'device_type_mapping_unknown_metric_field_error',
  DeviceTypeMappingValidationError = 'device_type_mapping_validation_error',
  InstallationCompanyDeviceInstallDeviceAlreadyRegisteredValidationError = 'installation_company_device_install_device_already_registered_validation_error',
  InstallationCompanyDeviceInstallDeviceTechnologyAlreadyRegisteredValidationError = 'installation_company_device_install_device_technology_already_registered_validation_error',
  Invalid = 'invalid',
  InvalidChoice = 'invalid_choice',
  ItemInvalid = 'item_invalid',
  MaxDigits = 'max_digits',
  MaxLength = 'max_length',
  MaxValue = 'max_value',
  MaxWholeDigits = 'max_whole_digits',
  MinLength = 'min_length',
  MinValue = 'min_value',
  PasswordEntirelyNumeric = 'password_entirely_numeric',
  PasswordIncorrect = 'password_incorrect',
  PasswordMismatch = 'password_mismatch',
  PasswordTooCommon = 'password_too_common',
  PasswordTooShort = 'password_too_short',
  PasswordTooSimilar = 'password_too_similar',
  RelatedInstallationCompanyTicketNotAllowedValidationError = 'related_installation_company_ticket_not_allowed_validation_error',
  Required = 'required',
  SerialNumberFormatValidationError = 'serial_number_format_validation_error',
  Unique = 'unique',
  UniqueTogether = 'unique_together',
}

export type ErrorCodeType = {
  __typename?: 'ErrorCodeType';
  code: ErrorCodeEnum;
  message: Scalars['String']['output'];
};

export type ExistingDevicesResponse = {
  __typename?: 'ExistingDevicesResponse';
  ok: Scalars['Boolean']['output'];
};

export type FileType = {
  __typename?: 'FileType';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FileUploadInput = {
  fileSize: Scalars['Int']['input'];
  originalFileName: Scalars['String']['input'];
};

export type FormErrorResponse = {
  __typename?: 'FormErrorResponse';
  errors: Array<FormErrorType>;
};

export type FormErrorType = {
  __typename?: 'FormErrorType';
  codes: Array<ErrorCodeType>;
  field: Scalars['String']['output'];
  inputName?: Maybe<Scalars['String']['output']>;
};

export type GpsInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type GpsType = {
  __typename?: 'GpsType';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export enum HeatPumpEkvitermTypeChoices {
  FourWay = 'FOUR_WAY',
  Kw = 'KW',
  Off = 'OFF',
}

export enum HeatPumpModeChoices {
  Cooling = 'COOLING',
  Stopped = 'STOPPED',
  ThermalSystemHeating = 'THERMAL_SYSTEM_HEATING',
  WatterHeating = 'WATTER_HEATING',
}

export type InstallationAttachmentDeleteResponse = BaseSuccessResponse | ObjectDoesNotExistsResponse;

export type InstallationAttachmentInput = {
  fileUpload: Scalars['ID']['input'];
  installation: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type InstallationAttachmentType = {
  __typename?: 'InstallationAttachmentType';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  file: FileType;
  fileSize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalFileName: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum InstallationCompaniesOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  DeviceCountAsc = 'DEVICE_COUNT_ASC',
  DeviceCountDesc = 'DEVICE_COUNT_DESC',
  UserCountAsc = 'USER_COUNT_ASC',
  UserCountDesc = 'USER_COUNT_DESC',
}

export type InstallationCompanyBaseTicketType = {
  __typename?: 'InstallationCompanyBaseTicketType';
  assignee?: Maybe<TicketAssigneeChoices>;
  category: TicketCategoryChoices;
  content: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  device?: Maybe<InstallationCompanyDeviceType>;
  deviceEvent?: Maybe<DeviceEventType>;
  id: Scalars['ID']['output'];
  isClosed: Scalars['Boolean']['output'];
  lastComment?: Maybe<CommentType>;
  modified: Scalars['DateTime']['output'];
  priority: TicketPriorityChoices;
  ticketType: TicketTypeChoices;
  title: Scalars['String']['output'];
  updatedBy?: Maybe<UserSelectItemType>;
};

export type InstallationCompanyConfigurationInput = {
  ticketsAllowed: Scalars['Boolean']['input'];
};

export type InstallationCompanyConfigurationResponse =
  | FormErrorResponse
  | InstallationCompanyConfigurationType
  | TariffRestrictionResponse;

export type InstallationCompanyConfigurationType = {
  __typename?: 'InstallationCompanyConfigurationType';
  ticketsAllowed: Scalars['Boolean']['output'];
};

export type InstallationCompanyCustomerDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyCustomerInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type InstallationCompanyCustomerInvitationDetailResponse =
  | InstallationCompanyCustomerInvitationType
  | ObjectDoesNotExistsResponse
  | TokenExpiredResponse;

export type InstallationCompanyCustomerInvitationResponse = BaseSuccessResponse | ObjectDoesNotExistsResponse;

export type InstallationCompanyCustomerInvitationType = {
  __typename?: 'InstallationCompanyCustomerInvitationType';
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  vatId?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum InstallationCompanyCustomerOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  DeviceCountAsc = 'DEVICE_COUNT_ASC',
  DeviceCountDesc = 'DEVICE_COUNT_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
  SiteCountAsc = 'SITE_COUNT_ASC',
  SiteCountDesc = 'SITE_COUNT_DESC',
}

export type InstallationCompanyCustomerSelectItemType = {
  __typename?: 'InstallationCompanyCustomerSelectItemType';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InstallationCompanyCustomerSiteDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyCustomerSiteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  gps?: InputMaybe<GpsInput>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export enum InstallationCompanyCustomerSiteOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
}

export type InstallationCompanyCustomerSiteType = {
  __typename?: 'InstallationCompanyCustomerSiteType';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryType>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  deviceCount: Scalars['Int']['output'];
  gps?: Maybe<GpsType>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<UserSelectItemType>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type InstallationCompanyCustomerSiteUpdateResponse =
  | FormErrorResponse
  | InstallationCompanyCustomerSiteUpdateSuccess
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyCustomerSiteUpdateSuccess = {
  __typename?: 'InstallationCompanyCustomerSiteUpdateSuccess';
  installationCompanyCustomerSite: InstallationCompanyCustomerSiteType;
};

export type InstallationCompanyCustomerTicketActionResponse =
  | InstallationCompanyCustomerTicketResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyCustomerTicketCreateResponse =
  | FormErrorResponse
  | InstallationCompanyCustomerTicketResponse;

export type InstallationCompanyCustomerTicketInput = {
  bridgePlcEvent?: InputMaybe<Scalars['ID']['input']>;
  content: Scalars['String']['input'];
  customer: Scalars['ID']['input'];
  device?: InputMaybe<Scalars['ID']['input']>;
  installationCompanyTechnician?: InputMaybe<Scalars['ID']['input']>;
  priority: TicketPriorityChoices;
  title: Scalars['String']['input'];
};

export type InstallationCompanyCustomerTicketResponse = {
  __typename?: 'InstallationCompanyCustomerTicketResponse';
  customerTicket: InstallationCompanyCustomerTicketType;
};

export type InstallationCompanyCustomerTicketType = {
  __typename?: 'InstallationCompanyCustomerTicketType';
  assignee: CustomerTicketAssigneeChoices;
  attachments: Array<CustomerTicketAttachmentType>;
  category: TicketCategoryChoices;
  content: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  customer: CustomerType;
  device?: Maybe<InstallationCompanyDeviceType>;
  deviceEvent?: Maybe<DeviceEventType>;
  id: Scalars['ID']['output'];
  installationCompany: InstallationCompanyType;
  installationCompanyTechnician?: Maybe<UserSelectItemType>;
  isClosed: Scalars['Boolean']['output'];
  lastComment?: Maybe<CommentType>;
  modified: Scalars['DateTime']['output'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['output'];
  updatedBy?: Maybe<UserSelectItemType>;
};

export type InstallationCompanyCustomerTicketUpdateResponse =
  | FormErrorResponse
  | InstallationCompanyCustomerTicketResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyCustomerType = {
  __typename?: 'InstallationCompanyCustomerType';
  companyId?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  customer?: Maybe<CustomerType>;
  deviceCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  installationCompany?: Maybe<InstallationCompanyType>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  siteCount: Scalars['Int']['output'];
  sites: Array<InstallationCompanyCustomerSiteType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserSelectItemType>;
  vatId?: Maybe<Scalars['String']['output']>;
};

export type InstallationCompanyCustomerUpdateResponse =
  | FormErrorResponse
  | InstallationCompanyCustomerUpdateSuccess
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyCustomerUpdateSuccess = {
  __typename?: 'InstallationCompanyCustomerUpdateSuccess';
  installationCompanyCustomer: InstallationCompanyCustomerType;
};

export type InstallationCompanyDashboardStatisticsType = {
  __typename?: 'InstallationCompanyDashboardStatisticsType';
  installationCompanyCustomerFveCount: Scalars['Int']['output'];
  installationCompanyCustomerHeatPumpCount: Scalars['Int']['output'];
  installationCompanyCustomerTicketClosedCount: Scalars['Int']['output'];
  installationCompanyCustomerTicketOpenCount: Scalars['Int']['output'];
  installationCompanyDeviceFveCount: Scalars['Int']['output'];
  installationCompanyDeviceHeatPumpCount: Scalars['Int']['output'];
  installationCompanyInstallationCompanyTicketClosedCount: Scalars['Int']['output'];
  installationCompanyInstallationCompanyTicketOpenCount: Scalars['Int']['output'];
  installationCompanyManufacturerTicketClosedCount: Scalars['Int']['output'];
  installationCompanyManufacturerTicketOpenCount: Scalars['Int']['output'];
  installationCompanyPlcEventTodayCount: Scalars['Int']['output'];
  installationCompanyPlcEventWeekHighestPriorityCount: Scalars['Int']['output'];
};

export enum InstallationCompanyDeviceOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  DeviceTypeAsc = 'DEVICE_TYPE_ASC',
  DeviceTypeDesc = 'DEVICE_TYPE_DESC',
  InstallationCompanyCustomerAsc = 'INSTALLATION_COMPANY_CUSTOMER_ASC',
  InstallationCompanyCustomerDesc = 'INSTALLATION_COMPANY_CUSTOMER__DESC',
  InstallationCreatedAsc = 'INSTALLATION_CREATED_ASC',
  InstallationCreatedDesc = 'INSTALLATION_CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
  SerialNumberAsc = 'SERIAL_NUMBER_ASC',
  SerialNumberDesc = 'SERIAL_NUMBER_DESC',
}

export type InstallationCompanyDeviceRegisterResponse =
  | BaseSuccessResponse
  | FormErrorResponse
  | TariffRestrictionResponse;

export type InstallationCompanyDeviceType = {
  __typename?: 'InstallationCompanyDeviceType';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  customerSite?: Maybe<CustomerSiteType>;
  deviceType: DeviceTypeType;
  id: Scalars['ID']['output'];
  installation: InstallationType;
  installationCompany?: Maybe<InstallationCompanyType>;
  installationCompanyCustomer?: Maybe<InstallationCompanyCustomerType>;
  installationCompanyCustomerSite?: Maybe<InstallationCompanyCustomerSiteType>;
  lastMetricsTimestamp?: Maybe<Scalars['DateTime']['output']>;
  manufacturerNote: Scalars['String']['output'];
  modified: Scalars['DateTime']['output'];
  plcPassword: Scalars['String']['output'];
  plcUsername: Scalars['String']['output'];
  registrationToken: Scalars['String']['output'];
  serialNumber: Scalars['String']['output'];
  state: DeviceStateChoices;
  technician?: Maybe<UserType>;
};

export type InstallationCompanyDeviceUninstallResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyExistingCustomerInput = {
  customer: Scalars['ID']['input'];
};

export type InstallationCompanyExistingCustomerSiteInput = {
  customerSite: Scalars['ID']['input'];
};

export type InstallationCompanyManufacturerTicketActionResponse =
  | InstallationCompanyManufacturerTicketResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyManufacturerTicketCreateResponse =
  | FormErrorResponse
  | InstallationCompanyManufacturerTicketResponse;

export type InstallationCompanyManufacturerTicketInput = {
  content: Scalars['String']['input'];
  customerTicket?: InputMaybe<Scalars['ID']['input']>;
  device?: InputMaybe<Scalars['ID']['input']>;
  installationCompany: Scalars['ID']['input'];
  installationCompanyTechnician?: InputMaybe<Scalars['ID']['input']>;
  manufacturer: Scalars['ID']['input'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['input'];
};

export type InstallationCompanyManufacturerTicketResponse = {
  __typename?: 'InstallationCompanyManufacturerTicketResponse';
  manufacturerTicket: InstallationCompanyManufacturerTicketType;
};

export type InstallationCompanyManufacturerTicketType = {
  __typename?: 'InstallationCompanyManufacturerTicketType';
  assignee: ManufacturerTicketAssigneeChoices;
  attachments: Array<ManufacturerTicketAttachmentType>;
  category: TicketCategoryChoices;
  content: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  customerTicket?: Maybe<InstallationCompanyCustomerTicketType>;
  device?: Maybe<InstallationCompanyDeviceType>;
  id: Scalars['ID']['output'];
  installationCompany: InstallationCompanyType;
  installationCompanyTechnician?: Maybe<UserSelectItemType>;
  isClosed: Scalars['Boolean']['output'];
  lastComment?: Maybe<CommentType>;
  manufacturer: ManufacturerType;
  modified: Scalars['DateTime']['output'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['output'];
  updatedBy?: Maybe<UserSelectItemType>;
};

export type InstallationCompanyManufacturerTicketUpdateResponse =
  | FormErrorResponse
  | InstallationCompanyManufacturerTicketResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyMoveRegistrationInput = {
  customerSite: Scalars['ID']['input'];
  device: Scalars['ID']['input'];
};

export type InstallationCompanyPaginatedDeviceListType = {
  __typename?: 'InstallationCompanyPaginatedDeviceListType';
  items: Array<InstallationCompanyDeviceType>;
  meta: PaginationMetaType;
};

export type InstallationCompanyPlcEventPaginatedDeviceListType = {
  __typename?: 'InstallationCompanyPlcEventPaginatedDeviceListType';
  items: Array<InstallationCompanyPlcEventType>;
  meta: PaginationMetaType;
};

export type InstallationCompanyPlcEventType = {
  __typename?: 'InstallationCompanyPlcEventType';
  created: Scalars['DateTime']['output'];
  customerTicket?: Maybe<InstallationCompanyCustomerTicketType>;
  device: InstallationCompanyDeviceType;
  deviceId: Scalars['ID']['output'];
  eventId: Scalars['Int']['output'];
  eventType?: Maybe<DeviceEventTypeType>;
  id: Scalars['ID']['output'];
  markedAsRead: Scalars['Boolean']['output'];
};

export type InstallationCompanyRegistrationInput = {
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  street: Scalars['String']['input'];
  vatId: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type InstallationCompanySelectItemType = {
  __typename?: 'InstallationCompanySelectItemType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InstallationCompanySendCommandResponse =
  | InstallationCompanySendCommandSuccessResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanySendCommandSuccessResponse = {
  __typename?: 'InstallationCompanySendCommandSuccessResponse';
  command: DeviceCommandType;
};

export type InstallationCompanyStripeSubscriptionType = {
  __typename?: 'InstallationCompanyStripeSubscriptionType';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  created: Scalars['DateTime']['output'];
  currentPeriodEnd: Scalars['DateTime']['output'];
  currentPeriodStart: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  installationCompany: InstallationCompanyType;
  installationCompanyTariff: InstallationCompanyTariffType;
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  organization: OrganizationUnionType;
  status: StripeSubscriptionStatusChoices;
  subscriptionCreated: Scalars['DateTime']['output'];
  tariff: StripeTariffType;
  tariffType: TariffTypeChoices;
};

export type InstallationCompanyTariffRestrictionInfoType = {
  __typename?: 'InstallationCompanyTariffRestrictionInfoType';
  actualNumberOfDevices: Scalars['Int']['output'];
  actualNumberOfUsers: Scalars['Int']['output'];
  availableNumberOfDevices?: Maybe<Scalars['Int']['output']>;
  availableNumberOfUsers?: Maybe<Scalars['Int']['output']>;
  devicesOverLimit: Scalars['Boolean']['output'];
  usersOverLimit: Scalars['Boolean']['output'];
};

export type InstallationCompanyTariffType = {
  __typename?: 'InstallationCompanyTariffType';
  activeSubscription?: Maybe<InstallationCompanyStripeSubscriptionType>;
  barChartDataGranularity: TariffBarChartDataGranularityChoices;
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  lineChartDataGranularity: TariffLineChartDataGranularityChoices;
  lineChartDataRetention: TariffLineChartDataRetentionChoices;
  name: Scalars['String']['output'];
  numberOfDevices?: Maybe<Scalars['Int']['output']>;
  numberOfUsers: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  priceType: TariffPriceTypeChoices;
  ticketsAllowed: Scalars['Boolean']['output'];
};

export type InstallationCompanyTicketActionResponse = InstallationCompanyTicketResponse | ObjectDoesNotExistsResponse;

export type InstallationCompanyTicketAttachmentType = {
  __typename?: 'InstallationCompanyTicketAttachmentType';
  created: Scalars['DateTime']['output'];
  file: FileType;
  fileSize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalFileName: Scalars['String']['output'];
};

export type InstallationCompanyTicketCommentAttachmentType = {
  __typename?: 'InstallationCompanyTicketCommentAttachmentType';
  created: Scalars['DateTime']['output'];
  file: FileType;
  fileSize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalFileName: Scalars['String']['output'];
};

export type InstallationCompanyTicketCommentCreateResponse =
  | FormErrorResponse
  | InstallationCompanyTicketCommentResponse;

export type InstallationCompanyTicketCommentDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyTicketCommentInput = {
  comment: Scalars['String']['input'];
  installationCompanyTicket: Scalars['ID']['input'];
};

export type InstallationCompanyTicketCommentResponse = {
  __typename?: 'InstallationCompanyTicketCommentResponse';
  installationCompanyTicketComment: InstallationCompanyTicketCommentType;
};

export type InstallationCompanyTicketCommentType = {
  __typename?: 'InstallationCompanyTicketCommentType';
  attachments: Array<InstallationCompanyTicketCommentAttachmentType>;
  comment: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
};

export type InstallationCompanyTicketCommentUpdateInput = {
  comment: Scalars['String']['input'];
};

export type InstallationCompanyTicketCommentUpdateResponse =
  | FormErrorResponse
  | InstallationCompanyTicketCommentResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyTicketCreateResponse = FormErrorResponse | InstallationCompanyTicketResponse;

export type InstallationCompanyTicketDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyTicketInput = {
  bridgePlcEvent?: InputMaybe<Scalars['ID']['input']>;
  content: Scalars['String']['input'];
  device?: InputMaybe<Scalars['ID']['input']>;
  installationCompanyTechnician?: InputMaybe<Scalars['ID']['input']>;
  priority: TicketPriorityChoices;
  title: Scalars['String']['input'];
};

export enum InstallationCompanyTicketOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
}

export type InstallationCompanyTicketResponse = {
  __typename?: 'InstallationCompanyTicketResponse';
  installationCompanyTicket: InstallationCompanyTicketType;
};

export type InstallationCompanyTicketType = {
  __typename?: 'InstallationCompanyTicketType';
  attachments: Array<InstallationCompanyTicketAttachmentType>;
  category: TicketCategoryChoices;
  content: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  device?: Maybe<InstallationCompanyDeviceType>;
  deviceEvent?: Maybe<DeviceEventType>;
  id: Scalars['ID']['output'];
  installationCompany: InstallationCompanyType;
  installationCompanyTechnician?: Maybe<UserSelectItemType>;
  isClosed: Scalars['Boolean']['output'];
  lastComment?: Maybe<CommentType>;
  modified: Scalars['DateTime']['output'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['output'];
  updatedBy?: Maybe<UserSelectItemType>;
};

export type InstallationCompanyTicketUpdateInput = {
  content: Scalars['String']['input'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['input'];
};

export type InstallationCompanyTicketUpdateResponse =
  | FormErrorResponse
  | InstallationCompanyTicketResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationCompanyType = {
  __typename?: 'InstallationCompanyType';
  city: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  country: CountryType;
  created: Scalars['DateTime']['output'];
  defaultPaymentMethod?: Maybe<Scalars['String']['output']>;
  deviceCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  subscription?: Maybe<InstallationCompanyStripeSubscriptionType>;
  tariff?: Maybe<InstallationCompanyTariffType>;
  ticketsAllowed: Scalars['Boolean']['output'];
  userCount: Scalars['Int']['output'];
  vatId: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type InstallationCompanyUpdateResponse = FormErrorResponse | InstallationCompanyUpdateSuccess;

export type InstallationCompanyUpdateSuccess = {
  __typename?: 'InstallationCompanyUpdateSuccess';
  installationCompany: InstallationCompanyType;
};

export type InstallationNoteAttachmentDeleteResponse = BaseSuccessResponse | ObjectDoesNotExistsResponse;

export type InstallationNoteAttachmentType = {
  __typename?: 'InstallationNoteAttachmentType';
  created: Scalars['DateTime']['output'];
  file: FileType;
  fileSize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalFileName: Scalars['String']['output'];
};

export type InstallationNoteCreateResponse = FormErrorResponse | InstallationNoteResponse;

export type InstallationNoteDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationNoteInput = {
  installation: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};

export type InstallationNoteResponse = {
  __typename?: 'InstallationNoteResponse';
  installationNote: InstallationNoteType;
};

export type InstallationNoteType = {
  __typename?: 'InstallationNoteType';
  attachments: Array<InstallationNoteAttachmentType>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  note: Scalars['String']['output'];
};

export type InstallationNoteUpdateInput = {
  note: Scalars['String']['input'];
};

export type InstallationNoteUpdateResponse =
  | FormErrorResponse
  | InstallationNoteResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type InstallationType = {
  __typename?: 'InstallationType';
  attachments: Array<InstallationAttachmentType>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  id: Scalars['ID']['output'];
  installationDate: Scalars['Date']['output'];
  modified: Scalars['DateTime']['output'];
  updatedBy?: Maybe<UserSelectItemType>;
};

export type InvalidCredentialsError = {
  __typename?: 'InvalidCredentialsError';
  ok: Scalars['Boolean']['output'];
};

export type InvalidDeviceResponse = {
  __typename?: 'InvalidDeviceResponse';
  deviceId: Scalars['ID']['output'];
};

export type InvalidRefreshToken = {
  __typename?: 'InvalidRefreshToken';
  ok: Scalars['Boolean']['output'];
};

export type InvalidTemperatureOffsetResponse = {
  __typename?: 'InvalidTemperatureOffsetResponse';
  temperatureOffset: Scalars['Int']['output'];
};

export type ManufacturerDeviceType = {
  __typename?: 'ManufacturerDeviceType';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  deviceType: DeviceTypeType;
  id: Scalars['ID']['output'];
  installationCompany?: Maybe<InstallationCompanyType>;
  lastMetricsTimestamp?: Maybe<Scalars['DateTime']['output']>;
  manufacturerModifiedAt: Scalars['DateTime']['output'];
  manufacturerNote: Scalars['String']['output'];
  modified: Scalars['DateTime']['output'];
  mqttTopics: Array<Scalars['String']['output']>;
  plcPassword: Scalars['String']['output'];
  plcUsername: Scalars['String']['output'];
  registrationToken: Scalars['String']['output'];
  serialNumber: Scalars['String']['output'];
  state: DeviceStateChoices;
};

export type ManufacturerManufacturerTicketActionResponse =
  | ManufacturerManufacturerTicketResponse
  | ObjectDoesNotExistsResponse;

export type ManufacturerManufacturerTicketCreateResponse = FormErrorResponse | ManufacturerManufacturerTicketResponse;

export type ManufacturerManufacturerTicketInput = {
  content: Scalars['String']['input'];
  device?: InputMaybe<Scalars['ID']['input']>;
  installationCompany: Scalars['ID']['input'];
  manufacturer: Scalars['ID']['input'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['input'];
};

export type ManufacturerManufacturerTicketResponse = {
  __typename?: 'ManufacturerManufacturerTicketResponse';
  manufacturerTicket: ManufacturerManufacturerTicketType;
};

export type ManufacturerManufacturerTicketType = {
  __typename?: 'ManufacturerManufacturerTicketType';
  assignee: ManufacturerTicketAssigneeChoices;
  attachments: Array<ManufacturerTicketAttachmentType>;
  category: TicketCategoryChoices;
  content: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  device?: Maybe<ManufacturerDeviceType>;
  id: Scalars['ID']['output'];
  installationCompany: InstallationCompanyType;
  isClosed: Scalars['Boolean']['output'];
  lastComment?: Maybe<CommentType>;
  manufacturer: ManufacturerType;
  modified: Scalars['DateTime']['output'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['output'];
  updatedBy?: Maybe<UserSelectItemType>;
};

export type ManufacturerManufacturerTicketUpdateResponse =
  | FormErrorResponse
  | ManufacturerManufacturerTicketResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type ManufacturerPaginatedDeviceListType = {
  __typename?: 'ManufacturerPaginatedDeviceListType';
  items: Array<ManufacturerDeviceType>;
  meta: PaginationMetaType;
};

export type ManufacturerRegistrationInput = {
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  note?: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  street: Scalars['String']['input'];
  vatId: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type ManufacturerSelectItemType = {
  __typename?: 'ManufacturerSelectItemType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ManufacturerSubscriptionInput = {
  currentPeriodEnd: Scalars['DateTime']['input'];
  currentPeriodStart: Scalars['DateTime']['input'];
  manufacturerTariff: Scalars['ID']['input'];
};

export type ManufacturerSubscriptionType = {
  __typename?: 'ManufacturerSubscriptionType';
  created: Scalars['DateTime']['output'];
  currentPeriodEnd: Scalars['DateTime']['output'];
  currentPeriodStart: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isExpired: Scalars['Boolean']['output'];
  manufacturer: ManufacturerType;
  manufacturerTariff: ManufacturerTariffType;
  modified: Scalars['DateTime']['output'];
};

export type ManufacturerTariffRestrictionInfoType = {
  __typename?: 'ManufacturerTariffRestrictionInfoType';
  actualNumberOfDeviceTypes: Scalars['Int']['output'];
  actualNumberOfDevices: Scalars['Int']['output'];
  availableNumberOfDeviceTypes?: Maybe<Scalars['Int']['output']>;
  availableNumberOfDevices?: Maybe<Scalars['Int']['output']>;
  deviceTypesOverLimit: Scalars['Boolean']['output'];
  devicesOverLimit: Scalars['Boolean']['output'];
};

export type ManufacturerTariffType = {
  __typename?: 'ManufacturerTariffType';
  activeSubscription?: Maybe<ManufacturerSubscriptionType>;
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  numberOfDeviceTypes?: Maybe<Scalars['Int']['output']>;
  numberOfDevices?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Float']['output'];
  priceType: TariffPriceTypeChoices;
};

export enum ManufacturerTicketAssigneeChoices {
  InstallationCompany = 'INSTALLATION_COMPANY',
  Manufacturer = 'MANUFACTURER',
}

export type ManufacturerTicketAttachmentType = {
  __typename?: 'ManufacturerTicketAttachmentType';
  created: Scalars['DateTime']['output'];
  file: FileType;
  fileSize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalFileName: Scalars['String']['output'];
};

export type ManufacturerTicketCommentAttachmentType = {
  __typename?: 'ManufacturerTicketCommentAttachmentType';
  created: Scalars['DateTime']['output'];
  file: FileType;
  fileSize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalFileName: Scalars['String']['output'];
};

export type ManufacturerTicketCommentCreateResponse = FormErrorResponse | ManufacturerTicketCommentResponse;

export type ManufacturerTicketCommentDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export type ManufacturerTicketCommentInput = {
  comment: Scalars['String']['input'];
  manufacturerTicket: Scalars['ID']['input'];
};

export type ManufacturerTicketCommentResponse = {
  __typename?: 'ManufacturerTicketCommentResponse';
  manufacturerTicketComment: ManufacturerTicketCommentType;
};

export type ManufacturerTicketCommentType = {
  __typename?: 'ManufacturerTicketCommentType';
  attachments: Array<ManufacturerTicketCommentAttachmentType>;
  comment: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
};

export type ManufacturerTicketCommentUpdateInput = {
  comment: Scalars['String']['input'];
};

export type ManufacturerTicketCommentUpdateResponse =
  | FormErrorResponse
  | ManufacturerTicketCommentResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type ManufacturerTicketDeleteResponse =
  | BaseSuccessResponse
  | ObjectCannotBeDeletedResponse
  | ObjectDoesNotExistsResponse;

export enum ManufacturerTicketOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
}

export type ManufacturerTicketUpdateInput = {
  content: Scalars['String']['input'];
  priority: TicketPriorityChoices;
  title: Scalars['String']['input'];
};

export type ManufacturerType = {
  __typename?: 'ManufacturerType';
  city: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  country: CountryType;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  deviceCount: Scalars['Int']['output'];
  deviceTypeCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  subscription?: Maybe<ManufacturerSubscriptionType>;
  tariff?: Maybe<ManufacturerTariffType>;
  userCount: Scalars['Int']['output'];
  vatId: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type ManufacturerUpdateDeviceSuccess = {
  __typename?: 'ManufacturerUpdateDeviceSuccess';
  device: ManufacturerDeviceType;
};

export enum ManufacturersOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
}

export type Mutation = {
  __typename?: 'Mutation';
  customerCheckoutSessionCreate: CheckoutSessionCreateResponse;
  customerCustomerSiteCreate: CustomerSiteCreateResponse;
  customerCustomerSiteDelete: CustomerSiteDeleteResponse;
  customerCustomerSiteSetDefault: CustomerSiteDeleteResponse;
  customerCustomerSiteUpdate: CustomerSiteUpdateResponse;
  customerCustomerTicketAssign: CustomerCustomerTicketActionResponse;
  customerCustomerTicketAttachmentDelete: AttachmentDeleteResponse;
  customerCustomerTicketClose: CustomerCustomerTicketActionResponse;
  customerCustomerTicketCommentAttachmentDelete: AttachmentDeleteResponse;
  customerCustomerTicketCommentCreate: CustomerTicketCommentCreateResponse;
  customerCustomerTicketCommentDelete: CustomerTicketCommentDeleteResponse;
  customerCustomerTicketCommentUpdate: CustomerTicketCommentUpdateResponse;
  customerCustomerTicketCreate: CustomerCustomerTicketCreateResponse;
  customerCustomerTicketDelete: CustomerTicketDeleteResponse;
  customerCustomerTicketUpdate: CustomerCustomerTicketUpdateResponse;
  customerCustomerUpdate: CustomerUpdateResponse;
  customerDeviceMoveRegistration: CustomerDeviceRegisterResponse;
  customerDeviceRegister: CustomerDeviceRegisterResponse;
  customerDeviceUnregister: CustomerDeviceUnregisterResponse;
  customerExportMetrics: PlcExportResponse;
  customerInviteRegistration: CustomerInviteRegistrationResponse;
  customerPaymentMethodCreate: PaymentMethodCreateResponse;
  customerPlcEventMarkAsRead: PlcEventMarkAsReadResponse;
  customerPlcEventMarkAsUnread: PlcEventMarkAsUnreadResponse;
  customerRegistration: UserRegistrationResponse;
  customerSetTemperature: CustomerSetTemperatureResponse;
  customerStripeSubscriptionCancel: SubscriptionCancelResponse;
  customerStripeSubscriptionUpdate: SubscriptionUpdateResponse;
  customerUserDisable: UserDisableResponse;
  customerUserEnable: UserEnableResponse;
  customerUserInvitation: UserInvitationResponse;
  customerUserUpdate: UserUpdateResponse;
  installationCompanyCheckoutSessionCreate: CheckoutSessionCreateResponse;
  installationCompanyCustomerTicketAssign: InstallationCompanyCustomerTicketActionResponse;
  installationCompanyCustomerTicketAssignTechnician: InstallationCompanyCustomerTicketActionResponse;
  installationCompanyCustomerTicketAttachmentDelete: AttachmentDeleteResponse;
  installationCompanyCustomerTicketClose: InstallationCompanyCustomerTicketActionResponse;
  installationCompanyCustomerTicketCommentAttachmentDelete: AttachmentDeleteResponse;
  installationCompanyCustomerTicketCommentCreate: CustomerTicketCommentCreateResponse;
  installationCompanyCustomerTicketCommentDelete: CustomerTicketCommentDeleteResponse;
  installationCompanyCustomerTicketCommentUpdate: CustomerTicketCommentUpdateResponse;
  installationCompanyCustomerTicketCreate: InstallationCompanyCustomerTicketCreateResponse;
  installationCompanyCustomerTicketDelete: CustomerTicketDeleteResponse;
  installationCompanyCustomerTicketUpdate: InstallationCompanyCustomerTicketUpdateResponse;
  installationCompanyDeviceMoveRegistration: InstallationCompanyDeviceRegisterResponse;
  installationCompanyDeviceRegister: InstallationCompanyDeviceRegisterResponse;
  installationCompanyDeviceRegisterNewCustomer: InstallationCompanyDeviceRegisterResponse;
  installationCompanyDeviceRegisterNewSite: InstallationCompanyDeviceRegisterResponse;
  installationCompanyDeviceUnregister: InstallationCompanyDeviceUninstallResponse;
  installationCompanyExportMetrics: PlcExportResponse;
  installationCompanyInstallationAttachmentCreate: AttachmentCreateResponse;
  installationCompanyInstallationAttachmentDelete: InstallationAttachmentDeleteResponse;
  installationCompanyInstallationCompanyConfiguration: InstallationCompanyConfigurationResponse;
  installationCompanyInstallationCompanyCustomerDelete: InstallationCompanyCustomerDeleteResponse;
  installationCompanyInstallationCompanyCustomerInvitation: InstallationCompanyCustomerInvitationResponse;
  installationCompanyInstallationCompanyCustomerSiteDelete: InstallationCompanyCustomerSiteDeleteResponse;
  installationCompanyInstallationCompanyCustomerSiteUpdate: InstallationCompanyCustomerSiteUpdateResponse;
  installationCompanyInstallationCompanyCustomerUpdate: InstallationCompanyCustomerUpdateResponse;
  installationCompanyInstallationCompanyTicketAssignTechnician: InstallationCompanyTicketActionResponse;
  installationCompanyInstallationCompanyTicketAttachmentDelete: AttachmentDeleteResponse;
  installationCompanyInstallationCompanyTicketClose: InstallationCompanyTicketActionResponse;
  installationCompanyInstallationCompanyTicketCommentAttachmentDelete: AttachmentDeleteResponse;
  installationCompanyInstallationCompanyTicketCommentCreate: InstallationCompanyTicketCommentCreateResponse;
  installationCompanyInstallationCompanyTicketCommentDelete: InstallationCompanyTicketCommentDeleteResponse;
  installationCompanyInstallationCompanyTicketCommentUpdate: InstallationCompanyTicketCommentUpdateResponse;
  installationCompanyInstallationCompanyTicketCreate: InstallationCompanyTicketCreateResponse;
  installationCompanyInstallationCompanyTicketDelete: InstallationCompanyTicketDeleteResponse;
  installationCompanyInstallationCompanyTicketUpdate: InstallationCompanyTicketUpdateResponse;
  installationCompanyInstallationCompanyUpdate: InstallationCompanyUpdateResponse;
  installationCompanyInstallationNoteAttachmentDelete: InstallationNoteAttachmentDeleteResponse;
  installationCompanyInstallationNoteCreate: InstallationNoteCreateResponse;
  installationCompanyInstallationNoteDelete: InstallationNoteDeleteResponse;
  installationCompanyInstallationNoteUpdate: InstallationNoteUpdateResponse;
  installationCompanyManufacturerTicketAssign: InstallationCompanyManufacturerTicketActionResponse;
  installationCompanyManufacturerTicketAssignTechnician: InstallationCompanyManufacturerTicketActionResponse;
  installationCompanyManufacturerTicketAttachmentDelete: AttachmentDeleteResponse;
  installationCompanyManufacturerTicketClose: InstallationCompanyManufacturerTicketActionResponse;
  installationCompanyManufacturerTicketCommentAttachmentDelete: AttachmentDeleteResponse;
  installationCompanyManufacturerTicketCommentCreate: ManufacturerTicketCommentCreateResponse;
  installationCompanyManufacturerTicketCommentDelete: ManufacturerTicketCommentDeleteResponse;
  installationCompanyManufacturerTicketCommentUpdate: ManufacturerTicketCommentUpdateResponse;
  installationCompanyManufacturerTicketCreate: InstallationCompanyManufacturerTicketCreateResponse;
  installationCompanyManufacturerTicketDelete: ManufacturerTicketDeleteResponse;
  installationCompanyManufacturerTicketUpdate: InstallationCompanyManufacturerTicketUpdateResponse;
  installationCompanyPlcEventMarkAsRead: PlcEventMarkAsReadResponse;
  installationCompanyPlcEventMarkAsUnread: PlcEventMarkAsUnreadResponse;
  installationCompanyRegistration: UserRegistrationResponse;
  installationCompanySendCommand: InstallationCompanySendCommandResponse;
  installationCompanyStripeSubscriptionCancel: SubscriptionCancelResponse;
  installationCompanyStripeSubscriptionUpdate: SubscriptionUpdateResponse;
  installationCompanyUserDisable: UserDisableResponse;
  installationCompanyUserEnable: UserEnableResponse;
  installationCompanyUserInvitation: UserInvitationResponse;
  installationCompanyUserUpdate: UserUpdateResponse;
  manufacturerDeviceCreate: CreateDevicesResponse;
  manufacturerDeviceDelete: DeleteDeviceResponse;
  manufacturerDeviceEventTypeDelete: DeviceEventTypeDeleteResponse;
  manufacturerDeviceEventTypeUpsert: DeviceEventTypeUpsertResponse;
  manufacturerDeviceExport: DeviceExportResponse;
  manufacturerDeviceTypeDelete: DeviceTypeDeleteResponse;
  manufacturerDeviceTypeUpsert: DeviceTypeUpsertResponse;
  manufacturerDeviceUpdate: UpdateDeviceResponse;
  manufacturerManufacturerTicketAssign: ManufacturerManufacturerTicketActionResponse;
  manufacturerManufacturerTicketAttachmentDelete: AttachmentDeleteResponse;
  manufacturerManufacturerTicketClose: ManufacturerManufacturerTicketActionResponse;
  manufacturerManufacturerTicketCommentAttachmentDelete: AttachmentDeleteResponse;
  manufacturerManufacturerTicketCommentCreate: ManufacturerTicketCommentCreateResponse;
  manufacturerManufacturerTicketCommentDelete: ManufacturerTicketCommentDeleteResponse;
  manufacturerManufacturerTicketCommentUpdate: ManufacturerTicketCommentUpdateResponse;
  manufacturerManufacturerTicketCreate: ManufacturerManufacturerTicketCreateResponse;
  manufacturerManufacturerTicketDelete: ManufacturerTicketDeleteResponse;
  manufacturerManufacturerTicketUpdate: ManufacturerManufacturerTicketUpdateResponse;
  manufacturerUserDisable: UserDisableResponse;
  manufacturerUserEnable: UserEnableResponse;
  manufacturerUserInvitation: UserInvitationResponse;
  manufacturerUserUpdate: UserUpdateResponse;
  preSignedUploadCreate: PreSignedUploadsResponse;
  systemAdminManufacturerCreate: UserRegistrationResponse;
  systemAdminManufacturerUpdate: SystemAdminManufacturerUpdateResponse;
  systemAdminUserDisable: UserDisableResponse;
  systemAdminUserEnable: UserEnableResponse;
  systemAdminUserInvitation: UserInvitationResponse;
  systemAdminUserUpdate: UserUpdateResponse;
  tokenObtain: TokenObtainResponse;
  tokenRefresh: TokenRefreshResponse;
  tokenRevoke: BaseSuccessResponse;
  translatorTokenObtain: Scalars['String']['output'];
  userActivation: UserActivationResponse;
  userChangePassword: UserChangePasswordResponse;
  userForgottenPassword: BaseSuccessResponse;
  userSetLanguage: UserSetLanguageResponse;
  userSetPassword: UserResetPasswordResponse;
  userVerifyToken: TokenVerificationResponse;
};

export type MutationCustomerCheckoutSessionCreateArgs = {
  tariffId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerSiteCreateArgs = {
  customerSiteInput: CustomerSiteInput;
};

export type MutationCustomerCustomerSiteDeleteArgs = {
  customerSiteId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerSiteSetDefaultArgs = {
  customerSiteId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerSiteUpdateArgs = {
  customerSiteId: Scalars['ID']['input'];
  customerSiteInput: CustomerSiteInput;
};

export type MutationCustomerCustomerTicketAssignArgs = {
  assignee: CustomerTicketAssigneeChoices;
  ticketId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerTicketAttachmentDeleteArgs = {
  customerTicketAttachmentId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerTicketCloseArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerTicketCommentAttachmentDeleteArgs = {
  customerTicketCommentAttachmentId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerTicketCommentCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  customerTicketCommentInput: CustomerTicketCommentInput;
};

export type MutationCustomerCustomerTicketCommentDeleteArgs = {
  customerCommentId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerTicketCommentUpdateArgs = {
  customerCommentId: Scalars['ID']['input'];
  customerTicketCommentInput: CustomerTicketCommentUpdateInput;
};

export type MutationCustomerCustomerTicketCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  customerTicketInput: CustomerCustomerTicketInput;
};

export type MutationCustomerCustomerTicketDeleteArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerTicketUpdateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  customerTicketUpdateInput: CustomerTicketUpdateInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationCustomerCustomerUpdateArgs = {
  customerInput: CustomerUpdateInput;
};

export type MutationCustomerDeviceMoveRegistrationArgs = {
  customerDeviceMoveRegistrationInput: CustomerDeviceMoveRegistrationInput;
};

export type MutationCustomerDeviceRegisterArgs = {
  customerDeviceRegisterInput: CustomerDeviceRegisterInput;
};

export type MutationCustomerDeviceUnregisterArgs = {
  deviceId: Scalars['ID']['input'];
};

export type MutationCustomerExportMetricsArgs = {
  date: Scalars['Date']['input'];
  deviceId: Scalars['ID']['input'];
};

export type MutationCustomerInviteRegistrationArgs = {
  customerInput?: InputMaybe<CustomerRegistrationInput>;
  token: Scalars['String']['input'];
  userRegistrationInput: UserRegistrationInput;
};

export type MutationCustomerPlcEventMarkAsReadArgs = {
  plcEventId: Scalars['ID']['input'];
};

export type MutationCustomerPlcEventMarkAsUnreadArgs = {
  plcEventId: Scalars['ID']['input'];
};

export type MutationCustomerRegistrationArgs = {
  customerInput?: InputMaybe<CustomerRegistrationInput>;
  userRegistrationInput: UserRegistrationInput;
};

export type MutationCustomerSetTemperatureArgs = {
  deviceId: Scalars['ID']['input'];
  temperatureOffset: Scalars['Int']['input'];
};

export type MutationCustomerStripeSubscriptionCancelArgs = {
  subscriptionId: Scalars['ID']['input'];
};

export type MutationCustomerStripeSubscriptionUpdateArgs = {
  subscriptionId: Scalars['ID']['input'];
  tariffId: Scalars['ID']['input'];
};

export type MutationCustomerUserDisableArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationCustomerUserEnableArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationCustomerUserInvitationArgs = {
  userInvitationInput: UserInvitationInput;
};

export type MutationCustomerUserUpdateArgs = {
  userId: Scalars['ID']['input'];
  userUpdateInput: UserUpdateInput;
};

export type MutationInstallationCompanyCheckoutSessionCreateArgs = {
  tariffId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyCustomerTicketAssignArgs = {
  assignee: CustomerTicketAssigneeChoices;
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyCustomerTicketAssignTechnicianArgs = {
  installationCompanyTechnicianId?: InputMaybe<Scalars['ID']['input']>;
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyCustomerTicketAttachmentDeleteArgs = {
  customerTicketAttachmentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyCustomerTicketCloseArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyCustomerTicketCommentAttachmentDeleteArgs = {
  customerTicketCommentAttachmentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyCustomerTicketCommentCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  customerTicketCommentInput: CustomerTicketCommentInput;
};

export type MutationInstallationCompanyCustomerTicketCommentDeleteArgs = {
  customerCommentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyCustomerTicketCommentUpdateArgs = {
  customerCommentId: Scalars['ID']['input'];
  customerTicketCommentInput: CustomerTicketCommentUpdateInput;
};

export type MutationInstallationCompanyCustomerTicketCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  customerTicketInput: InstallationCompanyCustomerTicketInput;
};

export type MutationInstallationCompanyCustomerTicketDeleteArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyCustomerTicketUpdateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  customerTicketUpdateInput: CustomerTicketUpdateInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyDeviceMoveRegistrationArgs = {
  customerDeviceMoveRegistrationInput: InstallationCompanyMoveRegistrationInput;
};

export type MutationInstallationCompanyDeviceRegisterArgs = {
  deviceRegisterInput: DeviceRegisterInput;
  existingCustomerSiteInput: InstallationCompanyExistingCustomerSiteInput;
};

export type MutationInstallationCompanyDeviceRegisterNewCustomerArgs = {
  customerInput: InstallationCompanyCustomerInput;
  customerSiteInput: InstallationCompanyCustomerSiteInput;
  deviceRegisterInput?: InputMaybe<DeviceRegisterInput>;
  sendInvitation?: Scalars['Boolean']['input'];
};

export type MutationInstallationCompanyDeviceRegisterNewSiteArgs = {
  customerSiteInput: InstallationCompanyCustomerSiteInput;
  deviceRegisterInput?: InputMaybe<DeviceRegisterInput>;
  existingCustomerInput: InstallationCompanyExistingCustomerInput;
};

export type MutationInstallationCompanyDeviceUnregisterArgs = {
  deviceId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyExportMetricsArgs = {
  date: Scalars['Date']['input'];
  deviceId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationAttachmentCreateArgs = {
  installationAttachmentInputs: Array<InstallationAttachmentInput>;
};

export type MutationInstallationCompanyInstallationAttachmentDeleteArgs = {
  installationAttachmentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyConfigurationArgs = {
  installationCompanyConfigurationInput: InstallationCompanyConfigurationInput;
};

export type MutationInstallationCompanyInstallationCompanyCustomerDeleteArgs = {
  customerId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyCustomerInvitationArgs = {
  installationCompanyCustomerId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyCustomerSiteDeleteArgs = {
  customerSiteId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyCustomerSiteUpdateArgs = {
  customerSiteId: Scalars['ID']['input'];
  customerSiteInput: InstallationCompanyCustomerSiteInput;
};

export type MutationInstallationCompanyInstallationCompanyCustomerUpdateArgs = {
  customerId: Scalars['ID']['input'];
  customerInput: InstallationCompanyCustomerInput;
};

export type MutationInstallationCompanyInstallationCompanyTicketAssignTechnicianArgs = {
  installationCompanyTechnicianId?: InputMaybe<Scalars['ID']['input']>;
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyTicketAttachmentDeleteArgs = {
  installationCompanyTicketAttachmentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyTicketCloseArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyTicketCommentAttachmentDeleteArgs = {
  installationCompanyTicketCommentAttachmentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyTicketCommentCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  installationCompanyTicketCommentInput: InstallationCompanyTicketCommentInput;
};

export type MutationInstallationCompanyInstallationCompanyTicketCommentDeleteArgs = {
  installationCompanyTicketCommentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyTicketCommentUpdateArgs = {
  installationCompanyTicketCommentId: Scalars['ID']['input'];
  installationCompanyTicketCommentInput: InstallationCompanyTicketCommentUpdateInput;
};

export type MutationInstallationCompanyInstallationCompanyTicketCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  installationCompanyTicketInput: InstallationCompanyTicketInput;
};

export type MutationInstallationCompanyInstallationCompanyTicketDeleteArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyTicketUpdateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  installationCompanyTicketUpdateInput: InstallationCompanyTicketUpdateInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationCompanyUpdateArgs = {
  installationCompanyInput: InstallationCompanyRegistrationInput;
};

export type MutationInstallationCompanyInstallationNoteAttachmentDeleteArgs = {
  installationNoteAttachmentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationNoteCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  installationNoteInput: InstallationNoteInput;
};

export type MutationInstallationCompanyInstallationNoteDeleteArgs = {
  installationNoteId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyInstallationNoteUpdateArgs = {
  installationNoteId: Scalars['ID']['input'];
  installationNoteInput: InstallationNoteUpdateInput;
};

export type MutationInstallationCompanyManufacturerTicketAssignArgs = {
  assignee: ManufacturerTicketAssigneeChoices;
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyManufacturerTicketAssignTechnicianArgs = {
  installationCompanyTechnicianId?: InputMaybe<Scalars['ID']['input']>;
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyManufacturerTicketAttachmentDeleteArgs = {
  manufacturerTicketAttachmentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyManufacturerTicketCloseArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyManufacturerTicketCommentAttachmentDeleteArgs = {
  manufacturerTicketCommentAttachmentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyManufacturerTicketCommentCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  manufacturerTicketCommentInput: ManufacturerTicketCommentInput;
};

export type MutationInstallationCompanyManufacturerTicketCommentDeleteArgs = {
  manufacturerTicketCommentId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyManufacturerTicketCommentUpdateArgs = {
  manufacturerTicketCommentId: Scalars['ID']['input'];
  manufacturerTicketCommentInput: ManufacturerTicketCommentUpdateInput;
};

export type MutationInstallationCompanyManufacturerTicketCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  installationCompanyManufacturerTicketInput: InstallationCompanyManufacturerTicketInput;
};

export type MutationInstallationCompanyManufacturerTicketDeleteArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyManufacturerTicketUpdateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  manufacturerTicketUpdateInput: ManufacturerTicketUpdateInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyPlcEventMarkAsReadArgs = {
  plcEventId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyPlcEventMarkAsUnreadArgs = {
  plcEventId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyRegistrationArgs = {
  installationCompanyInput: InstallationCompanyRegistrationInput;
  userRegistrationInput: UserRegistrationInput;
};

export type MutationInstallationCompanySendCommandArgs = {
  command: Scalars['String']['input'];
  deviceId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyStripeSubscriptionCancelArgs = {
  subscriptionId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyStripeSubscriptionUpdateArgs = {
  subscriptionId: Scalars['ID']['input'];
  tariffId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyUserDisableArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyUserEnableArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationInstallationCompanyUserInvitationArgs = {
  userInvitationInput: UserInvitationInput;
};

export type MutationInstallationCompanyUserUpdateArgs = {
  userId: Scalars['ID']['input'];
  userUpdateInput: UserUpdateInput;
};

export type MutationManufacturerDeviceCreateArgs = {
  deviceCreateInput: DeviceCreateInput;
};

export type MutationManufacturerDeviceDeleteArgs = {
  deviceId: Scalars['ID']['input'];
};

export type MutationManufacturerDeviceEventTypeDeleteArgs = {
  deviceEventTypeId: Scalars['ID']['input'];
};

export type MutationManufacturerDeviceEventTypeUpsertArgs = {
  deviceEventTypeId?: InputMaybe<Scalars['ID']['input']>;
  deviceTypeId: Scalars['ID']['input'];
  input: DeviceEventTypeInput;
};

export type MutationManufacturerDeviceExportArgs = {
  devices: Array<Scalars['ID']['input']>;
};

export type MutationManufacturerDeviceTypeDeleteArgs = {
  deviceTypeId: Scalars['ID']['input'];
};

export type MutationManufacturerDeviceTypeUpsertArgs = {
  deviceTypeId?: InputMaybe<Scalars['ID']['input']>;
  deviceTypeInput: DeviceTypeInput;
};

export type MutationManufacturerDeviceUpdateArgs = {
  deviceId: Scalars['ID']['input'];
  deviceInput: DeviceInput;
};

export type MutationManufacturerManufacturerTicketAssignArgs = {
  assignee: ManufacturerTicketAssigneeChoices;
  ticketId: Scalars['ID']['input'];
};

export type MutationManufacturerManufacturerTicketAttachmentDeleteArgs = {
  manufacturerTicketAttachmentId: Scalars['ID']['input'];
};

export type MutationManufacturerManufacturerTicketCloseArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationManufacturerManufacturerTicketCommentAttachmentDeleteArgs = {
  manufacturerTicketCommentAttachmentId: Scalars['ID']['input'];
};

export type MutationManufacturerManufacturerTicketCommentCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  manufacturerTicketCommentInput: ManufacturerTicketCommentInput;
};

export type MutationManufacturerManufacturerTicketCommentDeleteArgs = {
  manufacturerTicketCommentId: Scalars['ID']['input'];
};

export type MutationManufacturerManufacturerTicketCommentUpdateArgs = {
  manufacturerTicketCommentId: Scalars['ID']['input'];
  manufacturerTicketCommentInput: ManufacturerTicketCommentUpdateInput;
};

export type MutationManufacturerManufacturerTicketCreateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  manufacturerManufacturerTicketInput: ManufacturerManufacturerTicketInput;
};

export type MutationManufacturerManufacturerTicketDeleteArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationManufacturerManufacturerTicketUpdateArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  manufacturerTicketUpdateInput: ManufacturerTicketUpdateInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationManufacturerUserDisableArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationManufacturerUserEnableArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationManufacturerUserInvitationArgs = {
  userInvitationInput: UserInvitationInput;
};

export type MutationManufacturerUserUpdateArgs = {
  userId: Scalars['ID']['input'];
  userUpdateInput: UserUpdateInput;
};

export type MutationPreSignedUploadCreateArgs = {
  files: Array<FileUploadInput>;
};

export type MutationSystemAdminManufacturerCreateArgs = {
  manufacturerInput: ManufacturerRegistrationInput;
  manufacturerSubscriptionInput: ManufacturerSubscriptionInput;
  userCreationInput: UserCreationInput;
};

export type MutationSystemAdminManufacturerUpdateArgs = {
  manufacturerId: Scalars['ID']['input'];
  manufacturerInput: ManufacturerRegistrationInput;
  manufacturerSubscriptionId: Scalars['ID']['input'];
  manufacturerSubscriptionInput: ManufacturerSubscriptionInput;
};

export type MutationSystemAdminUserDisableArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationSystemAdminUserEnableArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationSystemAdminUserInvitationArgs = {
  userInvitationInput: AdminUserInvitationInput;
};

export type MutationSystemAdminUserUpdateArgs = {
  userId: Scalars['ID']['input'];
  userUpdateInput: AdminUserUpdateInput;
};

export type MutationTokenObtainArgs = {
  input: TokenObtainInput;
};

export type MutationUserActivationArgs = {
  userActivationInput: UserActivationInput;
};

export type MutationUserChangePasswordArgs = {
  userChangePasswordInput: UserChangePasswordInput;
};

export type MutationUserForgottenPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationUserSetLanguageArgs = {
  languageCode: UserLanguagesChoices;
};

export type MutationUserSetPasswordArgs = {
  userSetPasswordInput: UserSetPasswordInput;
};

export type MutationUserVerifyTokenArgs = {
  input: TokenVerificationInput;
};

export type ObjectCannotBeDeletedResponse = {
  __typename?: 'ObjectCannotBeDeletedResponse';
  id: Scalars['ID']['output'];
};

export type ObjectCannotBeModifiedResponse = {
  __typename?: 'ObjectCannotBeModifiedResponse';
  id: Scalars['ID']['output'];
};

export type ObjectDoesNotExistsResponse = {
  __typename?: 'ObjectDoesNotExistsResponse';
  id: Scalars['ID']['output'];
};

export type OrganizationUnionType = CustomerType | InstallationCompanyType | ManufacturerType;

export type PlcfveBarChartData = {
  __typename?: 'PLCFVEBarChartData';
  bucket: Scalars['DateTime']['output'];
  gridConsumption?: Maybe<Scalars['Float']['output']>;
  gridSupply?: Maybe<Scalars['Float']['output']>;
  loadPower?: Maybe<Scalars['Float']['output']>;
  mpptPower?: Maybe<Scalars['Float']['output']>;
};

export type PlcfveBarChartDataList = {
  __typename?: 'PLCFVEBarChartDataList';
  data: Array<PlcfveBarChartData>;
};

export type PlcfveBarChartDataResponse = PlcfveBarChartDataList | TariffRestrictionResponse;

export type PlcfveLineChartData = {
  __typename?: 'PLCFVELineChartData';
  batteryPower?: Maybe<Scalars['Float']['output']>;
  batterySoc?: Maybe<Scalars['Float']['output']>;
  bucket: Scalars['DateTime']['output'];
  gridL1Power?: Maybe<Scalars['Float']['output']>;
  gridL2Power?: Maybe<Scalars['Float']['output']>;
  gridL3Power?: Maybe<Scalars['Float']['output']>;
  gridPower?: Maybe<Scalars['Float']['output']>;
  loadL1Power?: Maybe<Scalars['Float']['output']>;
  loadL2Power?: Maybe<Scalars['Float']['output']>;
  loadL3Power?: Maybe<Scalars['Float']['output']>;
  loadPower?: Maybe<Scalars['Float']['output']>;
  mpptPower?: Maybe<Scalars['Float']['output']>;
};

export type PlcfveLineChartDataList = {
  __typename?: 'PLCFVELineChartDataList';
  data: Array<PlcfveLineChartData>;
};

export type PlcfveLineChartDataResponse = PlcfveLineChartDataList | TariffRestrictionResponse;

export type PlcHeatPumpBarChartData = {
  __typename?: 'PLCHeatPumpBarChartData';
  bucket: Scalars['DateTime']['output'];
  dhwTemperature?: Maybe<Scalars['Float']['output']>;
  indoorTemperature?: Maybe<Scalars['Float']['output']>;
  inputPower?: Maybe<Scalars['Float']['output']>;
  outdoorTemperature?: Maybe<Scalars['Float']['output']>;
  outputPower?: Maybe<Scalars['Float']['output']>;
};

export type PlcHeatPumpBarChartDataList = {
  __typename?: 'PLCHeatPumpBarChartDataList';
  data: Array<PlcHeatPumpBarChartData>;
};

export type PlcHeatPumpBarChartDataResponse = PlcHeatPumpBarChartDataList | TariffRestrictionResponse;

export type PlcHeatPumpLineChartData = {
  __typename?: 'PLCHeatPumpLineChartData';
  bivalentPowerConsumption?: Maybe<Scalars['Float']['output']>;
  bucket: Scalars['DateTime']['output'];
  dhwTarget?: Maybe<Scalars['Float']['output']>;
  dhwTemperature?: Maybe<Scalars['Float']['output']>;
  indoorTemperature?: Maybe<Scalars['Float']['output']>;
  inputPower?: Maybe<Scalars['Float']['output']>;
  outdoorTemperature?: Maybe<Scalars['Float']['output']>;
  outputPower?: Maybe<Scalars['Float']['output']>;
  outputTemperature?: Maybe<Scalars['Float']['output']>;
  targetIndoorTemperature?: Maybe<Scalars['Float']['output']>;
};

export type PlcHeatPumpLineChartDataList = {
  __typename?: 'PLCHeatPumpLineChartDataList';
  data: Array<PlcHeatPumpLineChartData>;
};

export type PlcHeatPumpLineChartDataResponse = PlcHeatPumpLineChartDataList | TariffRestrictionResponse;

export type PaginatedCompaniesListType = {
  __typename?: 'PaginatedCompaniesListType';
  items: Array<InstallationCompanyType>;
  meta: PaginationMetaType;
};

export type PaginatedCustomerCustomerTicketListType = {
  __typename?: 'PaginatedCustomerCustomerTicketListType';
  items: Array<CustomerCustomerTicketType>;
  meta: PaginationMetaType;
};

export type PaginatedCustomerSitesListType = {
  __typename?: 'PaginatedCustomerSitesListType';
  items: Array<CustomerSiteType>;
  meta: PaginationMetaType;
};

export type PaginatedCustomerStripeSubscriptionListType = {
  __typename?: 'PaginatedCustomerStripeSubscriptionListType';
  items: Array<CustomerStripeSubscriptionType>;
  meta: PaginationMetaType;
};

export type PaginatedCustomerTicketCommentListType = {
  __typename?: 'PaginatedCustomerTicketCommentListType';
  items: Array<CustomerTicketCommentType>;
  meta: PaginationMetaType;
};

export type PaginatedCustomersListType = {
  __typename?: 'PaginatedCustomersListType';
  items: Array<CustomerType>;
  meta: PaginationMetaType;
};

export type PaginatedDeviceEventTypeListType = {
  __typename?: 'PaginatedDeviceEventTypeListType';
  items: Array<DeviceEventTypeType>;
  meta: PaginationMetaType;
};

export type PaginatedDeviceTypeListType = {
  __typename?: 'PaginatedDeviceTypeListType';
  items: Array<DeviceTypeType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationAttachmentListType = {
  __typename?: 'PaginatedInstallationAttachmentListType';
  items: Array<InstallationAttachmentType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationCompanyBaseTicketListType = {
  __typename?: 'PaginatedInstallationCompanyBaseTicketListType';
  items: Array<InstallationCompanyBaseTicketType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationCompanyCustomerSitesListType = {
  __typename?: 'PaginatedInstallationCompanyCustomerSitesListType';
  items: Array<InstallationCompanyCustomerSiteType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationCompanyCustomerTicketListType = {
  __typename?: 'PaginatedInstallationCompanyCustomerTicketListType';
  items: Array<InstallationCompanyCustomerTicketType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationCompanyCustomersListType = {
  __typename?: 'PaginatedInstallationCompanyCustomersListType';
  items: Array<InstallationCompanyCustomerType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationCompanyManufacturerTicketListType = {
  __typename?: 'PaginatedInstallationCompanyManufacturerTicketListType';
  items: Array<InstallationCompanyManufacturerTicketType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationCompanyStripeSubscriptionListType = {
  __typename?: 'PaginatedInstallationCompanyStripeSubscriptionListType';
  items: Array<InstallationCompanyStripeSubscriptionType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationCompanyTicketCommentListType = {
  __typename?: 'PaginatedInstallationCompanyTicketCommentListType';
  items: Array<InstallationCompanyTicketCommentType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationCompanyTicketListType = {
  __typename?: 'PaginatedInstallationCompanyTicketListType';
  items: Array<InstallationCompanyTicketType>;
  meta: PaginationMetaType;
};

export type PaginatedInstallationNoteListType = {
  __typename?: 'PaginatedInstallationNoteListType';
  items: Array<InstallationNoteType>;
  meta: PaginationMetaType;
};

export type PaginatedManufacturerManufacturerTicketListType = {
  __typename?: 'PaginatedManufacturerManufacturerTicketListType';
  items: Array<ManufacturerManufacturerTicketType>;
  meta: PaginationMetaType;
};

export type PaginatedManufacturerTicketCommentListType = {
  __typename?: 'PaginatedManufacturerTicketCommentListType';
  items: Array<ManufacturerTicketCommentType>;
  meta: PaginationMetaType;
};

export type PaginatedStripeSubscriptionListType = {
  __typename?: 'PaginatedStripeSubscriptionListType';
  items: Array<StripeSubscriptionType>;
  meta: PaginationMetaType;
};

export type PaginatedSystemAdminManufacturerTypeListType = {
  __typename?: 'PaginatedSystemAdminManufacturerTypeListType';
  items: Array<SystemAdminManufacturerType>;
  meta: PaginationMetaType;
};

export type PaginatedUserListType = {
  __typename?: 'PaginatedUserListType';
  items: Array<UserType>;
  meta: PaginationMetaType;
};

export type PaginationMetaType = {
  __typename?: 'PaginationMetaType';
  currentPage: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaymentMethodCreateResponse = BaseSuccessResponse | CheckoutSession;

export type PlcEventMarkAsReadResponse = BaseSuccessResponse | ObjectDoesNotExistsResponse;

export type PlcEventMarkAsUnreadResponse = BaseSuccessResponse | ObjectDoesNotExistsResponse;

export enum PlcEventOrderingOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
}

export type PlcExportResponse = ObjectDoesNotExistsResponse | PlcExportSuccessResponse | TariffRestrictionResponse;

export type PlcExportSuccessResponse = {
  __typename?: 'PlcExportSuccessResponse';
  data: Scalars['Base64']['output'];
};

export type PlcFveMetricsMiniChartType = {
  __typename?: 'PlcFveMetricsMiniChartType';
  batteryCapacity?: Maybe<Scalars['Float']['output']>;
  batteryCurrent?: Maybe<Scalars['Float']['output']>;
  batteryPower?: Maybe<Scalars['Float']['output']>;
  batterySoc?: Maybe<Scalars['Float']['output']>;
  batterySocDisconnectActual?: Maybe<Scalars['Float']['output']>;
  batterySocDisconnectMinimum?: Maybe<Scalars['Float']['output']>;
  batterySocDisconnectNogrid?: Maybe<Scalars['Float']['output']>;
  batteryTemperature?: Maybe<Scalars['Float']['output']>;
  batteryVoltage?: Maybe<Scalars['Float']['output']>;
  bucket: Scalars['DateTime']['output'];
  chargingVoltage?: Maybe<Scalars['Float']['output']>;
  externalPvPower?: Maybe<Scalars['Float']['output']>;
  gridFeedPowerMax?: Maybe<Scalars['Float']['output']>;
  gridL1Power?: Maybe<Scalars['Float']['output']>;
  gridL2Power?: Maybe<Scalars['Float']['output']>;
  gridL3Power?: Maybe<Scalars['Float']['output']>;
  gridPower?: Maybe<Scalars['Float']['output']>;
  internalTemperature?: Maybe<Scalars['Float']['output']>;
  loadL1Power?: Maybe<Scalars['Float']['output']>;
  loadL2Power?: Maybe<Scalars['Float']['output']>;
  loadL3Power?: Maybe<Scalars['Float']['output']>;
  loadPower?: Maybe<Scalars['Float']['output']>;
  maxChargingCurrent?: Maybe<Scalars['Float']['output']>;
  mppt1Power?: Maybe<Scalars['Float']['output']>;
  mppt1Voltage?: Maybe<Scalars['Float']['output']>;
  mppt2Power?: Maybe<Scalars['Float']['output']>;
  mppt2Voltage?: Maybe<Scalars['Float']['output']>;
  mpptPower?: Maybe<Scalars['Float']['output']>;
  powerGridConsumption?: Maybe<Scalars['Float']['output']>;
  solarIrradiance?: Maybe<Scalars['Float']['output']>;
};

export type PlcFveMetricsType = {
  __typename?: 'PlcFveMetricsType';
  acChargerEnable?: Maybe<Scalars['Boolean']['output']>;
  batteryCapacity?: Maybe<Scalars['Float']['output']>;
  batteryCurrent?: Maybe<Scalars['Float']['output']>;
  batteryNotCharged?: Maybe<Scalars['Boolean']['output']>;
  batteryPower?: Maybe<Scalars['Float']['output']>;
  batterySoc?: Maybe<Scalars['Float']['output']>;
  batterySocDisconnectActual?: Maybe<Scalars['Float']['output']>;
  batterySocDisconnectMinimum?: Maybe<Scalars['Float']['output']>;
  batterySocDisconnectNogrid?: Maybe<Scalars['Float']['output']>;
  batteryTemperature?: Maybe<Scalars['Float']['output']>;
  batteryVoltage?: Maybe<Scalars['Float']['output']>;
  chargingVoltage?: Maybe<Scalars['Float']['output']>;
  created: Scalars['DateTime']['output'];
  do1State?: Maybe<Scalars['Boolean']['output']>;
  do2State?: Maybe<Scalars['Boolean']['output']>;
  externalPvPower?: Maybe<Scalars['Float']['output']>;
  gridAvailable?: Maybe<Scalars['Boolean']['output']>;
  gridFeedEnable?: Maybe<Scalars['Boolean']['output']>;
  gridFeedPowerMax?: Maybe<Scalars['Float']['output']>;
  gridL1Power?: Maybe<Scalars['Float']['output']>;
  gridL2Power?: Maybe<Scalars['Float']['output']>;
  gridL3Power?: Maybe<Scalars['Float']['output']>;
  gridPower: Scalars['Float']['output'];
  hwVersion?: Maybe<Scalars['String']['output']>;
  internalTemperature?: Maybe<Scalars['Float']['output']>;
  inverterSerialNumber?: Maybe<Scalars['String']['output']>;
  loadL1Power?: Maybe<Scalars['Float']['output']>;
  loadL2Power?: Maybe<Scalars['Float']['output']>;
  loadL3Power?: Maybe<Scalars['Float']['output']>;
  loadPower: Scalars['Float']['output'];
  maxChargingCurrent?: Maybe<Scalars['Float']['output']>;
  mppt1Installed?: Maybe<Scalars['Int']['output']>;
  mppt1Power?: Maybe<Scalars['Float']['output']>;
  mppt1Voltage?: Maybe<Scalars['Float']['output']>;
  mppt2Installed?: Maybe<Scalars['Int']['output']>;
  mppt2Power?: Maybe<Scalars['Float']['output']>;
  mppt2Voltage?: Maybe<Scalars['Float']['output']>;
  mpptPower: Scalars['Float']['output'];
  powerGridConsumption?: Maybe<Scalars['Float']['output']>;
  pvChargerEnable?: Maybe<Scalars['Boolean']['output']>;
  solarIrradiance?: Maybe<Scalars['Float']['output']>;
  stopAlarm?: Maybe<Scalars['Boolean']['output']>;
  swVersion?: Maybe<Scalars['String']['output']>;
};

export type PlcHeatPumpMetricsMiniChartType = {
  __typename?: 'PlcHeatPumpMetricsMiniChartType';
  bivalentPowerConsumption?: Maybe<Scalars['Float']['output']>;
  bucket: Scalars['DateTime']['output'];
  compressorTemperature?: Maybe<Scalars['Float']['output']>;
  condenserTemperature?: Maybe<Scalars['Float']['output']>;
  dhwHysteresis?: Maybe<Scalars['Float']['output']>;
  dhwTarget?: Maybe<Scalars['Float']['output']>;
  dhwTemperature?: Maybe<Scalars['Float']['output']>;
  ekvitermPower?: Maybe<Scalars['Float']['output']>;
  evaporatorTemperature?: Maybe<Scalars['Float']['output']>;
  heatingFactor?: Maybe<Scalars['Float']['output']>;
  heatingSystemHysteresis?: Maybe<Scalars['Float']['output']>;
  heatingSystemTarget?: Maybe<Scalars['Float']['output']>;
  heatingSystemTemperature?: Maybe<Scalars['Float']['output']>;
  indoorTemperature?: Maybe<Scalars['Float']['output']>;
  inputPower?: Maybe<Scalars['Float']['output']>;
  outdoorTemperature?: Maybe<Scalars['Float']['output']>;
  outdoorunitTargetPercentage?: Maybe<Scalars['Int']['output']>;
  outputPower?: Maybe<Scalars['Float']['output']>;
  outputTemperature?: Maybe<Scalars['Float']['output']>;
  targetIndoorTemperature?: Maybe<Scalars['Float']['output']>;
  watterFlow?: Maybe<Scalars['Float']['output']>;
};

export type PlcHeatPumpMetricsType = {
  __typename?: 'PlcHeatPumpMetricsType';
  bivalentPowerConsumption?: Maybe<Scalars['Float']['output']>;
  compressorTemperature?: Maybe<Scalars['Float']['output']>;
  condenserTemperature?: Maybe<Scalars['Float']['output']>;
  created: Scalars['DateTime']['output'];
  dhwHysteresis?: Maybe<Scalars['Float']['output']>;
  dhwTarget?: Maybe<Scalars['Float']['output']>;
  dhwTemperature?: Maybe<Scalars['Float']['output']>;
  ekvitermPower?: Maybe<Scalars['Float']['output']>;
  ekvitermType?: Maybe<HeatPumpEkvitermTypeChoices>;
  evaporatorTemperature?: Maybe<Scalars['Float']['output']>;
  feedbackFromOutputTemperature?: Maybe<Scalars['Boolean']['output']>;
  hasBoiler?: Maybe<Scalars['Boolean']['output']>;
  hasInverter?: Maybe<Scalars['Boolean']['output']>;
  heatingFactor?: Maybe<Scalars['Float']['output']>;
  heatingSeasonActive?: Maybe<Scalars['Boolean']['output']>;
  heatingSystemHysteresis?: Maybe<Scalars['Float']['output']>;
  heatingSystemTarget?: Maybe<Scalars['Float']['output']>;
  heatingSystemTemperature?: Maybe<Scalars['Float']['output']>;
  hwVersion?: Maybe<Scalars['String']['output']>;
  indoorTemperature?: Maybe<Scalars['Float']['output']>;
  inputPower?: Maybe<Scalars['Float']['output']>;
  internalPumpActive?: Maybe<Scalars['Boolean']['output']>;
  mode?: Maybe<HeatPumpModeChoices>;
  outdoorTemperature?: Maybe<Scalars['Float']['output']>;
  outdoorunitActive?: Maybe<Scalars['Boolean']['output']>;
  outdoorunitDefrosting?: Maybe<Scalars['Boolean']['output']>;
  outdoorunitTargetPercentage?: Maybe<Scalars['Int']['output']>;
  outputPower?: Maybe<Scalars['Float']['output']>;
  outputTemperature?: Maybe<Scalars['Float']['output']>;
  stopAlarm?: Maybe<Scalars['Boolean']['output']>;
  swVersion?: Maybe<Scalars['String']['output']>;
  targetIndoorTemperature?: Maybe<Scalars['Float']['output']>;
  watterFlow?: Maybe<Scalars['Float']['output']>;
};

export enum PlcMetricsBarChartPeriodChoices {
  OneDay = 'ONE_DAY',
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  OneYear = 'ONE_YEAR',
}

export enum PlcMetricsLineChartPeriodGraphQlChoices {
  FiveDays = 'FIVE_DAYS',
  OneDay = 'ONE_DAY',
  TwoDays = 'TWO_DAYS',
}

export type PreSignedUpload = {
  __typename?: 'PreSignedUpload';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type PreSignedUploads = {
  __typename?: 'PreSignedUploads';
  preSignedUploads: Array<PreSignedUpload>;
};

export type PreSignedUploadsResponse = FormErrorResponse | PreSignedUploads;

export type Query = {
  __typename?: 'Query';
  countryList: Array<CountryType>;
  customerCustomerSiteDetail?: Maybe<CustomerSiteType>;
  customerCustomerSiteList: PaginatedCustomerSitesListType;
  customerCustomerSiteSelectItems: Array<CustomerSiteType>;
  customerCustomerStripeSubscriptionList: PaginatedCustomerStripeSubscriptionListType;
  customerCustomerTariffSelectItems: Array<CustomerTariffType>;
  customerCustomerTicketCommentDetail?: Maybe<CustomerTicketCommentType>;
  customerCustomerTicketCommentList: PaginatedCustomerTicketCommentListType;
  customerCustomerTicketDetail?: Maybe<CustomerCustomerTicketType>;
  customerCustomerTicketLastNTickets: Array<CustomerCustomerTicketType>;
  customerCustomerTicketList: PaginatedCustomerCustomerTicketListType;
  customerDeviceDetail?: Maybe<CustomerDeviceType>;
  customerDeviceList: CustomerPaginatedDeviceListType;
  customerDeviceSelectItems: Array<CustomerDeviceType>;
  customerDeviceTypeSelectItems: Array<DeviceTypeSelectItemType>;
  customerInstallationCompanySelectItems: Array<InstallationCompanySelectItemType>;
  customerPlcEventDetail?: Maybe<CustomerPlcEventType>;
  customerPlcEventLastNEvents: Array<CustomerPlcEventType>;
  customerPlcEventList: CustomerPlcEventPaginatedDeviceListType;
  customerTariffRestrictionInfo: CustomerTariffRestrictionInfoType;
  deviceMappingSchema: Scalars['JSON']['output'];
  installationCompanyBaseTicketLastNTickets: Array<InstallationCompanyBaseTicketType>;
  installationCompanyBaseTicketList: PaginatedInstallationCompanyBaseTicketListType;
  installationCompanyCustomerInvitationDetail: InstallationCompanyCustomerInvitationDetailResponse;
  installationCompanyCustomerSelectItems: Array<CustomerSelectItemType>;
  installationCompanyCustomerTicketCommentDetail?: Maybe<CustomerTicketCommentType>;
  installationCompanyCustomerTicketCommentList: PaginatedCustomerTicketCommentListType;
  installationCompanyCustomerTicketDetail?: Maybe<InstallationCompanyCustomerTicketType>;
  installationCompanyCustomerTicketList: PaginatedInstallationCompanyCustomerTicketListType;
  installationCompanyDashboardStatisticsOverview: InstallationCompanyDashboardStatisticsType;
  installationCompanyDeviceCommandList: DeviceCommandPaginatedListType;
  installationCompanyDeviceDetail?: Maybe<InstallationCompanyDeviceType>;
  installationCompanyDeviceList: InstallationCompanyPaginatedDeviceListType;
  installationCompanyDeviceTypeSelectItems: Array<DeviceTypeSelectItemType>;
  installationCompanyInstallationAttachmentList: PaginatedInstallationAttachmentListType;
  installationCompanyInstallationCompanyCustomerDetail?: Maybe<InstallationCompanyCustomerType>;
  installationCompanyInstallationCompanyCustomerList: PaginatedInstallationCompanyCustomersListType;
  installationCompanyInstallationCompanyCustomerSelectItems: Array<InstallationCompanyCustomerSelectItemType>;
  installationCompanyInstallationCompanyCustomerSiteDetail?: Maybe<InstallationCompanyCustomerSiteType>;
  installationCompanyInstallationCompanyCustomerSiteList: PaginatedInstallationCompanyCustomerSitesListType;
  installationCompanyInstallationCompanyCustomerSiteSelectItems: Array<InstallationCompanyCustomerSiteType>;
  installationCompanyInstallationCompanyStripeSubscriptionList: PaginatedInstallationCompanyStripeSubscriptionListType;
  installationCompanyInstallationCompanyTariffSelectItems: Array<InstallationCompanyTariffType>;
  installationCompanyInstallationCompanyTechnicianSelectItems: Array<UserSelectItemType>;
  installationCompanyInstallationCompanyTicketCommentDetail?: Maybe<InstallationCompanyTicketCommentType>;
  installationCompanyInstallationCompanyTicketCommentList: PaginatedInstallationCompanyTicketCommentListType;
  installationCompanyInstallationCompanyTicketDetail?: Maybe<InstallationCompanyTicketType>;
  installationCompanyInstallationCompanyTicketList: PaginatedInstallationCompanyTicketListType;
  installationCompanyInstallationNoteDetail?: Maybe<InstallationNoteType>;
  installationCompanyInstallationNoteList: PaginatedInstallationNoteListType;
  installationCompanyManufacturerSelectItems: Array<ManufacturerSelectItemType>;
  installationCompanyManufacturerTicketCommentDetail?: Maybe<ManufacturerTicketCommentType>;
  installationCompanyManufacturerTicketCommentList: PaginatedManufacturerTicketCommentListType;
  installationCompanyManufacturerTicketDetail?: Maybe<InstallationCompanyManufacturerTicketType>;
  installationCompanyManufacturerTicketList: PaginatedInstallationCompanyManufacturerTicketListType;
  installationCompanyPlcEventDetail?: Maybe<InstallationCompanyPlcEventType>;
  installationCompanyPlcEventLastNEvents: Array<InstallationCompanyPlcEventType>;
  installationCompanyPlcEventList: InstallationCompanyPlcEventPaginatedDeviceListType;
  installationCompanyTariffRestrictionInfo: InstallationCompanyTariffRestrictionInfoType;
  invitedUserOrganizationDetail?: Maybe<OrganizationUnionType>;
  manufacturerDeviceCreatedBySelectItems: Array<UserSelectItemType>;
  manufacturerDeviceDetail?: Maybe<ManufacturerDeviceType>;
  manufacturerDeviceEventTypeDetail?: Maybe<DeviceEventTypeType>;
  manufacturerDeviceEventTypeList: PaginatedDeviceEventTypeListType;
  manufacturerDeviceList: ManufacturerPaginatedDeviceListType;
  manufacturerDeviceTypeCreatedBySelectItems: Array<UserSelectItemType>;
  manufacturerDeviceTypeDetail?: Maybe<DeviceTypeType>;
  manufacturerDeviceTypeList: PaginatedDeviceTypeListType;
  manufacturerDeviceTypeSelectItems: Array<DeviceTypeSelectItemType>;
  manufacturerInstallationCompanySelectItems: Array<InstallationCompanySelectItemType>;
  manufacturerManufacturerTariffSelectItems: Array<ManufacturerTariffType>;
  manufacturerManufacturerTicketCommentDetail?: Maybe<ManufacturerTicketCommentType>;
  manufacturerManufacturerTicketCommentList: PaginatedManufacturerTicketCommentListType;
  manufacturerManufacturerTicketDetail?: Maybe<ManufacturerManufacturerTicketType>;
  manufacturerManufacturerTicketList: PaginatedManufacturerManufacturerTicketListType;
  manufacturerTariffRestrictionInfo: ManufacturerTariffRestrictionInfoType;
  me?: Maybe<UserType>;
  organizationUserDetail?: Maybe<UserType>;
  organizationUserList: PaginatedUserListType;
  plcFveBarChartData: PlcfveBarChartDataResponse;
  plcFveCurrentData?: Maybe<PlcFveMetricsType>;
  plcFveLineChartData: PlcfveLineChartDataResponse;
  plcFveMiniChartData: Array<PlcFveMetricsMiniChartType>;
  plcHeatPumpBarChartData: PlcHeatPumpBarChartDataResponse;
  plcHeatPumpCurrentData?: Maybe<PlcHeatPumpMetricsType>;
  plcHeatPumpLineChartData: PlcHeatPumpLineChartDataResponse;
  plcHeatPumpMiniChartData: Array<PlcHeatPumpMetricsMiniChartType>;
  systemAdminCustomerDetail?: Maybe<CustomerType>;
  systemAdminCustomerList: PaginatedCustomersListType;
  systemAdminCustomerTariffSelectItems: Array<CustomerTariffType>;
  systemAdminInstallationCompanyDetail?: Maybe<InstallationCompanyType>;
  systemAdminInstallationCompanyList: PaginatedCompaniesListType;
  systemAdminInstallationCompanyTariffSelectItems: Array<InstallationCompanyTariffType>;
  systemAdminManufacturerDetail?: Maybe<SystemAdminManufacturerType>;
  systemAdminManufacturerList: PaginatedSystemAdminManufacturerTypeListType;
  systemAdminManufacturerTariffSelectItems: Array<ManufacturerTariffType>;
  systemAdminStripeSubscriptionList: PaginatedStripeSubscriptionListType;
  systemAdminUserDetail?: Maybe<UserType>;
  systemAdminUserList: PaginatedUserListType;
};

export type QueryCustomerCustomerSiteDetailArgs = {
  customerSiteId: Scalars['ID']['input'];
};

export type QueryCustomerCustomerSiteListArgs = {
  orderBy?: InputMaybe<CustomerSitesOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCustomerCustomerStripeSubscriptionListArgs = {
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
};

export type QueryCustomerCustomerTariffSelectItemsArgs = {
  priceType?: InputMaybe<TariffPriceTypeChoices>;
};

export type QueryCustomerCustomerTicketCommentDetailArgs = {
  commentId: Scalars['ID']['input'];
};

export type QueryCustomerCustomerTicketCommentListArgs = {
  customerTicket?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCustomerCustomerTicketDetailArgs = {
  ticketId: Scalars['ID']['input'];
};

export type QueryCustomerCustomerTicketLastNTicketsArgs = {
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  n?: Scalars['Int']['input'];
};

export type QueryCustomerCustomerTicketListArgs = {
  category?: InputMaybe<Array<TicketCategoryChoices>>;
  device?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
  installationCompany?: InputMaybe<Array<Scalars['ID']['input']>>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<CustomerTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Array<TicketPriorityChoices>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCustomerDeviceDetailArgs = {
  deviceId: Scalars['ID']['input'];
};

export type QueryCustomerDeviceListArgs = {
  orderBy?: InputMaybe<DeviceOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  technology?: InputMaybe<Array<DeviceTypeTechnologyChoices>>;
};

export type QueryCustomerDeviceSelectItemsArgs = {
  ticketsAllowed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCustomerDeviceTypeSelectItemsArgs = {
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCustomerInstallationCompanySelectItemsArgs = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  eventId?: InputMaybe<Scalars['ID']['input']>;
  ticketsAllowed: Scalars['Boolean']['input'];
};

export type QueryCustomerPlcEventDetailArgs = {
  plcEventId: Scalars['ID']['input'];
};

export type QueryCustomerPlcEventLastNEventsArgs = {
  n?: Scalars['Int']['input'];
};

export type QueryCustomerPlcEventListArgs = {
  customerSite?: InputMaybe<Array<Scalars['ID']['input']>>;
  device?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
  hasTicket?: InputMaybe<Scalars['Boolean']['input']>;
  notRead?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<PlcEventOrderingOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Array<DeviceEventPriorityChoices>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstallationCompanyBaseTicketLastNTicketsArgs = {
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  n?: Scalars['Int']['input'];
};

export type QueryInstallationCompanyBaseTicketListArgs = {
  device?: InputMaybe<Array<Scalars['ID']['input']>>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<TicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Array<TicketPriorityChoices>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstallationCompanyCustomerInvitationDetailArgs = {
  installationCompanyCustomerId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type QueryInstallationCompanyCustomerSelectItemsArgs = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  eventId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryInstallationCompanyCustomerTicketCommentDetailArgs = {
  commentId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyCustomerTicketCommentListArgs = {
  customerTicket?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryInstallationCompanyCustomerTicketDetailArgs = {
  ticketId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyCustomerTicketListArgs = {
  assignee?: InputMaybe<Array<CustomerTicketAssigneeChoices>>;
  category?: InputMaybe<Array<TicketCategoryChoices>>;
  device?: InputMaybe<Array<Scalars['ID']['input']>>;
  installationCompanyTechnician?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<CustomerTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Array<TicketPriorityChoices>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstallationCompanyDeviceCommandListArgs = {
  deviceId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryInstallationCompanyDeviceDetailArgs = {
  deviceId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyDeviceListArgs = {
  deviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
  installationCompanyTechnician?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<InstallationCompanyDeviceOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstallationCompanyDeviceTypeSelectItemsArgs = {
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstallationCompanyInstallationAttachmentListArgs = {
  deviceId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryInstallationCompanyInstallationCompanyCustomerDetailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  installationCompanyCustomerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryInstallationCompanyInstallationCompanyCustomerListArgs = {
  orderBy?: InputMaybe<InstallationCompanyCustomerOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstallationCompanyInstallationCompanyCustomerSiteDetailArgs = {
  customerSiteId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyInstallationCompanyCustomerSiteListArgs = {
  installationCompanyCustomerId?: InputMaybe<Scalars['ID']['input']>;
  orderBy?: InputMaybe<InstallationCompanyCustomerSiteOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryInstallationCompanyInstallationCompanyCustomerSiteSelectItemsArgs = {
  installationCompanyCustomerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryInstallationCompanyInstallationCompanyStripeSubscriptionListArgs = {
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
};

export type QueryInstallationCompanyInstallationCompanyTariffSelectItemsArgs = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  priceType?: InputMaybe<TariffPriceTypeChoices>;
};

export type QueryInstallationCompanyInstallationCompanyTechnicianSelectItemsArgs = {
  hasInstallations?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<UserRoleChoices>;
};

export type QueryInstallationCompanyInstallationCompanyTicketCommentDetailArgs = {
  commentId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyInstallationCompanyTicketCommentListArgs = {
  installationCompanyTicket?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryInstallationCompanyInstallationCompanyTicketDetailArgs = {
  ticketId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyInstallationCompanyTicketListArgs = {
  category?: InputMaybe<Array<TicketCategoryChoices>>;
  deviceCreatedBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
  installationCompanyTechnician?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<InstallationCompanyTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstallationCompanyInstallationNoteDetailArgs = {
  noteId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyInstallationNoteListArgs = {
  installationId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryInstallationCompanyManufacturerSelectItemsArgs = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryInstallationCompanyManufacturerTicketCommentDetailArgs = {
  commentId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyManufacturerTicketCommentListArgs = {
  manufacturerTicket?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryInstallationCompanyManufacturerTicketDetailArgs = {
  ticketId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyManufacturerTicketListArgs = {
  assignee?: InputMaybe<Array<ManufacturerTicketAssigneeChoices>>;
  category?: InputMaybe<Array<TicketCategoryChoices>>;
  device?: InputMaybe<Array<Scalars['ID']['input']>>;
  installationCompanyTechnician?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<ManufacturerTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Array<TicketPriorityChoices>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstallationCompanyPlcEventDetailArgs = {
  plcEventId: Scalars['ID']['input'];
};

export type QueryInstallationCompanyPlcEventLastNEventsArgs = {
  n?: Scalars['Int']['input'];
};

export type QueryInstallationCompanyPlcEventListArgs = {
  device?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
  hasTicket?: InputMaybe<Scalars['Boolean']['input']>;
  installationCompanyCustomer?: InputMaybe<Array<Scalars['ID']['input']>>;
  installationCompanyCustomerSite?: InputMaybe<Array<Scalars['ID']['input']>>;
  notRead?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<PlcEventOrderingOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Array<DeviceEventPriorityChoices>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInvitedUserOrganizationDetailArgs = {
  userActivationInput: UserActivationInput;
};

export type QueryManufacturerDeviceDetailArgs = {
  deviceId: Scalars['ID']['input'];
};

export type QueryManufacturerDeviceEventTypeDetailArgs = {
  deviceEventTypeId: Scalars['ID']['input'];
};

export type QueryManufacturerDeviceEventTypeListArgs = {
  deviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Array<DeviceEventPriorityChoices>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryManufacturerDeviceListArgs = {
  deviceCreatedBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<DeviceOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<DeviceStateChoices>>;
};

export type QueryManufacturerDeviceTypeDetailArgs = {
  deviceTypeId: Scalars['ID']['input'];
};

export type QueryManufacturerDeviceTypeListArgs = {
  apiType?: InputMaybe<Array<DeviceTypeApiTypeChoices>>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<DeviceTypeOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  technology?: InputMaybe<Array<DeviceTypeTechnologyChoices>>;
};

export type QueryManufacturerDeviceTypeSelectItemsArgs = {
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryManufacturerInstallationCompanySelectItemsArgs = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  ticketsAllowed: Scalars['Boolean']['input'];
};

export type QueryManufacturerManufacturerTicketCommentDetailArgs = {
  commentId: Scalars['ID']['input'];
};

export type QueryManufacturerManufacturerTicketCommentListArgs = {
  manufacturerTicket?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryManufacturerManufacturerTicketDetailArgs = {
  ticketId: Scalars['ID']['input'];
};

export type QueryManufacturerManufacturerTicketListArgs = {
  device?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceCreatedBy?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<ManufacturerTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrganizationUserDetailArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryOrganizationUserListArgs = {
  orderBy?: InputMaybe<UserOrderingChoices>;
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
};

export type QueryPlcFveBarChartDataArgs = {
  deviceId: Scalars['ID']['input'];
  period: PlcMetricsBarChartPeriodChoices;
  untilDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryPlcFveCurrentDataArgs = {
  deviceId: Scalars['ID']['input'];
};

export type QueryPlcFveLineChartDataArgs = {
  deviceId: Scalars['ID']['input'];
  period: PlcMetricsLineChartPeriodGraphQlChoices;
  untilDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryPlcFveMiniChartDataArgs = {
  deviceId: Scalars['ID']['input'];
};

export type QueryPlcHeatPumpBarChartDataArgs = {
  deviceId: Scalars['ID']['input'];
  period: PlcMetricsBarChartPeriodChoices;
  untilDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryPlcHeatPumpCurrentDataArgs = {
  deviceId: Scalars['ID']['input'];
};

export type QueryPlcHeatPumpLineChartDataArgs = {
  deviceId: Scalars['ID']['input'];
  period: PlcMetricsLineChartPeriodGraphQlChoices;
  untilDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryPlcHeatPumpMiniChartDataArgs = {
  deviceId: Scalars['ID']['input'];
};

export type QuerySystemAdminCustomerDetailArgs = {
  customerId: Scalars['ID']['input'];
};

export type QuerySystemAdminCustomerListArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<CustomersOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  tariff?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QuerySystemAdminInstallationCompanyDetailArgs = {
  installationCompanyId: Scalars['ID']['input'];
};

export type QuerySystemAdminInstallationCompanyListArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<InstallationCompaniesOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  tariff?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QuerySystemAdminManufacturerDetailArgs = {
  manufacturerId: Scalars['ID']['input'];
};

export type QuerySystemAdminManufacturerListArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<ManufacturersOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  tariff?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QuerySystemAdminStripeSubscriptionListArgs = {
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
};

export type QuerySystemAdminUserDetailArgs = {
  userId: Scalars['ID']['input'];
};

export type QuerySystemAdminUserListArgs = {
  orderBy?: InputMaybe<UserOrderingChoices>;
  page?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
};

export enum StripeSubscriptionStatusChoices {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID',
}

export type StripeSubscriptionType = {
  __typename?: 'StripeSubscriptionType';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  created: Scalars['DateTime']['output'];
  currentPeriodEnd: Scalars['DateTime']['output'];
  currentPeriodStart: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  organization: OrganizationUnionType;
  status: StripeSubscriptionStatusChoices;
  subscriptionCreated: Scalars['DateTime']['output'];
  tariff: StripeTariffType;
  tariffType: TariffTypeChoices;
};

export type StripeTariffType = {
  __typename?: 'StripeTariffType';
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  priceType: TariffPriceTypeChoices;
};

export type SubscriptionCancelResponse =
  | BaseSuccessResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type SubscriptionUpdateResponse =
  | BaseSuccessResponse
  | ObjectCannotBeModifiedResponse
  | ObjectDoesNotExistsResponse;

export type SystemAdminManufacturerType = {
  __typename?: 'SystemAdminManufacturerType';
  city: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  country: CountryType;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserSelectItemType>;
  deviceCount: Scalars['Int']['output'];
  deviceTypeCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  subscription?: Maybe<ManufacturerSubscriptionType>;
  tariff?: Maybe<ManufacturerTariffType>;
  userCount: Scalars['Int']['output'];
  vatId: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type SystemAdminManufacturerUpdateResponse = FormErrorResponse | SystemAdminManufacturerUpdateSuccess;

export type SystemAdminManufacturerUpdateSuccess = {
  __typename?: 'SystemAdminManufacturerUpdateSuccess';
  manufacturer: SystemAdminManufacturerType;
};

export enum TariffBarChartDataGranularityChoices {
  Days = 'DAYS',
  Hours = 'HOURS',
  Months = 'MONTHS',
}

export enum TariffLineChartDataGranularityChoices {
  FiveMinutes = 'FIVE_MINUTES',
  OneMinute = 'ONE_MINUTE',
}

export enum TariffLineChartDataRetentionChoices {
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  OneYear = 'ONE_YEAR',
  TwoDays = 'TWO_DAYS',
}

export enum TariffPriceTypeChoices {
  Monthly = 'MONTHLY',
  Unassigned = 'UNASSIGNED',
  Yearly = 'YEARLY',
}

export type TariffRestrictionResponse = {
  __typename?: 'TariffRestrictionResponse';
  ok: Scalars['Boolean']['output'];
};

export enum TariffTypeChoices {
  Customer = 'CUSTOMER',
  InstallationCompany = 'INSTALLATION_COMPANY',
}

export enum TicketAssigneeChoices {
  Customer = 'CUSTOMER',
  InstallationCompany = 'INSTALLATION_COMPANY',
  Manufacturer = 'MANUFACTURER',
}

export enum TicketCategoryChoices {
  Device = 'DEVICE',
  DeviceEvent = 'DEVICE_EVENT',
  General = 'GENERAL',
}

export enum TicketOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
}

export enum TicketPriorityChoices {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export enum TicketTypeChoices {
  Customer = 'CUSTOMER',
  InstallationCompany = 'INSTALLATION_COMPANY',
  Manufacturer = 'MANUFACTURER',
}

export type TokenExpiredResponse = {
  __typename?: 'TokenExpiredResponse';
  ok: Scalars['Boolean']['output'];
};

export type TokenObtainInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type TokenObtainResponse = InvalidCredentialsError | TokenResponseSuccess;

export type TokenRefreshResponse = InvalidRefreshToken | TokenResponseSuccess;

export type TokenResponseSuccess = {
  __typename?: 'TokenResponseSuccess';
  accessToken: Scalars['String']['output'];
  user: UserType;
};

export enum TokenVerificationActionChoices {
  Activation = 'ACTIVATION',
  PasswordReset = 'PASSWORD_RESET',
  UserInvitation = 'USER_INVITATION',
}

export type TokenVerificationInput = {
  action: TokenVerificationActionChoices;
  id: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type TokenVerificationResponse = ObjectDoesNotExistsResponse | TokenExpiredResponse | TokenVerificationSuccess;

export type TokenVerificationSuccess = {
  __typename?: 'TokenVerificationSuccess';
  token: Scalars['String']['output'];
};

export type UpdateDeviceResponse = FormErrorResponse | ManufacturerUpdateDeviceSuccess | ObjectDoesNotExistsResponse;

export type UserActivationInput = {
  id: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type UserActivationResponse =
  | BaseSuccessResponse
  | TokenExpiredResponse
  | TokenResponseSuccess
  | UserDisabledResponse;

export type UserChangePasswordInput = {
  originalPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UserChangePasswordResponse = BaseSuccessResponse | FormErrorResponse;

export type UserCreationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  languageCode: UserLanguagesChoices;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UserDisableResponse = BaseSuccessResponse | ObjectDoesNotExistsResponse;

export type UserDisabledResponse = {
  __typename?: 'UserDisabledResponse';
  id: Scalars['ID']['output'];
};

export type UserEnableResponse = BaseSuccessResponse | ObjectDoesNotExistsResponse;

export type UserInvitationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  languageCode: UserLanguagesChoices;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: UserRoleChoices;
  ticketNotifications: Scalars['Boolean']['input'];
};

export type UserInvitationResponse = FormErrorResponse | TariffRestrictionResponse | UserInvitationSuccess;

export type UserInvitationSuccess = {
  __typename?: 'UserInvitationSuccess';
  user: UserType;
};

export enum UserLanguagesChoices {
  Cs = 'CS',
  En = 'EN',
}

export enum UserOrderingChoices {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  ModifiedAsc = 'MODIFIED_ASC',
  ModifiedDesc = 'MODIFIED_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
}

export type UserRegistrationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  languageCode: UserLanguagesChoices;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UserRegistrationResponse = FormErrorResponse | UserRegistrationSuccess;

export type UserRegistrationSuccess = {
  __typename?: 'UserRegistrationSuccess';
  user: UserType;
};

export type UserResetPasswordResponse =
  | BaseSuccessResponse
  | FormErrorResponse
  | ObjectDoesNotExistsResponse
  | TokenExpiredResponse
  | TokenResponseSuccess
  | UserDisabledResponse;

export enum UserRoleChoices {
  Admin = 'ADMIN',
  User = 'USER',
}

export type UserSelectItemType = {
  __typename?: 'UserSelectItemType';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  userType: UserTypeChoices;
};

export type UserSetLanguageResponse = {
  __typename?: 'UserSetLanguageResponse';
  user: UserType;
};

export type UserSetPasswordInput = {
  id: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export enum UserStateChoices {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Invited = 'INVITED',
}

export type UserType = {
  __typename?: 'UserType';
  created: Scalars['DateTime']['output'];
  customer?: Maybe<CustomerType>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  installationCompany?: Maybe<InstallationCompanyType>;
  isActive: Scalars['Boolean']['output'];
  languageCode: UserLanguagesChoices;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  manufacturer?: Maybe<ManufacturerType>;
  modified: Scalars['DateTime']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  role: UserRoleChoices;
  state: UserStateChoices;
  ticketNotifications: Scalars['Boolean']['output'];
  userType: UserTypeChoices;
};

export enum UserTypeChoices {
  Customer = 'CUSTOMER',
  InstallationCompany = 'INSTALLATION_COMPANY',
  Manufacturer = 'MANUFACTURER',
  SystemAdmin = 'SYSTEM_ADMIN',
}

export type UserUpdateInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: UserRoleChoices;
  ticketNotifications: Scalars['Boolean']['input'];
};

export type UserUpdateResponse = FormErrorResponse | ObjectDoesNotExistsResponse | UserUpdateSuccess;

export type UserUpdateSuccess = {
  __typename?: 'UserUpdateSuccess';
  user: UserType;
};

export type CustomerCustomerTicketFragment = {
  __typename?: 'CustomerCustomerTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: CustomerTicketAssigneeChoices;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'CustomerDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  attachments: Array<{
    __typename?: 'CustomerTicketAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type CustomerDeviceFragment = {
  __typename?: 'CustomerDeviceType';
  id: string;
  created: any;
  modified: any;
  manufacturerNote: string;
  registrationToken: string;
  state: DeviceStateChoices;
  serialNumber: string;
  plcPassword: string;
  plcUsername: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  deviceType: {
    __typename?: 'DeviceTypeType';
    name: string;
    serialNumberFormat: string;
    technology: DeviceTypeTechnologyChoices;
    apiType: DeviceTypeApiTypeChoices;
    note: string;
    hwVersion?: string | null;
    id: string;
    created: any;
    modified: any;
    devicesCount: number;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    manufacturer: {
      __typename?: 'ManufacturerType';
      city: string;
      companyId: string;
      created: any;
      email: string;
      id: string;
      isActive: boolean;
      modified: any;
      name: string;
      phone?: string | null;
      street: string;
      vatId: string;
      zipCode: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      country: {
        __typename?: 'CountryType';
        name: string;
        code: string;
        alpha3: string;
        numeric: string;
        iocCode: string;
      };
    };
  };
  customerSite?: { __typename?: 'CustomerSiteType'; name: string } | null;
};

export type CustomerExtendedFragment = {
  __typename?: 'CustomerType';
  userCount: number;
  id: string;
  city?: string | null;
  companyId?: string | null;
  email: string;
  name: string;
  phone?: string | null;
  street?: string | null;
  vatId?: string | null;
  zipCode?: string | null;
  created: any;
  modified: any;
  deviceCount: number;
  isActive: boolean;
  country?: {
    __typename?: 'CountryType';
    name: string;
    code: string;
    alpha3: string;
    numeric: string;
    iocCode: string;
  } | null;
};

export type CustomerPlcEventFragment = {
  __typename?: 'CustomerPlcEventType';
  created: any;
  deviceId: string;
  id: string;
  eventId: number;
  markedAsRead: boolean;
  eventType?: {
    __typename?: 'DeviceEventTypeType';
    id: string;
    uid: number;
    priority: DeviceEventPriorityChoices;
    descriptionCs: string;
    descriptionEn: string;
    description: string;
    deviceType: {
      __typename?: 'DeviceTypeType';
      name: string;
      serialNumberFormat: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
      note: string;
      hwVersion?: string | null;
      id: string;
      created: any;
      modified: any;
      devicesCount: number;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      manufacturer: {
        __typename?: 'ManufacturerType';
        city: string;
        companyId: string;
        created: any;
        email: string;
        id: string;
        isActive: boolean;
        modified: any;
        name: string;
        phone?: string | null;
        street: string;
        vatId: string;
        zipCode: string;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        country: {
          __typename?: 'CountryType';
          name: string;
          code: string;
          alpha3: string;
          numeric: string;
          iocCode: string;
        };
      };
    };
  } | null;
  device: {
    __typename?: 'CustomerDeviceType';
    id: string;
    created: any;
    modified: any;
    manufacturerNote: string;
    registrationToken: string;
    state: DeviceStateChoices;
    serialNumber: string;
    plcPassword: string;
    plcUsername: string;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    deviceType: {
      __typename?: 'DeviceTypeType';
      name: string;
      serialNumberFormat: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
      note: string;
      hwVersion?: string | null;
      id: string;
      created: any;
      modified: any;
      devicesCount: number;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      manufacturer: {
        __typename?: 'ManufacturerType';
        city: string;
        companyId: string;
        created: any;
        email: string;
        id: string;
        isActive: boolean;
        modified: any;
        name: string;
        phone?: string | null;
        street: string;
        vatId: string;
        zipCode: string;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        country: {
          __typename?: 'CountryType';
          name: string;
          code: string;
          alpha3: string;
          numeric: string;
          iocCode: string;
        };
      };
    };
    customerSite?: { __typename?: 'CustomerSiteType'; name: string } | null;
  };
  customerTicket?: {
    __typename?: 'CustomerCustomerTicketType';
    id: string;
    customer: { __typename?: 'CustomerType'; street?: string | null; city?: string | null; name: string };
  } | null;
};

export type CustomerSiteFragment = {
  __typename?: 'CustomerSiteType';
  created: any;
  modified: any;
  id: string;
  name: string;
  devices: Array<{
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    modified: any;
    deviceType: {
      __typename?: 'DeviceTypeType';
      id: string;
      name: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
    };
  }>;
};

export type CustomerTicketAttachmentFragment = {
  __typename?: 'CustomerTicketAttachmentType';
  originalFileName: string;
  created: any;
  modified: any;
  id: string;
  fileSize: number;
  file: { __typename?: 'FileType'; url: string; name: string };
};

export type CustomerTicketCommentAttachmentFragment = {
  __typename?: 'CustomerTicketCommentAttachmentType';
  originalFileName: string;
  created: any;
  modified: any;
  id: string;
  fileSize: number;
  file: { __typename?: 'FileType'; url: string; name: string };
};

export type CustomerTicketCommentFragment = {
  __typename?: 'CustomerTicketCommentType';
  created: any;
  modified: any;
  id: string;
  comment: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  attachments: Array<{
    __typename?: 'CustomerTicketCommentAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type CustomerFragment = {
  __typename?: 'CustomerType';
  id: string;
  city?: string | null;
  companyId?: string | null;
  email: string;
  name: string;
  phone?: string | null;
  street?: string | null;
  vatId?: string | null;
  zipCode?: string | null;
  created: any;
  modified: any;
  deviceCount: number;
  isActive: boolean;
  country?: {
    __typename?: 'CountryType';
    name: string;
    code: string;
    alpha3: string;
    numeric: string;
    iocCode: string;
  } | null;
};

export type InstallationAttachmentFragment = {
  __typename?: 'InstallationAttachmentType';
  originalFileName: string;
  created: any;
  modified: any;
  id: string;
  title: string;
  fileSize: number;
  file: { __typename?: 'FileType'; url: string; name: string };
};

export type InstallationCompanyCustomerSiteFragment = {
  __typename?: 'InstallationCompanyCustomerSiteType';
  created: any;
  modified: any;
  street?: string | null;
  city?: string | null;
  zipCode?: string | null;
  id: string;
  name: string;
  note?: string | null;
  updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  country?: {
    __typename?: 'CountryType';
    name: string;
    code: string;
    alpha3: string;
    numeric: string;
    iocCode: string;
  } | null;
  gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
};

export type InstallationCompanyDeviceFragment = {
  __typename?: 'InstallationCompanyDeviceType';
  id: string;
  created: any;
  modified: any;
  manufacturerNote: string;
  registrationToken: string;
  state: DeviceStateChoices;
  serialNumber: string;
  plcPassword: string;
  plcUsername: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  deviceType: {
    __typename?: 'DeviceTypeType';
    name: string;
    serialNumberFormat: string;
    technology: DeviceTypeTechnologyChoices;
    apiType: DeviceTypeApiTypeChoices;
    note: string;
    hwVersion?: string | null;
    id: string;
    created: any;
    modified: any;
    devicesCount: number;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    manufacturer: {
      __typename?: 'ManufacturerType';
      city: string;
      companyId: string;
      created: any;
      email: string;
      id: string;
      isActive: boolean;
      modified: any;
      name: string;
      phone?: string | null;
      street: string;
      vatId: string;
      zipCode: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      country: {
        __typename?: 'CountryType';
        name: string;
        code: string;
        alpha3: string;
        numeric: string;
        iocCode: string;
      };
    };
  };
  installationCompanyCustomerSite?: {
    __typename?: 'InstallationCompanyCustomerSiteType';
    created: any;
    modified: any;
    street?: string | null;
    city?: string | null;
    zipCode?: string | null;
    id: string;
    name: string;
    note?: string | null;
    updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    country?: {
      __typename?: 'CountryType';
      name: string;
      code: string;
      alpha3: string;
      numeric: string;
      iocCode: string;
    } | null;
    gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
  } | null;
  technician?: { __typename?: 'UserType'; id: string; firstName: string; lastName: string } | null;
};

export type InstallationCompanyTicketFragment = {
  __typename?: 'InstallationCompanyTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  installationCompany: {
    __typename?: 'InstallationCompanyType';
    city: string;
    companyId: string;
    created: any;
    email: string;
    id: string;
    isActive: boolean;
    modified: any;
    name: string;
    phone?: string | null;
    street: string;
    vatId: string;
    zipCode: string;
    ticketsAllowed: boolean;
    country: {
      __typename?: 'CountryType';
      name: string;
      code: string;
      alpha3: string;
      numeric: string;
      iocCode: string;
    };
  };
  attachments: Array<{
    __typename?: 'InstallationCompanyTicketAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
  installationCompanyTechnician?: {
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
    userType: UserTypeChoices;
  } | null;
};

export type InstallationCompanyManufacturerTicketFragment = {
  __typename?: 'InstallationCompanyManufacturerTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: ManufacturerTicketAssigneeChoices;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  attachments: Array<{
    __typename?: 'ManufacturerTicketAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
  installationCompanyTechnician?: {
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
    userType: UserTypeChoices;
  } | null;
};

export type InstallationCompanyPlcEventFragment = {
  __typename?: 'InstallationCompanyPlcEventType';
  created: any;
  deviceId: string;
  id: string;
  eventId: number;
  markedAsRead: boolean;
  eventType?: {
    __typename?: 'DeviceEventTypeType';
    id: string;
    uid: number;
    priority: DeviceEventPriorityChoices;
    descriptionCs: string;
    descriptionEn: string;
    description: string;
    deviceType: {
      __typename?: 'DeviceTypeType';
      name: string;
      serialNumberFormat: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
      note: string;
      hwVersion?: string | null;
      id: string;
      created: any;
      modified: any;
      devicesCount: number;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      manufacturer: {
        __typename?: 'ManufacturerType';
        city: string;
        companyId: string;
        created: any;
        email: string;
        id: string;
        isActive: boolean;
        modified: any;
        name: string;
        phone?: string | null;
        street: string;
        vatId: string;
        zipCode: string;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        country: {
          __typename?: 'CountryType';
          name: string;
          code: string;
          alpha3: string;
          numeric: string;
          iocCode: string;
        };
      };
    };
  } | null;
  device: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    created: any;
    modified: any;
    manufacturerNote: string;
    registrationToken: string;
    state: DeviceStateChoices;
    serialNumber: string;
    plcPassword: string;
    plcUsername: string;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    deviceType: {
      __typename?: 'DeviceTypeType';
      name: string;
      serialNumberFormat: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
      note: string;
      hwVersion?: string | null;
      id: string;
      created: any;
      modified: any;
      devicesCount: number;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      manufacturer: {
        __typename?: 'ManufacturerType';
        city: string;
        companyId: string;
        created: any;
        email: string;
        id: string;
        isActive: boolean;
        modified: any;
        name: string;
        phone?: string | null;
        street: string;
        vatId: string;
        zipCode: string;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        country: {
          __typename?: 'CountryType';
          name: string;
          code: string;
          alpha3: string;
          numeric: string;
          iocCode: string;
        };
      };
    };
    installationCompanyCustomerSite?: {
      __typename?: 'InstallationCompanyCustomerSiteType';
      created: any;
      modified: any;
      street?: string | null;
      city?: string | null;
      zipCode?: string | null;
      id: string;
      name: string;
      note?: string | null;
      updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      country?: {
        __typename?: 'CountryType';
        name: string;
        code: string;
        alpha3: string;
        numeric: string;
        iocCode: string;
      } | null;
      gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
    } | null;
    technician?: { __typename?: 'UserType'; id: string; firstName: string; lastName: string } | null;
  };
  customerTicket?: { __typename?: 'InstallationCompanyCustomerTicketType'; id: string } | null;
};

export type InstallationCompanySelectItemFragment = {
  __typename?: 'InstallationCompanySelectItemType';
  id: string;
  name: string;
};

export type InstallationCompanyTicketCommentAttachmentFragment = {
  __typename?: 'InstallationCompanyTicketCommentAttachmentType';
  originalFileName: string;
  created: any;
  modified: any;
  id: string;
  fileSize: number;
  file: { __typename?: 'FileType'; url: string; name: string };
};

export type InstallationCompanyTicketCommentFragment = {
  __typename?: 'InstallationCompanyTicketCommentType';
  created: any;
  modified: any;
  id: string;
  comment: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  attachments: Array<{
    __typename?: 'InstallationCompanyTicketCommentAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type InstallationCompanyFragment = {
  __typename?: 'InstallationCompanyType';
  city: string;
  companyId: string;
  created: any;
  email: string;
  id: string;
  isActive: boolean;
  modified: any;
  name: string;
  phone?: string | null;
  street: string;
  vatId: string;
  zipCode: string;
  ticketsAllowed: boolean;
  country: { __typename?: 'CountryType'; name: string; code: string; alpha3: string; numeric: string; iocCode: string };
};

export type InstallationNoteFragment = {
  __typename?: 'InstallationNoteType';
  created: any;
  modified: any;
  id: string;
  note: string;
  attachments: Array<{
    __typename?: 'InstallationNoteAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type InstallationCompanyCustomerTicketFragment = {
  __typename?: 'InstallationCompanyCustomerTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: CustomerTicketAssigneeChoices;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  attachments: Array<{
    __typename?: 'CustomerTicketAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
  installationCompanyTechnician?: {
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
    userType: UserTypeChoices;
  } | null;
};

export type ManufacturerDeviceExtendedFragment = {
  __typename?: 'ManufacturerDeviceType';
  plcPassword: string;
  plcUsername: string;
  id: string;
  created: any;
  modified: any;
  manufacturerNote: string;
  registrationToken: string;
  state: DeviceStateChoices;
  serialNumber: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  deviceType: {
    __typename?: 'DeviceTypeType';
    name: string;
    serialNumberFormat: string;
    technology: DeviceTypeTechnologyChoices;
    apiType: DeviceTypeApiTypeChoices;
    note: string;
    hwVersion?: string | null;
    id: string;
    created: any;
    modified: any;
    devicesCount: number;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    manufacturer: {
      __typename?: 'ManufacturerType';
      city: string;
      companyId: string;
      created: any;
      email: string;
      id: string;
      isActive: boolean;
      modified: any;
      name: string;
      phone?: string | null;
      street: string;
      vatId: string;
      zipCode: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      country: {
        __typename?: 'CountryType';
        name: string;
        code: string;
        alpha3: string;
        numeric: string;
        iocCode: string;
      };
    };
  };
};

export type ManufacturerDeviceFragment = {
  __typename?: 'ManufacturerDeviceType';
  id: string;
  created: any;
  modified: any;
  manufacturerNote: string;
  registrationToken: string;
  state: DeviceStateChoices;
  serialNumber: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  deviceType: {
    __typename?: 'DeviceTypeType';
    name: string;
    serialNumberFormat: string;
    technology: DeviceTypeTechnologyChoices;
    apiType: DeviceTypeApiTypeChoices;
    note: string;
    hwVersion?: string | null;
    id: string;
    created: any;
    modified: any;
    devicesCount: number;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    manufacturer: {
      __typename?: 'ManufacturerType';
      city: string;
      companyId: string;
      created: any;
      email: string;
      id: string;
      isActive: boolean;
      modified: any;
      name: string;
      phone?: string | null;
      street: string;
      vatId: string;
      zipCode: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      country: {
        __typename?: 'CountryType';
        name: string;
        code: string;
        alpha3: string;
        numeric: string;
        iocCode: string;
      };
    };
  };
};

export type ManufacturerExtendedFragment = {
  __typename?: 'ManufacturerType';
  deviceCount: number;
  deviceTypeCount: number;
  userCount: number;
  city: string;
  companyId: string;
  created: any;
  email: string;
  id: string;
  isActive: boolean;
  modified: any;
  name: string;
  phone?: string | null;
  street: string;
  vatId: string;
  zipCode: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  country: { __typename?: 'CountryType'; name: string; code: string; alpha3: string; numeric: string; iocCode: string };
};

export type ManufacturerManufacturerTicketFragment = {
  __typename?: 'ManufacturerManufacturerTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: ManufacturerTicketAssigneeChoices;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'ManufacturerDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  attachments: Array<{
    __typename?: 'ManufacturerTicketAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type ManufacturerTicketAttachmentFragment = {
  __typename?: 'ManufacturerTicketAttachmentType';
  originalFileName: string;
  created: any;
  modified: any;
  id: string;
  fileSize: number;
  file: { __typename?: 'FileType'; url: string; name: string };
};

export type ManufacturerTicketCommentAttachmentFragment = {
  __typename?: 'ManufacturerTicketCommentAttachmentType';
  originalFileName: string;
  created: any;
  modified: any;
  id: string;
  fileSize: number;
  file: { __typename?: 'FileType'; url: string; name: string };
};

export type ManufacturerTicketCommentFragment = {
  __typename?: 'ManufacturerTicketCommentType';
  created: any;
  modified: any;
  id: string;
  comment: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  attachments: Array<{
    __typename?: 'ManufacturerTicketCommentAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type ManufacturerFragment = {
  __typename?: 'ManufacturerType';
  city: string;
  companyId: string;
  created: any;
  email: string;
  id: string;
  isActive: boolean;
  modified: any;
  name: string;
  phone?: string | null;
  street: string;
  vatId: string;
  zipCode: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  country: { __typename?: 'CountryType'; name: string; code: string; alpha3: string; numeric: string; iocCode: string };
};

export type CountryFragment = {
  __typename?: 'CountryType';
  name: string;
  code: string;
  alpha3: string;
  numeric: string;
  iocCode: string;
};

export type DeviceEventTypeFragment = {
  __typename?: 'DeviceEventTypeType';
  id: string;
  uid: number;
  priority: DeviceEventPriorityChoices;
  descriptionCs: string;
  descriptionEn: string;
  description: string;
  deviceType: {
    __typename?: 'DeviceTypeType';
    name: string;
    serialNumberFormat: string;
    technology: DeviceTypeTechnologyChoices;
    apiType: DeviceTypeApiTypeChoices;
    note: string;
    hwVersion?: string | null;
    id: string;
    created: any;
    modified: any;
    devicesCount: number;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    manufacturer: {
      __typename?: 'ManufacturerType';
      city: string;
      companyId: string;
      created: any;
      email: string;
      id: string;
      isActive: boolean;
      modified: any;
      name: string;
      phone?: string | null;
      street: string;
      vatId: string;
      zipCode: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      country: {
        __typename?: 'CountryType';
        name: string;
        code: string;
        alpha3: string;
        numeric: string;
        iocCode: string;
      };
    };
  };
};

export type DeviceTypeExtendedFragment = {
  __typename?: 'DeviceTypeType';
  mapping: string;
  name: string;
  serialNumberFormat: string;
  technology: DeviceTypeTechnologyChoices;
  apiType: DeviceTypeApiTypeChoices;
  note: string;
  hwVersion?: string | null;
  id: string;
  created: any;
  modified: any;
  devicesCount: number;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  manufacturer: {
    __typename?: 'ManufacturerType';
    city: string;
    companyId: string;
    created: any;
    email: string;
    id: string;
    isActive: boolean;
    modified: any;
    name: string;
    phone?: string | null;
    street: string;
    vatId: string;
    zipCode: string;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    country: {
      __typename?: 'CountryType';
      name: string;
      code: string;
      alpha3: string;
      numeric: string;
      iocCode: string;
    };
  };
};

export type DeviceTypeSelectItemFragment = { __typename?: 'DeviceTypeSelectItemType'; id: string; name: string };

export type DeviceTypeFragment = {
  __typename?: 'DeviceTypeType';
  name: string;
  serialNumberFormat: string;
  technology: DeviceTypeTechnologyChoices;
  apiType: DeviceTypeApiTypeChoices;
  note: string;
  hwVersion?: string | null;
  id: string;
  created: any;
  modified: any;
  devicesCount: number;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  manufacturer: {
    __typename?: 'ManufacturerType';
    city: string;
    companyId: string;
    created: any;
    email: string;
    id: string;
    isActive: boolean;
    modified: any;
    name: string;
    phone?: string | null;
    street: string;
    vatId: string;
    zipCode: string;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    country: {
      __typename?: 'CountryType';
      name: string;
      code: string;
      alpha3: string;
      numeric: string;
      iocCode: string;
    };
  };
};

export type ErrorCodeFragment = { __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string };

export type FileFragment = { __typename?: 'FileType'; url: string; name: string };

export type FormErrorFragment = {
  __typename?: 'FormErrorType';
  field: string;
  inputName?: string | null;
  codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
};

export type PaginationMetaFragment = {
  __typename?: 'PaginationMetaType';
  totalCount: number;
  pageSize: number;
  pagesCount: number;
  currentPage: number;
};

export type PlcFveMetricsFragment = {
  __typename?: 'PlcFveMetricsType';
  created: any;
  solarIrradiance?: number | null;
  internalTemperature?: number | null;
  batteryVoltage?: number | null;
  batteryCurrent?: number | null;
  batteryPower?: number | null;
  gridAvailable?: boolean | null;
  mppt1Voltage?: number | null;
  mppt1Power?: number | null;
  mppt2Voltage?: number | null;
  mppt2Power?: number | null;
  pvChargerEnable?: boolean | null;
  acChargerEnable?: boolean | null;
  gridFeedEnable?: boolean | null;
  chargingVoltage?: number | null;
  maxChargingCurrent?: number | null;
  powerGridConsumption?: number | null;
  hwVersion?: string | null;
  swVersion?: string | null;
  do1State?: boolean | null;
  do2State?: boolean | null;
  batteryNotCharged?: boolean | null;
  batteryTemperature?: number | null;
  batterySoc?: number | null;
  batteryCapacity?: number | null;
  batterySocDisconnectActual?: number | null;
  batterySocDisconnectMinimum?: number | null;
  batterySocDisconnectNogrid?: number | null;
  externalPvPower?: number | null;
  inverterSerialNumber?: string | null;
  gridPower: number;
  gridL1Power?: number | null;
  gridL2Power?: number | null;
  gridL3Power?: number | null;
  loadPower: number;
  loadL1Power?: number | null;
  loadL2Power?: number | null;
  loadL3Power?: number | null;
  mppt1Installed?: number | null;
  mppt2Installed?: number | null;
  stopAlarm?: boolean | null;
  gridFeedPowerMax?: number | null;
};

export type PlcHeatPumpMetricsFragment = {
  __typename?: 'PlcHeatPumpMetricsType';
  created: any;
  mode?: HeatPumpModeChoices | null;
  dhwTemperature?: number | null;
  dhwTarget?: number | null;
  dhwHysteresis?: number | null;
  heatingSystemTemperature?: number | null;
  heatingSystemTarget?: number | null;
  heatingSystemHysteresis?: number | null;
  outdoorunitTargetPercentage?: number | null;
  outputTemperature?: number | null;
  hasBoiler?: boolean | null;
  hasInverter?: boolean | null;
  feedbackFromOutputTemperature?: boolean | null;
  heatingSeasonActive?: boolean | null;
  stopAlarm?: boolean | null;
  bivalentPowerConsumption?: number | null;
  outputPower?: number | null;
  inputPower?: number | null;
  watterFlow?: number | null;
  heatingFactor?: number | null;
  outdoorTemperature?: number | null;
  indoorTemperature?: number | null;
  hwVersion?: string | null;
  swVersion?: string | null;
  outdoorunitActive?: boolean | null;
  outdoorunitDefrosting?: boolean | null;
  targetIndoorTemperature?: number | null;
  ekvitermType?: HeatPumpEkvitermTypeChoices | null;
  ekvitermPower?: number | null;
  internalPumpActive?: boolean | null;
  evaporatorTemperature?: number | null;
  condenserTemperature?: number | null;
  compressorTemperature?: number | null;
};

export type PreSignedUploadFragment = { __typename?: 'PreSignedUpload'; id: string; url: string };

export type UserSelectItemFragment = {
  __typename?: 'UserSelectItemType';
  id: string;
  firstName: string;
  lastName: string;
};

export type UserFragment = {
  __typename?: 'UserType';
  email: string;
  firstName: string;
  id: string;
  isActive: boolean;
  languageCode: UserLanguagesChoices;
  lastName: string;
  role: UserRoleChoices;
  userType: UserTypeChoices;
  lastLogin?: any | null;
  created: any;
  modified: any;
  state: UserStateChoices;
  phone?: string | null;
};

export type SystemAdminManufacturerCreateMutationVariables = Exact<{
  userCreationInput: UserCreationInput;
  manufacturerInput: ManufacturerRegistrationInput;
  manufacturerSubscriptionInput: ManufacturerSubscriptionInput;
}>;

export type SystemAdminManufacturerCreateMutation = {
  __typename?: 'Mutation';
  systemAdminManufacturerCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'UserRegistrationSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type SystemAdminManufacturerUpdateMutationVariables = Exact<{
  manufacturerId: Scalars['ID']['input'];
  manufacturerInput: ManufacturerRegistrationInput;
  manufacturerSubscriptionId: Scalars['ID']['input'];
  manufacturerSubscriptionInput: ManufacturerSubscriptionInput;
}>;

export type SystemAdminManufacturerUpdateMutation = {
  __typename?: 'Mutation';
  systemAdminManufacturerUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'SystemAdminManufacturerUpdateSuccess';
        manufacturer: { __typename?: 'SystemAdminManufacturerType'; id: string };
      };
};

export type SystemAdminUserDisableMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type SystemAdminUserDisableMutation = {
  __typename?: 'Mutation';
  systemAdminUserDisable:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type SystemAdminUserEnableMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type SystemAdminUserEnableMutation = {
  __typename?: 'Mutation';
  systemAdminUserEnable:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type SystemAdminUserInvitationMutationVariables = Exact<{
  userInvitationInput: AdminUserInvitationInput;
}>;

export type SystemAdminUserInvitationMutation = {
  __typename?: 'Mutation';
  systemAdminUserInvitation:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean }
    | {
        __typename?: 'UserInvitationSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type SystemAdminUserUpdateMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  userUpdateInput: AdminUserUpdateInput;
}>;

export type SystemAdminUserUpdateMutation = {
  __typename?: 'Mutation';
  systemAdminUserUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string }
    | {
        __typename?: 'UserUpdateSuccess';
        user: {
          __typename?: 'UserType';
          id: string;
          firstName: string;
          lastName: string;
          role: UserRoleChoices;
          phone?: string | null;
        };
      };
};

export type CustomerCheckoutSessionCreateMutationVariables = Exact<{
  tariffId: Scalars['ID']['input'];
}>;

export type CustomerCheckoutSessionCreateMutation = {
  __typename?: 'Mutation';
  customerCheckoutSessionCreate:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'CheckoutSession'; sessionId: string; publicKey: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerSiteCreateMutationVariables = Exact<{
  customerSiteInput: CustomerSiteInput;
}>;

export type CustomerCustomerSiteCreateMutation = {
  __typename?: 'Mutation';
  customerCustomerSiteCreate:
    | {
        __typename?: 'CustomerSiteCreateSuccess';
        customerSite: {
          __typename?: 'CustomerSiteType';
          created: any;
          modified: any;
          id: string;
          name: string;
          devices: Array<{
            __typename?: 'CustomerDeviceType';
            id: string;
            serialNumber: string;
            modified: any;
            deviceType: {
              __typename?: 'DeviceTypeType';
              id: string;
              name: string;
              technology: DeviceTypeTechnologyChoices;
              apiType: DeviceTypeApiTypeChoices;
            };
          }>;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      };
};

export type CustomerCustomerSiteDeleteMutationVariables = Exact<{
  customerSiteId: Scalars['ID']['input'];
}>;

export type CustomerCustomerSiteDeleteMutation = {
  __typename?: 'Mutation';
  customerCustomerSiteDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerSiteSetDefaultMutationVariables = Exact<{
  customerSiteId: Scalars['ID']['input'];
}>;

export type CustomerCustomerSiteSetDefaultMutation = {
  __typename?: 'Mutation';
  customerCustomerSiteSetDefault:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerSiteUpdateMutationVariables = Exact<{
  customerSiteId: Scalars['ID']['input'];
  customerSiteInput: CustomerSiteInput;
}>;

export type CustomerCustomerSiteUpdateMutation = {
  __typename?: 'Mutation';
  customerCustomerSiteUpdate:
    | {
        __typename?: 'CustomerSiteUpdateSuccess';
        customerSite: {
          __typename?: 'CustomerSiteType';
          created: any;
          modified: any;
          id: string;
          name: string;
          devices: Array<{
            __typename?: 'CustomerDeviceType';
            id: string;
            serialNumber: string;
            modified: any;
            deviceType: {
              __typename?: 'DeviceTypeType';
              id: string;
              name: string;
              technology: DeviceTypeTechnologyChoices;
              apiType: DeviceTypeApiTypeChoices;
            };
          }>;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerTicketAssignMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  assignee: CustomerTicketAssigneeChoices;
}>;

export type CustomerCustomerTicketAssignMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketAssign:
    | {
        __typename?: 'CustomerCustomerTicketResponse';
        customerTicket: {
          __typename?: 'CustomerCustomerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: CustomerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'CustomerDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerTicketAttachmentDeleteMutationVariables = Exact<{
  customerTicketAttachmentId: Scalars['ID']['input'];
}>;

export type CustomerCustomerTicketAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerTicketCloseMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type CustomerCustomerTicketCloseMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketClose:
    | {
        __typename?: 'CustomerCustomerTicketResponse';
        customerTicket: {
          __typename?: 'CustomerCustomerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: CustomerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'CustomerDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerTicketCommentAttachmentDeleteMutationVariables = Exact<{
  customerTicketCommentAttachmentId: Scalars['ID']['input'];
}>;

export type CustomerCustomerTicketCommentAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketCommentAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerTicketCommentCreateMutationVariables = Exact<{
  customerTicketCommentInput: CustomerTicketCommentInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type CustomerCustomerTicketCommentCreateMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketCommentCreate:
    | {
        __typename?: 'CustomerTicketCommentResponse';
        customerTicketComment: {
          __typename?: 'CustomerTicketCommentType';
          created: any;
          modified: any;
          id: string;
          comment: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketCommentAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      };
};

export type CustomerCustomerTicketCommentDeleteMutationVariables = Exact<{
  customerCommentId: Scalars['ID']['input'];
}>;

export type CustomerCustomerTicketCommentDeleteMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketCommentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerTicketCommentUpdateMutationVariables = Exact<{
  customerCommentId: Scalars['ID']['input'];
  customerTicketCommentInput: CustomerTicketCommentUpdateInput;
}>;

export type CustomerCustomerTicketCommentUpdateMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketCommentUpdate:
    | {
        __typename?: 'CustomerTicketCommentResponse';
        customerTicketComment: {
          __typename?: 'CustomerTicketCommentType';
          created: any;
          modified: any;
          id: string;
          comment: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketCommentAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerTicketCreateMutationVariables = Exact<{
  customerTicketInput: CustomerCustomerTicketInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type CustomerCustomerTicketCreateMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketCreate:
    | {
        __typename?: 'CustomerCustomerTicketResponse';
        customerTicket: {
          __typename?: 'CustomerCustomerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: CustomerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'CustomerDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      };
};

export type CustomerCustomerTicketDeleteMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type CustomerCustomerTicketDeleteMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerTicketUpdateMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  customerTicketUpdateInput: CustomerTicketUpdateInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type CustomerCustomerTicketUpdateMutation = {
  __typename?: 'Mutation';
  customerCustomerTicketUpdate:
    | {
        __typename?: 'CustomerCustomerTicketResponse';
        customerTicket: {
          __typename?: 'CustomerCustomerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: CustomerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'CustomerDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerCustomerUpdateMutationVariables = Exact<{
  customerInput: CustomerUpdateInput;
}>;

export type CustomerCustomerUpdateMutation = {
  __typename?: 'Mutation';
  customerCustomerUpdate:
    | {
        __typename?: 'CustomerUpdateSuccess';
        customer: {
          __typename?: 'CustomerType';
          id: string;
          name: string;
          email: string;
          phone?: string | null;
          companyId?: string | null;
          vatId?: string | null;
          street?: string | null;
          city?: string | null;
          zipCode?: string | null;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      };
};

export type CustomerDeviceMoveRegistrationMutationVariables = Exact<{
  customerDeviceMoveRegistrationInput: CustomerDeviceMoveRegistrationInput;
}>;

export type CustomerDeviceMoveRegistrationMutation = {
  __typename?: 'Mutation';
  customerDeviceMoveRegistration:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type CustomerDeviceRegisterMutationVariables = Exact<{
  customerDeviceRegisterInput: CustomerDeviceRegisterInput;
}>;

export type CustomerDeviceRegisterMutation = {
  __typename?: 'Mutation';
  customerDeviceRegister:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type CustomerDeviceUnregisterMutationVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type CustomerDeviceUnregisterMutation = {
  __typename?: 'Mutation';
  customerDeviceUnregister:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerExportMetricsMutationVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
}>;

export type CustomerExportMetricsMutation = {
  __typename?: 'Mutation';
  customerExportMetrics:
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string }
    | { __typename?: 'PlcExportSuccessResponse'; data: any }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type CustomerPaymentMethodCreateMutationVariables = Exact<{ [key: string]: never }>;

export type CustomerPaymentMethodCreateMutation = {
  __typename?: 'Mutation';
  customerPaymentMethodCreate:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'CheckoutSession'; sessionId: string; publicKey: string };
};

export type CustomerPlcEventMarkAsReadMutationVariables = Exact<{
  plcEventId: Scalars['ID']['input'];
}>;

export type CustomerPlcEventMarkAsReadMutation = {
  __typename?: 'Mutation';
  customerPlcEventMarkAsRead:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerPlcEventMarkAsUnreadMutationVariables = Exact<{
  plcEventId: Scalars['ID']['input'];
}>;

export type CustomerPlcEventMarkAsUnreadMutation = {
  __typename?: 'Mutation';
  customerPlcEventMarkAsUnread:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerRegistrationMutationVariables = Exact<{
  userRegistrationInput: UserRegistrationInput;
  customerInput?: InputMaybe<CustomerRegistrationInput>;
}>;

export type CustomerRegistrationMutation = {
  __typename?: 'Mutation';
  customerRegistration:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'UserRegistrationSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type CustomerSetTemperatureMutationVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  temperatureOffset: Scalars['Int']['input'];
}>;

export type CustomerSetTemperatureMutation = {
  __typename?: 'Mutation';
  customerSetTemperature:
    | {
        __typename?: 'CustomerSetTemperatureSuccessResponse';
        command: { __typename?: 'DeviceCommandType'; id: string; status: DeviceCommandStatusChoices };
      }
    | { __typename?: 'InvalidDeviceResponse'; deviceId: string }
    | { __typename?: 'InvalidTemperatureOffsetResponse'; temperatureOffset: number }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerStripeSubscriptionCancelMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
}>;

export type CustomerStripeSubscriptionCancelMutation = {
  __typename?: 'Mutation';
  customerStripeSubscriptionCancel:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerStripeSubscriptionUpdateMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
  tariffId: Scalars['ID']['input'];
}>;

export type CustomerStripeSubscriptionUpdateMutation = {
  __typename?: 'Mutation';
  customerStripeSubscriptionUpdate:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerUserDisableMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type CustomerUserDisableMutation = {
  __typename?: 'Mutation';
  customerUserDisable:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerUserEnableMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type CustomerUserEnableMutation = {
  __typename?: 'Mutation';
  customerUserEnable:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type CustomerUserInvitationMutationVariables = Exact<{
  userInvitationInput: UserInvitationInput;
}>;

export type CustomerUserInvitationMutation = {
  __typename?: 'Mutation';
  customerUserInvitation:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean }
    | {
        __typename?: 'UserInvitationSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type CustomerUserUpdateMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  userInvitationInput: UserUpdateInput;
}>;

export type CustomerUserUpdateMutation = {
  __typename?: 'Mutation';
  customerUserUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string }
    | {
        __typename?: 'UserUpdateSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type InstallationCompanyCheckoutSessionCreateMutationVariables = Exact<{
  tariffId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCheckoutSessionCreateMutation = {
  __typename?: 'Mutation';
  installationCompanyCheckoutSessionCreate:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'CheckoutSession'; sessionId: string; publicKey: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyCustomerTicketAssignTechnicianMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  installationCompanyTechnicianId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type InstallationCompanyCustomerTicketAssignTechnicianMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketAssignTechnician:
    | {
        __typename?: 'InstallationCompanyCustomerTicketResponse';
        customerTicket: {
          __typename?: 'InstallationCompanyCustomerTicketType';
          id: string;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyCustomerTicketAssignMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  assignee: CustomerTicketAssigneeChoices;
}>;

export type InstallationCompanyCustomerTicketAssignMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketAssign:
    | {
        __typename?: 'InstallationCompanyCustomerTicketResponse';
        customerTicket: {
          __typename?: 'InstallationCompanyCustomerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: CustomerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'InstallationCompanyDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyCustomerTicketAttachmentDeleteMutationVariables = Exact<{
  customerTicketAttachmentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCustomerTicketAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyCustomerTicketCloseMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCustomerTicketCloseMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketClose:
    | {
        __typename?: 'InstallationCompanyCustomerTicketResponse';
        customerTicket: {
          __typename?: 'InstallationCompanyCustomerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: CustomerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'InstallationCompanyDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyCustomerTicketCommentAttachmentDeleteMutationVariables = Exact<{
  customerTicketCommentAttachmentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCustomerTicketCommentAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketCommentAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyCustomerTicketCommentCreateMutationVariables = Exact<{
  customerTicketCommentInput: CustomerTicketCommentInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyCustomerTicketCommentCreateMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketCommentCreate:
    | {
        __typename?: 'CustomerTicketCommentResponse';
        customerTicketComment: {
          __typename?: 'CustomerTicketCommentType';
          created: any;
          modified: any;
          id: string;
          comment: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketCommentAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      };
};

export type InstallationCompanyCustomerTicketCommentDeleteMutationVariables = Exact<{
  customerCommentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCustomerTicketCommentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketCommentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse' }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyCustomerTicketCommentUpdateMutationVariables = Exact<{
  customerCommentId: Scalars['ID']['input'];
  customerTicketCommentInput: CustomerTicketCommentUpdateInput;
}>;

export type InstallationCompanyCustomerTicketCommentUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketCommentUpdate:
    | {
        __typename?: 'CustomerTicketCommentResponse';
        customerTicketComment: {
          __typename?: 'CustomerTicketCommentType';
          created: any;
          modified: any;
          id: string;
          comment: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketCommentAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyCustomerTicketCreateMutationVariables = Exact<{
  customerTicketInput: InstallationCompanyCustomerTicketInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyCustomerTicketCreateMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyCustomerTicketResponse';
        customerTicket: {
          __typename?: 'InstallationCompanyCustomerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: CustomerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'InstallationCompanyDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      };
};

export type InstallationCompanyCustomerTicketDeleteMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCustomerTicketDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyCustomerTicketUpdateMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  customerTicketUpdateInput: CustomerTicketUpdateInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyCustomerTicketUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyCustomerTicketUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyCustomerTicketResponse';
        customerTicket: {
          __typename?: 'InstallationCompanyCustomerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: CustomerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'InstallationCompanyDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'CustomerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyDeviceMoveRegistrationMutationVariables = Exact<{
  customerDeviceMoveRegistrationInput: InstallationCompanyMoveRegistrationInput;
}>;

export type InstallationCompanyDeviceMoveRegistrationMutation = {
  __typename?: 'Mutation';
  installationCompanyDeviceMoveRegistration:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type InstallationCompanyDeviceRegisterNewCustomerMutationVariables = Exact<{
  customerInput: InstallationCompanyCustomerInput;
  customerSiteInput: InstallationCompanyCustomerSiteInput;
  deviceRegisterInput?: InputMaybe<DeviceRegisterInput>;
  sendInvitation: Scalars['Boolean']['input'];
}>;

export type InstallationCompanyDeviceRegisterNewCustomerMutation = {
  __typename?: 'Mutation';
  installationCompanyDeviceRegisterNewCustomer:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type InstallationCompanyDeviceRegisterNewSiteMutationVariables = Exact<{
  existingCustomerInput: InstallationCompanyExistingCustomerInput;
  customerSiteInput: InstallationCompanyCustomerSiteInput;
  deviceRegisterInput?: InputMaybe<DeviceRegisterInput>;
}>;

export type InstallationCompanyDeviceRegisterNewSiteMutation = {
  __typename?: 'Mutation';
  installationCompanyDeviceRegisterNewSite:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type InstallationCompanyDeviceRegisterMutationVariables = Exact<{
  existingCustomerSiteInput: InstallationCompanyExistingCustomerSiteInput;
  deviceRegisterInput: DeviceRegisterInput;
}>;

export type InstallationCompanyDeviceRegisterMutation = {
  __typename?: 'Mutation';
  installationCompanyDeviceRegister:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type InstallationCompanyDeviceUnregisterMutationVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type InstallationCompanyDeviceUnregisterMutation = {
  __typename?: 'Mutation';
  installationCompanyDeviceUnregister:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyExportMetricsMutationVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
}>;

export type InstallationCompanyExportMetricsMutation = {
  __typename?: 'Mutation';
  installationCompanyExportMetrics:
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string }
    | { __typename?: 'PlcExportSuccessResponse'; data: any }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type InstallationCompanyInstallationAttachmentCreateMutationVariables = Exact<{
  installationAttachmentInputs: Array<InstallationAttachmentInput> | InstallationAttachmentInput;
}>;

export type InstallationCompanyInstallationAttachmentCreateMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationAttachmentCreate:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      };
};

export type InstallationCompanyInstallationAttachmentDeleteMutationVariables = Exact<{
  installationAttachmentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyConfigurationMutationVariables = Exact<{
  installationCompanyConfigurationInput: InstallationCompanyConfigurationInput;
}>;

export type InstallationCompanyInstallationCompanyConfigurationMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyConfiguration:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'InstallationCompanyConfigurationType'; ticketsAllowed: boolean }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type InstallationCompanyInstallationCompanyCustomerDeleteMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyCustomerDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyCustomerDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyCustomerInvitationMutationVariables = Exact<{
  installationCompanyCustomerId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyCustomerInvitationMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyCustomerInvitation:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyCustomerSiteDeleteMutationVariables = Exact<{
  customerSiteId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyCustomerSiteDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyCustomerSiteDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyCustomerSiteUpdateFragment = {
  __typename?: 'InstallationCompanyCustomerSiteType';
  id: string;
  name: string;
  street?: string | null;
  city?: string | null;
  zipCode?: string | null;
  note?: string | null;
  gps?: { __typename?: 'GpsType'; lng: number; lat: number } | null;
};

export type InstallationCompanyInstallationCompanyCustomerSiteUpdateMutationVariables = Exact<{
  customerSiteId: Scalars['ID']['input'];
  customerSiteInput: InstallationCompanyCustomerSiteInput;
}>;

export type InstallationCompanyInstallationCompanyCustomerSiteUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyCustomerSiteUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyCustomerSiteUpdateSuccess';
        installationCompanyCustomerSite: {
          __typename?: 'InstallationCompanyCustomerSiteType';
          id: string;
          name: string;
          street?: string | null;
          city?: string | null;
          zipCode?: string | null;
          note?: string | null;
          gps?: { __typename?: 'GpsType'; lng: number; lat: number } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyCustomerUpdateFragment = {
  __typename?: 'InstallationCompanyCustomerType';
  id: string;
  companyId?: string | null;
  email: string;
  name: string;
  phone: string;
  vatId?: string | null;
  note?: string | null;
  created: any;
  modified: any;
  sites: Array<{
    __typename?: 'InstallationCompanyCustomerSiteType';
    created: any;
    modified: any;
    street?: string | null;
    city?: string | null;
    zipCode?: string | null;
    id: string;
    name: string;
    note?: string | null;
    updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    country?: {
      __typename?: 'CountryType';
      name: string;
      code: string;
      alpha3: string;
      numeric: string;
      iocCode: string;
    } | null;
    gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
  }>;
};

export type InstallationCompanyInstallationCompanyCustomerUpdateMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  customerInput: InstallationCompanyCustomerInput;
}>;

export type InstallationCompanyInstallationCompanyCustomerUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyCustomerUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyCustomerUpdateSuccess';
        installationCompanyCustomer: {
          __typename?: 'InstallationCompanyCustomerType';
          id: string;
          companyId?: string | null;
          email: string;
          name: string;
          phone: string;
          vatId?: string | null;
          note?: string | null;
          created: any;
          modified: any;
          sites: Array<{
            __typename?: 'InstallationCompanyCustomerSiteType';
            created: any;
            modified: any;
            street?: string | null;
            city?: string | null;
            zipCode?: string | null;
            id: string;
            name: string;
            note?: string | null;
            updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
            createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
            country?: {
              __typename?: 'CountryType';
              name: string;
              code: string;
              alpha3: string;
              numeric: string;
              iocCode: string;
            } | null;
            gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
          }>;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyTicketAssignTechnicianMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  installationCompanyTechnicianId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type InstallationCompanyInstallationCompanyTicketAssignTechnicianMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyTicketAssignTechnician:
    | {
        __typename?: 'InstallationCompanyTicketResponse';
        installationCompanyTicket: {
          __typename?: 'InstallationCompanyTicketType';
          id: string;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyTicketAttachmentDeleteMutationVariables = Exact<{
  installationCompanyTicketAttachmentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyTicketAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyTicketAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyTicketCloseMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyTicketCloseMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyTicketClose:
    | {
        __typename?: 'InstallationCompanyTicketResponse';
        installationCompanyTicket: {
          __typename?: 'InstallationCompanyTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: {
            __typename?: 'InstallationCompanyType';
            city: string;
            companyId: string;
            created: any;
            email: string;
            id: string;
            isActive: boolean;
            modified: any;
            name: string;
            phone?: string | null;
            street: string;
            vatId: string;
            zipCode: string;
            ticketsAllowed: boolean;
            country: {
              __typename?: 'CountryType';
              name: string;
              code: string;
              alpha3: string;
              numeric: string;
              iocCode: string;
            };
          };
          attachments: Array<{
            __typename?: 'InstallationCompanyTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse' };
};

export type InstallationCompanyInstallationCompanyTicketCommentAttachmentDeleteMutationVariables = Exact<{
  installationCompanyTicketCommentAttachmentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyTicketCommentAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyTicketCommentAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyTicketCommentCreateMutationVariables = Exact<{
  installationCompanyTicketCommentInput: InstallationCompanyTicketCommentInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyInstallationCompanyTicketCommentCreateMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyTicketCommentCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyTicketCommentResponse';
        installationCompanyTicketComment: {
          __typename?: 'InstallationCompanyTicketCommentType';
          created: any;
          modified: any;
          id: string;
          comment: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          attachments: Array<{
            __typename?: 'InstallationCompanyTicketCommentAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      };
};

export type InstallationCompanyInstallationCompanyTicketCommentDeleteMutationVariables = Exact<{
  installationCompanyTicketCommentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyTicketCommentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyTicketCommentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse' }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyTicketCreateMutationVariables = Exact<{
  installationCompanyTicketInput: InstallationCompanyTicketInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyInstallationCompanyTicketCreateMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyTicketCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyTicketResponse';
        installationCompanyTicket: {
          __typename?: 'InstallationCompanyTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: {
            __typename?: 'InstallationCompanyType';
            city: string;
            companyId: string;
            created: any;
            email: string;
            id: string;
            isActive: boolean;
            modified: any;
            name: string;
            phone?: string | null;
            street: string;
            vatId: string;
            zipCode: string;
            ticketsAllowed: boolean;
            country: {
              __typename?: 'CountryType';
              name: string;
              code: string;
              alpha3: string;
              numeric: string;
              iocCode: string;
            };
          };
          attachments: Array<{
            __typename?: 'InstallationCompanyTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      };
};

export type InstallationCompanyInstallationCompanyTicketDeleteMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyTicketDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyTicketDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyTicketUpdateMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  installationCompanyTicketUpdateInput: InstallationCompanyTicketUpdateInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyInstallationCompanyTicketUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyTicketUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyTicketResponse';
        installationCompanyTicket: {
          __typename?: 'InstallationCompanyTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: {
            __typename?: 'InstallationCompanyType';
            city: string;
            companyId: string;
            created: any;
            email: string;
            id: string;
            isActive: boolean;
            modified: any;
            name: string;
            phone?: string | null;
            street: string;
            vatId: string;
            zipCode: string;
            ticketsAllowed: boolean;
            country: {
              __typename?: 'CountryType';
              name: string;
              code: string;
              alpha3: string;
              numeric: string;
              iocCode: string;
            };
          };
          attachments: Array<{
            __typename?: 'InstallationCompanyTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationCompanyUpdateMutationVariables = Exact<{
  installationCompanyInput: InstallationCompanyRegistrationInput;
}>;

export type InstallationCompanyInstallationCompanyUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationCompanyUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyUpdateSuccess';
        installationCompany: {
          __typename?: 'InstallationCompanyType';
          id: string;
          name: string;
          email: string;
          phone?: string | null;
          companyId: string;
          vatId: string;
          street: string;
          city: string;
          zipCode: string;
        };
      };
};

export type InstallationCompanyInstallationNoteAttachmentDeleteMutationVariables = Exact<{
  installationNoteAttachmentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationNoteAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationNoteAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationNoteCreateFragment = {
  __typename?: 'InstallationNoteType';
  created: any;
  modified: any;
  id: string;
  note: string;
  attachments: Array<{
    __typename?: 'InstallationNoteAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type InstallationCompanyInstallationNoteCreateMutationVariables = Exact<{
  installationNoteInput: InstallationNoteInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyInstallationNoteCreateMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationNoteCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationNoteResponse';
        installationNote: {
          __typename?: 'InstallationNoteType';
          created: any;
          modified: any;
          id: string;
          note: string;
          attachments: Array<{
            __typename?: 'InstallationNoteAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      };
};

export type InstallationCompanyInstallationNoteDeleteMutationVariables = Exact<{
  installationNoteId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationNoteDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationNoteDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyInstallationNoteUpdateMutationVariables = Exact<{
  installationNoteId: Scalars['ID']['input'];
  installationNoteInput: InstallationNoteUpdateInput;
}>;

export type InstallationCompanyInstallationNoteUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyInstallationNoteUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationNoteResponse';
        installationNote: { __typename?: 'InstallationNoteType'; id: string; note: string; modified: any };
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyManufacturerTicketAssignTechnicianMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  installationCompanyTechnicianId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type InstallationCompanyManufacturerTicketAssignTechnicianMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketAssignTechnician:
    | {
        __typename?: 'InstallationCompanyManufacturerTicketResponse';
        manufacturerTicket: {
          __typename?: 'InstallationCompanyManufacturerTicketType';
          id: string;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyManufacturerTicketAssignMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  assignee: ManufacturerTicketAssigneeChoices;
}>;

export type InstallationCompanyManufacturerTicketAssignMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketAssign:
    | {
        __typename?: 'InstallationCompanyManufacturerTicketResponse';
        manufacturerTicket: {
          __typename?: 'InstallationCompanyManufacturerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: ManufacturerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'InstallationCompanyDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyManufacturerTicketAttachmentDeleteMutationVariables = Exact<{
  manufacturerTicketAttachmentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyManufacturerTicketAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyManufacturerTicketCloseMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyManufacturerTicketCloseMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketClose:
    | {
        __typename?: 'InstallationCompanyManufacturerTicketResponse';
        manufacturerTicket: {
          __typename?: 'InstallationCompanyManufacturerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: ManufacturerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'InstallationCompanyDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyManufacturerTicketCommentAttachmentDeleteMutationVariables = Exact<{
  manufacturerTicketCommentAttachmentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyManufacturerTicketCommentAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketCommentAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyManufacturerTicketCommentCreateMutationVariables = Exact<{
  manufacturerTicketCommentInput: ManufacturerTicketCommentInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyManufacturerTicketCommentCreateMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketCommentCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'ManufacturerTicketCommentResponse';
        manufacturerTicketComment: {
          __typename?: 'ManufacturerTicketCommentType';
          created: any;
          modified: any;
          id: string;
          comment: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketCommentAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      };
};

export type InstallationCompanyManufacturerTicketCommentDeleteMutationVariables = Exact<{
  manufacturerTicketCommentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyManufacturerTicketCommentDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketCommentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse' }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyManufacturerTicketCommentUpdateMutationVariables = Exact<{
  manufacturerTicketCommentId: Scalars['ID']['input'];
  manufacturerTicketCommentInput: ManufacturerTicketCommentUpdateInput;
}>;

export type InstallationCompanyManufacturerTicketCommentUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketCommentUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'ManufacturerTicketCommentResponse';
        manufacturerTicketComment: {
          __typename?: 'ManufacturerTicketCommentType';
          created: any;
          modified: any;
          id: string;
          comment: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketCommentAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyManufacturerTicketCreateMutationVariables = Exact<{
  installationCompanyManufacturerTicketInput: InstallationCompanyManufacturerTicketInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyManufacturerTicketCreateMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyManufacturerTicketResponse';
        manufacturerTicket: {
          __typename?: 'InstallationCompanyManufacturerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: ManufacturerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'InstallationCompanyDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      };
};

export type InstallationCompanyManufacturerTicketDeleteMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyManufacturerTicketDeleteMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyManufacturerTicketUpdateMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  manufacturerTicketUpdateInput: ManufacturerTicketUpdateInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type InstallationCompanyManufacturerTicketUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyManufacturerTicketUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'InstallationCompanyManufacturerTicketResponse';
        manufacturerTicket: {
          __typename?: 'InstallationCompanyManufacturerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: ManufacturerTicketAssigneeChoices;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'InstallationCompanyDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
          installationCompanyTechnician?: {
            __typename?: 'UserSelectItemType';
            id: string;
            firstName: string;
            lastName: string;
            userType: UserTypeChoices;
          } | null;
        };
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyPlcEventMarkAsReadMutationVariables = Exact<{
  plcEventId: Scalars['ID']['input'];
}>;

export type InstallationCompanyPlcEventMarkAsReadMutation = {
  __typename?: 'Mutation';
  installationCompanyPlcEventMarkAsRead:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyPlcEventMarkAsUnreadMutationVariables = Exact<{
  plcEventId: Scalars['ID']['input'];
}>;

export type InstallationCompanyPlcEventMarkAsUnreadMutation = {
  __typename?: 'Mutation';
  installationCompanyPlcEventMarkAsUnread:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyRegistrationMutationVariables = Exact<{
  userRegistrationInput: UserRegistrationInput;
  installationCompanyInput: InstallationCompanyRegistrationInput;
}>;

export type InstallationCompanyRegistrationMutation = {
  __typename?: 'Mutation';
  installationCompanyRegistration:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'UserRegistrationSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type InstallationCompanySendCommandMutationVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  command: Scalars['String']['input'];
}>;

export type InstallationCompanySendCommandMutation = {
  __typename?: 'Mutation';
  installationCompanySendCommand:
    | {
        __typename?: 'InstallationCompanySendCommandSuccessResponse';
        command: {
          __typename?: 'DeviceCommandType';
          id: string;
          uid: string;
          command: string;
          created: any;
          responseReceived?: any | null;
          status: DeviceCommandStatusChoices;
          response?: string | null;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyStripeSubscriptionCancelMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
}>;

export type InstallationCompanyStripeSubscriptionCancelMutation = {
  __typename?: 'Mutation';
  installationCompanyStripeSubscriptionCancel:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyStripeSubscriptionUpdateMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
  tariffId: Scalars['ID']['input'];
}>;

export type InstallationCompanyStripeSubscriptionUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyStripeSubscriptionUpdate:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyUserDisableMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type InstallationCompanyUserDisableMutation = {
  __typename?: 'Mutation';
  installationCompanyUserDisable:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyUserEnableMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type InstallationCompanyUserEnableMutation = {
  __typename?: 'Mutation';
  installationCompanyUserEnable:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type InstallationCompanyUserInvitationMutationVariables = Exact<{
  userInvitationInput: UserInvitationInput;
}>;

export type InstallationCompanyUserInvitationMutation = {
  __typename?: 'Mutation';
  installationCompanyUserInvitation:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean }
    | {
        __typename?: 'UserInvitationSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type InstallationCompanyUserUpdateMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  userInvitationInput: UserUpdateInput;
}>;

export type InstallationCompanyUserUpdateMutation = {
  __typename?: 'Mutation';
  installationCompanyUserUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string }
    | {
        __typename?: 'UserUpdateSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type ManufacturerDeviceCreateMutationVariables = Exact<{
  deviceCreateInput: DeviceCreateInput;
}>;

export type ManufacturerDeviceCreateMutation = {
  __typename?: 'Mutation';
  manufacturerDeviceCreate:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type ManufacturerDeviceDeleteMutationVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ManufacturerDeviceDeleteMutation = {
  __typename?: 'Mutation';
  manufacturerDeviceDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse' }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerDeviceEventTypeDeleteMutationVariables = Exact<{
  deviceEventTypeId: Scalars['ID']['input'];
}>;

export type ManufacturerDeviceEventTypeDeleteMutation = {
  __typename?: 'Mutation';
  manufacturerDeviceEventTypeDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerDeviceEventTypeUpsertMutationVariables = Exact<{
  input: DeviceEventTypeInput;
  deviceTypeId: Scalars['ID']['input'];
  deviceEventTypeId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ManufacturerDeviceEventTypeUpsertMutation = {
  __typename?: 'Mutation';
  manufacturerDeviceEventTypeUpsert:
    | {
        __typename?: 'DeviceEventTypeUpsertSuccess';
        deviceEventType: {
          __typename?: 'DeviceEventTypeType';
          id: string;
          uid: number;
          priority: DeviceEventPriorityChoices;
          descriptionCs: string;
          descriptionEn: string;
          customerVisibility: boolean;
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerDeviceExportMutationVariables = Exact<{
  devices: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type ManufacturerDeviceExportMutation = {
  __typename?: 'Mutation';
  manufacturerDeviceExport: { __typename?: 'DeviceExportResponse'; data: any };
};

export type ManufacturerDeviceTypeDeleteMutationVariables = Exact<{
  deviceTypeId: Scalars['ID']['input'];
}>;

export type ManufacturerDeviceTypeDeleteMutation = {
  __typename?: 'Mutation';
  manufacturerDeviceTypeDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ExistingDevicesResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerDeviceTypeUpsertMutationVariables = Exact<{
  deviceTypeInput: DeviceTypeInput;
  deviceTypeId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ManufacturerDeviceTypeUpsertMutation = {
  __typename?: 'Mutation';
  manufacturerDeviceTypeUpsert:
    | {
        __typename?: 'DeviceTypeUpsertSuccess';
        deviceType: {
          __typename?: 'DeviceTypeType';
          mapping: string;
          name: string;
          serialNumberFormat: string;
          technology: DeviceTypeTechnologyChoices;
          apiType: DeviceTypeApiTypeChoices;
          note: string;
          hwVersion?: string | null;
          id: string;
          created: any;
          modified: any;
          devicesCount: number;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          manufacturer: {
            __typename?: 'ManufacturerType';
            city: string;
            companyId: string;
            created: any;
            email: string;
            id: string;
            isActive: boolean;
            modified: any;
            name: string;
            phone?: string | null;
            street: string;
            vatId: string;
            zipCode: string;
            createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
            country: {
              __typename?: 'CountryType';
              name: string;
              code: string;
              alpha3: string;
              numeric: string;
              iocCode: string;
            };
          };
        };
      }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string }
    | { __typename?: 'TariffRestrictionResponse' };
};

export type ManufacturerDeviceUpdateMutationVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  deviceInput: DeviceInput;
}>;

export type ManufacturerDeviceUpdateMutation = {
  __typename?: 'Mutation';
  manufacturerDeviceUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'ManufacturerUpdateDeviceSuccess';
        device: {
          __typename?: 'ManufacturerDeviceType';
          plcPassword: string;
          plcUsername: string;
          id: string;
          created: any;
          modified: any;
          manufacturerNote: string;
          registrationToken: string;
          state: DeviceStateChoices;
          serialNumber: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          deviceType: {
            __typename?: 'DeviceTypeType';
            name: string;
            serialNumberFormat: string;
            technology: DeviceTypeTechnologyChoices;
            apiType: DeviceTypeApiTypeChoices;
            note: string;
            hwVersion?: string | null;
            id: string;
            created: any;
            modified: any;
            devicesCount: number;
            createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
            manufacturer: {
              __typename?: 'ManufacturerType';
              city: string;
              companyId: string;
              created: any;
              email: string;
              id: string;
              isActive: boolean;
              modified: any;
              name: string;
              phone?: string | null;
              street: string;
              vatId: string;
              zipCode: string;
              createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
              country: {
                __typename?: 'CountryType';
                name: string;
                code: string;
                alpha3: string;
                numeric: string;
                iocCode: string;
              };
            };
          };
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerManufacturerTicketAssignMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  assignee: ManufacturerTicketAssigneeChoices;
}>;

export type ManufacturerManufacturerTicketAssignMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketAssign:
    | {
        __typename?: 'ManufacturerManufacturerTicketResponse';
        manufacturerTicket: {
          __typename?: 'ManufacturerManufacturerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: ManufacturerTicketAssigneeChoices;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'ManufacturerDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerManufacturerTicketAttachmentDeleteMutationVariables = Exact<{
  manufacturerTicketAttachmentId: Scalars['ID']['input'];
}>;

export type ManufacturerManufacturerTicketAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerManufacturerTicketCloseMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type ManufacturerManufacturerTicketCloseMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketClose:
    | {
        __typename?: 'ManufacturerManufacturerTicketResponse';
        manufacturerTicket: {
          __typename?: 'ManufacturerManufacturerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: ManufacturerTicketAssigneeChoices;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'ManufacturerDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerManufacturerTicketCommentAttachmentDeleteMutationVariables = Exact<{
  manufacturerTicketCommentAttachmentId: Scalars['ID']['input'];
}>;

export type ManufacturerManufacturerTicketCommentAttachmentDeleteMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketCommentAttachmentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerManufacturerTicketCommentCreateMutationVariables = Exact<{
  manufacturerTicketCommentInput: ManufacturerTicketCommentInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type ManufacturerManufacturerTicketCommentCreateMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketCommentCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'ManufacturerTicketCommentResponse';
        manufacturerTicketComment: {
          __typename?: 'ManufacturerTicketCommentType';
          created: any;
          modified: any;
          id: string;
          comment: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketCommentAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      };
};

export type ManufacturerManufacturerTicketCommentDeleteMutationVariables = Exact<{
  manufacturerTicketCommentId: Scalars['ID']['input'];
}>;

export type ManufacturerManufacturerTicketCommentDeleteMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketCommentDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse' }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerManufacturerTicketCommentUpdateMutationVariables = Exact<{
  manufacturerTicketCommentId: Scalars['ID']['input'];
  manufacturerTicketCommentInput: ManufacturerTicketCommentUpdateInput;
}>;

export type ManufacturerManufacturerTicketCommentUpdateMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketCommentUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'ManufacturerTicketCommentResponse';
        manufacturerTicketComment: {
          __typename?: 'ManufacturerTicketCommentType';
          created: any;
          modified: any;
          id: string;
          comment: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketCommentAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerManufacturerTicketCreateMutationVariables = Exact<{
  manufacturerManufacturerTicketInput: ManufacturerManufacturerTicketInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type ManufacturerManufacturerTicketCreateMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'ManufacturerManufacturerTicketResponse';
        manufacturerTicket: {
          __typename?: 'ManufacturerManufacturerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: ManufacturerTicketAssigneeChoices;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'ManufacturerDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      };
};

export type ManufacturerManufacturerTicketDeleteMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type ManufacturerManufacturerTicketDeleteMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketDelete:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectCannotBeDeletedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerManufacturerTicketUpdateMutationVariables = Exact<{
  ticketId: Scalars['ID']['input'];
  manufacturerTicketUpdateInput: ManufacturerTicketUpdateInput;
  attachments?: InputMaybe<Array<AttachmentInput> | AttachmentInput>;
}>;

export type ManufacturerManufacturerTicketUpdateMutation = {
  __typename?: 'Mutation';
  manufacturerManufacturerTicketUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'ManufacturerManufacturerTicketResponse';
        manufacturerTicket: {
          __typename?: 'ManufacturerManufacturerTicketType';
          created: any;
          modified: any;
          category: TicketCategoryChoices;
          priority: TicketPriorityChoices;
          title: string;
          content: string;
          id: string;
          isClosed: boolean;
          assignee: ManufacturerTicketAssigneeChoices;
          installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
          device?: {
            __typename?: 'ManufacturerDeviceType';
            id: string;
            deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
          } | null;
          attachments: Array<{
            __typename?: 'ManufacturerTicketAttachmentType';
            originalFileName: string;
            created: any;
            modified: any;
            id: string;
            fileSize: number;
            file: { __typename?: 'FileType'; url: string; name: string };
          }>;
        };
      }
    | { __typename?: 'ObjectCannotBeModifiedResponse'; id: string }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerUserDisableMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type ManufacturerUserDisableMutation = {
  __typename?: 'Mutation';
  manufacturerUserDisable:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerUserEnableMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type ManufacturerUserEnableMutation = {
  __typename?: 'Mutation';
  manufacturerUserEnable:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string };
};

export type ManufacturerUserInvitationMutationVariables = Exact<{
  userInvitationInput: UserInvitationInput;
}>;

export type ManufacturerUserInvitationMutation = {
  __typename?: 'Mutation';
  manufacturerUserInvitation:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean }
    | {
        __typename?: 'UserInvitationSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type ManufacturerUserUpdateMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  userInvitationInput: UserUpdateInput;
}>;

export type ManufacturerUserUpdateMutation = {
  __typename?: 'Mutation';
  manufacturerUserUpdate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string }
    | {
        __typename?: 'UserUpdateSuccess';
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type CustomerInviteRegistrationMutationVariables = Exact<{
  userRegistrationInput: UserRegistrationInput;
  customerInput?: InputMaybe<CustomerRegistrationInput>;
  token: Scalars['String']['input'];
}>;

export type CustomerInviteRegistrationMutation = {
  __typename?: 'Mutation';
  customerInviteRegistration:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'TokenExpiredResponse'; ok: boolean }
    | {
        __typename?: 'TokenResponseSuccess';
        accessToken: string;
        user: { __typename?: 'UserType'; id: string; userType: UserTypeChoices };
      };
};

export type PreSignedUploadCreateMutationVariables = Exact<{
  files: Array<FileUploadInput> | FileUploadInput;
}>;

export type PreSignedUploadCreateMutation = {
  __typename?: 'Mutation';
  preSignedUploadCreate:
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | {
        __typename?: 'PreSignedUploads';
        preSignedUploads: Array<{ __typename?: 'PreSignedUpload'; id: string; url: string }>;
      };
};

export type TokenObtainMutationVariables = Exact<{
  input: TokenObtainInput;
}>;

export type TokenObtainMutation = {
  __typename?: 'Mutation';
  tokenObtain:
    | { __typename?: 'InvalidCredentialsError'; ok: boolean }
    | {
        __typename?: 'TokenResponseSuccess';
        accessToken: string;
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type TokenRefreshMutationVariables = Exact<{ [key: string]: never }>;

export type TokenRefreshMutation = {
  __typename?: 'Mutation';
  tokenRefresh:
    | { __typename?: 'InvalidRefreshToken'; ok: boolean }
    | {
        __typename?: 'TokenResponseSuccess';
        accessToken: string;
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      };
};

export type TokenRevokeMutationVariables = Exact<{ [key: string]: never }>;

export type TokenRevokeMutation = {
  __typename?: 'Mutation';
  tokenRevoke: { __typename?: 'BaseSuccessResponse'; ok: boolean };
};

export type TranslatorTokenObtainMutationVariables = Exact<{ [key: string]: never }>;

export type TranslatorTokenObtainMutation = { __typename?: 'Mutation'; translatorTokenObtain: string };

export type UserActivationMutationVariables = Exact<{
  input: UserActivationInput;
}>;

export type UserActivationMutation = {
  __typename?: 'Mutation';
  userActivation:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | { __typename?: 'TokenExpiredResponse' }
    | {
        __typename?: 'TokenResponseSuccess';
        accessToken: string;
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      }
    | { __typename?: 'UserDisabledResponse' };
};

export type UserChangePasswordMutationVariables = Exact<{
  input: UserChangePasswordInput;
}>;

export type UserChangePasswordMutation = {
  __typename?: 'Mutation';
  userChangePassword:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      };
};

export type UserForgottenPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type UserForgottenPasswordMutation = {
  __typename?: 'Mutation';
  userForgottenPassword: { __typename?: 'BaseSuccessResponse'; ok: boolean };
};

export type UserSetLanguageMutationVariables = Exact<{
  languageCode: UserLanguagesChoices;
}>;

export type UserSetLanguageMutation = {
  __typename?: 'Mutation';
  userSetLanguage: {
    __typename?: 'UserSetLanguageResponse';
    user: {
      __typename?: 'UserType';
      email: string;
      firstName: string;
      id: string;
      isActive: boolean;
      languageCode: UserLanguagesChoices;
      lastName: string;
      role: UserRoleChoices;
      userType: UserTypeChoices;
      lastLogin?: any | null;
      created: any;
      modified: any;
      state: UserStateChoices;
      phone?: string | null;
    };
  };
};

export type UserSetPasswordMutationVariables = Exact<{
  userSetPasswordInput: UserSetPasswordInput;
}>;

export type UserSetPasswordMutation = {
  __typename?: 'Mutation';
  userSetPassword:
    | { __typename?: 'BaseSuccessResponse'; ok: boolean }
    | {
        __typename?: 'FormErrorResponse';
        errors: Array<{
          __typename?: 'FormErrorType';
          field: string;
          inputName?: string | null;
          codes: Array<{ __typename?: 'ErrorCodeType'; code: ErrorCodeEnum; message: string }>;
        }>;
      }
    | { __typename?: 'ObjectDoesNotExistsResponse' }
    | { __typename?: 'TokenExpiredResponse' }
    | {
        __typename?: 'TokenResponseSuccess';
        accessToken: string;
        user: {
          __typename?: 'UserType';
          email: string;
          firstName: string;
          id: string;
          isActive: boolean;
          languageCode: UserLanguagesChoices;
          lastName: string;
          role: UserRoleChoices;
          userType: UserTypeChoices;
          lastLogin?: any | null;
          created: any;
          modified: any;
          state: UserStateChoices;
          phone?: string | null;
        };
      }
    | { __typename?: 'UserDisabledResponse' };
};

export type UserVerifyTokenMutationVariables = Exact<{
  input: TokenVerificationInput;
}>;

export type UserVerifyTokenMutation = {
  __typename?: 'Mutation';
  userVerifyToken:
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string }
    | { __typename?: 'TokenExpiredResponse'; ok: boolean }
    | { __typename?: 'TokenVerificationSuccess'; token: string };
};

export type SystemAdminCustomerDetailQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type SystemAdminCustomerDetailQuery = {
  __typename?: 'Query';
  systemAdminCustomerDetail?: {
    __typename?: 'CustomerType';
    id: string;
    city?: string | null;
    companyId?: string | null;
    email: string;
    name: string;
    phone?: string | null;
    street?: string | null;
    vatId?: string | null;
    zipCode?: string | null;
    created: any;
    modified: any;
    deviceCount: number;
    isActive: boolean;
    country?: {
      __typename?: 'CountryType';
      name: string;
      code: string;
      alpha3: string;
      numeric: string;
      iocCode: string;
    } | null;
  } | null;
};

export type SystemAdminCustomerListFragment = {
  __typename?: 'CustomerType';
  id: string;
  companyId?: string | null;
  email: string;
  name: string;
  vatId?: string | null;
  created: any;
  deviceCount: number;
  isActive: boolean;
  userCount: number;
  subscription?: { __typename?: 'CustomerStripeSubscriptionType'; id: string; currentPeriodEnd: any } | null;
  tariff?: { __typename?: 'CustomerTariffType'; id: string; name: string } | null;
};

export type SystemAdminCustomerListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  tariff?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  orderBy?: InputMaybe<CustomersOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SystemAdminCustomerListQuery = {
  __typename?: 'Query';
  systemAdminCustomerList: {
    __typename?: 'PaginatedCustomersListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerType';
      id: string;
      companyId?: string | null;
      email: string;
      name: string;
      vatId?: string | null;
      created: any;
      deviceCount: number;
      isActive: boolean;
      userCount: number;
      subscription?: { __typename?: 'CustomerStripeSubscriptionType'; id: string; currentPeriodEnd: any } | null;
      tariff?: { __typename?: 'CustomerTariffType'; id: string; name: string } | null;
    }>;
  };
};

export type SystemAdminCustomerTariffSelectItemsFragment = {
  __typename?: 'CustomerTariffType';
  name: string;
  priceType: TariffPriceTypeChoices;
  price: number;
  currency: string;
  id: string;
};

export type SystemAdminCustomerTariffSelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type SystemAdminCustomerTariffSelectItemsQuery = {
  __typename?: 'Query';
  systemAdminCustomerTariffSelectItems: Array<{
    __typename?: 'CustomerTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
  }>;
};

export type SystemAdminInstallationCompanyDetailQueryVariables = Exact<{
  installationCompanyId: Scalars['ID']['input'];
}>;

export type SystemAdminInstallationCompanyDetailQuery = {
  __typename?: 'Query';
  systemAdminInstallationCompanyDetail?: {
    __typename?: 'InstallationCompanyType';
    city: string;
    companyId: string;
    created: any;
    email: string;
    id: string;
    isActive: boolean;
    modified: any;
    name: string;
    phone?: string | null;
    street: string;
    vatId: string;
    zipCode: string;
    ticketsAllowed: boolean;
    country: {
      __typename?: 'CountryType';
      name: string;
      code: string;
      alpha3: string;
      numeric: string;
      iocCode: string;
    };
  } | null;
};

export type SystemAdminInstallationCompanyListFragment = {
  __typename?: 'InstallationCompanyType';
  city: string;
  companyId: string;
  created: any;
  email: string;
  id: string;
  isActive: boolean;
  modified: any;
  name: string;
  vatId: string;
  deviceCount: number;
  userCount: number;
  subscription?: { __typename?: 'InstallationCompanyStripeSubscriptionType'; id: string; currentPeriodEnd: any } | null;
  tariff?: { __typename?: 'InstallationCompanyTariffType'; id: string; name: string } | null;
};

export type SystemAdminInstallationCompanyListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  tariff?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  orderBy?: InputMaybe<InstallationCompaniesOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SystemAdminInstallationCompanyListQuery = {
  __typename?: 'Query';
  systemAdminInstallationCompanyList: {
    __typename?: 'PaginatedCompaniesListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyType';
      city: string;
      companyId: string;
      created: any;
      email: string;
      id: string;
      isActive: boolean;
      modified: any;
      name: string;
      vatId: string;
      deviceCount: number;
      userCount: number;
      subscription?: {
        __typename?: 'InstallationCompanyStripeSubscriptionType';
        id: string;
        currentPeriodEnd: any;
      } | null;
      tariff?: { __typename?: 'InstallationCompanyTariffType'; id: string; name: string } | null;
    }>;
  };
};

export type SystemAdminInstallationCompanyTariffSelectItemsFragment = {
  __typename?: 'InstallationCompanyTariffType';
  name: string;
  priceType: TariffPriceTypeChoices;
  price: number;
  currency: string;
  id: string;
};

export type SystemAdminInstallationCompanyTariffSelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type SystemAdminInstallationCompanyTariffSelectItemsQuery = {
  __typename?: 'Query';
  systemAdminInstallationCompanyTariffSelectItems: Array<{
    __typename?: 'InstallationCompanyTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
  }>;
};

export type SystemAdminManufacturerDetailFragment = {
  __typename?: 'SystemAdminManufacturerType';
  city: string;
  companyId: string;
  email: string;
  id: string;
  name: string;
  phone?: string | null;
  street: string;
  vatId: string;
  zipCode: string;
  note: string;
  subscription?: {
    __typename?: 'ManufacturerSubscriptionType';
    id: string;
    currentPeriodStart: any;
    currentPeriodEnd: any;
    manufacturerTariff: { __typename?: 'ManufacturerTariffType'; id: string };
  } | null;
};

export type SystemAdminManufacturerDetailQueryVariables = Exact<{
  manufacturerId: Scalars['ID']['input'];
}>;

export type SystemAdminManufacturerDetailQuery = {
  __typename?: 'Query';
  systemAdminManufacturerDetail?: {
    __typename?: 'SystemAdminManufacturerType';
    city: string;
    companyId: string;
    email: string;
    id: string;
    name: string;
    phone?: string | null;
    street: string;
    vatId: string;
    zipCode: string;
    note: string;
    subscription?: {
      __typename?: 'ManufacturerSubscriptionType';
      id: string;
      currentPeriodStart: any;
      currentPeriodEnd: any;
      manufacturerTariff: { __typename?: 'ManufacturerTariffType'; id: string };
    } | null;
  } | null;
};

export type SystemAdminManufacturerListFragment = {
  __typename?: 'SystemAdminManufacturerType';
  companyId: string;
  created: any;
  email: string;
  id: string;
  isActive: boolean;
  modified: any;
  name: string;
  vatId: string;
  deviceCount: number;
  deviceTypeCount: number;
  userCount: number;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  subscription?: {
    __typename?: 'ManufacturerSubscriptionType';
    id: string;
    currentPeriodEnd: any;
    manufacturerTariff: { __typename?: 'ManufacturerTariffType'; id: string; name: string };
  } | null;
};

export type SystemAdminManufacturerListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  tariff?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  orderBy?: InputMaybe<ManufacturersOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SystemAdminManufacturerListQuery = {
  __typename?: 'Query';
  systemAdminManufacturerList: {
    __typename?: 'PaginatedSystemAdminManufacturerTypeListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'SystemAdminManufacturerType';
      companyId: string;
      created: any;
      email: string;
      id: string;
      isActive: boolean;
      modified: any;
      name: string;
      vatId: string;
      deviceCount: number;
      deviceTypeCount: number;
      userCount: number;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      subscription?: {
        __typename?: 'ManufacturerSubscriptionType';
        id: string;
        currentPeriodEnd: any;
        manufacturerTariff: { __typename?: 'ManufacturerTariffType'; id: string; name: string };
      } | null;
    }>;
  };
};

export type SystemAdminManufacturerTariffSelectItemsFragment = {
  __typename?: 'ManufacturerTariffType';
  name: string;
  priceType: TariffPriceTypeChoices;
  price: number;
  currency: string;
  id: string;
  numberOfDevices?: number | null;
  numberOfDeviceTypes?: number | null;
};

export type SystemAdminManufacturerTariffSelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type SystemAdminManufacturerTariffSelectItemsQuery = {
  __typename?: 'Query';
  systemAdminManufacturerTariffSelectItems: Array<{
    __typename?: 'ManufacturerTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
    numberOfDevices?: number | null;
    numberOfDeviceTypes?: number | null;
  }>;
};

export type SystemAdminStripeSubscriptionListFragment = {
  __typename?: 'StripeSubscriptionType';
  id: string;
  subscriptionCreated: any;
  currentPeriodStart: any;
  currentPeriodEnd: any;
  tariff: {
    __typename?: 'StripeTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
  };
};

export type SystemAdminStripeSubscriptionListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SystemAdminStripeSubscriptionListQuery = {
  __typename?: 'Query';
  systemAdminStripeSubscriptionList: {
    __typename?: 'PaginatedStripeSubscriptionListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'StripeSubscriptionType';
      id: string;
      subscriptionCreated: any;
      currentPeriodStart: any;
      currentPeriodEnd: any;
      tariff: {
        __typename?: 'StripeTariffType';
        name: string;
        priceType: TariffPriceTypeChoices;
        price: number;
        currency: string;
        id: string;
      };
    }>;
  };
};

export type SystemAdminUserDetailQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type SystemAdminUserDetailQuery = {
  __typename?: 'Query';
  systemAdminUserDetail?: {
    __typename?: 'UserType';
    email: string;
    firstName: string;
    id: string;
    isActive: boolean;
    languageCode: UserLanguagesChoices;
    lastName: string;
    role: UserRoleChoices;
    userType: UserTypeChoices;
    lastLogin?: any | null;
    created: any;
    modified: any;
    state: UserStateChoices;
    phone?: string | null;
  } | null;
};

export type SystemAdminUserListFragment = {
  __typename?: 'UserType';
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  role: UserRoleChoices;
  lastLogin?: any | null;
  created: any;
  state: UserStateChoices;
  modified: any;
};

export type SystemAdminUserListQueryVariables = Exact<{
  orderBy?: InputMaybe<UserOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SystemAdminUserListQuery = {
  __typename?: 'Query';
  systemAdminUserList: {
    __typename?: 'PaginatedUserListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'UserType';
      email: string;
      firstName: string;
      id: string;
      lastName: string;
      role: UserRoleChoices;
      lastLogin?: any | null;
      created: any;
      state: UserStateChoices;
      modified: any;
    }>;
  };
};

export type CustomerCustomerSiteDetailFragment = {
  __typename?: 'CustomerSiteType';
  created: any;
  modified: any;
  id: string;
  name: string;
  devices: Array<{
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    modified: any;
    deviceType: {
      __typename?: 'DeviceTypeType';
      id: string;
      name: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
    };
  }>;
};

export type CustomerCustomerSiteDetailQueryVariables = Exact<{
  customerSiteId: Scalars['ID']['input'];
}>;

export type CustomerCustomerSiteDetailQuery = {
  __typename?: 'Query';
  customerCustomerSiteDetail?: {
    __typename?: 'CustomerSiteType';
    created: any;
    modified: any;
    id: string;
    name: string;
    devices: Array<{
      __typename?: 'CustomerDeviceType';
      id: string;
      serialNumber: string;
      modified: any;
      deviceType: {
        __typename?: 'DeviceTypeType';
        id: string;
        name: string;
        technology: DeviceTypeTechnologyChoices;
        apiType: DeviceTypeApiTypeChoices;
      };
    }>;
  } | null;
};

export type CustomerCustomerSiteListFragment = {
  __typename?: 'CustomerSiteType';
  id: string;
  name: string;
  isDefault: boolean;
  devices: Array<{
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    modified: any;
    deviceType: {
      __typename?: 'DeviceTypeType';
      id: string;
      name: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
      mappingJson: any;
    };
    installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
  }>;
};

export type CustomerCustomerSiteListQueryVariables = Exact<{
  orderBy?: InputMaybe<CustomerSitesOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CustomerCustomerSiteListQuery = {
  __typename?: 'Query';
  customerCustomerSiteList: {
    __typename?: 'PaginatedCustomerSitesListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerSiteType';
      id: string;
      name: string;
      isDefault: boolean;
      devices: Array<{
        __typename?: 'CustomerDeviceType';
        id: string;
        serialNumber: string;
        modified: any;
        deviceType: {
          __typename?: 'DeviceTypeType';
          id: string;
          name: string;
          technology: DeviceTypeTechnologyChoices;
          apiType: DeviceTypeApiTypeChoices;
          mappingJson: any;
        };
        installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
      }>;
    }>;
  };
};

export type CustomerCustomerSiteSelectItemsDefaultFragment = {
  __typename?: 'CustomerSiteType';
  id: string;
  name: string;
  isDefault: boolean;
  devices: Array<{ __typename?: 'CustomerDeviceType'; id: string }>;
};

export type CustomerCustomerSiteSelectItemsDefaultQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerCustomerSiteSelectItemsDefaultQuery = {
  __typename?: 'Query';
  customerCustomerSiteSelectItems: Array<{
    __typename?: 'CustomerSiteType';
    id: string;
    name: string;
    isDefault: boolean;
    devices: Array<{ __typename?: 'CustomerDeviceType'; id: string }>;
  }>;
};

export type CustomerCustomerSiteSelectItemsFragment = { __typename?: 'CustomerSiteType'; id: string; name: string };

export type CustomerCustomerSiteSelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerCustomerSiteSelectItemsQuery = {
  __typename?: 'Query';
  customerCustomerSiteSelectItems: Array<{ __typename?: 'CustomerSiteType'; id: string; name: string }>;
};

export type CustomerCustomerStripeSubscriptionListFragment = {
  __typename?: 'CustomerStripeSubscriptionType';
  id: string;
  subscriptionCreated: any;
  currentPeriodStart: any;
  currentPeriodEnd: any;
  canceledAt?: any | null;
  status: StripeSubscriptionStatusChoices;
  tariff: {
    __typename?: 'StripeTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
  };
};

export type CustomerCustomerStripeSubscriptionListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CustomerCustomerStripeSubscriptionListQuery = {
  __typename?: 'Query';
  customerCustomerStripeSubscriptionList: {
    __typename?: 'PaginatedCustomerStripeSubscriptionListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerStripeSubscriptionType';
      id: string;
      subscriptionCreated: any;
      currentPeriodStart: any;
      currentPeriodEnd: any;
      canceledAt?: any | null;
      status: StripeSubscriptionStatusChoices;
      tariff: {
        __typename?: 'StripeTariffType';
        name: string;
        priceType: TariffPriceTypeChoices;
        price: number;
        currency: string;
        id: string;
      };
    }>;
  };
};

export type CustomerCustomerTariffSelectItemsFragment = {
  __typename?: 'CustomerTariffType';
  name: string;
  priceType: TariffPriceTypeChoices;
  price: number;
  currency: string;
  id: string;
  numberOfDevices?: number | null;
  numberOfUsers?: number | null;
  commands: boolean;
  dataExportImage: boolean;
  dataExportExcel: boolean;
  lineChartDataGranularity: TariffLineChartDataGranularityChoices;
  lineChartDataRetention: TariffLineChartDataRetentionChoices;
  barChartDataGranularity: TariffBarChartDataGranularityChoices;
  activeSubscription?: { __typename?: 'CustomerStripeSubscriptionType'; id: string } | null;
};

export type CustomerCustomerTariffSelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerCustomerTariffSelectItemsQuery = {
  __typename?: 'Query';
  monthly: Array<{
    __typename?: 'CustomerTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
    numberOfDevices?: number | null;
    numberOfUsers?: number | null;
    commands: boolean;
    dataExportImage: boolean;
    dataExportExcel: boolean;
    lineChartDataGranularity: TariffLineChartDataGranularityChoices;
    lineChartDataRetention: TariffLineChartDataRetentionChoices;
    barChartDataGranularity: TariffBarChartDataGranularityChoices;
    activeSubscription?: { __typename?: 'CustomerStripeSubscriptionType'; id: string } | null;
  }>;
  yearly: Array<{
    __typename?: 'CustomerTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
    numberOfDevices?: number | null;
    numberOfUsers?: number | null;
    commands: boolean;
    dataExportImage: boolean;
    dataExportExcel: boolean;
    lineChartDataGranularity: TariffLineChartDataGranularityChoices;
    lineChartDataRetention: TariffLineChartDataRetentionChoices;
    barChartDataGranularity: TariffBarChartDataGranularityChoices;
    activeSubscription?: { __typename?: 'CustomerStripeSubscriptionType'; id: string } | null;
  }>;
};

export type CustomerCustomerTicketCommentDetailQueryVariables = Exact<{
  commentId: Scalars['ID']['input'];
}>;

export type CustomerCustomerTicketCommentDetailQuery = {
  __typename?: 'Query';
  customerCustomerTicketCommentDetail?: {
    __typename?: 'CustomerTicketCommentType';
    created: any;
    modified: any;
    id: string;
    comment: string;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    attachments: Array<{
      __typename?: 'CustomerTicketCommentAttachmentType';
      originalFileName: string;
      created: any;
      modified: any;
      id: string;
      fileSize: number;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
  } | null;
};

export type CustomerCustomerTicketCommentListQueryVariables = Exact<{
  customerTicket?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CustomerCustomerTicketCommentListQuery = {
  __typename?: 'Query';
  customerCustomerTicketCommentList: {
    __typename?: 'PaginatedCustomerTicketCommentListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerTicketCommentType';
      created: any;
      modified: any;
      id: string;
      comment: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      attachments: Array<{
        __typename?: 'CustomerTicketCommentAttachmentType';
        originalFileName: string;
        created: any;
        modified: any;
        id: string;
        fileSize: number;
        file: { __typename?: 'FileType'; url: string; name: string };
      }>;
    }>;
  };
};

export type CustomerCustomerTicketDetailEditFragment = {
  __typename?: 'CustomerCustomerTicketType';
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: CustomerTicketAssigneeChoices;
  attachments: Array<{
    __typename?: 'CustomerTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type CustomerCustomerTicketDetailEditQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type CustomerCustomerTicketDetailEditQuery = {
  __typename?: 'Query';
  customerCustomerTicketDetail?: {
    __typename?: 'CustomerCustomerTicketType';
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    assignee: CustomerTicketAssigneeChoices;
    attachments: Array<{
      __typename?: 'CustomerTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
  } | null;
};

export type CustomerCustomerTicketDetailFragment = {
  __typename?: 'CustomerCustomerTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: CustomerTicketAssigneeChoices;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  deviceEvent?: {
    __typename?: 'DeviceEventType';
    id: string;
    eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
  } | null;
  attachments: Array<{
    __typename?: 'CustomerTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
};

export type CustomerCustomerTicketDetailQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type CustomerCustomerTicketDetailQuery = {
  __typename?: 'Query';
  customerCustomerTicketDetail?: {
    __typename?: 'CustomerCustomerTicketType';
    created: any;
    modified: any;
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    assignee: CustomerTicketAssigneeChoices;
    installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
    device?: {
      __typename?: 'CustomerDeviceType';
      id: string;
      serialNumber: string;
      deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
    } | null;
    deviceEvent?: {
      __typename?: 'DeviceEventType';
      id: string;
      eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
    } | null;
    attachments: Array<{
      __typename?: 'CustomerTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  } | null;
};

export type CustomerCustomerTicketListDetailFragment = {
  __typename?: 'CustomerCustomerTicketType';
  created: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  id: string;
  isClosed: boolean;
  assignee: CustomerTicketAssigneeChoices;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  lastComment?: {
    __typename?: 'CommentType';
    id: string;
    created: any;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  } | null;
};

export type CustomerCustomerTicketListDetailQueryVariables = Exact<{
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  installationCompany?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  priority?: InputMaybe<Array<TicketPriorityChoices> | TicketPriorityChoices>;
  category?: InputMaybe<Array<TicketCategoryChoices> | TicketCategoryChoices>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  device?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  orderBy?: InputMaybe<CustomerTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CustomerCustomerTicketListDetailQuery = {
  __typename?: 'Query';
  customerCustomerTicketList: {
    __typename?: 'PaginatedCustomerCustomerTicketListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerCustomerTicketType';
      created: any;
      category: TicketCategoryChoices;
      priority: TicketPriorityChoices;
      title: string;
      id: string;
      isClosed: boolean;
      assignee: CustomerTicketAssigneeChoices;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
      device?: { __typename?: 'CustomerDeviceType'; id: string; serialNumber: string } | null;
      lastComment?: {
        __typename?: 'CommentType';
        id: string;
        created: any;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      } | null;
    }>;
  };
};

export type CustomerCustomerTicketListFragment = {
  __typename?: 'CustomerCustomerTicketType';
  created: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  id: string;
  isClosed: boolean;
  assignee: CustomerTicketAssigneeChoices;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: { __typename?: 'CustomerDeviceType'; id: string; serialNumber: string } | null;
  lastComment?: {
    __typename?: 'CommentType';
    id: string;
    created: any;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  } | null;
};

export type CustomerCustomerTicketListQueryVariables = Exact<{
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  installationCompany?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  priority?: InputMaybe<Array<TicketPriorityChoices> | TicketPriorityChoices>;
  category?: InputMaybe<Array<TicketCategoryChoices> | TicketCategoryChoices>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  device?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  orderBy?: InputMaybe<CustomerTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CustomerCustomerTicketListQuery = {
  __typename?: 'Query';
  customerCustomerTicketList: {
    __typename?: 'PaginatedCustomerCustomerTicketListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerCustomerTicketType';
      created: any;
      category: TicketCategoryChoices;
      priority: TicketPriorityChoices;
      title: string;
      id: string;
      isClosed: boolean;
      assignee: CustomerTicketAssigneeChoices;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
      device?: { __typename?: 'CustomerDeviceType'; id: string; serialNumber: string } | null;
      lastComment?: {
        __typename?: 'CommentType';
        id: string;
        created: any;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      } | null;
    }>;
  };
};

export type CustomerDashboardDetailDeviceFragment = {
  __typename?: 'CustomerDeviceType';
  id: string;
  serialNumber: string;
  modified: any;
  deviceType: {
    __typename?: 'DeviceTypeType';
    id: string;
    name: string;
    technology: DeviceTypeTechnologyChoices;
    apiType: DeviceTypeApiTypeChoices;
    mappingJson: any;
  };
};

export type CustomerDashboardDetailFragment = {
  __typename?: 'CustomerSiteType';
  created: any;
  modified: any;
  id: string;
  name: string;
  devices: Array<{
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    modified: any;
    deviceType: {
      __typename?: 'DeviceTypeType';
      id: string;
      name: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
      mappingJson: any;
    };
  }>;
};

export type CustomerDashboardDetailQueryVariables = Exact<{
  customerSiteId: Scalars['ID']['input'];
}>;

export type CustomerDashboardDetailQuery = {
  __typename?: 'Query';
  customerCustomerSiteDetail?: {
    __typename?: 'CustomerSiteType';
    created: any;
    modified: any;
    id: string;
    name: string;
    devices: Array<{
      __typename?: 'CustomerDeviceType';
      id: string;
      serialNumber: string;
      modified: any;
      deviceType: {
        __typename?: 'DeviceTypeType';
        id: string;
        name: string;
        technology: DeviceTypeTechnologyChoices;
        apiType: DeviceTypeApiTypeChoices;
        mappingJson: any;
      };
    }>;
  } | null;
};

export type CustomerPlcEventLastNEventsFragment = {
  __typename?: 'CustomerPlcEventType';
  deviceId: string;
  id: string;
  eventId: number;
  markedAsRead: boolean;
  created: any;
  eventType?: {
    __typename?: 'DeviceEventTypeType';
    id: string;
    description: string;
    priority: DeviceEventPriorityChoices;
  } | null;
  device: {
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; name: string };
  };
};

export type CustomerPlcEventLastNEventsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerPlcEventLastNEventsQuery = {
  __typename?: 'Query';
  customerPlcEventLastNEvents: Array<{
    __typename?: 'CustomerPlcEventType';
    deviceId: string;
    id: string;
    eventId: number;
    markedAsRead: boolean;
    created: any;
    eventType?: {
      __typename?: 'DeviceEventTypeType';
      id: string;
      description: string;
      priority: DeviceEventPriorityChoices;
    } | null;
    device: {
      __typename?: 'CustomerDeviceType';
      id: string;
      serialNumber: string;
      deviceType: { __typename?: 'DeviceTypeType'; name: string };
    };
  }>;
};

export type CustomerCustomerTicketLastNTicketsFragment = {
  __typename?: 'CustomerCustomerTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  device?: { __typename?: 'CustomerDeviceType'; id: string; serialNumber: string } | null;
};

export type CustomerCustomerTicketLastNTicketsQueryVariables = Exact<{
  n?: Scalars['Int']['input'];
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CustomerCustomerTicketLastNTicketsQuery = {
  __typename?: 'Query';
  customerCustomerTicketLastNTickets: Array<{
    __typename?: 'CustomerCustomerTicketType';
    created: any;
    modified: any;
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    device?: { __typename?: 'CustomerDeviceType'; id: string; serialNumber: string } | null;
  }>;
};

export type CustomerDeviceDetailGuardQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type CustomerDeviceDetailGuardQuery = {
  __typename?: 'Query';
  customerDeviceDetail?: {
    __typename?: 'CustomerDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; apiType: DeviceTypeApiTypeChoices };
  } | null;
};

export type CustomerDeviceDetailTicketsFragment = {
  __typename?: 'CustomerDeviceType';
  id: string;
  serialNumber: string;
  installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; name: string } | null;
};

export type CustomerDeviceDetailTicketsQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type CustomerDeviceDetailTicketsQuery = {
  __typename?: 'Query';
  customerDeviceDetail?: {
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; name: string } | null;
  } | null;
};

export type CustomerDeviceDetailFragment = {
  __typename?: 'CustomerDeviceType';
  id: string;
  serialNumber: string;
  deviceType: {
    __typename?: 'DeviceTypeType';
    name: string;
    technology: DeviceTypeTechnologyChoices;
    apiType: DeviceTypeApiTypeChoices;
    id: string;
  };
  customerSite?: { __typename?: 'CustomerSiteType'; id: string; name: string } | null;
  installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
};

export type CustomerDeviceDetailQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type CustomerDeviceDetailQuery = {
  __typename?: 'Query';
  customerDeviceDetail?: {
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    deviceType: {
      __typename?: 'DeviceTypeType';
      name: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
      id: string;
    };
    customerSite?: { __typename?: 'CustomerSiteType'; id: string; name: string } | null;
    installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
  } | null;
};

export type CustomerDeviceListFragment = {
  __typename?: 'CustomerDeviceType';
  id: string;
  serialNumber: string;
  deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  customerSite?: { __typename?: 'CustomerSiteType'; id: string; name: string } | null;
};

export type CustomerDeviceListQueryVariables = Exact<{
  technology?: InputMaybe<Array<DeviceTypeTechnologyChoices> | DeviceTypeTechnologyChoices>;
  orderBy?: InputMaybe<DeviceOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CustomerDeviceListQuery = {
  __typename?: 'Query';
  customerDeviceList: {
    __typename?: 'CustomerPaginatedDeviceListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerDeviceType';
      id: string;
      serialNumber: string;
      deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
      customerSite?: { __typename?: 'CustomerSiteType'; id: string; name: string } | null;
    }>;
  };
};

export type CustomerDeviceSelectItemsTicketsAllowedFragment = {
  __typename?: 'CustomerDeviceType';
  id: string;
  serialNumber: string;
  installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; name: string } | null;
};

export type CustomerDeviceSelectItemsTicketsAllowedQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerDeviceSelectItemsTicketsAllowedQuery = {
  __typename?: 'Query';
  customerDeviceSelectItems: Array<{
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; name: string } | null;
  }>;
};

export type CustomerDeviceTypeSelectItemsQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type CustomerDeviceTypeSelectItemsQuery = {
  __typename?: 'Query';
  customerDeviceTypeSelectItems: Array<{ __typename?: 'DeviceTypeSelectItemType'; id: string; name: string }>;
};

export type CustomerInstallationCompanySelectItemsTicketsAllowedQueryVariables = Exact<{
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  eventId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CustomerInstallationCompanySelectItemsTicketsAllowedQuery = {
  __typename?: 'Query';
  customerInstallationCompanySelectItems: Array<{
    __typename?: 'InstallationCompanySelectItemType';
    id: string;
    name: string;
  }>;
};

export type CustomerInstallationCompanySelectItemsQueryVariables = Exact<{
  ticketsAllowed: Scalars['Boolean']['input'];
}>;

export type CustomerInstallationCompanySelectItemsQuery = {
  __typename?: 'Query';
  customerInstallationCompanySelectItems: Array<{
    __typename?: 'InstallationCompanySelectItemType';
    id: string;
    name: string;
  }>;
};

export type CustomerMyDeviceListFragment = {
  __typename?: 'CustomerDeviceType';
  id: string;
  serialNumber: string;
  lastMetricsTimestamp?: any | null;
  registeredAt?: any | null;
  deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; name: string } | null;
  customerSite?: { __typename?: 'CustomerSiteType'; id: string; name: string } | null;
};

export type CustomerMyDeviceListQueryVariables = Exact<{
  technology?: InputMaybe<Array<DeviceTypeTechnologyChoices> | DeviceTypeTechnologyChoices>;
  orderBy?: InputMaybe<DeviceOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CustomerMyDeviceListQuery = {
  __typename?: 'Query';
  customerDeviceList: {
    __typename?: 'CustomerPaginatedDeviceListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerDeviceType';
      id: string;
      serialNumber: string;
      lastMetricsTimestamp?: any | null;
      registeredAt?: any | null;
      deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
      installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; name: string } | null;
      customerSite?: { __typename?: 'CustomerSiteType'; id: string; name: string } | null;
    }>;
  };
};

export type CustomerPlcEventDetailTicketsFragment = {
  __typename?: 'CustomerPlcEventType';
  id: string;
  eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
};

export type CustomerPlcEventDetailTicketsQueryVariables = Exact<{
  plcEventId: Scalars['ID']['input'];
}>;

export type CustomerPlcEventDetailTicketsQuery = {
  __typename?: 'Query';
  customerPlcEventDetail?: {
    __typename?: 'CustomerPlcEventType';
    id: string;
    eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
  } | null;
};

export type CustomerPlcEventDetailQueryVariables = Exact<{
  plcEventId: Scalars['ID']['input'];
}>;

export type CustomerPlcEventDetailQuery = {
  __typename?: 'Query';
  customerPlcEventDetail?: {
    __typename?: 'CustomerPlcEventType';
    created: any;
    deviceId: string;
    id: string;
    eventId: number;
    markedAsRead: boolean;
    eventType?: {
      __typename?: 'DeviceEventTypeType';
      id: string;
      uid: number;
      priority: DeviceEventPriorityChoices;
      descriptionCs: string;
      descriptionEn: string;
      description: string;
      deviceType: {
        __typename?: 'DeviceTypeType';
        name: string;
        serialNumberFormat: string;
        technology: DeviceTypeTechnologyChoices;
        apiType: DeviceTypeApiTypeChoices;
        note: string;
        hwVersion?: string | null;
        id: string;
        created: any;
        modified: any;
        devicesCount: number;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        manufacturer: {
          __typename?: 'ManufacturerType';
          city: string;
          companyId: string;
          created: any;
          email: string;
          id: string;
          isActive: boolean;
          modified: any;
          name: string;
          phone?: string | null;
          street: string;
          vatId: string;
          zipCode: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          country: {
            __typename?: 'CountryType';
            name: string;
            code: string;
            alpha3: string;
            numeric: string;
            iocCode: string;
          };
        };
      };
    } | null;
    device: {
      __typename?: 'CustomerDeviceType';
      id: string;
      created: any;
      modified: any;
      manufacturerNote: string;
      registrationToken: string;
      state: DeviceStateChoices;
      serialNumber: string;
      plcPassword: string;
      plcUsername: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      deviceType: {
        __typename?: 'DeviceTypeType';
        name: string;
        serialNumberFormat: string;
        technology: DeviceTypeTechnologyChoices;
        apiType: DeviceTypeApiTypeChoices;
        note: string;
        hwVersion?: string | null;
        id: string;
        created: any;
        modified: any;
        devicesCount: number;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        manufacturer: {
          __typename?: 'ManufacturerType';
          city: string;
          companyId: string;
          created: any;
          email: string;
          id: string;
          isActive: boolean;
          modified: any;
          name: string;
          phone?: string | null;
          street: string;
          vatId: string;
          zipCode: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          country: {
            __typename?: 'CountryType';
            name: string;
            code: string;
            alpha3: string;
            numeric: string;
            iocCode: string;
          };
        };
      };
      customerSite?: { __typename?: 'CustomerSiteType'; name: string } | null;
    };
    customerTicket?: {
      __typename?: 'CustomerCustomerTicketType';
      id: string;
      customer: { __typename?: 'CustomerType'; street?: string | null; city?: string | null; name: string };
    } | null;
  } | null;
};

export type CustomerPlcEventListDetailFragment = {
  __typename?: 'CustomerPlcEventType';
  id: string;
  created: any;
  markedAsRead: boolean;
  eventId: number;
  eventType?: {
    __typename?: 'DeviceEventTypeType';
    id: string;
    description: string;
    priority: DeviceEventPriorityChoices;
  } | null;
  device: {
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string; apiType: DeviceTypeApiTypeChoices };
    customerSite?: { __typename?: 'CustomerSiteType'; name: string } | null;
    installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
  };
  customerTicket?: { __typename?: 'CustomerCustomerTicketType'; id: string } | null;
};

export type CustomerPlcEventListDetailQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Array<DeviceEventPriorityChoices> | DeviceEventPriorityChoices>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  device?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  customerSite?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  hasTicket?: InputMaybe<Scalars['Boolean']['input']>;
  notRead?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<PlcEventOrderingOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CustomerPlcEventListDetailQuery = {
  __typename?: 'Query';
  customerPlcEventList: {
    __typename?: 'CustomerPlcEventPaginatedDeviceListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerPlcEventType';
      id: string;
      created: any;
      markedAsRead: boolean;
      eventId: number;
      eventType?: {
        __typename?: 'DeviceEventTypeType';
        id: string;
        description: string;
        priority: DeviceEventPriorityChoices;
      } | null;
      device: {
        __typename?: 'CustomerDeviceType';
        id: string;
        serialNumber: string;
        deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string; apiType: DeviceTypeApiTypeChoices };
        customerSite?: { __typename?: 'CustomerSiteType'; name: string } | null;
        installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
      };
      customerTicket?: { __typename?: 'CustomerCustomerTicketType'; id: string } | null;
    }>;
  };
};

export type CustomerPlcEventListFragment = {
  __typename?: 'CustomerPlcEventType';
  id: string;
  created: any;
  markedAsRead: boolean;
  eventId: number;
  eventType?: {
    __typename?: 'DeviceEventTypeType';
    id: string;
    description: string;
    priority: DeviceEventPriorityChoices;
  } | null;
  device: {
    __typename?: 'CustomerDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string; apiType: DeviceTypeApiTypeChoices };
    customerSite?: { __typename?: 'CustomerSiteType'; name: string } | null;
    installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
  };
  customerTicket?: { __typename?: 'CustomerCustomerTicketType'; id: string } | null;
};

export type CustomerPlcEventListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Array<DeviceEventPriorityChoices> | DeviceEventPriorityChoices>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  customerSite?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  hasTicket?: InputMaybe<Scalars['Boolean']['input']>;
  notRead?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<PlcEventOrderingOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CustomerPlcEventListQuery = {
  __typename?: 'Query';
  customerPlcEventList: {
    __typename?: 'CustomerPlcEventPaginatedDeviceListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerPlcEventType';
      id: string;
      created: any;
      markedAsRead: boolean;
      eventId: number;
      eventType?: {
        __typename?: 'DeviceEventTypeType';
        id: string;
        description: string;
        priority: DeviceEventPriorityChoices;
      } | null;
      device: {
        __typename?: 'CustomerDeviceType';
        id: string;
        serialNumber: string;
        deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string; apiType: DeviceTypeApiTypeChoices };
        customerSite?: { __typename?: 'CustomerSiteType'; name: string } | null;
        installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
      };
      customerTicket?: { __typename?: 'CustomerCustomerTicketType'; id: string } | null;
    }>;
  };
};

export type CustomerPlcFveCurrentDataQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type CustomerPlcFveCurrentDataQuery = {
  __typename?: 'Query';
  customerDeviceDetail?: {
    __typename?: 'CustomerDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; mappingJson: any };
  } | null;
  plcFveCurrentData?: {
    __typename?: 'PlcFveMetricsType';
    created: any;
    solarIrradiance?: number | null;
    internalTemperature?: number | null;
    batteryVoltage?: number | null;
    batteryCurrent?: number | null;
    batteryPower?: number | null;
    gridAvailable?: boolean | null;
    mppt1Voltage?: number | null;
    mppt1Power?: number | null;
    mppt2Voltage?: number | null;
    mppt2Power?: number | null;
    pvChargerEnable?: boolean | null;
    acChargerEnable?: boolean | null;
    gridFeedEnable?: boolean | null;
    chargingVoltage?: number | null;
    maxChargingCurrent?: number | null;
    powerGridConsumption?: number | null;
    hwVersion?: string | null;
    swVersion?: string | null;
    do1State?: boolean | null;
    do2State?: boolean | null;
    batteryNotCharged?: boolean | null;
    batteryTemperature?: number | null;
    batterySoc?: number | null;
    batteryCapacity?: number | null;
    batterySocDisconnectActual?: number | null;
    batterySocDisconnectMinimum?: number | null;
    batterySocDisconnectNogrid?: number | null;
    externalPvPower?: number | null;
    inverterSerialNumber?: string | null;
    gridPower: number;
    gridL1Power?: number | null;
    gridL2Power?: number | null;
    gridL3Power?: number | null;
    loadPower: number;
    loadL1Power?: number | null;
    loadL2Power?: number | null;
    loadL3Power?: number | null;
    mppt1Installed?: number | null;
    mppt2Installed?: number | null;
    stopAlarm?: boolean | null;
    gridFeedPowerMax?: number | null;
  } | null;
};

export type CustomerPlcHeatPumpCurrentDataQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type CustomerPlcHeatPumpCurrentDataQuery = {
  __typename?: 'Query';
  customerDeviceDetail?: {
    __typename?: 'CustomerDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; mappingJson: any };
  } | null;
  plcHeatPumpCurrentData?: {
    __typename?: 'PlcHeatPumpMetricsType';
    created: any;
    mode?: HeatPumpModeChoices | null;
    dhwTemperature?: number | null;
    dhwTarget?: number | null;
    dhwHysteresis?: number | null;
    heatingSystemTemperature?: number | null;
    heatingSystemTarget?: number | null;
    heatingSystemHysteresis?: number | null;
    outdoorunitTargetPercentage?: number | null;
    outputTemperature?: number | null;
    hasBoiler?: boolean | null;
    hasInverter?: boolean | null;
    feedbackFromOutputTemperature?: boolean | null;
    heatingSeasonActive?: boolean | null;
    stopAlarm?: boolean | null;
    bivalentPowerConsumption?: number | null;
    outputPower?: number | null;
    inputPower?: number | null;
    watterFlow?: number | null;
    heatingFactor?: number | null;
    outdoorTemperature?: number | null;
    indoorTemperature?: number | null;
    hwVersion?: string | null;
    swVersion?: string | null;
    outdoorunitActive?: boolean | null;
    outdoorunitDefrosting?: boolean | null;
    targetIndoorTemperature?: number | null;
    ekvitermType?: HeatPumpEkvitermTypeChoices | null;
    ekvitermPower?: number | null;
    internalPumpActive?: boolean | null;
    evaporatorTemperature?: number | null;
    condenserTemperature?: number | null;
    compressorTemperature?: number | null;
  } | null;
};

export type CustomerTariffRestrictionInfoFragment = {
  __typename?: 'CustomerTariffRestrictionInfoType';
  actualNumberOfUsers: number;
  availableNumberOfUsers?: number | null;
  actualNumberOfDevices: number;
  availableNumberOfDevices?: number | null;
  usersOverLimit: boolean;
  devicesOverLimit: boolean;
  singlePhaseMetrics: boolean;
};

export type CustomerTariffRestrictionInfoQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerTariffRestrictionInfoQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    customer?: {
      __typename?: 'CustomerType';
      defaultPaymentMethod?: string | null;
      tariff?: {
        __typename?: 'CustomerTariffType';
        id: string;
        activeSubscription?: { __typename?: 'CustomerStripeSubscriptionType'; id: string } | null;
      } | null;
    } | null;
  } | null;
  customerTariffRestrictionInfo: {
    __typename?: 'CustomerTariffRestrictionInfoType';
    actualNumberOfUsers: number;
    availableNumberOfUsers?: number | null;
    actualNumberOfDevices: number;
    availableNumberOfDevices?: number | null;
    usersOverLimit: boolean;
    devicesOverLimit: boolean;
    singlePhaseMetrics: boolean;
  };
};

export type InstallationCompanyBaseTicketListFragment = {
  __typename?: 'InstallationCompanyBaseTicketType';
  id: string;
  created: any;
  category: TicketCategoryChoices;
  title: string;
  priority: TicketPriorityChoices;
  ticketType: TicketTypeChoices;
  isClosed: boolean;
  assignee?: TicketAssigneeChoices | null;
  lastComment?: {
    __typename?: 'CommentType';
    id: string;
    created: any;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  } | null;
};

export type InstallationCompanyBaseTicketListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Array<TicketPriorityChoices> | TicketPriorityChoices>;
  device?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  orderBy?: InputMaybe<TicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyBaseTicketListQuery = {
  __typename?: 'Query';
  installationCompanyBaseTicketList: {
    __typename?: 'PaginatedInstallationCompanyBaseTicketListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyBaseTicketType';
      id: string;
      created: any;
      category: TicketCategoryChoices;
      title: string;
      priority: TicketPriorityChoices;
      ticketType: TicketTypeChoices;
      isClosed: boolean;
      assignee?: TicketAssigneeChoices | null;
      lastComment?: {
        __typename?: 'CommentType';
        id: string;
        created: any;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      } | null;
    }>;
  };
};

export type InstallationCompanyCustomerSelectItemsQueryVariables = Exact<{
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  eventId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type InstallationCompanyCustomerSelectItemsQuery = {
  __typename?: 'Query';
  installationCompanyCustomerSelectItems: Array<{ __typename?: 'CustomerSelectItemType'; id: string; name: string }>;
};

export type InstallationCompanyCustomerTicketCommentDetailQueryVariables = Exact<{
  commentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCustomerTicketCommentDetailQuery = {
  __typename?: 'Query';
  installationCompanyCustomerTicketCommentDetail?: {
    __typename?: 'CustomerTicketCommentType';
    created: any;
    modified: any;
    id: string;
    comment: string;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    attachments: Array<{
      __typename?: 'CustomerTicketCommentAttachmentType';
      originalFileName: string;
      created: any;
      modified: any;
      id: string;
      fileSize: number;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
  } | null;
};

export type InstallationCompanyCustomerTicketCommentListQueryVariables = Exact<{
  customerTicket?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyCustomerTicketCommentListQuery = {
  __typename?: 'Query';
  installationCompanyCustomerTicketCommentList: {
    __typename?: 'PaginatedCustomerTicketCommentListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'CustomerTicketCommentType';
      created: any;
      modified: any;
      id: string;
      comment: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      attachments: Array<{
        __typename?: 'CustomerTicketCommentAttachmentType';
        originalFileName: string;
        created: any;
        modified: any;
        id: string;
        fileSize: number;
        file: { __typename?: 'FileType'; url: string; name: string };
      }>;
    }>;
  };
};

export type InstallationCompanyCustomerTicketDetailEditFragment = {
  __typename?: 'InstallationCompanyCustomerTicketType';
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: CustomerTicketAssigneeChoices;
  attachments: Array<{
    __typename?: 'CustomerTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type InstallationCompanyCustomerTicketDetailEditQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCustomerTicketDetailEditQuery = {
  __typename?: 'Query';
  installationCompanyCustomerTicketDetail?: {
    __typename?: 'InstallationCompanyCustomerTicketType';
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    assignee: CustomerTicketAssigneeChoices;
    attachments: Array<{
      __typename?: 'CustomerTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
  } | null;
};

export type InstallationCompanyCustomerTicketDetailForManufacturerFragment = {
  __typename?: 'InstallationCompanyCustomerTicketType';
  id: string;
  title: string;
  created: any;
};

export type InstallationCompanyCustomerTicketDetailForManufacturerQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCustomerTicketDetailForManufacturerQuery = {
  __typename?: 'Query';
  installationCompanyCustomerTicketDetail?: {
    __typename?: 'InstallationCompanyCustomerTicketType';
    id: string;
    title: string;
    created: any;
  } | null;
};

export type InstallationCompanyCustomerTicketDetailFragment = {
  __typename?: 'InstallationCompanyCustomerTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: CustomerTicketAssigneeChoices;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  attachments: Array<{
    __typename?: 'CustomerTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  deviceEvent?: {
    __typename?: 'DeviceEventType';
    id: string;
    eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
  } | null;
  installationCompanyTechnician?: {
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type InstallationCompanyCustomerTicketDetailQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyCustomerTicketDetailQuery = {
  __typename?: 'Query';
  installationCompanyCustomerTicketDetail?: {
    __typename?: 'InstallationCompanyCustomerTicketType';
    created: any;
    modified: any;
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    assignee: CustomerTicketAssigneeChoices;
    installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
    device?: {
      __typename?: 'InstallationCompanyDeviceType';
      id: string;
      serialNumber: string;
      deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
    } | null;
    attachments: Array<{
      __typename?: 'CustomerTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    deviceEvent?: {
      __typename?: 'DeviceEventType';
      id: string;
      eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
    } | null;
    installationCompanyTechnician?: {
      __typename?: 'UserSelectItemType';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
};

export type InstallationCompanyCustomerTicketListFragment = {
  __typename?: 'InstallationCompanyCustomerTicketType';
  created: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  id: string;
  isClosed: boolean;
  assignee: CustomerTicketAssigneeChoices;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  customer: { __typename?: 'CustomerType'; id: string; name: string };
  device?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    serialNumber: string;
    installationCompanyCustomerSite?: {
      __typename?: 'InstallationCompanyCustomerSiteType';
      id: string;
      name: string;
      street?: string | null;
      city?: string | null;
    } | null;
  } | null;
  lastComment?: {
    __typename?: 'CommentType';
    id: string;
    created: any;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  } | null;
  installationCompanyTechnician?: {
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type InstallationCompanyCustomerTicketListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Array<TicketPriorityChoices> | TicketPriorityChoices>;
  category?: InputMaybe<Array<TicketCategoryChoices> | TicketCategoryChoices>;
  device?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  assignee?: InputMaybe<Array<CustomerTicketAssigneeChoices> | CustomerTicketAssigneeChoices>;
  installationCompanyTechnician?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  >;
  orderBy?: InputMaybe<CustomerTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyCustomerTicketListQuery = {
  __typename?: 'Query';
  installationCompanyCustomerTicketList: {
    __typename?: 'PaginatedInstallationCompanyCustomerTicketListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyCustomerTicketType';
      created: any;
      category: TicketCategoryChoices;
      priority: TicketPriorityChoices;
      title: string;
      id: string;
      isClosed: boolean;
      assignee: CustomerTicketAssigneeChoices;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
      customer: { __typename?: 'CustomerType'; id: string; name: string };
      device?: {
        __typename?: 'InstallationCompanyDeviceType';
        id: string;
        serialNumber: string;
        installationCompanyCustomerSite?: {
          __typename?: 'InstallationCompanyCustomerSiteType';
          id: string;
          name: string;
          street?: string | null;
          city?: string | null;
        } | null;
      } | null;
      lastComment?: {
        __typename?: 'CommentType';
        id: string;
        created: any;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      } | null;
      installationCompanyTechnician?: {
        __typename?: 'UserSelectItemType';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    }>;
  };
};

export type InstallationCompanyPlcEventLastNEventsFragment = {
  __typename?: 'InstallationCompanyPlcEventType';
  deviceId: string;
  id: string;
  eventId: number;
  markedAsRead: boolean;
  created: any;
  eventType?: {
    __typename?: 'DeviceEventTypeType';
    id: string;
    description: string;
    priority: DeviceEventPriorityChoices;
  } | null;
  device: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; name: string };
  };
};

export type InstallationCompanyPlcEventLastNEventsQueryVariables = Exact<{ [key: string]: never }>;

export type InstallationCompanyPlcEventLastNEventsQuery = {
  __typename?: 'Query';
  installationCompanyPlcEventLastNEvents: Array<{
    __typename?: 'InstallationCompanyPlcEventType';
    deviceId: string;
    id: string;
    eventId: number;
    markedAsRead: boolean;
    created: any;
    eventType?: {
      __typename?: 'DeviceEventTypeType';
      id: string;
      description: string;
      priority: DeviceEventPriorityChoices;
    } | null;
    device: {
      __typename?: 'InstallationCompanyDeviceType';
      id: string;
      serialNumber: string;
      deviceType: { __typename?: 'DeviceTypeType'; name: string };
    };
  }>;
};

export type InstallationCompanyDashboardStatisticsOverviewFragment = {
  __typename?: 'InstallationCompanyDashboardStatisticsType';
  installationCompanyPlcEventTodayCount: number;
  installationCompanyPlcEventWeekHighestPriorityCount: number;
  installationCompanyCustomerTicketOpenCount: number;
  installationCompanyCustomerTicketClosedCount: number;
  installationCompanyManufacturerTicketOpenCount: number;
  installationCompanyManufacturerTicketClosedCount: number;
  installationCompanyInstallationCompanyTicketOpenCount: number;
  installationCompanyInstallationCompanyTicketClosedCount: number;
  installationCompanyDeviceFveCount: number;
  installationCompanyDeviceHeatPumpCount: number;
  installationCompanyCustomerFveCount: number;
  installationCompanyCustomerHeatPumpCount: number;
};

export type InstallationCompanyDashboardStatisticsOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type InstallationCompanyDashboardStatisticsOverviewQuery = {
  __typename?: 'Query';
  installationCompanyDashboardStatisticsOverview: {
    __typename?: 'InstallationCompanyDashboardStatisticsType';
    installationCompanyPlcEventTodayCount: number;
    installationCompanyPlcEventWeekHighestPriorityCount: number;
    installationCompanyCustomerTicketOpenCount: number;
    installationCompanyCustomerTicketClosedCount: number;
    installationCompanyManufacturerTicketOpenCount: number;
    installationCompanyManufacturerTicketClosedCount: number;
    installationCompanyInstallationCompanyTicketOpenCount: number;
    installationCompanyInstallationCompanyTicketClosedCount: number;
    installationCompanyDeviceFveCount: number;
    installationCompanyDeviceHeatPumpCount: number;
    installationCompanyCustomerFveCount: number;
    installationCompanyCustomerHeatPumpCount: number;
  };
};

export type InstallationCompanyBaseTicketLastNTicketsFragment = {
  __typename?: 'InstallationCompanyBaseTicketType';
  created: any;
  modified: any;
  ticketType: TicketTypeChoices;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  device?: { __typename?: 'InstallationCompanyDeviceType'; id: string; serialNumber: string } | null;
};

export type InstallationCompanyBaseTicketLastNTicketsQueryVariables = Exact<{ [key: string]: never }>;

export type InstallationCompanyBaseTicketLastNTicketsQuery = {
  __typename?: 'Query';
  installationCompanyBaseTicketLastNTickets: Array<{
    __typename?: 'InstallationCompanyBaseTicketType';
    created: any;
    modified: any;
    ticketType: TicketTypeChoices;
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    device?: { __typename?: 'InstallationCompanyDeviceType'; id: string; serialNumber: string } | null;
  }>;
};

export type InstallationCompanyDeviceCommandListFragment = {
  __typename?: 'DeviceCommandType';
  id: string;
  uid: string;
  command: string;
  created: any;
  responseReceived?: any | null;
  status: DeviceCommandStatusChoices;
  response?: string | null;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
};

export type InstallationCompanyDeviceCommandListQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyDeviceCommandListQuery = {
  __typename?: 'Query';
  installationCompanyDeviceCommandList: {
    __typename?: 'DeviceCommandPaginatedListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'DeviceCommandType';
      id: string;
      uid: string;
      command: string;
      created: any;
      responseReceived?: any | null;
      status: DeviceCommandStatusChoices;
      response?: string | null;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    }>;
  };
};

export type InstallationCompanyDeviceDetailGuardQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type InstallationCompanyDeviceDetailGuardQuery = {
  __typename?: 'Query';
  installationCompanyDeviceDetail?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; apiType: DeviceTypeApiTypeChoices };
  } | null;
};

export type InstallationCompanyDeviceDetailTicketsFragment = {
  __typename?: 'InstallationCompanyDeviceType';
  id: string;
  serialNumber: string;
  deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
};

export type InstallationCompanyDeviceDetailTicketsQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type InstallationCompanyDeviceDetailTicketsQuery = {
  __typename?: 'Query';
  installationCompanyDeviceDetail?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
};

export type InstallationCompanyDeviceDetailFragment = {
  __typename?: 'InstallationCompanyDeviceType';
  id: string;
  serialNumber: string;
  deviceType: {
    __typename?: 'DeviceTypeType';
    name: string;
    technology: DeviceTypeTechnologyChoices;
    apiType: DeviceTypeApiTypeChoices;
    id: string;
  };
  installationCompanyCustomerSite?: {
    __typename?: 'InstallationCompanyCustomerSiteType';
    id: string;
    name: string;
  } | null;
  installationCompanyCustomer?: { __typename?: 'InstallationCompanyCustomerType'; id: string } | null;
  installation: { __typename?: 'InstallationType'; id: string };
};

export type InstallationCompanyDeviceDetailQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type InstallationCompanyDeviceDetailQuery = {
  __typename?: 'Query';
  installationCompanyDeviceDetail?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    serialNumber: string;
    deviceType: {
      __typename?: 'DeviceTypeType';
      name: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
      id: string;
    };
    installationCompanyCustomerSite?: {
      __typename?: 'InstallationCompanyCustomerSiteType';
      id: string;
      name: string;
    } | null;
    installationCompanyCustomer?: { __typename?: 'InstallationCompanyCustomerType'; id: string } | null;
    installation: { __typename?: 'InstallationType'; id: string };
  } | null;
};

export type InstallationCompanyDeviceListFragment = {
  __typename?: 'InstallationCompanyDeviceType';
  id: string;
  serialNumber: string;
  lastMetricsTimestamp?: any | null;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  installation: { __typename?: 'InstallationType'; id: string; created: any };
  installationCompanyCustomerSite?: {
    __typename?: 'InstallationCompanyCustomerSiteType';
    id: string;
    name: string;
    street?: string | null;
    city?: string | null;
    zipCode?: string | null;
  } | null;
  technician?: { __typename?: 'UserType'; id: string; firstName: string; lastName: string } | null;
  installationCompanyCustomer?: { __typename?: 'InstallationCompanyCustomerType'; id: string; name: string } | null;
  customerSite?: {
    __typename?: 'CustomerSiteType';
    id: string;
    customer: {
      __typename?: 'CustomerType';
      id: string;
      name: string;
      tariff?: { __typename?: 'CustomerTariffType'; id: string; name: string } | null;
    };
  } | null;
};

export type InstallationCompanyDeviceListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  installationCompanyTechnician?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  orderBy?: InputMaybe<InstallationCompanyDeviceOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyDeviceListQuery = {
  __typename?: 'Query';
  installationCompanyDeviceList: {
    __typename?: 'InstallationCompanyPaginatedDeviceListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyDeviceType';
      id: string;
      serialNumber: string;
      lastMetricsTimestamp?: any | null;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
      installation: { __typename?: 'InstallationType'; id: string; created: any };
      installationCompanyCustomerSite?: {
        __typename?: 'InstallationCompanyCustomerSiteType';
        id: string;
        name: string;
        street?: string | null;
        city?: string | null;
        zipCode?: string | null;
      } | null;
      technician?: { __typename?: 'UserType'; id: string; firstName: string; lastName: string } | null;
      installationCompanyCustomer?: { __typename?: 'InstallationCompanyCustomerType'; id: string; name: string } | null;
      customerSite?: {
        __typename?: 'CustomerSiteType';
        id: string;
        customer: {
          __typename?: 'CustomerType';
          id: string;
          name: string;
          tariff?: { __typename?: 'CustomerTariffType'; id: string; name: string } | null;
        };
      } | null;
    }>;
  };
};

export type InstallationCompanyDeviceTypeSelectItemsQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type InstallationCompanyDeviceTypeSelectItemsQuery = {
  __typename?: 'Query';
  installationCompanyDeviceTypeSelectItems: Array<{
    __typename?: 'DeviceTypeSelectItemType';
    id: string;
    name: string;
  }>;
};

export type InstallationCompanyInstallationAttachmentListFragment = {
  __typename?: 'InstallationAttachmentType';
  originalFileName: string;
  fileSize: number;
  created: any;
  modified: any;
  id: string;
  title: string;
  file: { __typename?: 'FileType'; url: string; name: string };
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
};

export type InstallationCompanyInstallationAttachmentListQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyInstallationAttachmentListQuery = {
  __typename?: 'Query';
  installationCompanyInstallationAttachmentList: {
    __typename?: 'PaginatedInstallationAttachmentListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationAttachmentType';
      originalFileName: string;
      fileSize: number;
      created: any;
      modified: any;
      id: string;
      title: string;
      file: { __typename?: 'FileType'; url: string; name: string };
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    }>;
  };
};

export type InstallationCompanyInstallationCompanyCustomerDetailFragment = {
  __typename?: 'InstallationCompanyCustomerType';
  id: string;
  companyId?: string | null;
  email: string;
  name: string;
  phone: string;
  vatId?: string | null;
  note?: string | null;
  created: any;
  modified: any;
  sites: Array<{
    __typename?: 'InstallationCompanyCustomerSiteType';
    created: any;
    modified: any;
    street?: string | null;
    city?: string | null;
    zipCode?: string | null;
    id: string;
    name: string;
    note?: string | null;
    updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    country?: {
      __typename?: 'CountryType';
      name: string;
      code: string;
      alpha3: string;
      numeric: string;
      iocCode: string;
    } | null;
    gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
  }>;
};

export type InstallationCompanyInstallationCompanyCustomerDetailQueryVariables = Exact<{
  installationCompanyCustomerId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type InstallationCompanyInstallationCompanyCustomerDetailQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyCustomerDetail?: {
    __typename?: 'InstallationCompanyCustomerType';
    id: string;
    companyId?: string | null;
    email: string;
    name: string;
    phone: string;
    vatId?: string | null;
    note?: string | null;
    created: any;
    modified: any;
    sites: Array<{
      __typename?: 'InstallationCompanyCustomerSiteType';
      created: any;
      modified: any;
      street?: string | null;
      city?: string | null;
      zipCode?: string | null;
      id: string;
      name: string;
      note?: string | null;
      updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      country?: {
        __typename?: 'CountryType';
        name: string;
        code: string;
        alpha3: string;
        numeric: string;
        iocCode: string;
      } | null;
      gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
    }>;
  } | null;
};

export type InstallationCompanyInstallationCompanyCustomerListAutocompleteFragment = {
  __typename?: 'InstallationCompanyCustomerType';
  id: string;
  email: string;
  name: string;
  phone: string;
};

export type InstallationCompanyInstallationCompanyCustomerListAutocompleteQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type InstallationCompanyInstallationCompanyCustomerListAutocompleteQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyCustomerList: {
    __typename?: 'PaginatedInstallationCompanyCustomersListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyCustomerType';
      id: string;
      email: string;
      name: string;
      phone: string;
    }>;
  };
};

export type InstallationCompanyInstallationCompanyCustomerListFragment = {
  __typename?: 'InstallationCompanyCustomerType';
  id: string;
  email: string;
  name: string;
  phone: string;
  created: any;
  updatedAt?: any | null;
  siteCount: number;
  deviceCount: number;
};

export type InstallationCompanyInstallationCompanyCustomerListQueryVariables = Exact<{
  orderBy?: InputMaybe<InstallationCompanyCustomerOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type InstallationCompanyInstallationCompanyCustomerListQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyCustomerList: {
    __typename?: 'PaginatedInstallationCompanyCustomersListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyCustomerType';
      id: string;
      email: string;
      name: string;
      phone: string;
      created: any;
      updatedAt?: any | null;
      siteCount: number;
      deviceCount: number;
    }>;
  };
};

export type InstallationCompanyInstallationCompanyCustomerSelectItemsFragment = {
  __typename?: 'InstallationCompanyCustomerSelectItemType';
  id: string;
  name: string;
};

export type InstallationCompanyInstallationCompanyCustomerSelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type InstallationCompanyInstallationCompanyCustomerSelectItemsQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyCustomerSelectItems: Array<{
    __typename?: 'InstallationCompanyCustomerSelectItemType';
    id: string;
    name: string;
  }>;
};

export type InstallationCompanyInstallationCompanyCustomerSiteDetailFragment = {
  __typename?: 'InstallationCompanyCustomerSiteType';
  id: string;
  name: string;
  street?: string | null;
  city?: string | null;
  zipCode?: string | null;
  note?: string | null;
  gps?: { __typename?: 'GpsType'; lng: number; lat: number } | null;
};

export type InstallationCompanyInstallationCompanyCustomerSiteDetailQueryVariables = Exact<{
  customerSiteId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyCustomerSiteDetailQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyCustomerSiteDetail?: {
    __typename?: 'InstallationCompanyCustomerSiteType';
    id: string;
    name: string;
    street?: string | null;
    city?: string | null;
    zipCode?: string | null;
    note?: string | null;
    gps?: { __typename?: 'GpsType'; lng: number; lat: number } | null;
  } | null;
};

export type InstallationCompanyInstallationCompanyCustomerSiteListFragment = {
  __typename?: 'InstallationCompanyCustomerSiteType';
  created: any;
  modified: any;
  street?: string | null;
  city?: string | null;
  zipCode?: string | null;
  id: string;
  name: string;
  deviceCount: number;
  note?: string | null;
  gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
};

export type InstallationCompanyInstallationCompanyCustomerSiteListQueryVariables = Exact<{
  installationCompanyCustomerId?: InputMaybe<Scalars['ID']['input']>;
  orderBy?: InputMaybe<InstallationCompanyCustomerSiteOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyInstallationCompanyCustomerSiteListQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyCustomerSiteList: {
    __typename?: 'PaginatedInstallationCompanyCustomerSitesListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyCustomerSiteType';
      created: any;
      modified: any;
      street?: string | null;
      city?: string | null;
      zipCode?: string | null;
      id: string;
      name: string;
      deviceCount: number;
      note?: string | null;
      gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
    }>;
  };
};

export type InstallationCompanyInstallationCompanyCustomerSiteSelectItemsQueryVariables = Exact<{
  installationCompanyCustomerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type InstallationCompanyInstallationCompanyCustomerSiteSelectItemsQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyCustomerSiteSelectItems: Array<{
    __typename?: 'InstallationCompanyCustomerSiteType';
    created: any;
    modified: any;
    street?: string | null;
    city?: string | null;
    zipCode?: string | null;
    id: string;
    name: string;
    note?: string | null;
    updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    country?: {
      __typename?: 'CountryType';
      name: string;
      code: string;
      alpha3: string;
      numeric: string;
      iocCode: string;
    } | null;
    gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
  }>;
};

export type InstallationCompanyInstallationCompanyStripeSubscriptionListFragment = {
  __typename?: 'InstallationCompanyStripeSubscriptionType';
  id: string;
  subscriptionCreated: any;
  currentPeriodStart: any;
  currentPeriodEnd: any;
  canceledAt?: any | null;
  tariff: {
    __typename?: 'StripeTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
  };
};

export type InstallationCompanyInstallationCompanyStripeSubscriptionListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyInstallationCompanyStripeSubscriptionListQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyStripeSubscriptionList: {
    __typename?: 'PaginatedInstallationCompanyStripeSubscriptionListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyStripeSubscriptionType';
      id: string;
      subscriptionCreated: any;
      currentPeriodStart: any;
      currentPeriodEnd: any;
      canceledAt?: any | null;
      tariff: {
        __typename?: 'StripeTariffType';
        name: string;
        priceType: TariffPriceTypeChoices;
        price: number;
        currency: string;
        id: string;
      };
    }>;
  };
};

export type InstallationCompanyInstallationCompanyTariffSelectItemsFragment = {
  __typename?: 'InstallationCompanyTariffType';
  name: string;
  priceType: TariffPriceTypeChoices;
  price: number;
  currency: string;
  id: string;
  ticketsAllowed: boolean;
  numberOfUsers: number;
  numberOfDevices?: number | null;
  lineChartDataGranularity: TariffLineChartDataGranularityChoices;
  lineChartDataRetention: TariffLineChartDataRetentionChoices;
  barChartDataGranularity: TariffBarChartDataGranularityChoices;
  activeSubscription?: { __typename?: 'InstallationCompanyStripeSubscriptionType'; id: string } | null;
};

export type InstallationCompanyInstallationCompanyTariffSelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type InstallationCompanyInstallationCompanyTariffSelectItemsQuery = {
  __typename?: 'Query';
  monthly: Array<{
    __typename?: 'InstallationCompanyTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
    ticketsAllowed: boolean;
    numberOfUsers: number;
    numberOfDevices?: number | null;
    lineChartDataGranularity: TariffLineChartDataGranularityChoices;
    lineChartDataRetention: TariffLineChartDataRetentionChoices;
    barChartDataGranularity: TariffBarChartDataGranularityChoices;
    activeSubscription?: { __typename?: 'InstallationCompanyStripeSubscriptionType'; id: string } | null;
  }>;
  yearly: Array<{
    __typename?: 'InstallationCompanyTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
    ticketsAllowed: boolean;
    numberOfUsers: number;
    numberOfDevices?: number | null;
    lineChartDataGranularity: TariffLineChartDataGranularityChoices;
    lineChartDataRetention: TariffLineChartDataRetentionChoices;
    barChartDataGranularity: TariffBarChartDataGranularityChoices;
    activeSubscription?: { __typename?: 'InstallationCompanyStripeSubscriptionType'; id: string } | null;
  }>;
  default: Array<{
    __typename?: 'InstallationCompanyTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
    ticketsAllowed: boolean;
    numberOfUsers: number;
    numberOfDevices?: number | null;
    lineChartDataGranularity: TariffLineChartDataGranularityChoices;
    lineChartDataRetention: TariffLineChartDataRetentionChoices;
    barChartDataGranularity: TariffBarChartDataGranularityChoices;
    activeSubscription?: { __typename?: 'InstallationCompanyStripeSubscriptionType'; id: string } | null;
  }>;
};

export type InstallationCompanyInstallationCompanyTechnicianSelectItemsFragment = {
  __typename?: 'UserSelectItemType';
  id: string;
  firstName: string;
  lastName: string;
};

export type InstallationCompanyInstallationCompanyTechnicianSelectItemsQueryVariables = Exact<{
  hasInstallations?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<UserRoleChoices>;
}>;

export type InstallationCompanyInstallationCompanyTechnicianSelectItemsQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyTechnicianSelectItems: Array<{
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
  }>;
};

export type InstallationCompanyInstallationCompanyTicketCommentListQueryVariables = Exact<{
  installationCompanyTicket?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyInstallationCompanyTicketCommentListQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyTicketCommentList: {
    __typename?: 'PaginatedInstallationCompanyTicketCommentListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyTicketCommentType';
      created: any;
      modified: any;
      id: string;
      comment: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      attachments: Array<{
        __typename?: 'InstallationCompanyTicketCommentAttachmentType';
        originalFileName: string;
        created: any;
        modified: any;
        id: string;
        fileSize: number;
        file: { __typename?: 'FileType'; url: string; name: string };
      }>;
    }>;
  };
};

export type InstallationCompanyInstallationCompanyTicketDetailEditFragment = {
  __typename?: 'InstallationCompanyTicketType';
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  attachments: Array<{
    __typename?: 'InstallationCompanyTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type InstallationCompanyInstallationCompanyTicketDetailEditQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyTicketDetailEditQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyTicketDetail?: {
    __typename?: 'InstallationCompanyTicketType';
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    attachments: Array<{
      __typename?: 'InstallationCompanyTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
  } | null;
};

export type InstallationCompanyInstallationCompanyTicketDetailFragment = {
  __typename?: 'InstallationCompanyTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  attachments: Array<{
    __typename?: 'InstallationCompanyTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  deviceEvent?: {
    __typename?: 'DeviceEventType';
    id: string;
    eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
  } | null;
  installationCompanyTechnician?: {
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type InstallationCompanyInstallationCompanyTicketDetailQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationCompanyTicketDetailQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyTicketDetail?: {
    __typename?: 'InstallationCompanyTicketType';
    created: any;
    modified: any;
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
    device?: {
      __typename?: 'InstallationCompanyDeviceType';
      id: string;
      serialNumber: string;
      deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
    } | null;
    attachments: Array<{
      __typename?: 'InstallationCompanyTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    deviceEvent?: {
      __typename?: 'DeviceEventType';
      id: string;
      eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
    } | null;
    installationCompanyTechnician?: {
      __typename?: 'UserSelectItemType';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
};

export type InstallationCompanyInstallationCompanyTicketListFragment = {
  __typename?: 'InstallationCompanyTicketType';
  created: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  id: string;
  isClosed: boolean;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: { __typename?: 'InstallationCompanyDeviceType'; id: string; serialNumber: string } | null;
  lastComment?: {
    __typename?: 'CommentType';
    id: string;
    created: any;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  } | null;
  installationCompanyTechnician?: {
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type InstallationCompanyInstallationCompanyTicketListQueryVariables = Exact<{
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  deviceCreatedBy?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  category?: InputMaybe<Array<TicketCategoryChoices> | TicketCategoryChoices>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  installationCompanyTechnician?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  >;
  orderBy?: InputMaybe<InstallationCompanyTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyInstallationCompanyTicketListQuery = {
  __typename?: 'Query';
  installationCompanyInstallationCompanyTicketList: {
    __typename?: 'PaginatedInstallationCompanyTicketListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyTicketType';
      created: any;
      category: TicketCategoryChoices;
      priority: TicketPriorityChoices;
      title: string;
      id: string;
      isClosed: boolean;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
      device?: { __typename?: 'InstallationCompanyDeviceType'; id: string; serialNumber: string } | null;
      lastComment?: {
        __typename?: 'CommentType';
        id: string;
        created: any;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      } | null;
      installationCompanyTechnician?: {
        __typename?: 'UserSelectItemType';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    }>;
  };
};

export type InstallationCompanyInstallationNoteDetailQueryVariables = Exact<{
  noteId: Scalars['ID']['input'];
}>;

export type InstallationCompanyInstallationNoteDetailQuery = {
  __typename?: 'Query';
  installationCompanyInstallationNoteDetail?: {
    __typename?: 'InstallationNoteType';
    created: any;
    modified: any;
    id: string;
    note: string;
    attachments: Array<{
      __typename?: 'InstallationNoteAttachmentType';
      originalFileName: string;
      created: any;
      modified: any;
      id: string;
      fileSize: number;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
  } | null;
};

export type InstallationCompanyInstallationNoteListFragment = {
  __typename?: 'InstallationNoteType';
  created: any;
  modified: any;
  id: string;
  note: string;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  attachments: Array<{
    __typename?: 'InstallationNoteAttachmentType';
    originalFileName: string;
    created: any;
    modified: any;
    id: string;
    fileSize: number;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type InstallationCompanyInstallationNoteListQueryVariables = Exact<{
  installationId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyInstallationNoteListQuery = {
  __typename?: 'Query';
  installationCompanyInstallationNoteList: {
    __typename?: 'PaginatedInstallationNoteListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationNoteType';
      created: any;
      modified: any;
      id: string;
      note: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      attachments: Array<{
        __typename?: 'InstallationNoteAttachmentType';
        originalFileName: string;
        created: any;
        modified: any;
        id: string;
        fileSize: number;
        file: { __typename?: 'FileType'; url: string; name: string };
      }>;
    }>;
  };
};

export type InstallationCompanyManufacturerSelectItemsQueryVariables = Exact<{
  deviceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type InstallationCompanyManufacturerSelectItemsQuery = {
  __typename?: 'Query';
  installationCompanyManufacturerSelectItems: Array<{
    __typename?: 'ManufacturerSelectItemType';
    id: string;
    name: string;
  }>;
};

export type InstallationCompanyManufacturerTicketCommentDetailQueryVariables = Exact<{
  commentId: Scalars['ID']['input'];
}>;

export type InstallationCompanyManufacturerTicketCommentDetailQuery = {
  __typename?: 'Query';
  installationCompanyManufacturerTicketCommentDetail?: {
    __typename?: 'ManufacturerTicketCommentType';
    created: any;
    modified: any;
    id: string;
    comment: string;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    attachments: Array<{
      __typename?: 'ManufacturerTicketCommentAttachmentType';
      originalFileName: string;
      created: any;
      modified: any;
      id: string;
      fileSize: number;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
  } | null;
};

export type InstallationCompanyManufacturerTicketCommentListQueryVariables = Exact<{
  manufacturerTicket?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyManufacturerTicketCommentListQuery = {
  __typename?: 'Query';
  installationCompanyManufacturerTicketCommentList: {
    __typename?: 'PaginatedManufacturerTicketCommentListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'ManufacturerTicketCommentType';
      created: any;
      modified: any;
      id: string;
      comment: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      attachments: Array<{
        __typename?: 'ManufacturerTicketCommentAttachmentType';
        originalFileName: string;
        created: any;
        modified: any;
        id: string;
        fileSize: number;
        file: { __typename?: 'FileType'; url: string; name: string };
      }>;
    }>;
  };
};

export type InstallationCompanyManufacturerTicketDetailEditFragment = {
  __typename?: 'InstallationCompanyManufacturerTicketType';
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: ManufacturerTicketAssigneeChoices;
  attachments: Array<{
    __typename?: 'ManufacturerTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type InstallationCompanyManufacturerTicketDetailEditQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyManufacturerTicketDetailEditQuery = {
  __typename?: 'Query';
  installationCompanyManufacturerTicketDetail?: {
    __typename?: 'InstallationCompanyManufacturerTicketType';
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    assignee: ManufacturerTicketAssigneeChoices;
    attachments: Array<{
      __typename?: 'ManufacturerTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
  } | null;
};

export type InstallationCompanyManufacturerTicketDetailFragment = {
  __typename?: 'InstallationCompanyManufacturerTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: ManufacturerTicketAssigneeChoices;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  attachments: Array<{
    __typename?: 'ManufacturerTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  customerTicket?: { __typename?: 'InstallationCompanyCustomerTicketType'; id: string; title: string } | null;
  installationCompanyTechnician?: {
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type InstallationCompanyManufacturerTicketDetailQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type InstallationCompanyManufacturerTicketDetailQuery = {
  __typename?: 'Query';
  installationCompanyManufacturerTicketDetail?: {
    __typename?: 'InstallationCompanyManufacturerTicketType';
    created: any;
    modified: any;
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    assignee: ManufacturerTicketAssigneeChoices;
    installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
    device?: {
      __typename?: 'InstallationCompanyDeviceType';
      id: string;
      serialNumber: string;
      deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
    } | null;
    attachments: Array<{
      __typename?: 'ManufacturerTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    customerTicket?: { __typename?: 'InstallationCompanyCustomerTicketType'; id: string; title: string } | null;
    installationCompanyTechnician?: {
      __typename?: 'UserSelectItemType';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
};

export type InstallationCompanyManufacturerTicketListFragment = {
  __typename?: 'InstallationCompanyManufacturerTicketType';
  created: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  id: string;
  isClosed: boolean;
  assignee: ManufacturerTicketAssigneeChoices;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: { __typename?: 'InstallationCompanyDeviceType'; id: string; serialNumber: string } | null;
  manufacturer: { __typename?: 'ManufacturerType'; id: string; name: string };
  lastComment?: {
    __typename?: 'CommentType';
    id: string;
    created: any;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  } | null;
  installationCompanyTechnician?: {
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type InstallationCompanyManufacturerTicketListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Array<TicketPriorityChoices> | TicketPriorityChoices>;
  category?: InputMaybe<Array<TicketCategoryChoices> | TicketCategoryChoices>;
  device?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  assignee?: InputMaybe<Array<ManufacturerTicketAssigneeChoices> | ManufacturerTicketAssigneeChoices>;
  installationCompanyTechnician?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  >;
  orderBy?: InputMaybe<ManufacturerTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyManufacturerTicketListQuery = {
  __typename?: 'Query';
  installationCompanyManufacturerTicketList: {
    __typename?: 'PaginatedInstallationCompanyManufacturerTicketListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyManufacturerTicketType';
      created: any;
      category: TicketCategoryChoices;
      priority: TicketPriorityChoices;
      title: string;
      id: string;
      isClosed: boolean;
      assignee: ManufacturerTicketAssigneeChoices;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
      device?: { __typename?: 'InstallationCompanyDeviceType'; id: string; serialNumber: string } | null;
      manufacturer: { __typename?: 'ManufacturerType'; id: string; name: string };
      lastComment?: {
        __typename?: 'CommentType';
        id: string;
        created: any;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      } | null;
      installationCompanyTechnician?: {
        __typename?: 'UserSelectItemType';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    }>;
  };
};

export type InstallationCompanyPlcEventDetailTicketsFragment = {
  __typename?: 'InstallationCompanyPlcEventType';
  id: string;
  eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
};

export type InstallationCompanyPlcEventDetailTicketsQueryVariables = Exact<{
  plcEventId: Scalars['ID']['input'];
}>;

export type InstallationCompanyPlcEventDetailTicketsQuery = {
  __typename?: 'Query';
  installationCompanyPlcEventDetail?: {
    __typename?: 'InstallationCompanyPlcEventType';
    id: string;
    eventType?: { __typename?: 'DeviceEventTypeType'; id: string; description: string } | null;
  } | null;
};

export type InstallationCompanyPlcEventDetailQueryVariables = Exact<{
  plcEventId: Scalars['ID']['input'];
}>;

export type InstallationCompanyPlcEventDetailQuery = {
  __typename?: 'Query';
  installationCompanyPlcEventDetail?: {
    __typename?: 'InstallationCompanyPlcEventType';
    created: any;
    deviceId: string;
    id: string;
    eventId: number;
    markedAsRead: boolean;
    eventType?: {
      __typename?: 'DeviceEventTypeType';
      id: string;
      uid: number;
      priority: DeviceEventPriorityChoices;
      descriptionCs: string;
      descriptionEn: string;
      description: string;
      deviceType: {
        __typename?: 'DeviceTypeType';
        name: string;
        serialNumberFormat: string;
        technology: DeviceTypeTechnologyChoices;
        apiType: DeviceTypeApiTypeChoices;
        note: string;
        hwVersion?: string | null;
        id: string;
        created: any;
        modified: any;
        devicesCount: number;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        manufacturer: {
          __typename?: 'ManufacturerType';
          city: string;
          companyId: string;
          created: any;
          email: string;
          id: string;
          isActive: boolean;
          modified: any;
          name: string;
          phone?: string | null;
          street: string;
          vatId: string;
          zipCode: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          country: {
            __typename?: 'CountryType';
            name: string;
            code: string;
            alpha3: string;
            numeric: string;
            iocCode: string;
          };
        };
      };
    } | null;
    device: {
      __typename?: 'InstallationCompanyDeviceType';
      id: string;
      created: any;
      modified: any;
      manufacturerNote: string;
      registrationToken: string;
      state: DeviceStateChoices;
      serialNumber: string;
      plcPassword: string;
      plcUsername: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      deviceType: {
        __typename?: 'DeviceTypeType';
        name: string;
        serialNumberFormat: string;
        technology: DeviceTypeTechnologyChoices;
        apiType: DeviceTypeApiTypeChoices;
        note: string;
        hwVersion?: string | null;
        id: string;
        created: any;
        modified: any;
        devicesCount: number;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        manufacturer: {
          __typename?: 'ManufacturerType';
          city: string;
          companyId: string;
          created: any;
          email: string;
          id: string;
          isActive: boolean;
          modified: any;
          name: string;
          phone?: string | null;
          street: string;
          vatId: string;
          zipCode: string;
          createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
          country: {
            __typename?: 'CountryType';
            name: string;
            code: string;
            alpha3: string;
            numeric: string;
            iocCode: string;
          };
        };
      };
      installationCompanyCustomerSite?: {
        __typename?: 'InstallationCompanyCustomerSiteType';
        created: any;
        modified: any;
        street?: string | null;
        city?: string | null;
        zipCode?: string | null;
        id: string;
        name: string;
        note?: string | null;
        updatedBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
        country?: {
          __typename?: 'CountryType';
          name: string;
          code: string;
          alpha3: string;
          numeric: string;
          iocCode: string;
        } | null;
        gps?: { __typename?: 'GpsType'; lat: number; lng: number } | null;
      } | null;
      technician?: { __typename?: 'UserType'; id: string; firstName: string; lastName: string } | null;
    };
    customerTicket?: { __typename?: 'InstallationCompanyCustomerTicketType'; id: string } | null;
  } | null;
};

export type InstallationCompanyPlcEventListDetailFragment = {
  __typename?: 'InstallationCompanyPlcEventType';
  created: any;
  deviceId: string;
  id: string;
  eventId: number;
  markedAsRead: boolean;
  eventType?: {
    __typename?: 'DeviceEventTypeType';
    id: string;
    description: string;
    priority: DeviceEventPriorityChoices;
  } | null;
  device: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string; apiType: DeviceTypeApiTypeChoices };
    installationCompanyCustomerSite?: {
      __typename?: 'InstallationCompanyCustomerSiteType';
      id: string;
      name: string;
      street?: string | null;
      city?: string | null;
    } | null;
  };
  customerTicket?: { __typename?: 'InstallationCompanyCustomerTicketType'; id: string } | null;
};

export type InstallationCompanyPlcEventListDetailQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Array<DeviceEventPriorityChoices> | DeviceEventPriorityChoices>;
  device?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  installationCompanyCustomer?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  installationCompanyCustomerSite?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  hasTicket?: InputMaybe<Scalars['Boolean']['input']>;
  notRead?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<PlcEventOrderingOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyPlcEventListDetailQuery = {
  __typename?: 'Query';
  installationCompanyPlcEventList: {
    __typename?: 'InstallationCompanyPlcEventPaginatedDeviceListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyPlcEventType';
      created: any;
      deviceId: string;
      id: string;
      eventId: number;
      markedAsRead: boolean;
      eventType?: {
        __typename?: 'DeviceEventTypeType';
        id: string;
        description: string;
        priority: DeviceEventPriorityChoices;
      } | null;
      device: {
        __typename?: 'InstallationCompanyDeviceType';
        id: string;
        serialNumber: string;
        deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string; apiType: DeviceTypeApiTypeChoices };
        installationCompanyCustomerSite?: {
          __typename?: 'InstallationCompanyCustomerSiteType';
          id: string;
          name: string;
          street?: string | null;
          city?: string | null;
        } | null;
      };
      customerTicket?: { __typename?: 'InstallationCompanyCustomerTicketType'; id: string } | null;
    }>;
  };
};

export type InstallationCompanyPlcEventListFragment = {
  __typename?: 'InstallationCompanyPlcEventType';
  created: any;
  deviceId: string;
  id: string;
  eventId: number;
  markedAsRead: boolean;
  eventType?: {
    __typename?: 'DeviceEventTypeType';
    id: string;
    description: string;
    priority: DeviceEventPriorityChoices;
  } | null;
  device: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string; apiType: DeviceTypeApiTypeChoices };
    installationCompanyCustomerSite?: {
      __typename?: 'InstallationCompanyCustomerSiteType';
      id: string;
      name: string;
      street?: string | null;
      city?: string | null;
    } | null;
  };
  customerTicket?: { __typename?: 'InstallationCompanyCustomerTicketType'; id: string } | null;
};

export type InstallationCompanyPlcEventListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Array<DeviceEventPriorityChoices> | DeviceEventPriorityChoices>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  installationCompanyCustomer?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  installationCompanyCustomerSite?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  hasTicket?: InputMaybe<Scalars['Boolean']['input']>;
  notRead?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<PlcEventOrderingOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InstallationCompanyPlcEventListQuery = {
  __typename?: 'Query';
  installationCompanyPlcEventList: {
    __typename?: 'InstallationCompanyPlcEventPaginatedDeviceListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'InstallationCompanyPlcEventType';
      created: any;
      deviceId: string;
      id: string;
      eventId: number;
      markedAsRead: boolean;
      eventType?: {
        __typename?: 'DeviceEventTypeType';
        id: string;
        description: string;
        priority: DeviceEventPriorityChoices;
      } | null;
      device: {
        __typename?: 'InstallationCompanyDeviceType';
        id: string;
        serialNumber: string;
        deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string; apiType: DeviceTypeApiTypeChoices };
        installationCompanyCustomerSite?: {
          __typename?: 'InstallationCompanyCustomerSiteType';
          id: string;
          name: string;
          street?: string | null;
          city?: string | null;
        } | null;
      };
      customerTicket?: { __typename?: 'InstallationCompanyCustomerTicketType'; id: string } | null;
    }>;
  };
};

export type InstallationCompanyPlcFveCurrentDataQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type InstallationCompanyPlcFveCurrentDataQuery = {
  __typename?: 'Query';
  installationCompanyDeviceDetail?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; mappingJson: any };
  } | null;
  plcFveCurrentData?: {
    __typename?: 'PlcFveMetricsType';
    created: any;
    solarIrradiance?: number | null;
    internalTemperature?: number | null;
    batteryVoltage?: number | null;
    batteryCurrent?: number | null;
    batteryPower?: number | null;
    gridAvailable?: boolean | null;
    mppt1Voltage?: number | null;
    mppt1Power?: number | null;
    mppt2Voltage?: number | null;
    mppt2Power?: number | null;
    pvChargerEnable?: boolean | null;
    acChargerEnable?: boolean | null;
    gridFeedEnable?: boolean | null;
    chargingVoltage?: number | null;
    maxChargingCurrent?: number | null;
    powerGridConsumption?: number | null;
    hwVersion?: string | null;
    swVersion?: string | null;
    do1State?: boolean | null;
    do2State?: boolean | null;
    batteryNotCharged?: boolean | null;
    batteryTemperature?: number | null;
    batterySoc?: number | null;
    batteryCapacity?: number | null;
    batterySocDisconnectActual?: number | null;
    batterySocDisconnectMinimum?: number | null;
    batterySocDisconnectNogrid?: number | null;
    externalPvPower?: number | null;
    inverterSerialNumber?: string | null;
    gridPower: number;
    gridL1Power?: number | null;
    gridL2Power?: number | null;
    gridL3Power?: number | null;
    loadPower: number;
    loadL1Power?: number | null;
    loadL2Power?: number | null;
    loadL3Power?: number | null;
    mppt1Installed?: number | null;
    mppt2Installed?: number | null;
    stopAlarm?: boolean | null;
    gridFeedPowerMax?: number | null;
  } | null;
};

export type InstallationCompanyPlcHeatPumpCurrentDataQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type InstallationCompanyPlcHeatPumpCurrentDataQuery = {
  __typename?: 'Query';
  installationCompanyDeviceDetail?: {
    __typename?: 'InstallationCompanyDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; mappingJson: any };
  } | null;
  plcHeatPumpCurrentData?: {
    __typename?: 'PlcHeatPumpMetricsType';
    created: any;
    mode?: HeatPumpModeChoices | null;
    dhwTemperature?: number | null;
    dhwTarget?: number | null;
    dhwHysteresis?: number | null;
    heatingSystemTemperature?: number | null;
    heatingSystemTarget?: number | null;
    heatingSystemHysteresis?: number | null;
    outdoorunitTargetPercentage?: number | null;
    outputTemperature?: number | null;
    hasBoiler?: boolean | null;
    hasInverter?: boolean | null;
    feedbackFromOutputTemperature?: boolean | null;
    heatingSeasonActive?: boolean | null;
    stopAlarm?: boolean | null;
    bivalentPowerConsumption?: number | null;
    outputPower?: number | null;
    inputPower?: number | null;
    watterFlow?: number | null;
    heatingFactor?: number | null;
    outdoorTemperature?: number | null;
    indoorTemperature?: number | null;
    hwVersion?: string | null;
    swVersion?: string | null;
    outdoorunitActive?: boolean | null;
    outdoorunitDefrosting?: boolean | null;
    targetIndoorTemperature?: number | null;
    ekvitermType?: HeatPumpEkvitermTypeChoices | null;
    ekvitermPower?: number | null;
    internalPumpActive?: boolean | null;
    evaporatorTemperature?: number | null;
    condenserTemperature?: number | null;
    compressorTemperature?: number | null;
  } | null;
};

export type InstallationCompanyTariffRestrictionInfoFragment = {
  __typename?: 'InstallationCompanyTariffRestrictionInfoType';
  actualNumberOfUsers: number;
  availableNumberOfUsers?: number | null;
  actualNumberOfDevices: number;
  availableNumberOfDevices?: number | null;
  devicesOverLimit: boolean;
  usersOverLimit: boolean;
};

export type InstallationCompanyTariffRestrictionInfoQueryVariables = Exact<{ [key: string]: never }>;

export type InstallationCompanyTariffRestrictionInfoQuery = {
  __typename?: 'Query';
  installationCompanyTariffRestrictionInfo: {
    __typename?: 'InstallationCompanyTariffRestrictionInfoType';
    actualNumberOfUsers: number;
    availableNumberOfUsers?: number | null;
    actualNumberOfDevices: number;
    availableNumberOfDevices?: number | null;
    devicesOverLimit: boolean;
    usersOverLimit: boolean;
  };
};

export type ManufacturerDeviceCreatedBySelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type ManufacturerDeviceCreatedBySelectItemsQuery = {
  __typename?: 'Query';
  manufacturerDeviceCreatedBySelectItems: Array<{
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
  }>;
};

export type ManufacturerDeviceDetailTicketsFragment = {
  __typename?: 'ManufacturerDeviceType';
  id: string;
  serialNumber: string;
  deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
};

export type ManufacturerDeviceDetailTicketsQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ManufacturerDeviceDetailTicketsQuery = {
  __typename?: 'Query';
  manufacturerDeviceDetail?: {
    __typename?: 'ManufacturerDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
};

export type ManufacturerDeviceDetailFragment = {
  __typename?: 'ManufacturerDeviceType';
  id: string;
  plcPassword: string;
  plcUsername: string;
  manufacturerNote: string;
  registrationToken: string;
  state: DeviceStateChoices;
  serialNumber: string;
  mqttTopics: Array<string>;
  deviceType: { __typename?: 'DeviceTypeType'; id: string; apiType: DeviceTypeApiTypeChoices };
};

export type ManufacturerDeviceDetailQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ManufacturerDeviceDetailQuery = {
  __typename?: 'Query';
  manufacturerDeviceDetail?: {
    __typename?: 'ManufacturerDeviceType';
    id: string;
    plcPassword: string;
    plcUsername: string;
    manufacturerNote: string;
    registrationToken: string;
    state: DeviceStateChoices;
    serialNumber: string;
    mqttTopics: Array<string>;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; apiType: DeviceTypeApiTypeChoices };
  } | null;
};

export type ManufacturerDeviceEventTypeDetailFragment = {
  __typename?: 'DeviceEventTypeType';
  id: string;
  uid: number;
  priority: DeviceEventPriorityChoices;
  descriptionCs: string;
  descriptionEn: string;
  customerVisibility: boolean;
};

export type ManufacturerDeviceEventTypeDetailQueryVariables = Exact<{
  deviceEventTypeId: Scalars['ID']['input'];
}>;

export type ManufacturerDeviceEventTypeDetailQuery = {
  __typename?: 'Query';
  manufacturerDeviceEventTypeDetail?: {
    __typename?: 'DeviceEventTypeType';
    id: string;
    uid: number;
    priority: DeviceEventPriorityChoices;
    descriptionCs: string;
    descriptionEn: string;
    customerVisibility: boolean;
  } | null;
};

export type ManufacturerDeviceEventTypeListFragment = {
  __typename?: 'DeviceEventTypeType';
  id: string;
  uid: number;
  priority: DeviceEventPriorityChoices;
  descriptionCs: string;
  descriptionEn: string;
  customerVisibility: boolean;
};

export type ManufacturerDeviceEventTypeListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Array<DeviceEventPriorityChoices> | DeviceEventPriorityChoices>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ManufacturerDeviceEventTypeListQuery = {
  __typename?: 'Query';
  manufacturerDeviceEventTypeList: {
    __typename?: 'PaginatedDeviceEventTypeListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'DeviceEventTypeType';
      id: string;
      uid: number;
      priority: DeviceEventPriorityChoices;
      descriptionCs: string;
      descriptionEn: string;
      customerVisibility: boolean;
    }>;
  };
};

export type ManufacturerDeviceListFragment = {
  __typename?: 'ManufacturerDeviceType';
  id: string;
  created: any;
  manufacturerNote: string;
  state: DeviceStateChoices;
  serialNumber: string;
  manufacturerModifiedAt: any;
  lastMetricsTimestamp?: any | null;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  deviceType: { __typename?: 'DeviceTypeType'; name: string; id: string };
  installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
};

export type ManufacturerDeviceListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  deviceCreatedBy?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  state?: InputMaybe<Array<DeviceStateChoices> | DeviceStateChoices>;
  orderBy?: InputMaybe<DeviceOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ManufacturerDeviceListQuery = {
  __typename?: 'Query';
  manufacturerDeviceList: {
    __typename?: 'ManufacturerPaginatedDeviceListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'ManufacturerDeviceType';
      id: string;
      created: any;
      manufacturerNote: string;
      state: DeviceStateChoices;
      serialNumber: string;
      manufacturerModifiedAt: any;
      lastMetricsTimestamp?: any | null;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      deviceType: { __typename?: 'DeviceTypeType'; name: string; id: string };
      installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; ticketsAllowed: boolean } | null;
    }>;
  };
};

export type ManufacturerDeviceTypeCreatedBySelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type ManufacturerDeviceTypeCreatedBySelectItemsQuery = {
  __typename?: 'Query';
  manufacturerDeviceTypeCreatedBySelectItems: Array<{
    __typename?: 'UserSelectItemType';
    id: string;
    firstName: string;
    lastName: string;
  }>;
};

export type ManufacturerDeviceTypeDetailEventFragment = { __typename?: 'DeviceTypeType'; id: string; name: string };

export type ManufacturerDeviceTypeDetailEventQueryVariables = Exact<{
  deviceTypeId: Scalars['ID']['input'];
}>;

export type ManufacturerDeviceTypeDetailEventQuery = {
  __typename?: 'Query';
  manufacturerDeviceTypeDetail?: { __typename?: 'DeviceTypeType'; id: string; name: string } | null;
};

export type ManufacturerDeviceTypeDetailFragment = {
  __typename?: 'DeviceTypeType';
  id: string;
  name: string;
  mapping: string;
  serialNumberFormat: string;
  technology: DeviceTypeTechnologyChoices;
  apiType: DeviceTypeApiTypeChoices;
  note: string;
  hwVersion?: string | null;
  devicesCount: number;
};

export type ManufacturerDeviceTypeDetailQueryVariables = Exact<{
  deviceTypeId: Scalars['ID']['input'];
}>;

export type ManufacturerDeviceTypeDetailQuery = {
  __typename?: 'Query';
  manufacturerDeviceTypeDetail?: {
    __typename?: 'DeviceTypeType';
    id: string;
    name: string;
    mapping: string;
    serialNumberFormat: string;
    technology: DeviceTypeTechnologyChoices;
    apiType: DeviceTypeApiTypeChoices;
    note: string;
    hwVersion?: string | null;
    devicesCount: number;
  } | null;
};

export type ManufacturerDeviceTypeListFragment = {
  __typename?: 'DeviceTypeType';
  name: string;
  serialNumberFormat: string;
  technology: DeviceTypeTechnologyChoices;
  apiType: DeviceTypeApiTypeChoices;
  note: string;
  hwVersion?: string | null;
  id: string;
  created: any;
  modified: any;
  devicesCount: number;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
};

export type ManufacturerDeviceTypeListQueryVariables = Exact<{
  technology?: InputMaybe<Array<DeviceTypeTechnologyChoices> | DeviceTypeTechnologyChoices>;
  apiType?: InputMaybe<Array<DeviceTypeApiTypeChoices> | DeviceTypeApiTypeChoices>;
  createdBy?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  orderBy?: InputMaybe<DeviceTypeOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ManufacturerDeviceTypeListQuery = {
  __typename?: 'Query';
  manufacturerDeviceTypeList: {
    __typename?: 'PaginatedDeviceTypeListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'DeviceTypeType';
      name: string;
      serialNumberFormat: string;
      technology: DeviceTypeTechnologyChoices;
      apiType: DeviceTypeApiTypeChoices;
      note: string;
      hwVersion?: string | null;
      id: string;
      created: any;
      modified: any;
      devicesCount: number;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
    }>;
  };
};

export type ManufacturerDeviceTypeSelectItemsQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type ManufacturerDeviceTypeSelectItemsQuery = {
  __typename?: 'Query';
  manufacturerDeviceTypeSelectItems: Array<{ __typename?: 'DeviceTypeSelectItemType'; id: string; name: string }>;
};

export type ManufacturerInstallationCompanySelectItemsTicketsAllowedQueryVariables = Exact<{
  deviceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ManufacturerInstallationCompanySelectItemsTicketsAllowedQuery = {
  __typename?: 'Query';
  manufacturerInstallationCompanySelectItems: Array<{
    __typename?: 'InstallationCompanySelectItemType';
    id: string;
    name: string;
  }>;
};

export type ManufacturerManufacturerTariffSelectItemsFragment = {
  __typename?: 'ManufacturerTariffType';
  name: string;
  priceType: TariffPriceTypeChoices;
  price: number;
  currency: string;
  id: string;
  numberOfDevices?: number | null;
  numberOfDeviceTypes?: number | null;
  activeSubscription?: { __typename?: 'ManufacturerSubscriptionType'; id: string } | null;
};

export type ManufacturerManufacturerTariffSelectItemsQueryVariables = Exact<{ [key: string]: never }>;

export type ManufacturerManufacturerTariffSelectItemsQuery = {
  __typename?: 'Query';
  manufacturerManufacturerTariffSelectItems: Array<{
    __typename?: 'ManufacturerTariffType';
    name: string;
    priceType: TariffPriceTypeChoices;
    price: number;
    currency: string;
    id: string;
    numberOfDevices?: number | null;
    numberOfDeviceTypes?: number | null;
    activeSubscription?: { __typename?: 'ManufacturerSubscriptionType'; id: string } | null;
  }>;
};

export type ManufacturerManufacturerTicketCommentListQueryVariables = Exact<{
  manufacturerTicket?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ManufacturerManufacturerTicketCommentListQuery = {
  __typename?: 'Query';
  manufacturerManufacturerTicketCommentList: {
    __typename?: 'PaginatedManufacturerTicketCommentListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'ManufacturerTicketCommentType';
      created: any;
      modified: any;
      id: string;
      comment: string;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      attachments: Array<{
        __typename?: 'ManufacturerTicketCommentAttachmentType';
        originalFileName: string;
        created: any;
        modified: any;
        id: string;
        fileSize: number;
        file: { __typename?: 'FileType'; url: string; name: string };
      }>;
    }>;
  };
};

export type ManufacturerManufacturerTicketDetailEditFragment = {
  __typename?: 'ManufacturerManufacturerTicketType';
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: ManufacturerTicketAssigneeChoices;
  attachments: Array<{
    __typename?: 'ManufacturerTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
};

export type ManufacturerManufacturerTicketDetailEditQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type ManufacturerManufacturerTicketDetailEditQuery = {
  __typename?: 'Query';
  manufacturerManufacturerTicketDetail?: {
    __typename?: 'ManufacturerManufacturerTicketType';
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    assignee: ManufacturerTicketAssigneeChoices;
    attachments: Array<{
      __typename?: 'ManufacturerTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
  } | null;
};

export type ManufacturerManufacturerTicketDetailFragment = {
  __typename?: 'ManufacturerManufacturerTicketType';
  created: any;
  modified: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  content: string;
  id: string;
  isClosed: boolean;
  assignee: ManufacturerTicketAssigneeChoices;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'ManufacturerDeviceType';
    id: string;
    serialNumber: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  attachments: Array<{
    __typename?: 'ManufacturerTicketAttachmentType';
    id: string;
    fileSize: number;
    originalFileName: string;
    file: { __typename?: 'FileType'; url: string; name: string };
  }>;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
};

export type ManufacturerManufacturerTicketDetailQueryVariables = Exact<{
  ticketId: Scalars['ID']['input'];
}>;

export type ManufacturerManufacturerTicketDetailQuery = {
  __typename?: 'Query';
  manufacturerManufacturerTicketDetail?: {
    __typename?: 'ManufacturerManufacturerTicketType';
    created: any;
    modified: any;
    category: TicketCategoryChoices;
    priority: TicketPriorityChoices;
    title: string;
    content: string;
    id: string;
    isClosed: boolean;
    assignee: ManufacturerTicketAssigneeChoices;
    installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
    device?: {
      __typename?: 'ManufacturerDeviceType';
      id: string;
      serialNumber: string;
      deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
    } | null;
    attachments: Array<{
      __typename?: 'ManufacturerTicketAttachmentType';
      id: string;
      fileSize: number;
      originalFileName: string;
      file: { __typename?: 'FileType'; url: string; name: string };
    }>;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  } | null;
};

export type ManufacturerManufacturerTicketListFragment = {
  __typename?: 'ManufacturerManufacturerTicketType';
  created: any;
  category: TicketCategoryChoices;
  priority: TicketPriorityChoices;
  title: string;
  id: string;
  isClosed: boolean;
  assignee: ManufacturerTicketAssigneeChoices;
  createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
  device?: {
    __typename?: 'ManufacturerDeviceType';
    id: string;
    deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
  } | null;
  lastComment?: {
    __typename?: 'CommentType';
    id: string;
    created: any;
    createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
  } | null;
};

export type ManufacturerManufacturerTicketListQueryVariables = Exact<{
  device?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  deviceType?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  deviceCreatedBy?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<ManufacturerTicketOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ManufacturerManufacturerTicketListQuery = {
  __typename?: 'Query';
  manufacturerManufacturerTicketList: {
    __typename?: 'PaginatedManufacturerManufacturerTicketListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'ManufacturerManufacturerTicketType';
      created: any;
      category: TicketCategoryChoices;
      priority: TicketPriorityChoices;
      title: string;
      id: string;
      isClosed: boolean;
      assignee: ManufacturerTicketAssigneeChoices;
      createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      installationCompany: { __typename?: 'InstallationCompanyType'; id: string; name: string };
      device?: {
        __typename?: 'ManufacturerDeviceType';
        id: string;
        deviceType: { __typename?: 'DeviceTypeType'; id: string; name: string };
      } | null;
      lastComment?: {
        __typename?: 'CommentType';
        id: string;
        created: any;
        createdBy?: { __typename?: 'UserSelectItemType'; id: string; firstName: string; lastName: string } | null;
      } | null;
    }>;
  };
};

export type ManufacturerTariffRestrictionInfoFragment = {
  __typename?: 'ManufacturerTariffRestrictionInfoType';
  actualNumberOfDevices: number;
  availableNumberOfDevices?: number | null;
  actualNumberOfDeviceTypes: number;
  availableNumberOfDeviceTypes?: number | null;
  devicesOverLimit: boolean;
  deviceTypesOverLimit: boolean;
};

export type ManufacturerTariffRestrictionInfoQueryVariables = Exact<{ [key: string]: never }>;

export type ManufacturerTariffRestrictionInfoQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    manufacturer?: {
      __typename?: 'ManufacturerType';
      id: string;
      subscription?: {
        __typename?: 'ManufacturerSubscriptionType';
        id: string;
        currentPeriodEnd: any;
        isExpired: boolean;
      } | null;
    } | null;
  } | null;
  manufacturerTariffRestrictionInfo: {
    __typename?: 'ManufacturerTariffRestrictionInfoType';
    actualNumberOfDevices: number;
    availableNumberOfDevices?: number | null;
    actualNumberOfDeviceTypes: number;
    availableNumberOfDeviceTypes?: number | null;
    devicesOverLimit: boolean;
    deviceTypesOverLimit: boolean;
  };
};

export type CountryListQueryVariables = Exact<{ [key: string]: never }>;

export type CountryListQuery = {
  __typename?: 'Query';
  countryList: Array<{
    __typename?: 'CountryType';
    name: string;
    code: string;
    alpha3: string;
    numeric: string;
    iocCode: string;
  }>;
};

export type DeviceMappingSchemaQueryVariables = Exact<{ [key: string]: never }>;

export type DeviceMappingSchemaQuery = { __typename?: 'Query'; deviceMappingSchema: any };

export type InstallationCompanyCustomerInvitationDetailFragment = {
  __typename?: 'InstallationCompanyCustomerInvitationType';
  id: string;
  name: string;
  email: string;
  city?: string | null;
  phone?: string | null;
  street?: string | null;
  zipCode?: string | null;
  vatId?: string | null;
  companyId?: string | null;
};

export type InstallationCompanyCustomerInvitationDetailQueryVariables = Exact<{
  installationCompanyCustomerId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
}>;

export type InstallationCompanyCustomerInvitationDetailQuery = {
  __typename?: 'Query';
  installationCompanyCustomerInvitationDetail:
    | {
        __typename?: 'InstallationCompanyCustomerInvitationType';
        id: string;
        name: string;
        email: string;
        city?: string | null;
        phone?: string | null;
        street?: string | null;
        zipCode?: string | null;
        vatId?: string | null;
        companyId?: string | null;
      }
    | { __typename?: 'ObjectDoesNotExistsResponse'; id: string }
    | { __typename?: 'TokenExpiredResponse'; ok: boolean };
};

export type InvitedUserOrganizationDetailQueryVariables = Exact<{
  userActivationInput: UserActivationInput;
}>;

export type InvitedUserOrganizationDetailQuery = {
  __typename?: 'Query';
  invitedUserOrganizationDetail?:
    | { __typename?: 'CustomerType'; id: string; name: string }
    | { __typename?: 'InstallationCompanyType'; id: string; name: string }
    | { __typename?: 'ManufacturerType'; id: string; name: string }
    | null;
};

export type MeAdminFragment = {
  __typename?: 'UserType';
  id: string;
  firstName: string;
  lastName: string;
  role: UserRoleChoices;
  phone?: string | null;
};

export type MeAdminQueryVariables = Exact<{ [key: string]: never }>;

export type MeAdminQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    firstName: string;
    lastName: string;
    role: UserRoleChoices;
    phone?: string | null;
  } | null;
};

export type MeCustomerExportFragment = {
  __typename?: 'UserType';
  id: string;
  customer?: {
    __typename?: 'CustomerType';
    id: string;
    subscription?: {
      __typename?: 'CustomerStripeSubscriptionType';
      id: string;
      customerTariff: {
        __typename?: 'CustomerTariffType';
        id: string;
        dataExportExcel: boolean;
        dataExportImage: boolean;
      };
    } | null;
  } | null;
};

export type MeCustomerExportQueryVariables = Exact<{ [key: string]: never }>;

export type MeCustomerExportQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    customer?: {
      __typename?: 'CustomerType';
      id: string;
      subscription?: {
        __typename?: 'CustomerStripeSubscriptionType';
        id: string;
        customerTariff: {
          __typename?: 'CustomerTariffType';
          id: string;
          dataExportExcel: boolean;
          dataExportImage: boolean;
        };
      } | null;
    } | null;
  } | null;
};

export type MeCustomerMqttFragment = {
  __typename?: 'UserType';
  id: string;
  customer?: {
    __typename?: 'CustomerType';
    id: string;
    subscription?: {
      __typename?: 'CustomerStripeSubscriptionType';
      id: string;
      customerTariff: { __typename?: 'CustomerTariffType'; id: string; commands: boolean };
    } | null;
  } | null;
};

export type MeCustomerMqttQueryVariables = Exact<{ [key: string]: never }>;

export type MeCustomerMqttQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    customer?: {
      __typename?: 'CustomerType';
      id: string;
      subscription?: {
        __typename?: 'CustomerStripeSubscriptionType';
        id: string;
        customerTariff: { __typename?: 'CustomerTariffType'; id: string; commands: boolean };
      } | null;
    } | null;
  } | null;
};

export type MeCustomerSubscriptionFragment = {
  __typename?: 'UserType';
  id: string;
  customer?: {
    __typename?: 'CustomerType';
    id: string;
    tariff?: {
      __typename?: 'CustomerTariffType';
      id: string;
      name: string;
      activeSubscription?: {
        __typename?: 'CustomerStripeSubscriptionType';
        id: string;
        canceledAt?: any | null;
        currentPeriodEnd: any;
        status: StripeSubscriptionStatusChoices;
      } | null;
    } | null;
  } | null;
};

export type MeCustomerSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type MeCustomerSubscriptionQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    customer?: {
      __typename?: 'CustomerType';
      id: string;
      tariff?: {
        __typename?: 'CustomerTariffType';
        id: string;
        name: string;
        activeSubscription?: {
          __typename?: 'CustomerStripeSubscriptionType';
          id: string;
          canceledAt?: any | null;
          currentPeriodEnd: any;
          status: StripeSubscriptionStatusChoices;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type MeCustomerFragment = {
  __typename?: 'UserType';
  id: string;
  customer?: {
    __typename?: 'CustomerType';
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    companyId?: string | null;
    vatId?: string | null;
    street?: string | null;
    city?: string | null;
    zipCode?: string | null;
  } | null;
};

export type MeCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type MeCustomerQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    customer?: {
      __typename?: 'CustomerType';
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      companyId?: string | null;
      vatId?: string | null;
      street?: string | null;
      city?: string | null;
      zipCode?: string | null;
    } | null;
  } | null;
};

export type MeInstallationCompanySubscriptionFragment = {
  __typename?: 'UserType';
  id: string;
  installationCompany?: {
    __typename?: 'InstallationCompanyType';
    id: string;
    tariff?: {
      __typename?: 'InstallationCompanyTariffType';
      id: string;
      name: string;
      activeSubscription?: {
        __typename?: 'InstallationCompanyStripeSubscriptionType';
        id: string;
        canceledAt?: any | null;
        currentPeriodEnd: any;
      } | null;
    } | null;
  } | null;
};

export type MeInstallationCompanySubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type MeInstallationCompanySubscriptionQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    installationCompany?: {
      __typename?: 'InstallationCompanyType';
      id: string;
      tariff?: {
        __typename?: 'InstallationCompanyTariffType';
        id: string;
        name: string;
        activeSubscription?: {
          __typename?: 'InstallationCompanyStripeSubscriptionType';
          id: string;
          canceledAt?: any | null;
          currentPeriodEnd: any;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type MeInstallationCompanyTicketsAllowedFragment = {
  __typename?: 'UserType';
  id: string;
  installationCompany?: {
    __typename?: 'InstallationCompanyType';
    id: string;
    name: string;
    ticketsAllowed: boolean;
    subscription?: {
      __typename?: 'InstallationCompanyStripeSubscriptionType';
      id: string;
      installationCompanyTariff: { __typename?: 'InstallationCompanyTariffType'; id: string; ticketsAllowed: boolean };
    } | null;
  } | null;
};

export type MeInstallationCompanyTicketsAllowedQueryVariables = Exact<{ [key: string]: never }>;

export type MeInstallationCompanyTicketsAllowedQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    installationCompany?: {
      __typename?: 'InstallationCompanyType';
      id: string;
      name: string;
      ticketsAllowed: boolean;
      subscription?: {
        __typename?: 'InstallationCompanyStripeSubscriptionType';
        id: string;
        installationCompanyTariff: {
          __typename?: 'InstallationCompanyTariffType';
          id: string;
          ticketsAllowed: boolean;
        };
      } | null;
    } | null;
  } | null;
};

export type MeInstallationCompanyFragment = {
  __typename?: 'UserType';
  id: string;
  installationCompany?: {
    __typename?: 'InstallationCompanyType';
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    companyId: string;
    vatId: string;
    street: string;
    city: string;
    zipCode: string;
  } | null;
};

export type MeInstallationCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type MeInstallationCompanyQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    installationCompany?: {
      __typename?: 'InstallationCompanyType';
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      companyId: string;
      vatId: string;
      street: string;
      city: string;
      zipCode: string;
    } | null;
  } | null;
};

export type MeManufacturerFragment = {
  __typename?: 'UserType';
  id: string;
  manufacturer?: {
    __typename?: 'ManufacturerType';
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    companyId: string;
    vatId: string;
    street: string;
    city: string;
    zipCode: string;
    subscription?: {
      __typename?: 'ManufacturerSubscriptionType';
      currentPeriodEnd: any;
      currentPeriodStart: any;
    } | null;
  } | null;
};

export type MeManufacturerQueryVariables = Exact<{ [key: string]: never }>;

export type MeManufacturerQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    manufacturer?: {
      __typename?: 'ManufacturerType';
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      companyId: string;
      vatId: string;
      street: string;
      city: string;
      zipCode: string;
      subscription?: {
        __typename?: 'ManufacturerSubscriptionType';
        currentPeriodEnd: any;
        currentPeriodStart: any;
      } | null;
    } | null;
  } | null;
};

export type MeUserFragment = {
  __typename?: 'UserType';
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  languageCode: UserLanguagesChoices;
  role: UserRoleChoices;
  userType: UserTypeChoices;
  state: UserStateChoices;
  phone?: string | null;
  installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; name: string } | null;
  customer?: { __typename?: 'CustomerType'; id: string } | null;
  manufacturer?: { __typename?: 'ManufacturerType'; id: string; name: string } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
    languageCode: UserLanguagesChoices;
    role: UserRoleChoices;
    userType: UserTypeChoices;
    state: UserStateChoices;
    phone?: string | null;
    installationCompany?: { __typename?: 'InstallationCompanyType'; id: string; name: string } | null;
    customer?: { __typename?: 'CustomerType'; id: string } | null;
    manufacturer?: { __typename?: 'ManufacturerType'; id: string; name: string } | null;
  } | null;
};

export type ChartFveBatteryCapacityQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveBatteryCapacityQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveBatteryCurrentQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveBatteryCurrentQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveBatteryPowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveBatteryPowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveBatterySocDisconnectActualQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveBatterySocDisconnectActualQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveBatterySocDisconnectMinimumQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveBatterySocDisconnectMinimumQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveBatterySocDisconnectNogridQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveBatterySocDisconnectNogridQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveBatterySocQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveBatterySocQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveBatteryTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveBatteryTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveBatteryVoltageQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveBatteryVoltageQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveChargingVoltageQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveChargingVoltageQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveExternalPvPowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveExternalPvPowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveGridFeedPowerMaxQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveGridFeedPowerMaxQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveGridL1PowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveGridL1PowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveGridL2PowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveGridL2PowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveGridL3PowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveGridL3PowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveGridPowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveGridPowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveInternalTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveInternalTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveLoadL1PowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveLoadL1PowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveLoadL2PowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveLoadL2PowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveLoadL3PowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveLoadL3PowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveLoadPowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveLoadPowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveMaxChargingCurrentQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveMaxChargingCurrentQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveMpptPowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveMpptPowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveMppt1PowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveMppt1PowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveMppt1VoltageQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveMppt1VoltageQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveMppt2PowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveMppt2PowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveMppt2VoltageQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveMppt2VoltageQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFvePowerGridConsumptionQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFvePowerGridConsumptionQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartFveSolarIrradianceQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartFveSolarIrradianceQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcFveMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpBivalentPowerConsumptionQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpBivalentPowerConsumptionQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpCompressorTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpCompressorTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpCondenserTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpCondenserTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpDhwHysteresisQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpDhwHysteresisQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpDhwTargetQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpDhwTargetQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpDhwTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpDhwTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpEkvitermPowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpEkvitermPowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpEvaporatorTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpEvaporatorTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpHeatingFactorQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpHeatingFactorQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpHeatingSystemHysteresisQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpHeatingSystemHysteresisQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpHeatingSystemTargetQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpHeatingSystemTargetQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpHeatingSystemTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpHeatingSystemTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpIndoorTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpIndoorTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpInputPowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpInputPowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpOutdoorTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpOutdoorTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpOutdoorunitTargetPercentageQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpOutdoorunitTargetPercentageQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpOutputPowerQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpOutputPowerQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpOutputTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpOutputTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpTargetIndoorTemperatureQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpTargetIndoorTemperatureQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type ChartHeatPumpWatterFlowQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type ChartHeatPumpWatterFlowQuery = {
  __typename?: 'Query';
  chartData: Array<{ __typename?: 'PlcHeatPumpMetricsMiniChartType'; bucket: any; value?: number | null }>;
};

export type OrganizationUserDetailFragment = {
  __typename?: 'UserType';
  id: string;
  firstName: string;
  lastName: string;
  role: UserRoleChoices;
  phone?: string | null;
  ticketNotifications: boolean;
};

export type OrganizationUserDetailQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type OrganizationUserDetailQuery = {
  __typename?: 'Query';
  organizationUserDetail?: {
    __typename?: 'UserType';
    id: string;
    firstName: string;
    lastName: string;
    role: UserRoleChoices;
    phone?: string | null;
    ticketNotifications: boolean;
  } | null;
};

export type OrganizationUserListFragment = {
  __typename?: 'UserType';
  email: string;
  firstName: string;
  id: string;
  isActive: boolean;
  lastName: string;
  role: UserRoleChoices;
  lastLogin?: any | null;
  created: any;
  state: UserStateChoices;
  modified: any;
};

export type OrganizationUserListQueryVariables = Exact<{
  orderBy?: InputMaybe<UserOrderingChoices>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type OrganizationUserListQuery = {
  __typename?: 'Query';
  organizationUserList: {
    __typename?: 'PaginatedUserListType';
    meta: {
      __typename?: 'PaginationMetaType';
      totalCount: number;
      pageSize: number;
      pagesCount: number;
      currentPage: number;
    };
    items: Array<{
      __typename?: 'UserType';
      email: string;
      firstName: string;
      id: string;
      isActive: boolean;
      lastName: string;
      role: UserRoleChoices;
      lastLogin?: any | null;
      created: any;
      state: UserStateChoices;
      modified: any;
    }>;
  };
};

export type PlcfveBarChartDataFragment = {
  __typename?: 'PLCFVEBarChartData';
  mpptPower?: number | null;
  loadPower?: number | null;
  gridSupply?: number | null;
  gridConsumption?: number | null;
  bucket: any;
};

export type PlcFveBarChartDataQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  period: PlcMetricsBarChartPeriodChoices;
  untilDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type PlcFveBarChartDataQuery = {
  __typename?: 'Query';
  plcFveBarChartData:
    | {
        __typename?: 'PLCFVEBarChartDataList';
        data: Array<{
          __typename?: 'PLCFVEBarChartData';
          mpptPower?: number | null;
          loadPower?: number | null;
          gridSupply?: number | null;
          gridConsumption?: number | null;
          bucket: any;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type PlcFveDashboardMetricsCountsFragment = {
  __typename?: 'PlcFveMetricsType';
  created: any;
  gridL1Power?: number | null;
  gridL2Power?: number | null;
  gridL3Power?: number | null;
  loadL1Power?: number | null;
  loadL2Power?: number | null;
  loadL3Power?: number | null;
  mppt1Power?: number | null;
  mppt2Power?: number | null;
  batterySoc?: number | null;
};

export type PlcFveDashboardMetricsChartFragment = {
  __typename?: 'PlcFveMetricsMiniChartType';
  bucket: any;
  mpptPower?: number | null;
  gridPower?: number | null;
  loadPower?: number | null;
  batterySoc?: number | null;
};

export type PlcFveDashboardMetricsQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type PlcFveDashboardMetricsQuery = {
  __typename?: 'Query';
  plcFveMiniChartData: Array<{
    __typename?: 'PlcFveMetricsMiniChartType';
    bucket: any;
    mpptPower?: number | null;
    gridPower?: number | null;
    loadPower?: number | null;
    batterySoc?: number | null;
  }>;
  plcFveCurrentData?: {
    __typename?: 'PlcFveMetricsType';
    created: any;
    gridL1Power?: number | null;
    gridL2Power?: number | null;
    gridL3Power?: number | null;
    loadL1Power?: number | null;
    loadL2Power?: number | null;
    loadL3Power?: number | null;
    mppt1Power?: number | null;
    mppt2Power?: number | null;
    batterySoc?: number | null;
  } | null;
};

export type PlcfveLineChartDataFragment = {
  __typename?: 'PLCFVELineChartData';
  mpptPower?: number | null;
  loadL1Power?: number | null;
  loadL2Power?: number | null;
  loadL3Power?: number | null;
  loadPower?: number | null;
  gridL1Power?: number | null;
  gridL2Power?: number | null;
  gridL3Power?: number | null;
  gridPower?: number | null;
  batterySoc?: number | null;
  batteryPower?: number | null;
  bucket: any;
};

export type PlcFveLineChartDataQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  period: PlcMetricsLineChartPeriodGraphQlChoices;
  untilDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type PlcFveLineChartDataQuery = {
  __typename?: 'Query';
  plcFveLineChartData:
    | {
        __typename?: 'PLCFVELineChartDataList';
        data: Array<{
          __typename?: 'PLCFVELineChartData';
          mpptPower?: number | null;
          loadL1Power?: number | null;
          loadL2Power?: number | null;
          loadL3Power?: number | null;
          loadPower?: number | null;
          gridL1Power?: number | null;
          gridL2Power?: number | null;
          gridL3Power?: number | null;
          gridPower?: number | null;
          batterySoc?: number | null;
          batteryPower?: number | null;
          bucket: any;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type PlcHeatPumpBarChartDataFragment = {
  __typename?: 'PLCHeatPumpBarChartData';
  outputPower?: number | null;
  inputPower?: number | null;
  indoorTemperature?: number | null;
  dhwTemperature?: number | null;
  outdoorTemperature?: number | null;
  bucket: any;
};

export type PlcHeatPumpBarChartDataQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  period: PlcMetricsBarChartPeriodChoices;
  untilDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type PlcHeatPumpBarChartDataQuery = {
  __typename?: 'Query';
  plcHeatPumpBarChartData:
    | {
        __typename?: 'PLCHeatPumpBarChartDataList';
        data: Array<{
          __typename?: 'PLCHeatPumpBarChartData';
          outputPower?: number | null;
          inputPower?: number | null;
          indoorTemperature?: number | null;
          dhwTemperature?: number | null;
          outdoorTemperature?: number | null;
          bucket: any;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export type PlcHeatPumpDashboardMetricsCountsFragment = {
  __typename?: 'PlcHeatPumpMetricsType';
  created: any;
  targetIndoorTemperature?: number | null;
  indoorTemperature?: number | null;
  dhwTemperature?: number | null;
  outdoorTemperature?: number | null;
};

export type PlcHeatPumpDashboardMetricsChartFragment = {
  __typename?: 'PlcHeatPumpMetricsMiniChartType';
  bucket: any;
  targetIndoorTemperature?: number | null;
  indoorTemperature?: number | null;
  dhwTemperature?: number | null;
  outdoorTemperature?: number | null;
};

export type PlcHeatPumpDashboardMetricsQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;

export type PlcHeatPumpDashboardMetricsQuery = {
  __typename?: 'Query';
  plcHeatPumpMiniChartData: Array<{
    __typename?: 'PlcHeatPumpMetricsMiniChartType';
    bucket: any;
    targetIndoorTemperature?: number | null;
    indoorTemperature?: number | null;
    dhwTemperature?: number | null;
    outdoorTemperature?: number | null;
  }>;
  plcHeatPumpCurrentData?: {
    __typename?: 'PlcHeatPumpMetricsType';
    created: any;
    targetIndoorTemperature?: number | null;
    indoorTemperature?: number | null;
    dhwTemperature?: number | null;
    outdoorTemperature?: number | null;
  } | null;
};

export type PlcHeatPumpLineChartDataFragment = {
  __typename?: 'PLCHeatPumpLineChartData';
  targetIndoorTemperature?: number | null;
  indoorTemperature?: number | null;
  dhwTarget?: number | null;
  dhwTemperature?: number | null;
  outdoorTemperature?: number | null;
  outputTemperature?: number | null;
  inputPower?: number | null;
  outputPower?: number | null;
  bivalentPowerConsumption?: number | null;
  bucket: any;
};

export type PlcHeatPumpLineChartDataQueryVariables = Exact<{
  deviceId: Scalars['ID']['input'];
  period: PlcMetricsLineChartPeriodGraphQlChoices;
  untilDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type PlcHeatPumpLineChartDataQuery = {
  __typename?: 'Query';
  plcHeatPumpLineChartData:
    | {
        __typename?: 'PLCHeatPumpLineChartDataList';
        data: Array<{
          __typename?: 'PLCHeatPumpLineChartData';
          targetIndoorTemperature?: number | null;
          indoorTemperature?: number | null;
          dhwTarget?: number | null;
          dhwTemperature?: number | null;
          outdoorTemperature?: number | null;
          outputTemperature?: number | null;
          inputPower?: number | null;
          outputPower?: number | null;
          bivalentPowerConsumption?: number | null;
          bucket: any;
        }>;
      }
    | { __typename?: 'TariffRestrictionResponse'; ok: boolean };
};

export const UserSelectItemFragmentDoc = gql`
  fragment UserSelectItem on UserSelectItemType {
    id
    firstName
    lastName
  }
`;
export const FileFragmentDoc = gql`
  fragment File on FileType {
    url
    name
  }
`;
export const CustomerTicketAttachmentFragmentDoc = gql`
  fragment CustomerTicketAttachment on CustomerTicketAttachmentType {
    originalFileName
    file {
      ...File
    }
    created
    modified
    id
    fileSize
  }
  ${FileFragmentDoc}
`;
export const CustomerCustomerTicketFragmentDoc = gql`
  fragment CustomerCustomerTicket on CustomerCustomerTicketType {
    created
    modified
    category
    priority
    title
    content
    id
    createdBy {
      ...UserSelectItem
    }
    isClosed
    assignee
    installationCompany {
      id
      name
    }
    device {
      id
      deviceType {
        id
        name
      }
    }
    attachments {
      ...CustomerTicketAttachment
    }
  }
  ${UserSelectItemFragmentDoc}
  ${CustomerTicketAttachmentFragmentDoc}
`;
export const CountryFragmentDoc = gql`
  fragment Country on CountryType {
    name
    code
    alpha3
    numeric
    iocCode
  }
`;
export const CustomerFragmentDoc = gql`
  fragment Customer on CustomerType {
    id
    city
    companyId
    email
    name
    phone
    street
    vatId
    zipCode
    created
    modified
    deviceCount
    isActive
    country {
      ...Country
    }
  }
  ${CountryFragmentDoc}
`;
export const CustomerExtendedFragmentDoc = gql`
  fragment CustomerExtended on CustomerType {
    ...Customer
    userCount
  }
  ${CustomerFragmentDoc}
`;
export const ManufacturerFragmentDoc = gql`
  fragment Manufacturer on ManufacturerType {
    city
    companyId
    created
    email
    id
    isActive
    modified
    name
    phone
    street
    vatId
    zipCode
    createdBy {
      ...UserSelectItem
    }
    country {
      ...Country
    }
  }
  ${UserSelectItemFragmentDoc}
  ${CountryFragmentDoc}
`;
export const DeviceTypeFragmentDoc = gql`
  fragment DeviceType on DeviceTypeType {
    name
    serialNumberFormat
    technology
    apiType
    note
    hwVersion
    id
    created
    modified
    devicesCount
    createdBy {
      ...UserSelectItem
    }
    manufacturer {
      ...Manufacturer
    }
  }
  ${UserSelectItemFragmentDoc}
  ${ManufacturerFragmentDoc}
`;
export const DeviceEventTypeFragmentDoc = gql`
  fragment DeviceEventType on DeviceEventTypeType {
    id
    uid
    priority
    descriptionCs
    descriptionEn
    description
    deviceType {
      ...DeviceType
    }
  }
  ${DeviceTypeFragmentDoc}
`;
export const CustomerDeviceFragmentDoc = gql`
  fragment CustomerDevice on CustomerDeviceType {
    id
    created
    modified
    manufacturerNote
    registrationToken
    state
    serialNumber
    plcPassword
    plcUsername
    createdBy {
      ...UserSelectItem
    }
    deviceType {
      ...DeviceType
    }
    customerSite {
      name
    }
  }
  ${UserSelectItemFragmentDoc}
  ${DeviceTypeFragmentDoc}
`;
export const CustomerPlcEventFragmentDoc = gql`
  fragment CustomerPlcEvent on CustomerPlcEventType {
    created
    deviceId
    id
    eventId
    eventType {
      ...DeviceEventType
    }
    markedAsRead
    device {
      ...CustomerDevice
    }
    customerTicket {
      id
      customer {
        street
        city
        name
      }
    }
  }
  ${DeviceEventTypeFragmentDoc}
  ${CustomerDeviceFragmentDoc}
`;
export const CustomerSiteFragmentDoc = gql`
  fragment CustomerSite on CustomerSiteType {
    created
    modified
    id
    name
    devices {
      id
      serialNumber
      modified
      deviceType {
        id
        name
        technology
        apiType
      }
    }
  }
`;
export const CustomerTicketCommentAttachmentFragmentDoc = gql`
  fragment CustomerTicketCommentAttachment on CustomerTicketCommentAttachmentType {
    originalFileName
    file {
      ...File
    }
    created
    modified
    id
    fileSize
  }
  ${FileFragmentDoc}
`;
export const CustomerTicketCommentFragmentDoc = gql`
  fragment CustomerTicketComment on CustomerTicketCommentType {
    created
    modified
    createdBy {
      id
      firstName
      lastName
    }
    id
    comment
    attachments {
      ...CustomerTicketCommentAttachment
    }
  }
  ${CustomerTicketCommentAttachmentFragmentDoc}
`;
export const InstallationAttachmentFragmentDoc = gql`
  fragment InstallationAttachment on InstallationAttachmentType {
    originalFileName
    file {
      ...File
    }
    created
    modified
    id
    title
    fileSize
  }
  ${FileFragmentDoc}
`;
export const InstallationCompanyFragmentDoc = gql`
  fragment InstallationCompany on InstallationCompanyType {
    city
    companyId
    created
    email
    id
    isActive
    modified
    name
    phone
    street
    vatId
    zipCode
    ticketsAllowed
    country {
      ...Country
    }
  }
  ${CountryFragmentDoc}
`;
export const InstallationCompanyTicketFragmentDoc = gql`
  fragment InstallationCompanyTicket on InstallationCompanyTicketType {
    created
    modified
    updatedBy {
      ...UserSelectItem
    }
    createdBy {
      ...UserSelectItem
    }
    category
    priority
    title
    content
    id
    isClosed
    installationCompany {
      ...InstallationCompany
    }
    attachments {
      originalFileName
      file {
        url
        name
      }
      created
      modified
      id
      fileSize
    }
    installationCompanyTechnician {
      id
      firstName
      lastName
      userType
    }
  }
  ${UserSelectItemFragmentDoc}
  ${InstallationCompanyFragmentDoc}
`;
export const ManufacturerTicketAttachmentFragmentDoc = gql`
  fragment ManufacturerTicketAttachment on ManufacturerTicketAttachmentType {
    originalFileName
    file {
      ...File
    }
    created
    modified
    id
    fileSize
  }
  ${FileFragmentDoc}
`;
export const InstallationCompanyManufacturerTicketFragmentDoc = gql`
  fragment InstallationCompanyManufacturerTicket on InstallationCompanyManufacturerTicketType {
    created
    modified
    category
    priority
    title
    content
    id
    isClosed
    assignee
    createdBy {
      ...UserSelectItem
    }
    installationCompany {
      id
      name
    }
    device {
      id
      deviceType {
        id
        name
      }
    }
    attachments {
      ...ManufacturerTicketAttachment
    }
    installationCompanyTechnician {
      id
      firstName
      lastName
      userType
    }
  }
  ${UserSelectItemFragmentDoc}
  ${ManufacturerTicketAttachmentFragmentDoc}
`;
export const InstallationCompanyCustomerSiteFragmentDoc = gql`
  fragment InstallationCompanyCustomerSite on InstallationCompanyCustomerSiteType {
    updatedBy {
      ...UserSelectItem
    }
    createdBy {
      ...UserSelectItem
    }
    created
    modified
    street
    city
    zipCode
    country {
      ...Country
    }
    id
    name
    gps {
      lat
      lng
    }
    note
  }
  ${UserSelectItemFragmentDoc}
  ${CountryFragmentDoc}
`;
export const InstallationCompanyDeviceFragmentDoc = gql`
  fragment InstallationCompanyDevice on InstallationCompanyDeviceType {
    id
    created
    modified
    manufacturerNote
    registrationToken
    state
    serialNumber
    plcPassword
    plcUsername
    createdBy {
      ...UserSelectItem
    }
    deviceType {
      ...DeviceType
    }
    installationCompanyCustomerSite {
      ...InstallationCompanyCustomerSite
    }
    technician {
      id
      firstName
      lastName
    }
  }
  ${UserSelectItemFragmentDoc}
  ${DeviceTypeFragmentDoc}
  ${InstallationCompanyCustomerSiteFragmentDoc}
`;
export const InstallationCompanyPlcEventFragmentDoc = gql`
  fragment InstallationCompanyPlcEvent on InstallationCompanyPlcEventType {
    created
    deviceId
    id
    eventId
    eventType {
      ...DeviceEventType
    }
    markedAsRead
    device {
      ...InstallationCompanyDevice
    }
    customerTicket {
      id
    }
  }
  ${DeviceEventTypeFragmentDoc}
  ${InstallationCompanyDeviceFragmentDoc}
`;
export const InstallationCompanySelectItemFragmentDoc = gql`
  fragment InstallationCompanySelectItem on InstallationCompanySelectItemType {
    id
    name
  }
`;
export const InstallationCompanyTicketCommentAttachmentFragmentDoc = gql`
  fragment InstallationCompanyTicketCommentAttachment on InstallationCompanyTicketCommentAttachmentType {
    originalFileName
    file {
      url
      name
    }
    created
    modified
    id
    fileSize
  }
`;
export const InstallationCompanyTicketCommentFragmentDoc = gql`
  fragment InstallationCompanyTicketComment on InstallationCompanyTicketCommentType {
    created
    modified
    createdBy {
      ...UserSelectItem
    }
    id
    comment
    attachments {
      ...InstallationCompanyTicketCommentAttachment
    }
  }
  ${UserSelectItemFragmentDoc}
  ${InstallationCompanyTicketCommentAttachmentFragmentDoc}
`;
export const InstallationNoteFragmentDoc = gql`
  fragment InstallationNote on InstallationNoteType {
    created
    modified
    id
    note
    attachments {
      originalFileName
      file {
        ...File
      }
      created
      modified
      id
      fileSize
    }
  }
  ${FileFragmentDoc}
`;
export const InstallationCompanyCustomerTicketFragmentDoc = gql`
  fragment InstallationCompanyCustomerTicket on InstallationCompanyCustomerTicketType {
    created
    modified
    category
    priority
    title
    content
    id
    isClosed
    assignee
    createdBy {
      ...UserSelectItem
    }
    installationCompany {
      id
      name
    }
    device {
      id
      deviceType {
        id
        name
      }
    }
    attachments {
      ...CustomerTicketAttachment
    }
    installationCompanyTechnician {
      id
      firstName
      lastName
      userType
    }
  }
  ${UserSelectItemFragmentDoc}
  ${CustomerTicketAttachmentFragmentDoc}
`;
export const ManufacturerDeviceFragmentDoc = gql`
  fragment ManufacturerDevice on ManufacturerDeviceType {
    id
    created
    modified
    manufacturerNote
    registrationToken
    state
    serialNumber
    createdBy {
      ...UserSelectItem
    }
    deviceType {
      ...DeviceType
    }
  }
  ${UserSelectItemFragmentDoc}
  ${DeviceTypeFragmentDoc}
`;
export const ManufacturerDeviceExtendedFragmentDoc = gql`
  fragment ManufacturerDeviceExtended on ManufacturerDeviceType {
    plcPassword
    plcUsername
    ...ManufacturerDevice
  }
  ${ManufacturerDeviceFragmentDoc}
`;
export const ManufacturerExtendedFragmentDoc = gql`
  fragment ManufacturerExtended on ManufacturerType {
    ...Manufacturer
    deviceCount
    deviceTypeCount
    userCount
  }
  ${ManufacturerFragmentDoc}
`;
export const ManufacturerManufacturerTicketFragmentDoc = gql`
  fragment ManufacturerManufacturerTicket on ManufacturerManufacturerTicketType {
    created
    modified
    category
    priority
    title
    content
    id
    isClosed
    assignee
    installationCompany {
      id
      name
    }
    device {
      id
      deviceType {
        id
        name
      }
    }
    attachments {
      ...ManufacturerTicketAttachment
    }
  }
  ${ManufacturerTicketAttachmentFragmentDoc}
`;
export const ManufacturerTicketCommentAttachmentFragmentDoc = gql`
  fragment ManufacturerTicketCommentAttachment on ManufacturerTicketCommentAttachmentType {
    originalFileName
    file {
      ...File
    }
    created
    modified
    id
    fileSize
  }
  ${FileFragmentDoc}
`;
export const ManufacturerTicketCommentFragmentDoc = gql`
  fragment ManufacturerTicketComment on ManufacturerTicketCommentType {
    created
    modified
    createdBy {
      id
      firstName
      lastName
    }
    id
    comment
    attachments {
      ...ManufacturerTicketCommentAttachment
    }
  }
  ${ManufacturerTicketCommentAttachmentFragmentDoc}
`;
export const DeviceTypeExtendedFragmentDoc = gql`
  fragment DeviceTypeExtended on DeviceTypeType {
    mapping
    ...DeviceType
  }
  ${DeviceTypeFragmentDoc}
`;
export const DeviceTypeSelectItemFragmentDoc = gql`
  fragment DeviceTypeSelectItem on DeviceTypeSelectItemType {
    id
    name
  }
`;
export const ErrorCodeFragmentDoc = gql`
  fragment ErrorCode on ErrorCodeType {
    code
    message
  }
`;
export const FormErrorFragmentDoc = gql`
  fragment FormError on FormErrorType {
    field
    inputName
    codes {
      ...ErrorCode
    }
  }
  ${ErrorCodeFragmentDoc}
`;
export const PaginationMetaFragmentDoc = gql`
  fragment PaginationMeta on PaginationMetaType {
    totalCount
    pageSize
    pagesCount
    currentPage
  }
`;
export const PlcFveMetricsFragmentDoc = gql`
  fragment PlcFveMetrics on PlcFveMetricsType {
    created
    solarIrradiance
    internalTemperature
    batteryVoltage
    batteryCurrent
    batteryPower
    gridAvailable
    mppt1Voltage
    mppt1Power
    mppt2Voltage
    mppt2Power
    pvChargerEnable
    acChargerEnable
    gridFeedEnable
    chargingVoltage
    maxChargingCurrent
    powerGridConsumption
    hwVersion
    swVersion
    do1State
    do2State
    batteryNotCharged
    batteryTemperature
    batterySoc
    batteryCapacity
    batterySocDisconnectActual
    batterySocDisconnectMinimum
    batterySocDisconnectNogrid
    externalPvPower
    inverterSerialNumber
    gridPower
    gridL1Power
    gridL2Power
    gridL3Power
    loadPower
    loadL1Power
    loadL2Power
    loadL3Power
    mppt1Installed
    mppt2Installed
    stopAlarm
    gridFeedPowerMax
  }
`;
export const PlcHeatPumpMetricsFragmentDoc = gql`
  fragment PlcHeatPumpMetrics on PlcHeatPumpMetricsType {
    created
    mode
    dhwTemperature
    dhwTarget
    dhwHysteresis
    heatingSystemTemperature
    heatingSystemTarget
    heatingSystemHysteresis
    outdoorunitTargetPercentage
    outputTemperature
    hasBoiler
    hasInverter
    feedbackFromOutputTemperature
    heatingSeasonActive
    stopAlarm
    bivalentPowerConsumption
    outputPower
    inputPower
    watterFlow
    heatingFactor
    outdoorTemperature
    indoorTemperature
    hwVersion
    swVersion
    outdoorunitActive
    outdoorunitDefrosting
    targetIndoorTemperature
    ekvitermType
    ekvitermPower
    internalPumpActive
    evaporatorTemperature
    condenserTemperature
    compressorTemperature
  }
`;
export const PreSignedUploadFragmentDoc = gql`
  fragment PreSignedUpload on PreSignedUpload {
    id
    url
  }
`;
export const UserFragmentDoc = gql`
  fragment User on UserType {
    email
    firstName
    id
    isActive
    languageCode
    lastName
    role
    userType
    lastLogin
    created
    modified
    state
    phone
  }
`;
export const InstallationCompanyInstallationCompanyCustomerSiteUpdateFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyCustomerSiteUpdate on InstallationCompanyCustomerSiteType {
    id
    name
    street
    city
    zipCode
    note
    gps {
      lng
      lat
    }
  }
`;
export const InstallationCompanyInstallationCompanyCustomerUpdateFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyCustomerUpdate on InstallationCompanyCustomerType {
    id
    companyId
    email
    name
    phone
    vatId
    note
    created
    modified
    sites {
      ...InstallationCompanyCustomerSite
    }
  }
  ${InstallationCompanyCustomerSiteFragmentDoc}
`;
export const InstallationCompanyInstallationNoteCreateFragmentDoc = gql`
  fragment InstallationCompanyInstallationNoteCreate on InstallationNoteType {
    created
    modified
    id
    note
    attachments {
      originalFileName
      file {
        ...File
      }
      created
      modified
      id
      fileSize
    }
  }
  ${FileFragmentDoc}
`;
export const SystemAdminCustomerListFragmentDoc = gql`
  fragment SystemAdminCustomerList on CustomerType {
    id
    companyId
    email
    name
    vatId
    created
    deviceCount
    isActive
    subscription {
      id
      currentPeriodEnd
    }
    userCount
    tariff {
      id
      name
    }
  }
`;
export const SystemAdminCustomerTariffSelectItemsFragmentDoc = gql`
  fragment SystemAdminCustomerTariffSelectItems on CustomerTariffType {
    name
    priceType
    price
    currency
    id
  }
`;
export const SystemAdminInstallationCompanyListFragmentDoc = gql`
  fragment SystemAdminInstallationCompanyList on InstallationCompanyType {
    city
    companyId
    created
    email
    id
    isActive
    modified
    name
    vatId
    deviceCount
    userCount
    subscription {
      id
      currentPeriodEnd
    }
    tariff {
      id
      name
    }
  }
`;
export const SystemAdminInstallationCompanyTariffSelectItemsFragmentDoc = gql`
  fragment SystemAdminInstallationCompanyTariffSelectItems on InstallationCompanyTariffType {
    name
    priceType
    price
    currency
    id
  }
`;
export const SystemAdminManufacturerDetailFragmentDoc = gql`
  fragment SystemAdminManufacturerDetail on SystemAdminManufacturerType {
    city
    companyId
    email
    id
    name
    phone
    street
    vatId
    zipCode
    note
    subscription {
      id
      manufacturerTariff {
        id
      }
      currentPeriodStart
      currentPeriodEnd
    }
  }
`;
export const SystemAdminManufacturerListFragmentDoc = gql`
  fragment SystemAdminManufacturerList on SystemAdminManufacturerType {
    companyId
    created
    email
    id
    isActive
    modified
    name
    vatId
    createdBy {
      ...UserSelectItem
    }
    deviceCount
    deviceTypeCount
    userCount
    subscription {
      id
      currentPeriodEnd
      manufacturerTariff {
        id
        name
      }
    }
  }
  ${UserSelectItemFragmentDoc}
`;
export const SystemAdminManufacturerTariffSelectItemsFragmentDoc = gql`
  fragment SystemAdminManufacturerTariffSelectItems on ManufacturerTariffType {
    name
    priceType
    price
    currency
    id
    numberOfDevices
    numberOfDeviceTypes
  }
`;
export const SystemAdminStripeSubscriptionListFragmentDoc = gql`
  fragment SystemAdminStripeSubscriptionList on StripeSubscriptionType {
    id
    subscriptionCreated
    currentPeriodStart
    currentPeriodEnd
    tariff {
      name
      priceType
      price
      currency
      id
    }
  }
`;
export const SystemAdminUserListFragmentDoc = gql`
  fragment SystemAdminUserList on UserType {
    email
    firstName
    id
    lastName
    role
    lastLogin
    created
    state
    modified
  }
`;
export const CustomerCustomerSiteDetailFragmentDoc = gql`
  fragment CustomerCustomerSiteDetail on CustomerSiteType {
    created
    modified
    id
    name
    devices {
      id
      serialNumber
      modified
      deviceType {
        id
        name
        technology
        apiType
      }
    }
  }
`;
export const CustomerCustomerSiteListFragmentDoc = gql`
  fragment CustomerCustomerSiteList on CustomerSiteType {
    id
    name
    isDefault
    devices {
      id
      serialNumber
      modified
      deviceType {
        id
        name
        technology
        apiType
        mappingJson
      }
      installationCompany {
        id
        ticketsAllowed
      }
    }
  }
`;
export const CustomerCustomerSiteSelectItemsDefaultFragmentDoc = gql`
  fragment CustomerCustomerSiteSelectItemsDefault on CustomerSiteType {
    id
    name
    isDefault
    devices {
      id
    }
  }
`;
export const CustomerCustomerSiteSelectItemsFragmentDoc = gql`
  fragment CustomerCustomerSiteSelectItems on CustomerSiteType {
    id
    name
  }
`;
export const CustomerCustomerStripeSubscriptionListFragmentDoc = gql`
  fragment CustomerCustomerStripeSubscriptionList on CustomerStripeSubscriptionType {
    id
    subscriptionCreated
    currentPeriodStart
    currentPeriodEnd
    canceledAt
    status
    tariff {
      name
      priceType
      price
      currency
      id
    }
  }
`;
export const CustomerCustomerTariffSelectItemsFragmentDoc = gql`
  fragment CustomerCustomerTariffSelectItems on CustomerTariffType {
    name
    priceType
    price
    currency
    id
    activeSubscription {
      id
    }
    numberOfDevices
    numberOfUsers
    commands
    dataExportImage
    dataExportExcel
    lineChartDataGranularity
    lineChartDataRetention
    barChartDataGranularity
  }
`;
export const CustomerCustomerTicketDetailEditFragmentDoc = gql`
  fragment CustomerCustomerTicketDetailEdit on CustomerCustomerTicketType {
    priority
    title
    content
    id
    isClosed
    assignee
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
  }
`;
export const CustomerCustomerTicketDetailFragmentDoc = gql`
  fragment CustomerCustomerTicketDetail on CustomerCustomerTicketType {
    created
    modified
    category
    priority
    title
    content
    id
    isClosed
    assignee
    installationCompany {
      id
      name
    }
    device {
      id
      serialNumber
      deviceType {
        id
        name
      }
    }
    deviceEvent {
      id
    }
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
    deviceEvent {
      id
      eventType {
        id
        description
      }
    }
  }
`;
export const CustomerCustomerTicketListDetailFragmentDoc = gql`
  fragment CustomerCustomerTicketListDetail on CustomerCustomerTicketType {
    created
    category
    priority
    title
    id
    isClosed
    assignee
    installationCompany {
      id
      name
    }
    lastComment {
      id
      created
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;
export const CustomerCustomerTicketListFragmentDoc = gql`
  fragment CustomerCustomerTicketList on CustomerCustomerTicketType {
    created
    category
    priority
    title
    id
    isClosed
    assignee
    createdBy {
      ...UserSelectItem
    }
    installationCompany {
      id
      name
    }
    device {
      id
      serialNumber
    }
    lastComment {
      id
      created
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
  ${UserSelectItemFragmentDoc}
`;
export const CustomerDashboardDetailDeviceFragmentDoc = gql`
  fragment CustomerDashboardDetailDevice on CustomerDeviceType {
    id
    serialNumber
    modified
    deviceType {
      id
      name
      technology
      apiType
      mappingJson
    }
  }
`;
export const CustomerDashboardDetailFragmentDoc = gql`
  fragment CustomerDashboardDetail on CustomerSiteType {
    created
    modified
    id
    name
    devices {
      ...CustomerDashboardDetailDevice
    }
  }
  ${CustomerDashboardDetailDeviceFragmentDoc}
`;
export const CustomerPlcEventLastNEventsFragmentDoc = gql`
  fragment CustomerPlcEventLastNEvents on CustomerPlcEventType {
    deviceId
    id
    eventId
    markedAsRead
    created
    eventType {
      id
      description
      priority
    }
    device {
      id
      serialNumber
      deviceType {
        name
      }
    }
  }
`;
export const CustomerCustomerTicketLastNTicketsFragmentDoc = gql`
  fragment CustomerCustomerTicketLastNTickets on CustomerCustomerTicketType {
    created
    modified
    updatedBy {
      ...UserSelectItem
    }
    createdBy {
      ...UserSelectItem
    }
    device {
      id
      serialNumber
    }
    category
    priority
    title
    content
    id
  }
  ${UserSelectItemFragmentDoc}
`;
export const CustomerDeviceDetailTicketsFragmentDoc = gql`
  fragment CustomerDeviceDetailTickets on CustomerDeviceType {
    id
    serialNumber
    installationCompany {
      id
      name
    }
  }
`;
export const CustomerDeviceDetailFragmentDoc = gql`
  fragment CustomerDeviceDetail on CustomerDeviceType {
    id
    serialNumber
    deviceType {
      name
      technology
      apiType
      id
    }
    customerSite {
      id
      name
    }
    installationCompany {
      id
      ticketsAllowed
    }
  }
`;
export const CustomerDeviceListFragmentDoc = gql`
  fragment CustomerDeviceList on CustomerDeviceType {
    id
    serialNumber
    deviceType {
      id
      name
    }
    customerSite {
      id
      name
    }
  }
`;
export const CustomerDeviceSelectItemsTicketsAllowedFragmentDoc = gql`
  fragment CustomerDeviceSelectItemsTicketsAllowed on CustomerDeviceType {
    id
    serialNumber
    installationCompany {
      id
      name
    }
  }
`;
export const CustomerMyDeviceListFragmentDoc = gql`
  fragment CustomerMyDeviceList on CustomerDeviceType {
    id
    serialNumber
    deviceType {
      id
      name
    }
    lastMetricsTimestamp
    registeredAt
    installationCompany {
      id
      name
    }
    customerSite {
      id
      name
    }
  }
`;
export const CustomerPlcEventDetailTicketsFragmentDoc = gql`
  fragment CustomerPlcEventDetailTickets on CustomerPlcEventType {
    id
    eventType {
      id
      description
    }
  }
`;
export const CustomerPlcEventListDetailFragmentDoc = gql`
  fragment CustomerPlcEventListDetail on CustomerPlcEventType {
    id
    created
    markedAsRead
    eventId
    eventType {
      id
      description
      priority
    }
    device {
      id
      serialNumber
      deviceType {
        id
        name
        apiType
      }
      customerSite {
        name
      }
      installationCompany {
        id
        ticketsAllowed
      }
    }
    customerTicket {
      id
    }
  }
`;
export const CustomerPlcEventListFragmentDoc = gql`
  fragment CustomerPlcEventList on CustomerPlcEventType {
    id
    created
    markedAsRead
    eventId
    eventType {
      id
      description
      priority
    }
    device {
      id
      serialNumber
      deviceType {
        id
        name
        apiType
      }
      customerSite {
        name
      }
      installationCompany {
        id
        ticketsAllowed
      }
    }
    customerTicket {
      id
    }
  }
`;
export const CustomerTariffRestrictionInfoFragmentDoc = gql`
  fragment CustomerTariffRestrictionInfo on CustomerTariffRestrictionInfoType {
    actualNumberOfUsers
    availableNumberOfUsers
    actualNumberOfDevices
    availableNumberOfDevices
    usersOverLimit
    devicesOverLimit
    singlePhaseMetrics
  }
`;
export const InstallationCompanyBaseTicketListFragmentDoc = gql`
  fragment InstallationCompanyBaseTicketList on InstallationCompanyBaseTicketType {
    id
    created
    category
    title
    priority
    ticketType
    isClosed
    assignee
    lastComment {
      id
      created
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;
export const InstallationCompanyCustomerTicketDetailEditFragmentDoc = gql`
  fragment InstallationCompanyCustomerTicketDetailEdit on InstallationCompanyCustomerTicketType {
    category
    priority
    title
    content
    id
    isClosed
    assignee
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
  }
`;
export const InstallationCompanyCustomerTicketDetailForManufacturerFragmentDoc = gql`
  fragment InstallationCompanyCustomerTicketDetailForManufacturer on InstallationCompanyCustomerTicketType {
    id
    title
    created
  }
`;
export const InstallationCompanyCustomerTicketDetailFragmentDoc = gql`
  fragment InstallationCompanyCustomerTicketDetail on InstallationCompanyCustomerTicketType {
    created
    modified
    category
    priority
    title
    content
    id
    isClosed
    assignee
    installationCompany {
      id
      name
    }
    device {
      id
      serialNumber
      deviceType {
        id
        name
      }
    }
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
    deviceEvent {
      id
      eventType {
        id
        description
      }
    }
    installationCompanyTechnician {
      id
      firstName
      lastName
    }
  }
`;
export const InstallationCompanyCustomerTicketListFragmentDoc = gql`
  fragment InstallationCompanyCustomerTicketList on InstallationCompanyCustomerTicketType {
    created
    category
    priority
    title
    id
    isClosed
    assignee
    createdBy {
      ...UserSelectItem
    }
    installationCompany {
      id
      name
    }
    customer {
      id
      name
    }
    device {
      id
      serialNumber
      installationCompanyCustomerSite {
        id
        name
        street
        city
      }
    }
    lastComment {
      id
      created
      createdBy {
        id
        firstName
        lastName
      }
    }
    installationCompanyTechnician {
      id
      firstName
      lastName
    }
  }
  ${UserSelectItemFragmentDoc}
`;
export const InstallationCompanyPlcEventLastNEventsFragmentDoc = gql`
  fragment InstallationCompanyPlcEventLastNEvents on InstallationCompanyPlcEventType {
    deviceId
    id
    eventId
    markedAsRead
    created
    eventType {
      id
      description
      priority
    }
    device {
      id
      serialNumber
      deviceType {
        name
      }
    }
  }
`;
export const InstallationCompanyDashboardStatisticsOverviewFragmentDoc = gql`
  fragment InstallationCompanyDashboardStatisticsOverview on InstallationCompanyDashboardStatisticsType {
    installationCompanyPlcEventTodayCount
    installationCompanyPlcEventWeekHighestPriorityCount
    installationCompanyCustomerTicketOpenCount
    installationCompanyCustomerTicketClosedCount
    installationCompanyManufacturerTicketOpenCount
    installationCompanyManufacturerTicketClosedCount
    installationCompanyInstallationCompanyTicketOpenCount
    installationCompanyInstallationCompanyTicketClosedCount
    installationCompanyDeviceFveCount
    installationCompanyDeviceHeatPumpCount
    installationCompanyCustomerFveCount
    installationCompanyCustomerHeatPumpCount
  }
`;
export const InstallationCompanyBaseTicketLastNTicketsFragmentDoc = gql`
  fragment InstallationCompanyBaseTicketLastNTickets on InstallationCompanyBaseTicketType {
    created
    modified
    updatedBy {
      ...UserSelectItem
    }
    createdBy {
      ...UserSelectItem
    }
    ticketType
    category
    device {
      id
      serialNumber
    }
    priority
    title
    content
    id
  }
  ${UserSelectItemFragmentDoc}
`;
export const InstallationCompanyDeviceCommandListFragmentDoc = gql`
  fragment InstallationCompanyDeviceCommandList on DeviceCommandType {
    id
    uid
    command
    created
    responseReceived
    status
    response
    createdBy {
      id
      firstName
      lastName
    }
  }
`;
export const InstallationCompanyDeviceDetailTicketsFragmentDoc = gql`
  fragment InstallationCompanyDeviceDetailTickets on InstallationCompanyDeviceType {
    id
    serialNumber
    deviceType {
      id
      name
    }
  }
`;
export const InstallationCompanyDeviceDetailFragmentDoc = gql`
  fragment InstallationCompanyDeviceDetail on InstallationCompanyDeviceType {
    id
    serialNumber
    deviceType {
      name
      technology
      apiType
      id
    }
    installationCompanyCustomerSite {
      id
      name
    }
    installationCompanyCustomer {
      id
    }
    installation {
      id
    }
  }
`;
export const InstallationCompanyDeviceListFragmentDoc = gql`
  fragment InstallationCompanyDeviceList on InstallationCompanyDeviceType {
    id
    serialNumber
    createdBy {
      id
      firstName
      lastName
    }
    deviceType {
      id
      name
    }
    installation {
      id
      created
    }
    installationCompanyCustomerSite {
      id
      name
      street
      city
      zipCode
    }
    technician {
      id
      firstName
      lastName
    }
    installationCompanyCustomer {
      id
      name
    }
    lastMetricsTimestamp
    customerSite {
      id
      customer {
        id
        name
        tariff {
          id
          name
        }
      }
    }
  }
`;
export const InstallationCompanyInstallationAttachmentListFragmentDoc = gql`
  fragment InstallationCompanyInstallationAttachmentList on InstallationAttachmentType {
    originalFileName
    fileSize
    file {
      url
      name
    }
    createdBy {
      id
      firstName
      lastName
    }
    created
    modified
    id
    title
  }
`;
export const InstallationCompanyInstallationCompanyCustomerDetailFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyCustomerDetail on InstallationCompanyCustomerType {
    id
    companyId
    email
    name
    phone
    vatId
    note
    created
    modified
    sites {
      ...InstallationCompanyCustomerSite
    }
  }
  ${InstallationCompanyCustomerSiteFragmentDoc}
`;
export const InstallationCompanyInstallationCompanyCustomerListAutocompleteFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyCustomerListAutocomplete on InstallationCompanyCustomerType {
    id
    email
    name
    phone
  }
`;
export const InstallationCompanyInstallationCompanyCustomerListFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyCustomerList on InstallationCompanyCustomerType {
    id
    email
    name
    phone
    created
    updatedAt
    siteCount
    deviceCount
  }
`;
export const InstallationCompanyInstallationCompanyCustomerSelectItemsFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyCustomerSelectItems on InstallationCompanyCustomerSelectItemType {
    id
    name
  }
`;
export const InstallationCompanyInstallationCompanyCustomerSiteDetailFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyCustomerSiteDetail on InstallationCompanyCustomerSiteType {
    id
    name
    street
    city
    zipCode
    note
    gps {
      lng
      lat
    }
  }
`;
export const InstallationCompanyInstallationCompanyCustomerSiteListFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyCustomerSiteList on InstallationCompanyCustomerSiteType {
    created
    modified
    street
    city
    zipCode
    id
    name
    gps {
      lat
      lng
    }
    deviceCount
    note
  }
`;
export const InstallationCompanyInstallationCompanyStripeSubscriptionListFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyStripeSubscriptionList on InstallationCompanyStripeSubscriptionType {
    id
    subscriptionCreated
    currentPeriodStart
    currentPeriodEnd
    canceledAt
    tariff {
      name
      priceType
      price
      currency
      id
    }
  }
`;
export const InstallationCompanyInstallationCompanyTariffSelectItemsFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyTariffSelectItems on InstallationCompanyTariffType {
    name
    priceType
    price
    currency
    id
    activeSubscription {
      id
    }
    ticketsAllowed
    numberOfUsers
    numberOfDevices
    lineChartDataGranularity
    lineChartDataRetention
    barChartDataGranularity
  }
`;
export const InstallationCompanyInstallationCompanyTechnicianSelectItemsFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyTechnicianSelectItems on UserSelectItemType {
    id
    firstName
    lastName
  }
`;
export const InstallationCompanyInstallationCompanyTicketDetailEditFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyTicketDetailEdit on InstallationCompanyTicketType {
    category
    priority
    title
    content
    id
    isClosed
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
  }
`;
export const InstallationCompanyInstallationCompanyTicketDetailFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyTicketDetail on InstallationCompanyTicketType {
    created
    modified
    category
    priority
    title
    content
    id
    isClosed
    installationCompany {
      id
      name
    }
    device {
      id
      serialNumber
      deviceType {
        id
        name
      }
    }
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
    deviceEvent {
      id
      eventType {
        id
        description
      }
    }
    installationCompanyTechnician {
      id
      firstName
      lastName
    }
  }
`;
export const InstallationCompanyInstallationCompanyTicketListFragmentDoc = gql`
  fragment InstallationCompanyInstallationCompanyTicketList on InstallationCompanyTicketType {
    created
    category
    priority
    title
    id
    isClosed
    createdBy {
      ...UserSelectItem
    }
    installationCompany {
      id
      name
    }
    device {
      id
      serialNumber
    }
    lastComment {
      id
      created
      createdBy {
        id
        firstName
        lastName
      }
    }
    installationCompanyTechnician {
      id
      firstName
      lastName
    }
  }
  ${UserSelectItemFragmentDoc}
`;
export const InstallationCompanyInstallationNoteListFragmentDoc = gql`
  fragment InstallationCompanyInstallationNoteList on InstallationNoteType {
    created
    modified
    createdBy {
      id
      firstName
      lastName
    }
    id
    note
    attachments {
      originalFileName
      file {
        ...File
      }
      created
      modified
      id
      fileSize
    }
  }
  ${FileFragmentDoc}
`;
export const InstallationCompanyManufacturerTicketDetailEditFragmentDoc = gql`
  fragment InstallationCompanyManufacturerTicketDetailEdit on InstallationCompanyManufacturerTicketType {
    category
    priority
    title
    content
    id
    isClosed
    assignee
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
  }
`;
export const InstallationCompanyManufacturerTicketDetailFragmentDoc = gql`
  fragment InstallationCompanyManufacturerTicketDetail on InstallationCompanyManufacturerTicketType {
    created
    modified
    category
    priority
    title
    content
    id
    isClosed
    assignee
    installationCompany {
      id
      name
    }
    device {
      id
      serialNumber
      deviceType {
        id
        name
      }
    }
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
    customerTicket {
      id
      title
    }
    installationCompanyTechnician {
      id
      firstName
      lastName
    }
  }
`;
export const InstallationCompanyManufacturerTicketListFragmentDoc = gql`
  fragment InstallationCompanyManufacturerTicketList on InstallationCompanyManufacturerTicketType {
    created
    category
    priority
    title
    id
    isClosed
    assignee
    createdBy {
      ...UserSelectItem
    }
    installationCompany {
      id
      name
    }
    device {
      id
      serialNumber
    }
    manufacturer {
      id
      name
    }
    lastComment {
      id
      created
      createdBy {
        id
        firstName
        lastName
      }
    }
    installationCompanyTechnician {
      id
      firstName
      lastName
    }
  }
  ${UserSelectItemFragmentDoc}
`;
export const InstallationCompanyPlcEventDetailTicketsFragmentDoc = gql`
  fragment InstallationCompanyPlcEventDetailTickets on InstallationCompanyPlcEventType {
    id
    eventType {
      id
      description
    }
  }
`;
export const InstallationCompanyPlcEventListDetailFragmentDoc = gql`
  fragment InstallationCompanyPlcEventListDetail on InstallationCompanyPlcEventType {
    created
    deviceId
    id
    eventId
    markedAsRead
    eventType {
      id
      description
      priority
    }
    device {
      id
      serialNumber
      deviceType {
        id
        name
        apiType
      }
      installationCompanyCustomerSite {
        id
        name
        street
        city
      }
    }
    customerTicket {
      id
    }
  }
`;
export const InstallationCompanyPlcEventListFragmentDoc = gql`
  fragment InstallationCompanyPlcEventList on InstallationCompanyPlcEventType {
    created
    deviceId
    id
    eventId
    markedAsRead
    eventType {
      id
      description
      priority
    }
    device {
      id
      serialNumber
      deviceType {
        id
        name
        apiType
      }
      installationCompanyCustomerSite {
        id
        name
        street
        city
      }
    }
    customerTicket {
      id
    }
  }
`;
export const InstallationCompanyTariffRestrictionInfoFragmentDoc = gql`
  fragment InstallationCompanyTariffRestrictionInfo on InstallationCompanyTariffRestrictionInfoType {
    actualNumberOfUsers
    availableNumberOfUsers
    actualNumberOfDevices
    availableNumberOfDevices
    devicesOverLimit
    usersOverLimit
  }
`;
export const ManufacturerDeviceDetailTicketsFragmentDoc = gql`
  fragment ManufacturerDeviceDetailTickets on ManufacturerDeviceType {
    id
    serialNumber
    deviceType {
      id
      name
    }
  }
`;
export const ManufacturerDeviceDetailFragmentDoc = gql`
  fragment ManufacturerDeviceDetail on ManufacturerDeviceType {
    id
    plcPassword
    plcUsername
    manufacturerNote
    registrationToken
    state
    serialNumber
    mqttTopics
    deviceType {
      id
      apiType
    }
  }
`;
export const ManufacturerDeviceEventTypeDetailFragmentDoc = gql`
  fragment ManufacturerDeviceEventTypeDetail on DeviceEventTypeType {
    id
    uid
    priority
    descriptionCs
    descriptionEn
    customerVisibility
  }
`;
export const ManufacturerDeviceEventTypeListFragmentDoc = gql`
  fragment ManufacturerDeviceEventTypeList on DeviceEventTypeType {
    id
    uid
    priority
    descriptionCs
    descriptionEn
    customerVisibility
  }
`;
export const ManufacturerDeviceListFragmentDoc = gql`
  fragment ManufacturerDeviceList on ManufacturerDeviceType {
    id
    created
    manufacturerNote
    state
    serialNumber
    manufacturerModifiedAt
    lastMetricsTimestamp
    createdBy {
      ...UserSelectItem
    }
    deviceType {
      name
      id
    }
    installationCompany {
      id
      ticketsAllowed
    }
  }
  ${UserSelectItemFragmentDoc}
`;
export const ManufacturerDeviceTypeDetailEventFragmentDoc = gql`
  fragment ManufacturerDeviceTypeDetailEvent on DeviceTypeType {
    id
    name
  }
`;
export const ManufacturerDeviceTypeDetailFragmentDoc = gql`
  fragment ManufacturerDeviceTypeDetail on DeviceTypeType {
    id
    name
    mapping
    serialNumberFormat
    technology
    apiType
    note
    hwVersion
    devicesCount
  }
`;
export const ManufacturerDeviceTypeListFragmentDoc = gql`
  fragment ManufacturerDeviceTypeList on DeviceTypeType {
    name
    serialNumberFormat
    technology
    apiType
    note
    hwVersion
    id
    created
    modified
    devicesCount
    createdBy {
      ...UserSelectItem
    }
  }
  ${UserSelectItemFragmentDoc}
`;
export const ManufacturerManufacturerTariffSelectItemsFragmentDoc = gql`
  fragment ManufacturerManufacturerTariffSelectItems on ManufacturerTariffType {
    name
    priceType
    price
    currency
    id
    activeSubscription {
      id
    }
    numberOfDevices
    numberOfDeviceTypes
  }
`;
export const ManufacturerManufacturerTicketDetailEditFragmentDoc = gql`
  fragment ManufacturerManufacturerTicketDetailEdit on ManufacturerManufacturerTicketType {
    priority
    title
    content
    id
    isClosed
    assignee
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
  }
`;
export const ManufacturerManufacturerTicketDetailFragmentDoc = gql`
  fragment ManufacturerManufacturerTicketDetail on ManufacturerManufacturerTicketType {
    created
    modified
    category
    priority
    title
    content
    id
    isClosed
    assignee
    installationCompany {
      id
      name
    }
    device {
      id
      serialNumber
      deviceType {
        id
        name
      }
    }
    attachments {
      id
      fileSize
      originalFileName
      file {
        url
        name
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
  }
`;
export const ManufacturerManufacturerTicketListFragmentDoc = gql`
  fragment ManufacturerManufacturerTicketList on ManufacturerManufacturerTicketType {
    created
    category
    priority
    title
    id
    isClosed
    assignee
    createdBy {
      ...UserSelectItem
    }
    installationCompany {
      id
      name
    }
    device {
      id
      deviceType {
        id
        name
      }
    }
    lastComment {
      id
      created
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
  ${UserSelectItemFragmentDoc}
`;
export const ManufacturerTariffRestrictionInfoFragmentDoc = gql`
  fragment ManufacturerTariffRestrictionInfo on ManufacturerTariffRestrictionInfoType {
    actualNumberOfDevices
    availableNumberOfDevices
    actualNumberOfDeviceTypes
    availableNumberOfDeviceTypes
    devicesOverLimit
    deviceTypesOverLimit
  }
`;
export const InstallationCompanyCustomerInvitationDetailFragmentDoc = gql`
  fragment InstallationCompanyCustomerInvitationDetail on InstallationCompanyCustomerInvitationType {
    id
    name
    email
    city
    phone
    street
    zipCode
    vatId
    companyId
  }
`;
export const MeAdminFragmentDoc = gql`
  fragment MeAdmin on UserType {
    id
    firstName
    lastName
    role
    phone
  }
`;
export const MeCustomerExportFragmentDoc = gql`
  fragment MeCustomerExport on UserType {
    id
    customer {
      id
      subscription {
        id
        customerTariff {
          id
          dataExportExcel
          dataExportImage
        }
      }
    }
  }
`;
export const MeCustomerMqttFragmentDoc = gql`
  fragment MeCustomerMqtt on UserType {
    id
    customer {
      id
      subscription {
        id
        customerTariff {
          id
          commands
        }
      }
    }
  }
`;
export const MeCustomerSubscriptionFragmentDoc = gql`
  fragment MeCustomerSubscription on UserType {
    id
    customer {
      id
      tariff {
        id
        name
        activeSubscription {
          id
          canceledAt
          currentPeriodEnd
          status
        }
      }
    }
  }
`;
export const MeCustomerFragmentDoc = gql`
  fragment MeCustomer on UserType {
    id
    customer {
      id
      name
      email
      phone
      companyId
      vatId
      street
      city
      zipCode
    }
  }
`;
export const MeInstallationCompanySubscriptionFragmentDoc = gql`
  fragment MeInstallationCompanySubscription on UserType {
    id
    installationCompany {
      id
      tariff {
        id
        name
        activeSubscription {
          id
          canceledAt
          currentPeriodEnd
        }
      }
    }
  }
`;
export const MeInstallationCompanyTicketsAllowedFragmentDoc = gql`
  fragment MeInstallationCompanyTicketsAllowed on UserType {
    id
    installationCompany {
      id
      name
      ticketsAllowed
      subscription {
        id
        installationCompanyTariff {
          id
          ticketsAllowed
        }
      }
    }
  }
`;
export const MeInstallationCompanyFragmentDoc = gql`
  fragment MeInstallationCompany on UserType {
    id
    installationCompany {
      id
      name
      email
      phone
      companyId
      vatId
      street
      city
      zipCode
    }
  }
`;
export const MeManufacturerFragmentDoc = gql`
  fragment MeManufacturer on UserType {
    id
    manufacturer {
      id
      name
      email
      phone
      companyId
      vatId
      street
      city
      zipCode
      subscription {
        currentPeriodEnd
        currentPeriodStart
      }
    }
  }
`;
export const MeUserFragmentDoc = gql`
  fragment MeUser on UserType {
    id
    email
    firstName
    lastName
    isActive
    languageCode
    role
    userType
    state
    phone
    installationCompany {
      id
      name
    }
    customer {
      id
    }
    manufacturer {
      id
      name
    }
  }
`;
export const OrganizationUserDetailFragmentDoc = gql`
  fragment OrganizationUserDetail on UserType {
    id
    firstName
    lastName
    role
    phone
    ticketNotifications
  }
`;
export const OrganizationUserListFragmentDoc = gql`
  fragment OrganizationUserList on UserType {
    email
    firstName
    id
    isActive
    lastName
    role
    lastLogin
    created
    state
    modified
  }
`;
export const PlcfveBarChartDataFragmentDoc = gql`
  fragment PLCFVEBarChartData on PLCFVEBarChartData {
    mpptPower
    loadPower
    gridSupply
    gridConsumption
    bucket
  }
`;
export const PlcFveDashboardMetricsCountsFragmentDoc = gql`
  fragment PlcFveDashboardMetricsCounts on PlcFveMetricsType {
    created
    gridL1Power
    gridL2Power
    gridL3Power
    loadL1Power
    loadL2Power
    loadL3Power
    mppt1Power
    mppt2Power
    batterySoc
  }
`;
export const PlcFveDashboardMetricsChartFragmentDoc = gql`
  fragment PlcFveDashboardMetricsChart on PlcFveMetricsMiniChartType {
    bucket
    mpptPower
    gridPower
    loadPower
    batterySoc
  }
`;
export const PlcfveLineChartDataFragmentDoc = gql`
  fragment PLCFVELineChartData on PLCFVELineChartData {
    mpptPower
    loadL1Power
    loadL2Power
    loadL3Power
    loadPower
    gridL1Power
    gridL2Power
    gridL3Power
    gridPower
    batterySoc
    batteryPower
    bucket
  }
`;
export const PlcHeatPumpBarChartDataFragmentDoc = gql`
  fragment PLCHeatPumpBarChartData on PLCHeatPumpBarChartData {
    outputPower
    inputPower
    indoorTemperature
    dhwTemperature
    outdoorTemperature
    bucket
  }
`;
export const PlcHeatPumpDashboardMetricsCountsFragmentDoc = gql`
  fragment PlcHeatPumpDashboardMetricsCounts on PlcHeatPumpMetricsType {
    created
    targetIndoorTemperature
    indoorTemperature
    dhwTemperature
    outdoorTemperature
  }
`;
export const PlcHeatPumpDashboardMetricsChartFragmentDoc = gql`
  fragment PlcHeatPumpDashboardMetricsChart on PlcHeatPumpMetricsMiniChartType {
    bucket
    targetIndoorTemperature
    indoorTemperature
    dhwTemperature
    outdoorTemperature
  }
`;
export const PlcHeatPumpLineChartDataFragmentDoc = gql`
  fragment PlcHeatPumpLineChartData on PLCHeatPumpLineChartData {
    targetIndoorTemperature
    indoorTemperature
    dhwTarget
    dhwTemperature
    outdoorTemperature
    outputTemperature
    inputPower
    outputPower
    bivalentPowerConsumption
    bucket
  }
`;
export const SystemAdminManufacturerCreateDocument = gql`
  mutation SystemAdminManufacturerCreate(
    $userCreationInput: UserCreationInput!
    $manufacturerInput: ManufacturerRegistrationInput!
    $manufacturerSubscriptionInput: ManufacturerSubscriptionInput!
  ) {
    systemAdminManufacturerCreate(
      userCreationInput: $userCreationInput
      manufacturerInput: $manufacturerInput
      manufacturerSubscriptionInput: $manufacturerSubscriptionInput
    ) {
      ... on UserRegistrationSuccess {
        user {
          ...User
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminManufacturerCreateGQL extends Apollo.Mutation<
  SystemAdminManufacturerCreateMutation,
  SystemAdminManufacturerCreateMutationVariables
> {
  override document = SystemAdminManufacturerCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminManufacturerUpdateDocument = gql`
  mutation SystemAdminManufacturerUpdate(
    $manufacturerId: ID!
    $manufacturerInput: ManufacturerRegistrationInput!
    $manufacturerSubscriptionId: ID!
    $manufacturerSubscriptionInput: ManufacturerSubscriptionInput!
  ) {
    systemAdminManufacturerUpdate(
      manufacturerId: $manufacturerId
      manufacturerInput: $manufacturerInput
      manufacturerSubscriptionId: $manufacturerSubscriptionId
      manufacturerSubscriptionInput: $manufacturerSubscriptionInput
    ) {
      ... on SystemAdminManufacturerUpdateSuccess {
        manufacturer {
          id
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminManufacturerUpdateGQL extends Apollo.Mutation<
  SystemAdminManufacturerUpdateMutation,
  SystemAdminManufacturerUpdateMutationVariables
> {
  override document = SystemAdminManufacturerUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminUserDisableDocument = gql`
  mutation SystemAdminUserDisable($userId: ID!) {
    systemAdminUserDisable(userId: $userId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminUserDisableGQL extends Apollo.Mutation<
  SystemAdminUserDisableMutation,
  SystemAdminUserDisableMutationVariables
> {
  override document = SystemAdminUserDisableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminUserEnableDocument = gql`
  mutation SystemAdminUserEnable($userId: ID!) {
    systemAdminUserEnable(userId: $userId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminUserEnableGQL extends Apollo.Mutation<
  SystemAdminUserEnableMutation,
  SystemAdminUserEnableMutationVariables
> {
  override document = SystemAdminUserEnableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminUserInvitationDocument = gql`
  mutation SystemAdminUserInvitation($userInvitationInput: AdminUserInvitationInput!) {
    systemAdminUserInvitation(userInvitationInput: $userInvitationInput) {
      ... on UserInvitationSuccess {
        user {
          ...User
        }
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminUserInvitationGQL extends Apollo.Mutation<
  SystemAdminUserInvitationMutation,
  SystemAdminUserInvitationMutationVariables
> {
  override document = SystemAdminUserInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminUserUpdateDocument = gql`
  mutation SystemAdminUserUpdate($userId: ID!, $userUpdateInput: AdminUserUpdateInput!) {
    systemAdminUserUpdate(userId: $userId, userUpdateInput: $userUpdateInput) {
      ... on UserUpdateSuccess {
        user {
          id
          firstName
          lastName
          role
          phone
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminUserUpdateGQL extends Apollo.Mutation<
  SystemAdminUserUpdateMutation,
  SystemAdminUserUpdateMutationVariables
> {
  override document = SystemAdminUserUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCheckoutSessionCreateDocument = gql`
  mutation CustomerCheckoutSessionCreate($tariffId: ID!) {
    customerCheckoutSessionCreate(tariffId: $tariffId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on CheckoutSession {
        sessionId
        publicKey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCheckoutSessionCreateGQL extends Apollo.Mutation<
  CustomerCheckoutSessionCreateMutation,
  CustomerCheckoutSessionCreateMutationVariables
> {
  override document = CustomerCheckoutSessionCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerSiteCreateDocument = gql`
  mutation CustomerCustomerSiteCreate($customerSiteInput: CustomerSiteInput!) {
    customerCustomerSiteCreate(customerSiteInput: $customerSiteInput) {
      ... on CustomerSiteCreateSuccess {
        customerSite {
          ...CustomerSite
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${CustomerSiteFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerSiteCreateGQL extends Apollo.Mutation<
  CustomerCustomerSiteCreateMutation,
  CustomerCustomerSiteCreateMutationVariables
> {
  override document = CustomerCustomerSiteCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerSiteDeleteDocument = gql`
  mutation CustomerCustomerSiteDelete($customerSiteId: ID!) {
    customerCustomerSiteDelete(customerSiteId: $customerSiteId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerSiteDeleteGQL extends Apollo.Mutation<
  CustomerCustomerSiteDeleteMutation,
  CustomerCustomerSiteDeleteMutationVariables
> {
  override document = CustomerCustomerSiteDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerSiteSetDefaultDocument = gql`
  mutation CustomerCustomerSiteSetDefault($customerSiteId: ID!) {
    customerCustomerSiteSetDefault(customerSiteId: $customerSiteId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerSiteSetDefaultGQL extends Apollo.Mutation<
  CustomerCustomerSiteSetDefaultMutation,
  CustomerCustomerSiteSetDefaultMutationVariables
> {
  override document = CustomerCustomerSiteSetDefaultDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerSiteUpdateDocument = gql`
  mutation CustomerCustomerSiteUpdate($customerSiteId: ID!, $customerSiteInput: CustomerSiteInput!) {
    customerCustomerSiteUpdate(customerSiteId: $customerSiteId, customerSiteInput: $customerSiteInput) {
      ... on CustomerSiteUpdateSuccess {
        customerSite {
          ...CustomerSite
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${CustomerSiteFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerSiteUpdateGQL extends Apollo.Mutation<
  CustomerCustomerSiteUpdateMutation,
  CustomerCustomerSiteUpdateMutationVariables
> {
  override document = CustomerCustomerSiteUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketAssignDocument = gql`
  mutation CustomerCustomerTicketAssign($ticketId: ID!, $assignee: CustomerTicketAssigneeChoices!) {
    customerCustomerTicketAssign(ticketId: $ticketId, assignee: $assignee) {
      ... on CustomerCustomerTicketResponse {
        customerTicket {
          ...CustomerCustomerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${CustomerCustomerTicketFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketAssignGQL extends Apollo.Mutation<
  CustomerCustomerTicketAssignMutation,
  CustomerCustomerTicketAssignMutationVariables
> {
  override document = CustomerCustomerTicketAssignDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketAttachmentDeleteDocument = gql`
  mutation CustomerCustomerTicketAttachmentDelete($customerTicketAttachmentId: ID!) {
    customerCustomerTicketAttachmentDelete(customerTicketAttachmentId: $customerTicketAttachmentId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketAttachmentDeleteGQL extends Apollo.Mutation<
  CustomerCustomerTicketAttachmentDeleteMutation,
  CustomerCustomerTicketAttachmentDeleteMutationVariables
> {
  override document = CustomerCustomerTicketAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketCloseDocument = gql`
  mutation CustomerCustomerTicketClose($ticketId: ID!) {
    customerCustomerTicketClose(ticketId: $ticketId) {
      ... on CustomerCustomerTicketResponse {
        customerTicket {
          ...CustomerCustomerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${CustomerCustomerTicketFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketCloseGQL extends Apollo.Mutation<
  CustomerCustomerTicketCloseMutation,
  CustomerCustomerTicketCloseMutationVariables
> {
  override document = CustomerCustomerTicketCloseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketCommentAttachmentDeleteDocument = gql`
  mutation CustomerCustomerTicketCommentAttachmentDelete($customerTicketCommentAttachmentId: ID!) {
    customerCustomerTicketCommentAttachmentDelete(
      customerTicketCommentAttachmentId: $customerTicketCommentAttachmentId
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketCommentAttachmentDeleteGQL extends Apollo.Mutation<
  CustomerCustomerTicketCommentAttachmentDeleteMutation,
  CustomerCustomerTicketCommentAttachmentDeleteMutationVariables
> {
  override document = CustomerCustomerTicketCommentAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketCommentCreateDocument = gql`
  mutation CustomerCustomerTicketCommentCreate(
    $customerTicketCommentInput: CustomerTicketCommentInput!
    $attachments: [AttachmentInput!] = null
  ) {
    customerCustomerTicketCommentCreate(
      customerTicketCommentInput: $customerTicketCommentInput
      attachments: $attachments
    ) {
      ... on CustomerTicketCommentResponse {
        customerTicketComment {
          ...CustomerTicketComment
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${CustomerTicketCommentFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketCommentCreateGQL extends Apollo.Mutation<
  CustomerCustomerTicketCommentCreateMutation,
  CustomerCustomerTicketCommentCreateMutationVariables
> {
  override document = CustomerCustomerTicketCommentCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketCommentDeleteDocument = gql`
  mutation CustomerCustomerTicketCommentDelete($customerCommentId: ID!) {
    customerCustomerTicketCommentDelete(customerCommentId: $customerCommentId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketCommentDeleteGQL extends Apollo.Mutation<
  CustomerCustomerTicketCommentDeleteMutation,
  CustomerCustomerTicketCommentDeleteMutationVariables
> {
  override document = CustomerCustomerTicketCommentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketCommentUpdateDocument = gql`
  mutation CustomerCustomerTicketCommentUpdate(
    $customerCommentId: ID!
    $customerTicketCommentInput: CustomerTicketCommentUpdateInput!
  ) {
    customerCustomerTicketCommentUpdate(
      customerCommentId: $customerCommentId
      customerTicketCommentInput: $customerTicketCommentInput
    ) {
      ... on CustomerTicketCommentResponse {
        customerTicketComment {
          ...CustomerTicketComment
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${CustomerTicketCommentFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketCommentUpdateGQL extends Apollo.Mutation<
  CustomerCustomerTicketCommentUpdateMutation,
  CustomerCustomerTicketCommentUpdateMutationVariables
> {
  override document = CustomerCustomerTicketCommentUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketCreateDocument = gql`
  mutation CustomerCustomerTicketCreate(
    $customerTicketInput: CustomerCustomerTicketInput!
    $attachments: [AttachmentInput!] = null
  ) {
    customerCustomerTicketCreate(customerTicketInput: $customerTicketInput, attachments: $attachments) {
      ... on CustomerCustomerTicketResponse {
        customerTicket {
          ...CustomerCustomerTicket
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${CustomerCustomerTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketCreateGQL extends Apollo.Mutation<
  CustomerCustomerTicketCreateMutation,
  CustomerCustomerTicketCreateMutationVariables
> {
  override document = CustomerCustomerTicketCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketDeleteDocument = gql`
  mutation CustomerCustomerTicketDelete($ticketId: ID!) {
    customerCustomerTicketDelete(ticketId: $ticketId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketDeleteGQL extends Apollo.Mutation<
  CustomerCustomerTicketDeleteMutation,
  CustomerCustomerTicketDeleteMutationVariables
> {
  override document = CustomerCustomerTicketDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketUpdateDocument = gql`
  mutation CustomerCustomerTicketUpdate(
    $ticketId: ID!
    $customerTicketUpdateInput: CustomerTicketUpdateInput!
    $attachments: [AttachmentInput!] = null
  ) {
    customerCustomerTicketUpdate(
      ticketId: $ticketId
      customerTicketUpdateInput: $customerTicketUpdateInput
      attachments: $attachments
    ) {
      ... on CustomerCustomerTicketResponse {
        customerTicket {
          ...CustomerCustomerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${CustomerCustomerTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketUpdateGQL extends Apollo.Mutation<
  CustomerCustomerTicketUpdateMutation,
  CustomerCustomerTicketUpdateMutationVariables
> {
  override document = CustomerCustomerTicketUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerUpdateDocument = gql`
  mutation CustomerCustomerUpdate($customerInput: CustomerUpdateInput!) {
    customerCustomerUpdate(customerInput: $customerInput) {
      ... on CustomerUpdateSuccess {
        customer {
          id
          name
          email
          phone
          companyId
          vatId
          street
          city
          zipCode
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerUpdateGQL extends Apollo.Mutation<
  CustomerCustomerUpdateMutation,
  CustomerCustomerUpdateMutationVariables
> {
  override document = CustomerCustomerUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDeviceMoveRegistrationDocument = gql`
  mutation CustomerDeviceMoveRegistration($customerDeviceMoveRegistrationInput: CustomerDeviceMoveRegistrationInput!) {
    customerDeviceMoveRegistration(customerDeviceMoveRegistrationInput: $customerDeviceMoveRegistrationInput) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDeviceMoveRegistrationGQL extends Apollo.Mutation<
  CustomerDeviceMoveRegistrationMutation,
  CustomerDeviceMoveRegistrationMutationVariables
> {
  override document = CustomerDeviceMoveRegistrationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDeviceRegisterDocument = gql`
  mutation CustomerDeviceRegister($customerDeviceRegisterInput: CustomerDeviceRegisterInput!) {
    customerDeviceRegister(customerDeviceRegisterInput: $customerDeviceRegisterInput) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDeviceRegisterGQL extends Apollo.Mutation<
  CustomerDeviceRegisterMutation,
  CustomerDeviceRegisterMutationVariables
> {
  override document = CustomerDeviceRegisterDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDeviceUnregisterDocument = gql`
  mutation CustomerDeviceUnregister($deviceId: ID!) {
    customerDeviceUnregister(deviceId: $deviceId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDeviceUnregisterGQL extends Apollo.Mutation<
  CustomerDeviceUnregisterMutation,
  CustomerDeviceUnregisterMutationVariables
> {
  override document = CustomerDeviceUnregisterDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerExportMetricsDocument = gql`
  mutation CustomerExportMetrics($deviceId: ID!, $date: Date!) {
    customerExportMetrics(deviceId: $deviceId, date: $date) {
      ... on PlcExportSuccessResponse {
        data
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerExportMetricsGQL extends Apollo.Mutation<
  CustomerExportMetricsMutation,
  CustomerExportMetricsMutationVariables
> {
  override document = CustomerExportMetricsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPaymentMethodCreateDocument = gql`
  mutation CustomerPaymentMethodCreate {
    customerPaymentMethodCreate {
      ... on CheckoutSession {
        sessionId
        publicKey
      }
      ... on BaseSuccessResponse {
        ok
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPaymentMethodCreateGQL extends Apollo.Mutation<
  CustomerPaymentMethodCreateMutation,
  CustomerPaymentMethodCreateMutationVariables
> {
  override document = CustomerPaymentMethodCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPlcEventMarkAsReadDocument = gql`
  mutation CustomerPlcEventMarkAsRead($plcEventId: ID!) {
    customerPlcEventMarkAsRead(plcEventId: $plcEventId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPlcEventMarkAsReadGQL extends Apollo.Mutation<
  CustomerPlcEventMarkAsReadMutation,
  CustomerPlcEventMarkAsReadMutationVariables
> {
  override document = CustomerPlcEventMarkAsReadDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPlcEventMarkAsUnreadDocument = gql`
  mutation CustomerPlcEventMarkAsUnread($plcEventId: ID!) {
    customerPlcEventMarkAsUnread(plcEventId: $plcEventId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPlcEventMarkAsUnreadGQL extends Apollo.Mutation<
  CustomerPlcEventMarkAsUnreadMutation,
  CustomerPlcEventMarkAsUnreadMutationVariables
> {
  override document = CustomerPlcEventMarkAsUnreadDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerRegistrationDocument = gql`
  mutation CustomerRegistration(
    $userRegistrationInput: UserRegistrationInput!
    $customerInput: CustomerRegistrationInput = null
  ) {
    customerRegistration(userRegistrationInput: $userRegistrationInput, customerInput: $customerInput) {
      ... on UserRegistrationSuccess {
        user {
          ...User
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerRegistrationGQL extends Apollo.Mutation<
  CustomerRegistrationMutation,
  CustomerRegistrationMutationVariables
> {
  override document = CustomerRegistrationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerSetTemperatureDocument = gql`
  mutation CustomerSetTemperature($deviceId: ID!, $temperatureOffset: Int!) {
    customerSetTemperature(deviceId: $deviceId, temperatureOffset: $temperatureOffset) {
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on CustomerSetTemperatureSuccessResponse {
        command {
          id
          status
        }
      }
      ... on InvalidDeviceResponse {
        deviceId
      }
      ... on InvalidTemperatureOffsetResponse {
        temperatureOffset
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerSetTemperatureGQL extends Apollo.Mutation<
  CustomerSetTemperatureMutation,
  CustomerSetTemperatureMutationVariables
> {
  override document = CustomerSetTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerStripeSubscriptionCancelDocument = gql`
  mutation CustomerStripeSubscriptionCancel($subscriptionId: ID!) {
    customerStripeSubscriptionCancel(subscriptionId: $subscriptionId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerStripeSubscriptionCancelGQL extends Apollo.Mutation<
  CustomerStripeSubscriptionCancelMutation,
  CustomerStripeSubscriptionCancelMutationVariables
> {
  override document = CustomerStripeSubscriptionCancelDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerStripeSubscriptionUpdateDocument = gql`
  mutation CustomerStripeSubscriptionUpdate($subscriptionId: ID!, $tariffId: ID!) {
    customerStripeSubscriptionUpdate(subscriptionId: $subscriptionId, tariffId: $tariffId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerStripeSubscriptionUpdateGQL extends Apollo.Mutation<
  CustomerStripeSubscriptionUpdateMutation,
  CustomerStripeSubscriptionUpdateMutationVariables
> {
  override document = CustomerStripeSubscriptionUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerUserDisableDocument = gql`
  mutation CustomerUserDisable($userId: ID!) {
    customerUserDisable(userId: $userId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerUserDisableGQL extends Apollo.Mutation<
  CustomerUserDisableMutation,
  CustomerUserDisableMutationVariables
> {
  override document = CustomerUserDisableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerUserEnableDocument = gql`
  mutation CustomerUserEnable($userId: ID!) {
    customerUserEnable(userId: $userId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerUserEnableGQL extends Apollo.Mutation<
  CustomerUserEnableMutation,
  CustomerUserEnableMutationVariables
> {
  override document = CustomerUserEnableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerUserInvitationDocument = gql`
  mutation CustomerUserInvitation($userInvitationInput: UserInvitationInput!) {
    customerUserInvitation(userInvitationInput: $userInvitationInput) {
      ... on UserInvitationSuccess {
        user {
          ...User
        }
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerUserInvitationGQL extends Apollo.Mutation<
  CustomerUserInvitationMutation,
  CustomerUserInvitationMutationVariables
> {
  override document = CustomerUserInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerUserUpdateDocument = gql`
  mutation CustomerUserUpdate($userId: ID!, $userInvitationInput: UserUpdateInput!) {
    customerUserUpdate(userId: $userId, userUpdateInput: $userInvitationInput) {
      ... on UserUpdateSuccess {
        user {
          ...User
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerUserUpdateGQL extends Apollo.Mutation<
  CustomerUserUpdateMutation,
  CustomerUserUpdateMutationVariables
> {
  override document = CustomerUserUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCheckoutSessionCreateDocument = gql`
  mutation InstallationCompanyCheckoutSessionCreate($tariffId: ID!) {
    installationCompanyCheckoutSessionCreate(tariffId: $tariffId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on CheckoutSession {
        sessionId
        publicKey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCheckoutSessionCreateGQL extends Apollo.Mutation<
  InstallationCompanyCheckoutSessionCreateMutation,
  InstallationCompanyCheckoutSessionCreateMutationVariables
> {
  override document = InstallationCompanyCheckoutSessionCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketAssignTechnicianDocument = gql`
  mutation InstallationCompanyCustomerTicketAssignTechnician($ticketId: ID!, $installationCompanyTechnicianId: ID) {
    installationCompanyCustomerTicketAssignTechnician(
      ticketId: $ticketId
      installationCompanyTechnicianId: $installationCompanyTechnicianId
    ) {
      ... on InstallationCompanyCustomerTicketResponse {
        customerTicket {
          id
          installationCompanyTechnician {
            id
            firstName
            lastName
          }
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketAssignTechnicianGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketAssignTechnicianMutation,
  InstallationCompanyCustomerTicketAssignTechnicianMutationVariables
> {
  override document = InstallationCompanyCustomerTicketAssignTechnicianDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketAssignDocument = gql`
  mutation InstallationCompanyCustomerTicketAssign($ticketId: ID!, $assignee: CustomerTicketAssigneeChoices!) {
    installationCompanyCustomerTicketAssign(ticketId: $ticketId, assignee: $assignee) {
      ... on InstallationCompanyCustomerTicketResponse {
        customerTicket {
          ...InstallationCompanyCustomerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${InstallationCompanyCustomerTicketFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketAssignGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketAssignMutation,
  InstallationCompanyCustomerTicketAssignMutationVariables
> {
  override document = InstallationCompanyCustomerTicketAssignDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketAttachmentDeleteDocument = gql`
  mutation InstallationCompanyCustomerTicketAttachmentDelete($customerTicketAttachmentId: ID!) {
    installationCompanyCustomerTicketAttachmentDelete(customerTicketAttachmentId: $customerTicketAttachmentId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketAttachmentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketAttachmentDeleteMutation,
  InstallationCompanyCustomerTicketAttachmentDeleteMutationVariables
> {
  override document = InstallationCompanyCustomerTicketAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketCloseDocument = gql`
  mutation InstallationCompanyCustomerTicketClose($ticketId: ID!) {
    installationCompanyCustomerTicketClose(ticketId: $ticketId) {
      ... on InstallationCompanyCustomerTicketResponse {
        customerTicket {
          ...InstallationCompanyCustomerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${InstallationCompanyCustomerTicketFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketCloseGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketCloseMutation,
  InstallationCompanyCustomerTicketCloseMutationVariables
> {
  override document = InstallationCompanyCustomerTicketCloseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketCommentAttachmentDeleteDocument = gql`
  mutation InstallationCompanyCustomerTicketCommentAttachmentDelete($customerTicketCommentAttachmentId: ID!) {
    installationCompanyCustomerTicketCommentAttachmentDelete(
      customerTicketCommentAttachmentId: $customerTicketCommentAttachmentId
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketCommentAttachmentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketCommentAttachmentDeleteMutation,
  InstallationCompanyCustomerTicketCommentAttachmentDeleteMutationVariables
> {
  override document = InstallationCompanyCustomerTicketCommentAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketCommentCreateDocument = gql`
  mutation InstallationCompanyCustomerTicketCommentCreate(
    $customerTicketCommentInput: CustomerTicketCommentInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyCustomerTicketCommentCreate(
      customerTicketCommentInput: $customerTicketCommentInput
      attachments: $attachments
    ) {
      ... on CustomerTicketCommentResponse {
        customerTicketComment {
          ...CustomerTicketComment
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${CustomerTicketCommentFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketCommentCreateGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketCommentCreateMutation,
  InstallationCompanyCustomerTicketCommentCreateMutationVariables
> {
  override document = InstallationCompanyCustomerTicketCommentCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketCommentDeleteDocument = gql`
  mutation InstallationCompanyCustomerTicketCommentDelete($customerCommentId: ID!) {
    installationCompanyCustomerTicketCommentDelete(customerCommentId: $customerCommentId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketCommentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketCommentDeleteMutation,
  InstallationCompanyCustomerTicketCommentDeleteMutationVariables
> {
  override document = InstallationCompanyCustomerTicketCommentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketCommentUpdateDocument = gql`
  mutation InstallationCompanyCustomerTicketCommentUpdate(
    $customerCommentId: ID!
    $customerTicketCommentInput: CustomerTicketCommentUpdateInput!
  ) {
    installationCompanyCustomerTicketCommentUpdate(
      customerCommentId: $customerCommentId
      customerTicketCommentInput: $customerTicketCommentInput
    ) {
      ... on CustomerTicketCommentResponse {
        customerTicketComment {
          ...CustomerTicketComment
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${CustomerTicketCommentFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketCommentUpdateGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketCommentUpdateMutation,
  InstallationCompanyCustomerTicketCommentUpdateMutationVariables
> {
  override document = InstallationCompanyCustomerTicketCommentUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketCreateDocument = gql`
  mutation InstallationCompanyCustomerTicketCreate(
    $customerTicketInput: InstallationCompanyCustomerTicketInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyCustomerTicketCreate(customerTicketInput: $customerTicketInput, attachments: $attachments) {
      ... on InstallationCompanyCustomerTicketResponse {
        customerTicket {
          ...InstallationCompanyCustomerTicket
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${InstallationCompanyCustomerTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketCreateGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketCreateMutation,
  InstallationCompanyCustomerTicketCreateMutationVariables
> {
  override document = InstallationCompanyCustomerTicketCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketDeleteDocument = gql`
  mutation InstallationCompanyCustomerTicketDelete($ticketId: ID!) {
    installationCompanyCustomerTicketDelete(ticketId: $ticketId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketDeleteGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketDeleteMutation,
  InstallationCompanyCustomerTicketDeleteMutationVariables
> {
  override document = InstallationCompanyCustomerTicketDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketUpdateDocument = gql`
  mutation InstallationCompanyCustomerTicketUpdate(
    $ticketId: ID!
    $customerTicketUpdateInput: CustomerTicketUpdateInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyCustomerTicketUpdate(
      ticketId: $ticketId
      customerTicketUpdateInput: $customerTicketUpdateInput
      attachments: $attachments
    ) {
      ... on InstallationCompanyCustomerTicketResponse {
        customerTicket {
          ...InstallationCompanyCustomerTicket
        }
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${InstallationCompanyCustomerTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketUpdateGQL extends Apollo.Mutation<
  InstallationCompanyCustomerTicketUpdateMutation,
  InstallationCompanyCustomerTicketUpdateMutationVariables
> {
  override document = InstallationCompanyCustomerTicketUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceMoveRegistrationDocument = gql`
  mutation InstallationCompanyDeviceMoveRegistration(
    $customerDeviceMoveRegistrationInput: InstallationCompanyMoveRegistrationInput!
  ) {
    installationCompanyDeviceMoveRegistration(
      customerDeviceMoveRegistrationInput: $customerDeviceMoveRegistrationInput
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceMoveRegistrationGQL extends Apollo.Mutation<
  InstallationCompanyDeviceMoveRegistrationMutation,
  InstallationCompanyDeviceMoveRegistrationMutationVariables
> {
  override document = InstallationCompanyDeviceMoveRegistrationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceRegisterNewCustomerDocument = gql`
  mutation InstallationCompanyDeviceRegisterNewCustomer(
    $customerInput: InstallationCompanyCustomerInput!
    $customerSiteInput: InstallationCompanyCustomerSiteInput!
    $deviceRegisterInput: DeviceRegisterInput
    $sendInvitation: Boolean!
  ) {
    installationCompanyDeviceRegisterNewCustomer(
      customerInput: $customerInput
      customerSiteInput: $customerSiteInput
      deviceRegisterInput: $deviceRegisterInput
      sendInvitation: $sendInvitation
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceRegisterNewCustomerGQL extends Apollo.Mutation<
  InstallationCompanyDeviceRegisterNewCustomerMutation,
  InstallationCompanyDeviceRegisterNewCustomerMutationVariables
> {
  override document = InstallationCompanyDeviceRegisterNewCustomerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceRegisterNewSiteDocument = gql`
  mutation InstallationCompanyDeviceRegisterNewSite(
    $existingCustomerInput: InstallationCompanyExistingCustomerInput!
    $customerSiteInput: InstallationCompanyCustomerSiteInput!
    $deviceRegisterInput: DeviceRegisterInput
  ) {
    installationCompanyDeviceRegisterNewSite(
      existingCustomerInput: $existingCustomerInput
      customerSiteInput: $customerSiteInput
      deviceRegisterInput: $deviceRegisterInput
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceRegisterNewSiteGQL extends Apollo.Mutation<
  InstallationCompanyDeviceRegisterNewSiteMutation,
  InstallationCompanyDeviceRegisterNewSiteMutationVariables
> {
  override document = InstallationCompanyDeviceRegisterNewSiteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceRegisterDocument = gql`
  mutation InstallationCompanyDeviceRegister(
    $existingCustomerSiteInput: InstallationCompanyExistingCustomerSiteInput!
    $deviceRegisterInput: DeviceRegisterInput!
  ) {
    installationCompanyDeviceRegister(
      existingCustomerSiteInput: $existingCustomerSiteInput
      deviceRegisterInput: $deviceRegisterInput
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceRegisterGQL extends Apollo.Mutation<
  InstallationCompanyDeviceRegisterMutation,
  InstallationCompanyDeviceRegisterMutationVariables
> {
  override document = InstallationCompanyDeviceRegisterDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceUnregisterDocument = gql`
  mutation InstallationCompanyDeviceUnregister($deviceId: ID!) {
    installationCompanyDeviceUnregister(deviceId: $deviceId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceUnregisterGQL extends Apollo.Mutation<
  InstallationCompanyDeviceUnregisterMutation,
  InstallationCompanyDeviceUnregisterMutationVariables
> {
  override document = InstallationCompanyDeviceUnregisterDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyExportMetricsDocument = gql`
  mutation InstallationCompanyExportMetrics($deviceId: ID!, $date: Date!) {
    installationCompanyExportMetrics(deviceId: $deviceId, date: $date) {
      ... on PlcExportSuccessResponse {
        data
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyExportMetricsGQL extends Apollo.Mutation<
  InstallationCompanyExportMetricsMutation,
  InstallationCompanyExportMetricsMutationVariables
> {
  override document = InstallationCompanyExportMetricsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationAttachmentCreateDocument = gql`
  mutation InstallationCompanyInstallationAttachmentCreate(
    $installationAttachmentInputs: [InstallationAttachmentInput!]!
  ) {
    installationCompanyInstallationAttachmentCreate(installationAttachmentInputs: $installationAttachmentInputs) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationAttachmentCreateGQL extends Apollo.Mutation<
  InstallationCompanyInstallationAttachmentCreateMutation,
  InstallationCompanyInstallationAttachmentCreateMutationVariables
> {
  override document = InstallationCompanyInstallationAttachmentCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationAttachmentDeleteDocument = gql`
  mutation InstallationCompanyInstallationAttachmentDelete($installationAttachmentId: ID!) {
    installationCompanyInstallationAttachmentDelete(installationAttachmentId: $installationAttachmentId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationAttachmentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyInstallationAttachmentDeleteMutation,
  InstallationCompanyInstallationAttachmentDeleteMutationVariables
> {
  override document = InstallationCompanyInstallationAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyConfigurationDocument = gql`
  mutation InstallationCompanyInstallationCompanyConfiguration(
    $installationCompanyConfigurationInput: InstallationCompanyConfigurationInput!
  ) {
    installationCompanyInstallationCompanyConfiguration(
      installationCompanyConfigurationInput: $installationCompanyConfigurationInput
    ) {
      ... on InstallationCompanyConfigurationType {
        ticketsAllowed
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyConfigurationGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyConfigurationMutation,
  InstallationCompanyInstallationCompanyConfigurationMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyConfigurationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerDeleteDocument = gql`
  mutation InstallationCompanyInstallationCompanyCustomerDelete($customerId: ID!) {
    installationCompanyInstallationCompanyCustomerDelete(customerId: $customerId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerDeleteGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyCustomerDeleteMutation,
  InstallationCompanyInstallationCompanyCustomerDeleteMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerInvitationDocument = gql`
  mutation InstallationCompanyInstallationCompanyCustomerInvitation($installationCompanyCustomerId: ID!) {
    installationCompanyInstallationCompanyCustomerInvitation(
      installationCompanyCustomerId: $installationCompanyCustomerId
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerInvitationGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyCustomerInvitationMutation,
  InstallationCompanyInstallationCompanyCustomerInvitationMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerSiteDeleteDocument = gql`
  mutation InstallationCompanyInstallationCompanyCustomerSiteDelete($customerSiteId: ID!) {
    installationCompanyInstallationCompanyCustomerSiteDelete(customerSiteId: $customerSiteId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerSiteDeleteGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyCustomerSiteDeleteMutation,
  InstallationCompanyInstallationCompanyCustomerSiteDeleteMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerSiteDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerSiteUpdateDocument = gql`
  mutation InstallationCompanyInstallationCompanyCustomerSiteUpdate(
    $customerSiteId: ID!
    $customerSiteInput: InstallationCompanyCustomerSiteInput!
  ) {
    installationCompanyInstallationCompanyCustomerSiteUpdate(
      customerSiteId: $customerSiteId
      customerSiteInput: $customerSiteInput
    ) {
      ... on InstallationCompanyCustomerSiteUpdateSuccess {
        installationCompanyCustomerSite {
          ...InstallationCompanyInstallationCompanyCustomerSiteUpdate
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${InstallationCompanyInstallationCompanyCustomerSiteUpdateFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerSiteUpdateGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyCustomerSiteUpdateMutation,
  InstallationCompanyInstallationCompanyCustomerSiteUpdateMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerSiteUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerUpdateDocument = gql`
  mutation InstallationCompanyInstallationCompanyCustomerUpdate(
    $customerId: ID!
    $customerInput: InstallationCompanyCustomerInput!
  ) {
    installationCompanyInstallationCompanyCustomerUpdate(customerId: $customerId, customerInput: $customerInput) {
      ... on InstallationCompanyCustomerUpdateSuccess {
        installationCompanyCustomer {
          ...InstallationCompanyInstallationCompanyCustomerUpdate
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${InstallationCompanyInstallationCompanyCustomerUpdateFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerUpdateGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyCustomerUpdateMutation,
  InstallationCompanyInstallationCompanyCustomerUpdateMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketAssignTechnicianDocument = gql`
  mutation InstallationCompanyInstallationCompanyTicketAssignTechnician(
    $ticketId: ID!
    $installationCompanyTechnicianId: ID
  ) {
    installationCompanyInstallationCompanyTicketAssignTechnician(
      ticketId: $ticketId
      installationCompanyTechnicianId: $installationCompanyTechnicianId
    ) {
      ... on InstallationCompanyTicketResponse {
        installationCompanyTicket {
          id
          installationCompanyTechnician {
            id
            firstName
            lastName
          }
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketAssignTechnicianGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyTicketAssignTechnicianMutation,
  InstallationCompanyInstallationCompanyTicketAssignTechnicianMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketAssignTechnicianDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketAttachmentDeleteDocument = gql`
  mutation InstallationCompanyInstallationCompanyTicketAttachmentDelete($installationCompanyTicketAttachmentId: ID!) {
    installationCompanyInstallationCompanyTicketAttachmentDelete(
      installationCompanyTicketAttachmentId: $installationCompanyTicketAttachmentId
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketAttachmentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyTicketAttachmentDeleteMutation,
  InstallationCompanyInstallationCompanyTicketAttachmentDeleteMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketCloseDocument = gql`
  mutation installationCompanyInstallationCompanyTicketClose($ticketId: ID!) {
    installationCompanyInstallationCompanyTicketClose(ticketId: $ticketId) {
      ... on InstallationCompanyTicketResponse {
        installationCompanyTicket {
          ...InstallationCompanyTicket
        }
      }
    }
  }
  ${InstallationCompanyTicketFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketCloseGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyTicketCloseMutation,
  InstallationCompanyInstallationCompanyTicketCloseMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketCloseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketCommentAttachmentDeleteDocument = gql`
  mutation InstallationCompanyInstallationCompanyTicketCommentAttachmentDelete(
    $installationCompanyTicketCommentAttachmentId: ID!
  ) {
    installationCompanyInstallationCompanyTicketCommentAttachmentDelete(
      installationCompanyTicketCommentAttachmentId: $installationCompanyTicketCommentAttachmentId
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketCommentAttachmentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyTicketCommentAttachmentDeleteMutation,
  InstallationCompanyInstallationCompanyTicketCommentAttachmentDeleteMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketCommentAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketCommentCreateDocument = gql`
  mutation InstallationCompanyInstallationCompanyTicketCommentCreate(
    $installationCompanyTicketCommentInput: InstallationCompanyTicketCommentInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyInstallationCompanyTicketCommentCreate(
      installationCompanyTicketCommentInput: $installationCompanyTicketCommentInput
      attachments: $attachments
    ) {
      ... on InstallationCompanyTicketCommentResponse {
        installationCompanyTicketComment {
          created
          modified
          createdBy {
            ...UserSelectItem
          }
          id
          comment
          attachments {
            ...InstallationCompanyTicketCommentAttachment
          }
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserSelectItemFragmentDoc}
  ${InstallationCompanyTicketCommentAttachmentFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketCommentCreateGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyTicketCommentCreateMutation,
  InstallationCompanyInstallationCompanyTicketCommentCreateMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketCommentCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketCommentDeleteDocument = gql`
  mutation InstallationCompanyInstallationCompanyTicketCommentDelete($installationCompanyTicketCommentId: ID!) {
    installationCompanyInstallationCompanyTicketCommentDelete(
      installationCompanyTicketCommentId: $installationCompanyTicketCommentId
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketCommentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyTicketCommentDeleteMutation,
  InstallationCompanyInstallationCompanyTicketCommentDeleteMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketCommentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketCreateDocument = gql`
  mutation InstallationCompanyInstallationCompanyTicketCreate(
    $installationCompanyTicketInput: InstallationCompanyTicketInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyInstallationCompanyTicketCreate(
      installationCompanyTicketInput: $installationCompanyTicketInput
      attachments: $attachments
    ) {
      ... on InstallationCompanyTicketResponse {
        installationCompanyTicket {
          ...InstallationCompanyTicket
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${InstallationCompanyTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketCreateGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyTicketCreateMutation,
  InstallationCompanyInstallationCompanyTicketCreateMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketDeleteDocument = gql`
  mutation InstallationCompanyInstallationCompanyTicketDelete($ticketId: ID!) {
    installationCompanyInstallationCompanyTicketDelete(ticketId: $ticketId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketDeleteGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyTicketDeleteMutation,
  InstallationCompanyInstallationCompanyTicketDeleteMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketUpdateDocument = gql`
  mutation InstallationCompanyInstallationCompanyTicketUpdate(
    $ticketId: ID!
    $installationCompanyTicketUpdateInput: InstallationCompanyTicketUpdateInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyInstallationCompanyTicketUpdate(
      ticketId: $ticketId
      installationCompanyTicketUpdateInput: $installationCompanyTicketUpdateInput
      attachments: $attachments
    ) {
      ... on InstallationCompanyTicketResponse {
        installationCompanyTicket {
          ...InstallationCompanyTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${InstallationCompanyTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketUpdateGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyTicketUpdateMutation,
  InstallationCompanyInstallationCompanyTicketUpdateMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyUpdateDocument = gql`
  mutation InstallationCompanyInstallationCompanyUpdate(
    $installationCompanyInput: InstallationCompanyRegistrationInput!
  ) {
    installationCompanyInstallationCompanyUpdate(installationCompanyInput: $installationCompanyInput) {
      ... on InstallationCompanyUpdateSuccess {
        installationCompany {
          id
          name
          email
          phone
          companyId
          vatId
          street
          city
          zipCode
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyUpdateGQL extends Apollo.Mutation<
  InstallationCompanyInstallationCompanyUpdateMutation,
  InstallationCompanyInstallationCompanyUpdateMutationVariables
> {
  override document = InstallationCompanyInstallationCompanyUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationNoteAttachmentDeleteDocument = gql`
  mutation InstallationCompanyInstallationNoteAttachmentDelete($installationNoteAttachmentId: ID!) {
    installationCompanyInstallationNoteAttachmentDelete(installationNoteAttachmentId: $installationNoteAttachmentId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationNoteAttachmentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyInstallationNoteAttachmentDeleteMutation,
  InstallationCompanyInstallationNoteAttachmentDeleteMutationVariables
> {
  override document = InstallationCompanyInstallationNoteAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationNoteCreateDocument = gql`
  mutation InstallationCompanyInstallationNoteCreate(
    $installationNoteInput: InstallationNoteInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyInstallationNoteCreate(
      installationNoteInput: $installationNoteInput
      attachments: $attachments
    ) {
      ... on InstallationNoteResponse {
        installationNote {
          ...InstallationCompanyInstallationNoteCreate
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${InstallationCompanyInstallationNoteCreateFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationNoteCreateGQL extends Apollo.Mutation<
  InstallationCompanyInstallationNoteCreateMutation,
  InstallationCompanyInstallationNoteCreateMutationVariables
> {
  override document = InstallationCompanyInstallationNoteCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationNoteDeleteDocument = gql`
  mutation InstallationCompanyInstallationNoteDelete($installationNoteId: ID!) {
    installationCompanyInstallationNoteDelete(installationNoteId: $installationNoteId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationNoteDeleteGQL extends Apollo.Mutation<
  InstallationCompanyInstallationNoteDeleteMutation,
  InstallationCompanyInstallationNoteDeleteMutationVariables
> {
  override document = InstallationCompanyInstallationNoteDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationNoteUpdateDocument = gql`
  mutation InstallationCompanyInstallationNoteUpdate(
    $installationNoteId: ID!
    $installationNoteInput: InstallationNoteUpdateInput!
  ) {
    installationCompanyInstallationNoteUpdate(
      installationNoteId: $installationNoteId
      installationNoteInput: $installationNoteInput
    ) {
      ... on InstallationNoteResponse {
        installationNote {
          id
          note
          modified
        }
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationNoteUpdateGQL extends Apollo.Mutation<
  InstallationCompanyInstallationNoteUpdateMutation,
  InstallationCompanyInstallationNoteUpdateMutationVariables
> {
  override document = InstallationCompanyInstallationNoteUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketAssignTechnicianDocument = gql`
  mutation InstallationCompanyManufacturerTicketAssignTechnician($ticketId: ID!, $installationCompanyTechnicianId: ID) {
    installationCompanyManufacturerTicketAssignTechnician(
      ticketId: $ticketId
      installationCompanyTechnicianId: $installationCompanyTechnicianId
    ) {
      ... on InstallationCompanyManufacturerTicketResponse {
        manufacturerTicket {
          id
          installationCompanyTechnician {
            id
            firstName
            lastName
          }
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketAssignTechnicianGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketAssignTechnicianMutation,
  InstallationCompanyManufacturerTicketAssignTechnicianMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketAssignTechnicianDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketAssignDocument = gql`
  mutation InstallationCompanyManufacturerTicketAssign($ticketId: ID!, $assignee: ManufacturerTicketAssigneeChoices!) {
    installationCompanyManufacturerTicketAssign(ticketId: $ticketId, assignee: $assignee) {
      ... on InstallationCompanyManufacturerTicketResponse {
        manufacturerTicket {
          ...InstallationCompanyManufacturerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${InstallationCompanyManufacturerTicketFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketAssignGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketAssignMutation,
  InstallationCompanyManufacturerTicketAssignMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketAssignDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketAttachmentDeleteDocument = gql`
  mutation InstallationCompanyManufacturerTicketAttachmentDelete($manufacturerTicketAttachmentId: ID!) {
    installationCompanyManufacturerTicketAttachmentDelete(
      manufacturerTicketAttachmentId: $manufacturerTicketAttachmentId
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketAttachmentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketAttachmentDeleteMutation,
  InstallationCompanyManufacturerTicketAttachmentDeleteMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketCloseDocument = gql`
  mutation InstallationCompanyManufacturerTicketClose($ticketId: ID!) {
    installationCompanyManufacturerTicketClose(ticketId: $ticketId) {
      ... on InstallationCompanyManufacturerTicketResponse {
        manufacturerTicket {
          ...InstallationCompanyManufacturerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${InstallationCompanyManufacturerTicketFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketCloseGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketCloseMutation,
  InstallationCompanyManufacturerTicketCloseMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketCloseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketCommentAttachmentDeleteDocument = gql`
  mutation InstallationCompanyManufacturerTicketCommentAttachmentDelete($manufacturerTicketCommentAttachmentId: ID!) {
    installationCompanyManufacturerTicketCommentAttachmentDelete(
      manufacturerTicketCommentAttachmentId: $manufacturerTicketCommentAttachmentId
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketCommentAttachmentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketCommentAttachmentDeleteMutation,
  InstallationCompanyManufacturerTicketCommentAttachmentDeleteMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketCommentAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketCommentCreateDocument = gql`
  mutation InstallationCompanyManufacturerTicketCommentCreate(
    $manufacturerTicketCommentInput: ManufacturerTicketCommentInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyManufacturerTicketCommentCreate(
      manufacturerTicketCommentInput: $manufacturerTicketCommentInput
      attachments: $attachments
    ) {
      ... on ManufacturerTicketCommentResponse {
        manufacturerTicketComment {
          ...ManufacturerTicketComment
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${ManufacturerTicketCommentFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketCommentCreateGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketCommentCreateMutation,
  InstallationCompanyManufacturerTicketCommentCreateMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketCommentCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketCommentDeleteDocument = gql`
  mutation InstallationCompanyManufacturerTicketCommentDelete($manufacturerTicketCommentId: ID!) {
    installationCompanyManufacturerTicketCommentDelete(manufacturerTicketCommentId: $manufacturerTicketCommentId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketCommentDeleteGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketCommentDeleteMutation,
  InstallationCompanyManufacturerTicketCommentDeleteMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketCommentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketCommentUpdateDocument = gql`
  mutation InstallationCompanyManufacturerTicketCommentUpdate(
    $manufacturerTicketCommentId: ID!
    $manufacturerTicketCommentInput: ManufacturerTicketCommentUpdateInput!
  ) {
    installationCompanyManufacturerTicketCommentUpdate(
      manufacturerTicketCommentId: $manufacturerTicketCommentId
      manufacturerTicketCommentInput: $manufacturerTicketCommentInput
    ) {
      ... on ManufacturerTicketCommentResponse {
        manufacturerTicketComment {
          ...ManufacturerTicketComment
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${ManufacturerTicketCommentFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketCommentUpdateGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketCommentUpdateMutation,
  InstallationCompanyManufacturerTicketCommentUpdateMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketCommentUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketCreateDocument = gql`
  mutation InstallationCompanyManufacturerTicketCreate(
    $installationCompanyManufacturerTicketInput: InstallationCompanyManufacturerTicketInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyManufacturerTicketCreate(
      installationCompanyManufacturerTicketInput: $installationCompanyManufacturerTicketInput
      attachments: $attachments
    ) {
      ... on InstallationCompanyManufacturerTicketResponse {
        manufacturerTicket {
          ...InstallationCompanyManufacturerTicket
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${InstallationCompanyManufacturerTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketCreateGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketCreateMutation,
  InstallationCompanyManufacturerTicketCreateMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketDeleteDocument = gql`
  mutation InstallationCompanyManufacturerTicketDelete($ticketId: ID!) {
    installationCompanyManufacturerTicketDelete(ticketId: $ticketId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketDeleteGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketDeleteMutation,
  InstallationCompanyManufacturerTicketDeleteMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketUpdateDocument = gql`
  mutation InstallationCompanyManufacturerTicketUpdate(
    $ticketId: ID!
    $manufacturerTicketUpdateInput: ManufacturerTicketUpdateInput!
    $attachments: [AttachmentInput!] = null
  ) {
    installationCompanyManufacturerTicketUpdate(
      ticketId: $ticketId
      manufacturerTicketUpdateInput: $manufacturerTicketUpdateInput
      attachments: $attachments
    ) {
      ... on InstallationCompanyManufacturerTicketResponse {
        manufacturerTicket {
          ...InstallationCompanyManufacturerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${InstallationCompanyManufacturerTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketUpdateGQL extends Apollo.Mutation<
  InstallationCompanyManufacturerTicketUpdateMutation,
  InstallationCompanyManufacturerTicketUpdateMutationVariables
> {
  override document = InstallationCompanyManufacturerTicketUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyPlcEventMarkAsReadDocument = gql`
  mutation InstallationCompanyPlcEventMarkAsRead($plcEventId: ID!) {
    installationCompanyPlcEventMarkAsRead(plcEventId: $plcEventId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyPlcEventMarkAsReadGQL extends Apollo.Mutation<
  InstallationCompanyPlcEventMarkAsReadMutation,
  InstallationCompanyPlcEventMarkAsReadMutationVariables
> {
  override document = InstallationCompanyPlcEventMarkAsReadDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyPlcEventMarkAsUnreadDocument = gql`
  mutation InstallationCompanyPlcEventMarkAsUnread($plcEventId: ID!) {
    installationCompanyPlcEventMarkAsUnread(plcEventId: $plcEventId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyPlcEventMarkAsUnreadGQL extends Apollo.Mutation<
  InstallationCompanyPlcEventMarkAsUnreadMutation,
  InstallationCompanyPlcEventMarkAsUnreadMutationVariables
> {
  override document = InstallationCompanyPlcEventMarkAsUnreadDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyRegistrationDocument = gql`
  mutation InstallationCompanyRegistration(
    $userRegistrationInput: UserRegistrationInput!
    $installationCompanyInput: InstallationCompanyRegistrationInput!
  ) {
    installationCompanyRegistration(
      userRegistrationInput: $userRegistrationInput
      installationCompanyInput: $installationCompanyInput
    ) {
      ... on UserRegistrationSuccess {
        user {
          ...User
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyRegistrationGQL extends Apollo.Mutation<
  InstallationCompanyRegistrationMutation,
  InstallationCompanyRegistrationMutationVariables
> {
  override document = InstallationCompanyRegistrationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanySendCommandDocument = gql`
  mutation InstallationCompanySendCommand($deviceId: ID!, $command: String!) {
    installationCompanySendCommand(deviceId: $deviceId, command: $command) {
      ... on InstallationCompanySendCommandSuccessResponse {
        command {
          id
          uid
          command
          created
          responseReceived
          status
          response
          createdBy {
            id
            firstName
            lastName
          }
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanySendCommandGQL extends Apollo.Mutation<
  InstallationCompanySendCommandMutation,
  InstallationCompanySendCommandMutationVariables
> {
  override document = InstallationCompanySendCommandDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyStripeSubscriptionCancelDocument = gql`
  mutation InstallationCompanyStripeSubscriptionCancel($subscriptionId: ID!) {
    installationCompanyStripeSubscriptionCancel(subscriptionId: $subscriptionId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyStripeSubscriptionCancelGQL extends Apollo.Mutation<
  InstallationCompanyStripeSubscriptionCancelMutation,
  InstallationCompanyStripeSubscriptionCancelMutationVariables
> {
  override document = InstallationCompanyStripeSubscriptionCancelDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyStripeSubscriptionUpdateDocument = gql`
  mutation InstallationCompanyStripeSubscriptionUpdate($subscriptionId: ID!, $tariffId: ID!) {
    installationCompanyStripeSubscriptionUpdate(subscriptionId: $subscriptionId, tariffId: $tariffId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyStripeSubscriptionUpdateGQL extends Apollo.Mutation<
  InstallationCompanyStripeSubscriptionUpdateMutation,
  InstallationCompanyStripeSubscriptionUpdateMutationVariables
> {
  override document = InstallationCompanyStripeSubscriptionUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyUserDisableDocument = gql`
  mutation InstallationCompanyUserDisable($userId: ID!) {
    installationCompanyUserDisable(userId: $userId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyUserDisableGQL extends Apollo.Mutation<
  InstallationCompanyUserDisableMutation,
  InstallationCompanyUserDisableMutationVariables
> {
  override document = InstallationCompanyUserDisableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyUserEnableDocument = gql`
  mutation InstallationCompanyUserEnable($userId: ID!) {
    installationCompanyUserEnable(userId: $userId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyUserEnableGQL extends Apollo.Mutation<
  InstallationCompanyUserEnableMutation,
  InstallationCompanyUserEnableMutationVariables
> {
  override document = InstallationCompanyUserEnableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyUserInvitationDocument = gql`
  mutation InstallationCompanyUserInvitation($userInvitationInput: UserInvitationInput!) {
    installationCompanyUserInvitation(userInvitationInput: $userInvitationInput) {
      ... on UserInvitationSuccess {
        user {
          ...User
        }
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyUserInvitationGQL extends Apollo.Mutation<
  InstallationCompanyUserInvitationMutation,
  InstallationCompanyUserInvitationMutationVariables
> {
  override document = InstallationCompanyUserInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyUserUpdateDocument = gql`
  mutation InstallationCompanyUserUpdate($userId: ID!, $userInvitationInput: UserUpdateInput!) {
    installationCompanyUserUpdate(userId: $userId, userUpdateInput: $userInvitationInput) {
      ... on UserUpdateSuccess {
        user {
          ...User
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyUserUpdateGQL extends Apollo.Mutation<
  InstallationCompanyUserUpdateMutation,
  InstallationCompanyUserUpdateMutationVariables
> {
  override document = InstallationCompanyUserUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceCreateDocument = gql`
  mutation ManufacturerDeviceCreate($deviceCreateInput: DeviceCreateInput!) {
    manufacturerDeviceCreate(deviceCreateInput: $deviceCreateInput) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceCreateGQL extends Apollo.Mutation<
  ManufacturerDeviceCreateMutation,
  ManufacturerDeviceCreateMutationVariables
> {
  override document = ManufacturerDeviceCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceDeleteDocument = gql`
  mutation ManufacturerDeviceDelete($deviceId: ID!) {
    manufacturerDeviceDelete(deviceId: $deviceId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceDeleteGQL extends Apollo.Mutation<
  ManufacturerDeviceDeleteMutation,
  ManufacturerDeviceDeleteMutationVariables
> {
  override document = ManufacturerDeviceDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceEventTypeDeleteDocument = gql`
  mutation ManufacturerDeviceEventTypeDelete($deviceEventTypeId: ID!) {
    manufacturerDeviceEventTypeDelete(deviceEventTypeId: $deviceEventTypeId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceEventTypeDeleteGQL extends Apollo.Mutation<
  ManufacturerDeviceEventTypeDeleteMutation,
  ManufacturerDeviceEventTypeDeleteMutationVariables
> {
  override document = ManufacturerDeviceEventTypeDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceEventTypeUpsertDocument = gql`
  mutation ManufacturerDeviceEventTypeUpsert(
    $input: DeviceEventTypeInput!
    $deviceTypeId: ID!
    $deviceEventTypeId: ID = null
  ) {
    manufacturerDeviceEventTypeUpsert(
      input: $input
      deviceTypeId: $deviceTypeId
      deviceEventTypeId: $deviceEventTypeId
    ) {
      ... on DeviceEventTypeUpsertSuccess {
        deviceEventType {
          id
          uid
          priority
          descriptionCs
          descriptionEn
          customerVisibility
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceEventTypeUpsertGQL extends Apollo.Mutation<
  ManufacturerDeviceEventTypeUpsertMutation,
  ManufacturerDeviceEventTypeUpsertMutationVariables
> {
  override document = ManufacturerDeviceEventTypeUpsertDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceExportDocument = gql`
  mutation ManufacturerDeviceExport($devices: [ID!]!) {
    manufacturerDeviceExport(devices: $devices) {
      data
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceExportGQL extends Apollo.Mutation<
  ManufacturerDeviceExportMutation,
  ManufacturerDeviceExportMutationVariables
> {
  override document = ManufacturerDeviceExportDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceTypeDeleteDocument = gql`
  mutation ManufacturerDeviceTypeDelete($deviceTypeId: ID!) {
    manufacturerDeviceTypeDelete(deviceTypeId: $deviceTypeId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ExistingDevicesResponse {
        ok
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceTypeDeleteGQL extends Apollo.Mutation<
  ManufacturerDeviceTypeDeleteMutation,
  ManufacturerDeviceTypeDeleteMutationVariables
> {
  override document = ManufacturerDeviceTypeDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceTypeUpsertDocument = gql`
  mutation ManufacturerDeviceTypeUpsert($deviceTypeInput: DeviceTypeInput!, $deviceTypeId: ID = null) {
    manufacturerDeviceTypeUpsert(deviceTypeInput: $deviceTypeInput, deviceTypeId: $deviceTypeId) {
      ... on DeviceTypeUpsertSuccess {
        deviceType {
          ...DeviceTypeExtended
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${DeviceTypeExtendedFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceTypeUpsertGQL extends Apollo.Mutation<
  ManufacturerDeviceTypeUpsertMutation,
  ManufacturerDeviceTypeUpsertMutationVariables
> {
  override document = ManufacturerDeviceTypeUpsertDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceUpdateDocument = gql`
  mutation ManufacturerDeviceUpdate($deviceId: ID!, $deviceInput: DeviceInput!) {
    manufacturerDeviceUpdate(deviceId: $deviceId, deviceInput: $deviceInput) {
      ... on ManufacturerUpdateDeviceSuccess {
        device {
          ...ManufacturerDeviceExtended
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${ManufacturerDeviceExtendedFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceUpdateGQL extends Apollo.Mutation<
  ManufacturerDeviceUpdateMutation,
  ManufacturerDeviceUpdateMutationVariables
> {
  override document = ManufacturerDeviceUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketAssignDocument = gql`
  mutation ManufacturerManufacturerTicketAssign($ticketId: ID!, $assignee: ManufacturerTicketAssigneeChoices!) {
    manufacturerManufacturerTicketAssign(ticketId: $ticketId, assignee: $assignee) {
      ... on ManufacturerManufacturerTicketResponse {
        manufacturerTicket {
          ...ManufacturerManufacturerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${ManufacturerManufacturerTicketFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketAssignGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketAssignMutation,
  ManufacturerManufacturerTicketAssignMutationVariables
> {
  override document = ManufacturerManufacturerTicketAssignDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketAttachmentDeleteDocument = gql`
  mutation ManufacturerManufacturerTicketAttachmentDelete($manufacturerTicketAttachmentId: ID!) {
    manufacturerManufacturerTicketAttachmentDelete(manufacturerTicketAttachmentId: $manufacturerTicketAttachmentId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketAttachmentDeleteGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketAttachmentDeleteMutation,
  ManufacturerManufacturerTicketAttachmentDeleteMutationVariables
> {
  override document = ManufacturerManufacturerTicketAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketCloseDocument = gql`
  mutation ManufacturerManufacturerTicketClose($ticketId: ID!) {
    manufacturerManufacturerTicketClose(ticketId: $ticketId) {
      ... on ManufacturerManufacturerTicketResponse {
        manufacturerTicket {
          ...ManufacturerManufacturerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${ManufacturerManufacturerTicketFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketCloseGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketCloseMutation,
  ManufacturerManufacturerTicketCloseMutationVariables
> {
  override document = ManufacturerManufacturerTicketCloseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketCommentAttachmentDeleteDocument = gql`
  mutation ManufacturerManufacturerTicketCommentAttachmentDelete($manufacturerTicketCommentAttachmentId: ID!) {
    manufacturerManufacturerTicketCommentAttachmentDelete(
      manufacturerTicketCommentAttachmentId: $manufacturerTicketCommentAttachmentId
    ) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketCommentAttachmentDeleteGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketCommentAttachmentDeleteMutation,
  ManufacturerManufacturerTicketCommentAttachmentDeleteMutationVariables
> {
  override document = ManufacturerManufacturerTicketCommentAttachmentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketCommentCreateDocument = gql`
  mutation ManufacturerManufacturerTicketCommentCreate(
    $manufacturerTicketCommentInput: ManufacturerTicketCommentInput!
    $attachments: [AttachmentInput!] = null
  ) {
    manufacturerManufacturerTicketCommentCreate(
      manufacturerTicketCommentInput: $manufacturerTicketCommentInput
      attachments: $attachments
    ) {
      ... on ManufacturerTicketCommentResponse {
        manufacturerTicketComment {
          ...ManufacturerTicketComment
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${ManufacturerTicketCommentFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketCommentCreateGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketCommentCreateMutation,
  ManufacturerManufacturerTicketCommentCreateMutationVariables
> {
  override document = ManufacturerManufacturerTicketCommentCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketCommentDeleteDocument = gql`
  mutation ManufacturerManufacturerTicketCommentDelete($manufacturerTicketCommentId: ID!) {
    manufacturerManufacturerTicketCommentDelete(manufacturerTicketCommentId: $manufacturerTicketCommentId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketCommentDeleteGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketCommentDeleteMutation,
  ManufacturerManufacturerTicketCommentDeleteMutationVariables
> {
  override document = ManufacturerManufacturerTicketCommentDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketCommentUpdateDocument = gql`
  mutation ManufacturerManufacturerTicketCommentUpdate(
    $manufacturerTicketCommentId: ID!
    $manufacturerTicketCommentInput: ManufacturerTicketCommentUpdateInput!
  ) {
    manufacturerManufacturerTicketCommentUpdate(
      manufacturerTicketCommentId: $manufacturerTicketCommentId
      manufacturerTicketCommentInput: $manufacturerTicketCommentInput
    ) {
      ... on ManufacturerTicketCommentResponse {
        manufacturerTicketComment {
          ...ManufacturerTicketComment
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${ManufacturerTicketCommentFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketCommentUpdateGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketCommentUpdateMutation,
  ManufacturerManufacturerTicketCommentUpdateMutationVariables
> {
  override document = ManufacturerManufacturerTicketCommentUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketCreateDocument = gql`
  mutation ManufacturerManufacturerTicketCreate(
    $manufacturerManufacturerTicketInput: ManufacturerManufacturerTicketInput!
    $attachments: [AttachmentInput!] = null
  ) {
    manufacturerManufacturerTicketCreate(
      manufacturerManufacturerTicketInput: $manufacturerManufacturerTicketInput
      attachments: $attachments
    ) {
      ... on ManufacturerManufacturerTicketResponse {
        manufacturerTicket {
          ...ManufacturerManufacturerTicket
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${ManufacturerManufacturerTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketCreateGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketCreateMutation,
  ManufacturerManufacturerTicketCreateMutationVariables
> {
  override document = ManufacturerManufacturerTicketCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketDeleteDocument = gql`
  mutation ManufacturerManufacturerTicketDelete($ticketId: ID!) {
    manufacturerManufacturerTicketDelete(ticketId: $ticketId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeDeletedResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketDeleteGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketDeleteMutation,
  ManufacturerManufacturerTicketDeleteMutationVariables
> {
  override document = ManufacturerManufacturerTicketDeleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketUpdateDocument = gql`
  mutation ManufacturerManufacturerTicketUpdate(
    $ticketId: ID!
    $manufacturerTicketUpdateInput: ManufacturerTicketUpdateInput!
    $attachments: [AttachmentInput!] = null
  ) {
    manufacturerManufacturerTicketUpdate(
      ticketId: $ticketId
      manufacturerTicketUpdateInput: $manufacturerTicketUpdateInput
      attachments: $attachments
    ) {
      ... on ManufacturerManufacturerTicketResponse {
        manufacturerTicket {
          ...ManufacturerManufacturerTicket
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on ObjectCannotBeModifiedResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${ManufacturerManufacturerTicketFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketUpdateGQL extends Apollo.Mutation<
  ManufacturerManufacturerTicketUpdateMutation,
  ManufacturerManufacturerTicketUpdateMutationVariables
> {
  override document = ManufacturerManufacturerTicketUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerUserDisableDocument = gql`
  mutation ManufacturerUserDisable($userId: ID!) {
    manufacturerUserDisable(userId: $userId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerUserDisableGQL extends Apollo.Mutation<
  ManufacturerUserDisableMutation,
  ManufacturerUserDisableMutationVariables
> {
  override document = ManufacturerUserDisableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerUserEnableDocument = gql`
  mutation ManufacturerUserEnable($userId: ID!) {
    manufacturerUserEnable(userId: $userId) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerUserEnableGQL extends Apollo.Mutation<
  ManufacturerUserEnableMutation,
  ManufacturerUserEnableMutationVariables
> {
  override document = ManufacturerUserEnableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerUserInvitationDocument = gql`
  mutation ManufacturerUserInvitation($userInvitationInput: UserInvitationInput!) {
    manufacturerUserInvitation(userInvitationInput: $userInvitationInput) {
      ... on UserInvitationSuccess {
        user {
          ...User
        }
      }
      ... on TariffRestrictionResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerUserInvitationGQL extends Apollo.Mutation<
  ManufacturerUserInvitationMutation,
  ManufacturerUserInvitationMutationVariables
> {
  override document = ManufacturerUserInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerUserUpdateDocument = gql`
  mutation ManufacturerUserUpdate($userId: ID!, $userInvitationInput: UserUpdateInput!) {
    manufacturerUserUpdate(userId: $userId, userUpdateInput: $userInvitationInput) {
      ... on UserUpdateSuccess {
        user {
          ...User
        }
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerUserUpdateGQL extends Apollo.Mutation<
  ManufacturerUserUpdateMutation,
  ManufacturerUserUpdateMutationVariables
> {
  override document = ManufacturerUserUpdateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerInviteRegistrationDocument = gql`
  mutation CustomerInviteRegistration(
    $userRegistrationInput: UserRegistrationInput!
    $customerInput: CustomerRegistrationInput = null
    $token: String!
  ) {
    customerInviteRegistration(
      userRegistrationInput: $userRegistrationInput
      customerInput: $customerInput
      token: $token
    ) {
      ... on TokenResponseSuccess {
        user {
          id
          userType
        }
        accessToken
      }
      ... on TokenExpiredResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerInviteRegistrationGQL extends Apollo.Mutation<
  CustomerInviteRegistrationMutation,
  CustomerInviteRegistrationMutationVariables
> {
  override document = CustomerInviteRegistrationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PreSignedUploadCreateDocument = gql`
  mutation PreSignedUploadCreate($files: [FileUploadInput!]!) {
    preSignedUploadCreate(files: $files) {
      ... on PreSignedUploads {
        preSignedUploads {
          ...PreSignedUpload
        }
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${PreSignedUploadFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PreSignedUploadCreateGQL extends Apollo.Mutation<
  PreSignedUploadCreateMutation,
  PreSignedUploadCreateMutationVariables
> {
  override document = PreSignedUploadCreateDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TokenObtainDocument = gql`
  mutation TokenObtain($input: TokenObtainInput!) {
    tokenObtain(input: $input) {
      ... on TokenResponseSuccess {
        user {
          ...User
        }
        accessToken
      }
      ... on InvalidCredentialsError {
        ok
      }
    }
  }
  ${UserFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class TokenObtainGQL extends Apollo.Mutation<TokenObtainMutation, TokenObtainMutationVariables> {
  override document = TokenObtainDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TokenRefreshDocument = gql`
  mutation TokenRefresh {
    tokenRefresh {
      ... on TokenResponseSuccess {
        user {
          ...User
        }
        accessToken
      }
      ... on InvalidRefreshToken {
        ok
      }
    }
  }
  ${UserFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class TokenRefreshGQL extends Apollo.Mutation<TokenRefreshMutation, TokenRefreshMutationVariables> {
  override document = TokenRefreshDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TokenRevokeDocument = gql`
  mutation TokenRevoke {
    tokenRevoke {
      ok
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TokenRevokeGQL extends Apollo.Mutation<TokenRevokeMutation, TokenRevokeMutationVariables> {
  override document = TokenRevokeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TranslatorTokenObtainDocument = gql`
  mutation TranslatorTokenObtain {
    translatorTokenObtain
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TranslatorTokenObtainGQL extends Apollo.Mutation<
  TranslatorTokenObtainMutation,
  TranslatorTokenObtainMutationVariables
> {
  override document = TranslatorTokenObtainDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UserActivationDocument = gql`
  mutation UserActivation($input: UserActivationInput!) {
    userActivation(userActivationInput: $input) {
      ... on TokenResponseSuccess {
        user {
          ...User
        }
        accessToken
      }
      ... on BaseSuccessResponse {
        ok
      }
    }
  }
  ${UserFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UserActivationGQL extends Apollo.Mutation<UserActivationMutation, UserActivationMutationVariables> {
  override document = UserActivationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UserChangePasswordDocument = gql`
  mutation UserChangePassword($input: UserChangePasswordInput!) {
    userChangePassword(userChangePasswordInput: $input) {
      ... on BaseSuccessResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UserChangePasswordGQL extends Apollo.Mutation<
  UserChangePasswordMutation,
  UserChangePasswordMutationVariables
> {
  override document = UserChangePasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UserForgottenPasswordDocument = gql`
  mutation UserForgottenPassword($email: String!) {
    userForgottenPassword(email: $email) {
      ok
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UserForgottenPasswordGQL extends Apollo.Mutation<
  UserForgottenPasswordMutation,
  UserForgottenPasswordMutationVariables
> {
  override document = UserForgottenPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UserSetLanguageDocument = gql`
  mutation UserSetLanguage($languageCode: UserLanguagesChoices!) {
    userSetLanguage(languageCode: $languageCode) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UserSetLanguageGQL extends Apollo.Mutation<UserSetLanguageMutation, UserSetLanguageMutationVariables> {
  override document = UserSetLanguageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UserSetPasswordDocument = gql`
  mutation UserSetPassword($userSetPasswordInput: UserSetPasswordInput!) {
    userSetPassword(userSetPasswordInput: $userSetPasswordInput) {
      ... on TokenResponseSuccess {
        user {
          ...User
        }
        accessToken
      }
      ... on BaseSuccessResponse {
        ok
      }
      ... on FormErrorResponse {
        errors {
          ...FormError
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${FormErrorFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UserSetPasswordGQL extends Apollo.Mutation<UserSetPasswordMutation, UserSetPasswordMutationVariables> {
  override document = UserSetPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UserVerifyTokenDocument = gql`
  mutation UserVerifyToken($input: TokenVerificationInput!) {
    userVerifyToken(input: $input) {
      ... on TokenVerificationSuccess {
        token
      }
      ... on TokenExpiredResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UserVerifyTokenGQL extends Apollo.Mutation<UserVerifyTokenMutation, UserVerifyTokenMutationVariables> {
  override document = UserVerifyTokenDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminCustomerDetailDocument = gql`
  query SystemAdminCustomerDetail($customerId: ID!) {
    systemAdminCustomerDetail(customerId: $customerId) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminCustomerDetailGQL extends Apollo.Query<
  SystemAdminCustomerDetailQuery,
  SystemAdminCustomerDetailQueryVariables
> {
  override document = SystemAdminCustomerDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminCustomerListDocument = gql`
  query SystemAdminCustomerList(
    $q: String = ""
    $isActive: Boolean = null
    $tariff: [ID!] = null
    $orderBy: CustomersOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    systemAdminCustomerList(q: $q, isActive: $isActive, tariff: $tariff, orderBy: $orderBy, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...SystemAdminCustomerList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${SystemAdminCustomerListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminCustomerListGQL extends Apollo.Query<
  SystemAdminCustomerListQuery,
  SystemAdminCustomerListQueryVariables
> {
  override document = SystemAdminCustomerListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminCustomerTariffSelectItemsDocument = gql`
  query SystemAdminCustomerTariffSelectItems {
    systemAdminCustomerTariffSelectItems {
      ...SystemAdminCustomerTariffSelectItems
    }
  }
  ${SystemAdminCustomerTariffSelectItemsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminCustomerTariffSelectItemsGQL extends Apollo.Query<
  SystemAdminCustomerTariffSelectItemsQuery,
  SystemAdminCustomerTariffSelectItemsQueryVariables
> {
  override document = SystemAdminCustomerTariffSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminInstallationCompanyDetailDocument = gql`
  query SystemAdminInstallationCompanyDetail($installationCompanyId: ID!) {
    systemAdminInstallationCompanyDetail(installationCompanyId: $installationCompanyId) {
      ...InstallationCompany
    }
  }
  ${InstallationCompanyFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminInstallationCompanyDetailGQL extends Apollo.Query<
  SystemAdminInstallationCompanyDetailQuery,
  SystemAdminInstallationCompanyDetailQueryVariables
> {
  override document = SystemAdminInstallationCompanyDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminInstallationCompanyListDocument = gql`
  query SystemAdminInstallationCompanyList(
    $q: String = ""
    $isActive: Boolean = null
    $tariff: [ID!] = null
    $orderBy: InstallationCompaniesOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    systemAdminInstallationCompanyList(q: $q, isActive: $isActive, tariff: $tariff, orderBy: $orderBy, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...SystemAdminInstallationCompanyList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${SystemAdminInstallationCompanyListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminInstallationCompanyListGQL extends Apollo.Query<
  SystemAdminInstallationCompanyListQuery,
  SystemAdminInstallationCompanyListQueryVariables
> {
  override document = SystemAdminInstallationCompanyListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminInstallationCompanyTariffSelectItemsDocument = gql`
  query SystemAdminInstallationCompanyTariffSelectItems {
    systemAdminInstallationCompanyTariffSelectItems {
      ...SystemAdminInstallationCompanyTariffSelectItems
    }
  }
  ${SystemAdminInstallationCompanyTariffSelectItemsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminInstallationCompanyTariffSelectItemsGQL extends Apollo.Query<
  SystemAdminInstallationCompanyTariffSelectItemsQuery,
  SystemAdminInstallationCompanyTariffSelectItemsQueryVariables
> {
  override document = SystemAdminInstallationCompanyTariffSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminManufacturerDetailDocument = gql`
  query SystemAdminManufacturerDetail($manufacturerId: ID!) {
    systemAdminManufacturerDetail(manufacturerId: $manufacturerId) {
      ...SystemAdminManufacturerDetail
    }
  }
  ${SystemAdminManufacturerDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminManufacturerDetailGQL extends Apollo.Query<
  SystemAdminManufacturerDetailQuery,
  SystemAdminManufacturerDetailQueryVariables
> {
  override document = SystemAdminManufacturerDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminManufacturerListDocument = gql`
  query SystemAdminManufacturerList(
    $q: String = ""
    $isActive: Boolean = null
    $tariff: [ID!] = null
    $orderBy: ManufacturersOrderingChoices = MODIFIED_DESC
    $page: Int = 1
  ) {
    systemAdminManufacturerList(q: $q, isActive: $isActive, tariff: $tariff, orderBy: $orderBy, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...SystemAdminManufacturerList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${SystemAdminManufacturerListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminManufacturerListGQL extends Apollo.Query<
  SystemAdminManufacturerListQuery,
  SystemAdminManufacturerListQueryVariables
> {
  override document = SystemAdminManufacturerListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminManufacturerTariffSelectItemsDocument = gql`
  query SystemAdminManufacturerTariffSelectItems {
    systemAdminManufacturerTariffSelectItems {
      ...SystemAdminManufacturerTariffSelectItems
    }
  }
  ${SystemAdminManufacturerTariffSelectItemsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminManufacturerTariffSelectItemsGQL extends Apollo.Query<
  SystemAdminManufacturerTariffSelectItemsQuery,
  SystemAdminManufacturerTariffSelectItemsQueryVariables
> {
  override document = SystemAdminManufacturerTariffSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminStripeSubscriptionListDocument = gql`
  query SystemAdminStripeSubscriptionList($page: Int = 1) {
    systemAdminStripeSubscriptionList(page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...SystemAdminStripeSubscriptionList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${SystemAdminStripeSubscriptionListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminStripeSubscriptionListGQL extends Apollo.Query<
  SystemAdminStripeSubscriptionListQuery,
  SystemAdminStripeSubscriptionListQueryVariables
> {
  override document = SystemAdminStripeSubscriptionListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminUserDetailDocument = gql`
  query SystemAdminUserDetail($userId: ID!) {
    systemAdminUserDetail(userId: $userId) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminUserDetailGQL extends Apollo.Query<
  SystemAdminUserDetailQuery,
  SystemAdminUserDetailQueryVariables
> {
  override document = SystemAdminUserDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SystemAdminUserListDocument = gql`
  query SystemAdminUserList($orderBy: UserOrderingChoices = MODIFIED_DESC, $page: Int = 1) {
    systemAdminUserList(orderBy: $orderBy, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...SystemAdminUserList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${SystemAdminUserListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SystemAdminUserListGQL extends Apollo.Query<SystemAdminUserListQuery, SystemAdminUserListQueryVariables> {
  override document = SystemAdminUserListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerSiteDetailDocument = gql`
  query CustomerCustomerSiteDetail($customerSiteId: ID!) {
    customerCustomerSiteDetail(customerSiteId: $customerSiteId) {
      ...CustomerCustomerSiteDetail
    }
  }
  ${CustomerCustomerSiteDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerSiteDetailGQL extends Apollo.Query<
  CustomerCustomerSiteDetailQuery,
  CustomerCustomerSiteDetailQueryVariables
> {
  override document = CustomerCustomerSiteDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerSiteListDocument = gql`
  query CustomerCustomerSiteList($orderBy: CustomerSitesOrderingChoices = MODIFIED_DESC, $page: Int = 1) {
    customerCustomerSiteList(orderBy: $orderBy, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerCustomerSiteList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerCustomerSiteListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerSiteListGQL extends Apollo.Query<
  CustomerCustomerSiteListQuery,
  CustomerCustomerSiteListQueryVariables
> {
  override document = CustomerCustomerSiteListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerSiteSelectItemsDefaultDocument = gql`
  query CustomerCustomerSiteSelectItemsDefault {
    customerCustomerSiteSelectItems {
      ...CustomerCustomerSiteSelectItemsDefault
    }
  }
  ${CustomerCustomerSiteSelectItemsDefaultFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerSiteSelectItemsDefaultGQL extends Apollo.Query<
  CustomerCustomerSiteSelectItemsDefaultQuery,
  CustomerCustomerSiteSelectItemsDefaultQueryVariables
> {
  override document = CustomerCustomerSiteSelectItemsDefaultDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerSiteSelectItemsDocument = gql`
  query CustomerCustomerSiteSelectItems {
    customerCustomerSiteSelectItems {
      ...CustomerCustomerSiteSelectItems
    }
  }
  ${CustomerCustomerSiteSelectItemsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerSiteSelectItemsGQL extends Apollo.Query<
  CustomerCustomerSiteSelectItemsQuery,
  CustomerCustomerSiteSelectItemsQueryVariables
> {
  override document = CustomerCustomerSiteSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerStripeSubscriptionListDocument = gql`
  query CustomerCustomerStripeSubscriptionList($page: Int = 1) {
    customerCustomerStripeSubscriptionList(page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerCustomerStripeSubscriptionList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerCustomerStripeSubscriptionListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerStripeSubscriptionListGQL extends Apollo.Query<
  CustomerCustomerStripeSubscriptionListQuery,
  CustomerCustomerStripeSubscriptionListQueryVariables
> {
  override document = CustomerCustomerStripeSubscriptionListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTariffSelectItemsDocument = gql`
  query CustomerCustomerTariffSelectItems {
    monthly: customerCustomerTariffSelectItems(priceType: MONTHLY) {
      ...CustomerCustomerTariffSelectItems
    }
    yearly: customerCustomerTariffSelectItems(priceType: YEARLY) {
      ...CustomerCustomerTariffSelectItems
    }
  }
  ${CustomerCustomerTariffSelectItemsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTariffSelectItemsGQL extends Apollo.Query<
  CustomerCustomerTariffSelectItemsQuery,
  CustomerCustomerTariffSelectItemsQueryVariables
> {
  override document = CustomerCustomerTariffSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketCommentDetailDocument = gql`
  query CustomerCustomerTicketCommentDetail($commentId: ID!) {
    customerCustomerTicketCommentDetail(commentId: $commentId) {
      ...CustomerTicketComment
    }
  }
  ${CustomerTicketCommentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketCommentDetailGQL extends Apollo.Query<
  CustomerCustomerTicketCommentDetailQuery,
  CustomerCustomerTicketCommentDetailQueryVariables
> {
  override document = CustomerCustomerTicketCommentDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketCommentListDocument = gql`
  query CustomerCustomerTicketCommentList($customerTicket: [ID!] = null, $page: Int = 1) {
    customerCustomerTicketCommentList(customerTicket: $customerTicket, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerTicketComment
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerTicketCommentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketCommentListGQL extends Apollo.Query<
  CustomerCustomerTicketCommentListQuery,
  CustomerCustomerTicketCommentListQueryVariables
> {
  override document = CustomerCustomerTicketCommentListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketDetailEditDocument = gql`
  query CustomerCustomerTicketDetailEdit($ticketId: ID!) {
    customerCustomerTicketDetail(ticketId: $ticketId) {
      ...CustomerCustomerTicketDetailEdit
    }
  }
  ${CustomerCustomerTicketDetailEditFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketDetailEditGQL extends Apollo.Query<
  CustomerCustomerTicketDetailEditQuery,
  CustomerCustomerTicketDetailEditQueryVariables
> {
  override document = CustomerCustomerTicketDetailEditDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketDetailDocument = gql`
  query CustomerCustomerTicketDetail($ticketId: ID!) {
    customerCustomerTicketDetail(ticketId: $ticketId) {
      ...CustomerCustomerTicketDetail
    }
  }
  ${CustomerCustomerTicketDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketDetailGQL extends Apollo.Query<
  CustomerCustomerTicketDetailQuery,
  CustomerCustomerTicketDetailQueryVariables
> {
  override document = CustomerCustomerTicketDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketListDetailDocument = gql`
  query CustomerCustomerTicketListDetail(
    $isClosed: Boolean = false
    $q: String = ""
    $installationCompany: [ID!] = null
    $priority: [TicketPriorityChoices!] = null
    $category: [TicketCategoryChoices!] = null
    $deviceType: [ID!] = null
    $device: [ID!] = null
    $orderBy: CustomerTicketOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    customerCustomerTicketList(
      isClosed: $isClosed
      q: $q
      installationCompany: $installationCompany
      priority: $priority
      category: $category
      deviceType: $deviceType
      device: $device
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerCustomerTicketList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerCustomerTicketListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketListDetailGQL extends Apollo.Query<
  CustomerCustomerTicketListDetailQuery,
  CustomerCustomerTicketListDetailQueryVariables
> {
  override document = CustomerCustomerTicketListDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketListDocument = gql`
  query CustomerCustomerTicketList(
    $isClosed: Boolean = false
    $q: String = ""
    $installationCompany: [ID!] = null
    $priority: [TicketPriorityChoices!] = null
    $category: [TicketCategoryChoices!] = null
    $deviceType: [ID!] = null
    $device: [ID!] = null
    $orderBy: CustomerTicketOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    customerCustomerTicketList(
      isClosed: $isClosed
      q: $q
      installationCompany: $installationCompany
      priority: $priority
      category: $category
      deviceType: $deviceType
      device: $device
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerCustomerTicketList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerCustomerTicketListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketListGQL extends Apollo.Query<
  CustomerCustomerTicketListQuery,
  CustomerCustomerTicketListQueryVariables
> {
  override document = CustomerCustomerTicketListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDashboardDetailDocument = gql`
  query CustomerDashboardDetail($customerSiteId: ID!) {
    customerCustomerSiteDetail(customerSiteId: $customerSiteId) {
      ...CustomerDashboardDetail
    }
  }
  ${CustomerDashboardDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDashboardDetailGQL extends Apollo.Query<
  CustomerDashboardDetailQuery,
  CustomerDashboardDetailQueryVariables
> {
  override document = CustomerDashboardDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPlcEventLastNEventsDocument = gql`
  query CustomerPlcEventLastNEvents {
    customerPlcEventLastNEvents {
      ...CustomerPlcEventLastNEvents
    }
  }
  ${CustomerPlcEventLastNEventsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPlcEventLastNEventsGQL extends Apollo.Query<
  CustomerPlcEventLastNEventsQuery,
  CustomerPlcEventLastNEventsQueryVariables
> {
  override document = CustomerPlcEventLastNEventsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerCustomerTicketLastNTicketsDocument = gql`
  query CustomerCustomerTicketLastNTickets($n: Int! = 5, $isClosed: Boolean = false) {
    customerCustomerTicketLastNTickets(n: $n, isClosed: $isClosed) {
      ...CustomerCustomerTicketLastNTickets
    }
  }
  ${CustomerCustomerTicketLastNTicketsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerCustomerTicketLastNTicketsGQL extends Apollo.Query<
  CustomerCustomerTicketLastNTicketsQuery,
  CustomerCustomerTicketLastNTicketsQueryVariables
> {
  override document = CustomerCustomerTicketLastNTicketsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDeviceDetailGuardDocument = gql`
  query CustomerDeviceDetailGuard($deviceId: ID!) {
    customerDeviceDetail(deviceId: $deviceId) {
      id
      deviceType {
        id
        apiType
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDeviceDetailGuardGQL extends Apollo.Query<
  CustomerDeviceDetailGuardQuery,
  CustomerDeviceDetailGuardQueryVariables
> {
  override document = CustomerDeviceDetailGuardDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDeviceDetailTicketsDocument = gql`
  query CustomerDeviceDetailTickets($deviceId: ID!) {
    customerDeviceDetail(deviceId: $deviceId) {
      ...CustomerDeviceDetailTickets
    }
  }
  ${CustomerDeviceDetailTicketsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDeviceDetailTicketsGQL extends Apollo.Query<
  CustomerDeviceDetailTicketsQuery,
  CustomerDeviceDetailTicketsQueryVariables
> {
  override document = CustomerDeviceDetailTicketsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDeviceDetailDocument = gql`
  query CustomerDeviceDetail($deviceId: ID!) {
    customerDeviceDetail(deviceId: $deviceId) {
      ...CustomerDeviceDetail
    }
  }
  ${CustomerDeviceDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDeviceDetailGQL extends Apollo.Query<
  CustomerDeviceDetailQuery,
  CustomerDeviceDetailQueryVariables
> {
  override document = CustomerDeviceDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDeviceListDocument = gql`
  query CustomerDeviceList(
    $technology: [DeviceTypeTechnologyChoices!] = null
    $orderBy: DeviceOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    customerDeviceList(technology: $technology, orderBy: $orderBy, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerDeviceList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerDeviceListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDeviceListGQL extends Apollo.Query<CustomerDeviceListQuery, CustomerDeviceListQueryVariables> {
  override document = CustomerDeviceListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDeviceSelectItemsTicketsAllowedDocument = gql`
  query CustomerDeviceSelectItemsTicketsAllowed {
    customerDeviceSelectItems(ticketsAllowed: true) {
      ...CustomerDeviceSelectItemsTicketsAllowed
    }
  }
  ${CustomerDeviceSelectItemsTicketsAllowedFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDeviceSelectItemsTicketsAllowedGQL extends Apollo.Query<
  CustomerDeviceSelectItemsTicketsAllowedQuery,
  CustomerDeviceSelectItemsTicketsAllowedQueryVariables
> {
  override document = CustomerDeviceSelectItemsTicketsAllowedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerDeviceTypeSelectItemsDocument = gql`
  query CustomerDeviceTypeSelectItems($q: String = "") {
    customerDeviceTypeSelectItems(q: $q) {
      ...DeviceTypeSelectItem
    }
  }
  ${DeviceTypeSelectItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerDeviceTypeSelectItemsGQL extends Apollo.Query<
  CustomerDeviceTypeSelectItemsQuery,
  CustomerDeviceTypeSelectItemsQueryVariables
> {
  override document = CustomerDeviceTypeSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerInstallationCompanySelectItemsTicketsAllowedDocument = gql`
  query CustomerInstallationCompanySelectItemsTicketsAllowed($deviceId: ID = null, $eventId: ID = null) {
    customerInstallationCompanySelectItems(ticketsAllowed: true, deviceId: $deviceId, eventId: $eventId) {
      ...InstallationCompanySelectItem
    }
  }
  ${InstallationCompanySelectItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerInstallationCompanySelectItemsTicketsAllowedGQL extends Apollo.Query<
  CustomerInstallationCompanySelectItemsTicketsAllowedQuery,
  CustomerInstallationCompanySelectItemsTicketsAllowedQueryVariables
> {
  override document = CustomerInstallationCompanySelectItemsTicketsAllowedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerInstallationCompanySelectItemsDocument = gql`
  query CustomerInstallationCompanySelectItems($ticketsAllowed: Boolean!) {
    customerInstallationCompanySelectItems(ticketsAllowed: $ticketsAllowed) {
      ...InstallationCompanySelectItem
    }
  }
  ${InstallationCompanySelectItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerInstallationCompanySelectItemsGQL extends Apollo.Query<
  CustomerInstallationCompanySelectItemsQuery,
  CustomerInstallationCompanySelectItemsQueryVariables
> {
  override document = CustomerInstallationCompanySelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerMyDeviceListDocument = gql`
  query CustomerMyDeviceList(
    $technology: [DeviceTypeTechnologyChoices!] = null
    $orderBy: DeviceOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    customerDeviceList(technology: $technology, orderBy: $orderBy, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerMyDeviceList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerMyDeviceListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerMyDeviceListGQL extends Apollo.Query<
  CustomerMyDeviceListQuery,
  CustomerMyDeviceListQueryVariables
> {
  override document = CustomerMyDeviceListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPlcEventDetailTicketsDocument = gql`
  query CustomerPlcEventDetailTickets($plcEventId: ID!) {
    customerPlcEventDetail(plcEventId: $plcEventId) {
      ...CustomerPlcEventDetailTickets
    }
  }
  ${CustomerPlcEventDetailTicketsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPlcEventDetailTicketsGQL extends Apollo.Query<
  CustomerPlcEventDetailTicketsQuery,
  CustomerPlcEventDetailTicketsQueryVariables
> {
  override document = CustomerPlcEventDetailTicketsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPlcEventDetailDocument = gql`
  query CustomerPlcEventDetail($plcEventId: ID!) {
    customerPlcEventDetail(plcEventId: $plcEventId) {
      ...CustomerPlcEvent
    }
  }
  ${CustomerPlcEventFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPlcEventDetailGQL extends Apollo.Query<
  CustomerPlcEventDetailQuery,
  CustomerPlcEventDetailQueryVariables
> {
  override document = CustomerPlcEventDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPlcEventListDetailDocument = gql`
  query CustomerPlcEventListDetail(
    $q: String = ""
    $priority: [DeviceEventPriorityChoices!] = null
    $deviceType: [ID!] = null
    $device: [ID!] = null
    $customerSite: [ID!] = null
    $hasTicket: Boolean = null
    $notRead: Boolean = null
    $orderBy: PlcEventOrderingOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    customerPlcEventList(
      q: $q
      priority: $priority
      deviceType: $deviceType
      device: $device
      customerSite: $customerSite
      hasTicket: $hasTicket
      notRead: $notRead
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerPlcEventList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerPlcEventListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPlcEventListDetailGQL extends Apollo.Query<
  CustomerPlcEventListDetailQuery,
  CustomerPlcEventListDetailQueryVariables
> {
  override document = CustomerPlcEventListDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPlcEventListDocument = gql`
  query CustomerPlcEventList(
    $q: String = ""
    $priority: [DeviceEventPriorityChoices!] = null
    $deviceType: [ID!] = null
    $customerSite: [ID!] = null
    $hasTicket: Boolean = null
    $notRead: Boolean = null
    $orderBy: PlcEventOrderingOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    customerPlcEventList(
      q: $q
      priority: $priority
      deviceType: $deviceType
      customerSite: $customerSite
      hasTicket: $hasTicket
      notRead: $notRead
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerPlcEventList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerPlcEventListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPlcEventListGQL extends Apollo.Query<
  CustomerPlcEventListQuery,
  CustomerPlcEventListQueryVariables
> {
  override document = CustomerPlcEventListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPlcFveCurrentDataDocument = gql`
  query CustomerPlcFveCurrentData($deviceId: ID!) {
    customerDeviceDetail(deviceId: $deviceId) {
      id
      deviceType {
        id
        mappingJson
      }
    }
    plcFveCurrentData(deviceId: $deviceId) {
      ...PlcFveMetrics
    }
  }
  ${PlcFveMetricsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPlcFveCurrentDataGQL extends Apollo.Query<
  CustomerPlcFveCurrentDataQuery,
  CustomerPlcFveCurrentDataQueryVariables
> {
  override document = CustomerPlcFveCurrentDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerPlcHeatPumpCurrentDataDocument = gql`
  query CustomerPlcHeatPumpCurrentData($deviceId: ID!) {
    customerDeviceDetail(deviceId: $deviceId) {
      id
      deviceType {
        id
        mappingJson
      }
    }
    plcHeatPumpCurrentData(deviceId: $deviceId) {
      ...PlcHeatPumpMetrics
    }
  }
  ${PlcHeatPumpMetricsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerPlcHeatPumpCurrentDataGQL extends Apollo.Query<
  CustomerPlcHeatPumpCurrentDataQuery,
  CustomerPlcHeatPumpCurrentDataQueryVariables
> {
  override document = CustomerPlcHeatPumpCurrentDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CustomerTariffRestrictionInfoDocument = gql`
  query CustomerTariffRestrictionInfo {
    me {
      id
      customer {
        defaultPaymentMethod
        tariff {
          id
          activeSubscription {
            id
          }
        }
      }
    }
    customerTariffRestrictionInfo {
      ...CustomerTariffRestrictionInfo
    }
  }
  ${CustomerTariffRestrictionInfoFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerTariffRestrictionInfoGQL extends Apollo.Query<
  CustomerTariffRestrictionInfoQuery,
  CustomerTariffRestrictionInfoQueryVariables
> {
  override document = CustomerTariffRestrictionInfoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyBaseTicketListDocument = gql`
  query InstallationCompanyBaseTicketList(
    $q: String = ""
    $isClosed: Boolean = false
    $priority: [TicketPriorityChoices!] = null
    $device: [ID!] = null
    $orderBy: TicketOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    installationCompanyBaseTicketList(
      isClosed: $isClosed
      priority: $priority
      device: $device
      orderBy: $orderBy
      page: $page
      q: $q
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyBaseTicketList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyBaseTicketListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyBaseTicketListGQL extends Apollo.Query<
  InstallationCompanyBaseTicketListQuery,
  InstallationCompanyBaseTicketListQueryVariables
> {
  override document = InstallationCompanyBaseTicketListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerSelectItemsDocument = gql`
  query InstallationCompanyCustomerSelectItems($deviceId: ID = null, $eventId: ID = null) {
    installationCompanyCustomerSelectItems(deviceId: $deviceId, eventId: $eventId) {
      id
      name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerSelectItemsGQL extends Apollo.Query<
  InstallationCompanyCustomerSelectItemsQuery,
  InstallationCompanyCustomerSelectItemsQueryVariables
> {
  override document = InstallationCompanyCustomerSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketCommentDetailDocument = gql`
  query InstallationCompanyCustomerTicketCommentDetail($commentId: ID!) {
    installationCompanyCustomerTicketCommentDetail(commentId: $commentId) {
      ...CustomerTicketComment
    }
  }
  ${CustomerTicketCommentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketCommentDetailGQL extends Apollo.Query<
  InstallationCompanyCustomerTicketCommentDetailQuery,
  InstallationCompanyCustomerTicketCommentDetailQueryVariables
> {
  override document = InstallationCompanyCustomerTicketCommentDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketCommentListDocument = gql`
  query InstallationCompanyCustomerTicketCommentList($customerTicket: [ID!] = null, $page: Int = 1) {
    installationCompanyCustomerTicketCommentList(customerTicket: $customerTicket, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...CustomerTicketComment
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${CustomerTicketCommentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketCommentListGQL extends Apollo.Query<
  InstallationCompanyCustomerTicketCommentListQuery,
  InstallationCompanyCustomerTicketCommentListQueryVariables
> {
  override document = InstallationCompanyCustomerTicketCommentListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketDetailEditDocument = gql`
  query InstallationCompanyCustomerTicketDetailEdit($ticketId: ID!) {
    installationCompanyCustomerTicketDetail(ticketId: $ticketId) {
      ...InstallationCompanyCustomerTicketDetailEdit
    }
  }
  ${InstallationCompanyCustomerTicketDetailEditFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketDetailEditGQL extends Apollo.Query<
  InstallationCompanyCustomerTicketDetailEditQuery,
  InstallationCompanyCustomerTicketDetailEditQueryVariables
> {
  override document = InstallationCompanyCustomerTicketDetailEditDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketDetailForManufacturerDocument = gql`
  query InstallationCompanyCustomerTicketDetailForManufacturer($ticketId: ID!) {
    installationCompanyCustomerTicketDetail(ticketId: $ticketId) {
      ...InstallationCompanyCustomerTicketDetailForManufacturer
    }
  }
  ${InstallationCompanyCustomerTicketDetailForManufacturerFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketDetailForManufacturerGQL extends Apollo.Query<
  InstallationCompanyCustomerTicketDetailForManufacturerQuery,
  InstallationCompanyCustomerTicketDetailForManufacturerQueryVariables
> {
  override document = InstallationCompanyCustomerTicketDetailForManufacturerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketDetailDocument = gql`
  query InstallationCompanyCustomerTicketDetail($ticketId: ID!) {
    installationCompanyCustomerTicketDetail(ticketId: $ticketId) {
      ...InstallationCompanyCustomerTicketDetail
    }
  }
  ${InstallationCompanyCustomerTicketDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketDetailGQL extends Apollo.Query<
  InstallationCompanyCustomerTicketDetailQuery,
  InstallationCompanyCustomerTicketDetailQueryVariables
> {
  override document = InstallationCompanyCustomerTicketDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerTicketListDocument = gql`
  query InstallationCompanyCustomerTicketList(
    $q: String = ""
    $isClosed: Boolean = false
    $priority: [TicketPriorityChoices!] = null
    $category: [TicketCategoryChoices!] = null
    $device: [ID!] = null
    $assignee: [CustomerTicketAssigneeChoices!] = null
    $installationCompanyTechnician: [ID] = null
    $orderBy: CustomerTicketOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    installationCompanyCustomerTicketList(
      isClosed: $isClosed
      q: $q
      priority: $priority
      category: $category
      device: $device
      assignee: $assignee
      installationCompanyTechnician: $installationCompanyTechnician
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyCustomerTicketList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyCustomerTicketListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerTicketListGQL extends Apollo.Query<
  InstallationCompanyCustomerTicketListQuery,
  InstallationCompanyCustomerTicketListQueryVariables
> {
  override document = InstallationCompanyCustomerTicketListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyPlcEventLastNEventsDocument = gql`
  query InstallationCompanyPlcEventLastNEvents {
    installationCompanyPlcEventLastNEvents {
      ...InstallationCompanyPlcEventLastNEvents
    }
  }
  ${InstallationCompanyPlcEventLastNEventsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyPlcEventLastNEventsGQL extends Apollo.Query<
  InstallationCompanyPlcEventLastNEventsQuery,
  InstallationCompanyPlcEventLastNEventsQueryVariables
> {
  override document = InstallationCompanyPlcEventLastNEventsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDashboardStatisticsOverviewDocument = gql`
  query InstallationCompanyDashboardStatisticsOverview {
    installationCompanyDashboardStatisticsOverview {
      ...InstallationCompanyDashboardStatisticsOverview
    }
  }
  ${InstallationCompanyDashboardStatisticsOverviewFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDashboardStatisticsOverviewGQL extends Apollo.Query<
  InstallationCompanyDashboardStatisticsOverviewQuery,
  InstallationCompanyDashboardStatisticsOverviewQueryVariables
> {
  override document = InstallationCompanyDashboardStatisticsOverviewDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyBaseTicketLastNTicketsDocument = gql`
  query InstallationCompanyBaseTicketLastNTickets {
    installationCompanyBaseTicketLastNTickets {
      ...InstallationCompanyBaseTicketLastNTickets
    }
  }
  ${InstallationCompanyBaseTicketLastNTicketsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyBaseTicketLastNTicketsGQL extends Apollo.Query<
  InstallationCompanyBaseTicketLastNTicketsQuery,
  InstallationCompanyBaseTicketLastNTicketsQueryVariables
> {
  override document = InstallationCompanyBaseTicketLastNTicketsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceCommandListDocument = gql`
  query InstallationCompanyDeviceCommandList($deviceId: ID!, $page: Int) {
    installationCompanyDeviceCommandList(deviceId: $deviceId, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyDeviceCommandList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyDeviceCommandListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceCommandListGQL extends Apollo.Query<
  InstallationCompanyDeviceCommandListQuery,
  InstallationCompanyDeviceCommandListQueryVariables
> {
  override document = InstallationCompanyDeviceCommandListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceDetailGuardDocument = gql`
  query InstallationCompanyDeviceDetailGuard($deviceId: ID!) {
    installationCompanyDeviceDetail(deviceId: $deviceId) {
      id
      deviceType {
        id
        apiType
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceDetailGuardGQL extends Apollo.Query<
  InstallationCompanyDeviceDetailGuardQuery,
  InstallationCompanyDeviceDetailGuardQueryVariables
> {
  override document = InstallationCompanyDeviceDetailGuardDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceDetailTicketsDocument = gql`
  query InstallationCompanyDeviceDetailTickets($deviceId: ID!) {
    installationCompanyDeviceDetail(deviceId: $deviceId) {
      ...InstallationCompanyDeviceDetailTickets
    }
  }
  ${InstallationCompanyDeviceDetailTicketsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceDetailTicketsGQL extends Apollo.Query<
  InstallationCompanyDeviceDetailTicketsQuery,
  InstallationCompanyDeviceDetailTicketsQueryVariables
> {
  override document = InstallationCompanyDeviceDetailTicketsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceDetailDocument = gql`
  query InstallationCompanyDeviceDetail($deviceId: ID!) {
    installationCompanyDeviceDetail(deviceId: $deviceId) {
      ...InstallationCompanyDeviceDetail
    }
  }
  ${InstallationCompanyDeviceDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceDetailGQL extends Apollo.Query<
  InstallationCompanyDeviceDetailQuery,
  InstallationCompanyDeviceDetailQueryVariables
> {
  override document = InstallationCompanyDeviceDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceListDocument = gql`
  query InstallationCompanyDeviceList(
    $q: String = ""
    $deviceType: [ID!] = null
    $installationCompanyTechnician: [ID!] = null
    $orderBy: InstallationCompanyDeviceOrderingChoices = MODIFIED_DESC
    $page: Int = 1
  ) {
    installationCompanyDeviceList(
      orderBy: $orderBy
      page: $page
      installationCompanyTechnician: $installationCompanyTechnician
      deviceType: $deviceType
      q: $q
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyDeviceList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyDeviceListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceListGQL extends Apollo.Query<
  InstallationCompanyDeviceListQuery,
  InstallationCompanyDeviceListQueryVariables
> {
  override document = InstallationCompanyDeviceListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyDeviceTypeSelectItemsDocument = gql`
  query InstallationCompanyDeviceTypeSelectItems($q: String = "") {
    installationCompanyDeviceTypeSelectItems(q: $q) {
      ...DeviceTypeSelectItem
    }
  }
  ${DeviceTypeSelectItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyDeviceTypeSelectItemsGQL extends Apollo.Query<
  InstallationCompanyDeviceTypeSelectItemsQuery,
  InstallationCompanyDeviceTypeSelectItemsQueryVariables
> {
  override document = InstallationCompanyDeviceTypeSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationAttachmentListDocument = gql`
  query InstallationCompanyInstallationAttachmentList($deviceId: ID!, $page: Int = 1) {
    installationCompanyInstallationAttachmentList(deviceId: $deviceId, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyInstallationAttachmentList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyInstallationAttachmentListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationAttachmentListGQL extends Apollo.Query<
  InstallationCompanyInstallationAttachmentListQuery,
  InstallationCompanyInstallationAttachmentListQueryVariables
> {
  override document = InstallationCompanyInstallationAttachmentListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerDetailDocument = gql`
  query InstallationCompanyInstallationCompanyCustomerDetail($installationCompanyCustomerId: ID, $email: String) {
    installationCompanyInstallationCompanyCustomerDetail(
      installationCompanyCustomerId: $installationCompanyCustomerId
      email: $email
    ) {
      ...InstallationCompanyInstallationCompanyCustomerDetail
    }
  }
  ${InstallationCompanyInstallationCompanyCustomerDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerDetailGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyCustomerDetailQuery,
  InstallationCompanyInstallationCompanyCustomerDetailQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerListAutocompleteDocument = gql`
  query InstallationCompanyInstallationCompanyCustomerListAutocomplete($q: String = "") {
    installationCompanyInstallationCompanyCustomerList(orderBy: CREATED_DESC, q: $q) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyInstallationCompanyCustomerListAutocomplete
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyInstallationCompanyCustomerListAutocompleteFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerListAutocompleteGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyCustomerListAutocompleteQuery,
  InstallationCompanyInstallationCompanyCustomerListAutocompleteQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerListAutocompleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerListDocument = gql`
  query InstallationCompanyInstallationCompanyCustomerList(
    $orderBy: InstallationCompanyCustomerOrderingChoices = CREATED_DESC
    $page: Int = 1
    $q: String = ""
  ) {
    installationCompanyInstallationCompanyCustomerList(orderBy: $orderBy, page: $page, q: $q) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyInstallationCompanyCustomerList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyInstallationCompanyCustomerListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerListGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyCustomerListQuery,
  InstallationCompanyInstallationCompanyCustomerListQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerSelectItemsDocument = gql`
  query InstallationCompanyInstallationCompanyCustomerSelectItems {
    installationCompanyInstallationCompanyCustomerSelectItems {
      ...InstallationCompanyInstallationCompanyCustomerSelectItems
    }
  }
  ${InstallationCompanyInstallationCompanyCustomerSelectItemsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerSelectItemsGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyCustomerSelectItemsQuery,
  InstallationCompanyInstallationCompanyCustomerSelectItemsQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerSiteDetailDocument = gql`
  query InstallationCompanyInstallationCompanyCustomerSiteDetail($customerSiteId: ID!) {
    installationCompanyInstallationCompanyCustomerSiteDetail(customerSiteId: $customerSiteId) {
      ...InstallationCompanyInstallationCompanyCustomerSiteDetail
    }
  }
  ${InstallationCompanyInstallationCompanyCustomerSiteDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerSiteDetailGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyCustomerSiteDetailQuery,
  InstallationCompanyInstallationCompanyCustomerSiteDetailQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerSiteDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerSiteListDocument = gql`
  query InstallationCompanyInstallationCompanyCustomerSiteList(
    $installationCompanyCustomerId: ID = null
    $orderBy: InstallationCompanyCustomerSiteOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    installationCompanyInstallationCompanyCustomerSiteList(
      orderBy: $orderBy
      page: $page
      installationCompanyCustomerId: $installationCompanyCustomerId
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyInstallationCompanyCustomerSiteList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyInstallationCompanyCustomerSiteListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerSiteListGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyCustomerSiteListQuery,
  InstallationCompanyInstallationCompanyCustomerSiteListQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerSiteListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyCustomerSiteSelectItemsDocument = gql`
  query InstallationCompanyInstallationCompanyCustomerSiteSelectItems($installationCompanyCustomerId: ID) {
    installationCompanyInstallationCompanyCustomerSiteSelectItems(
      installationCompanyCustomerId: $installationCompanyCustomerId
    ) {
      ...InstallationCompanyCustomerSite
    }
  }
  ${InstallationCompanyCustomerSiteFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyCustomerSiteSelectItemsGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyCustomerSiteSelectItemsQuery,
  InstallationCompanyInstallationCompanyCustomerSiteSelectItemsQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyCustomerSiteSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyStripeSubscriptionListDocument = gql`
  query InstallationCompanyInstallationCompanyStripeSubscriptionList($page: Int = 1) {
    installationCompanyInstallationCompanyStripeSubscriptionList(page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyInstallationCompanyStripeSubscriptionList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyInstallationCompanyStripeSubscriptionListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyStripeSubscriptionListGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyStripeSubscriptionListQuery,
  InstallationCompanyInstallationCompanyStripeSubscriptionListQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyStripeSubscriptionListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTariffSelectItemsDocument = gql`
  query InstallationCompanyInstallationCompanyTariffSelectItems {
    monthly: installationCompanyInstallationCompanyTariffSelectItems(priceType: MONTHLY) {
      ...InstallationCompanyInstallationCompanyTariffSelectItems
    }
    yearly: installationCompanyInstallationCompanyTariffSelectItems(priceType: YEARLY) {
      ...InstallationCompanyInstallationCompanyTariffSelectItems
    }
    default: installationCompanyInstallationCompanyTariffSelectItems(isDefault: true) {
      ...InstallationCompanyInstallationCompanyTariffSelectItems
    }
  }
  ${InstallationCompanyInstallationCompanyTariffSelectItemsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTariffSelectItemsGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyTariffSelectItemsQuery,
  InstallationCompanyInstallationCompanyTariffSelectItemsQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyTariffSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTechnicianSelectItemsDocument = gql`
  query InstallationCompanyInstallationCompanyTechnicianSelectItems(
    $hasInstallations: Boolean
    $isActive: Boolean
    $role: UserRoleChoices
  ) {
    installationCompanyInstallationCompanyTechnicianSelectItems(
      hasInstallations: $hasInstallations
      isActive: $isActive
      role: $role
    ) {
      ...InstallationCompanyInstallationCompanyTechnicianSelectItems
    }
  }
  ${InstallationCompanyInstallationCompanyTechnicianSelectItemsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTechnicianSelectItemsGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyTechnicianSelectItemsQuery,
  InstallationCompanyInstallationCompanyTechnicianSelectItemsQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyTechnicianSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketCommentListDocument = gql`
  query InstallationCompanyInstallationCompanyTicketCommentList(
    $installationCompanyTicket: [ID!] = null
    $page: Int = 1
  ) {
    installationCompanyInstallationCompanyTicketCommentList(
      installationCompanyTicket: $installationCompanyTicket
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyTicketComment
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyTicketCommentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketCommentListGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyTicketCommentListQuery,
  InstallationCompanyInstallationCompanyTicketCommentListQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketCommentListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketDetailEditDocument = gql`
  query InstallationCompanyInstallationCompanyTicketDetailEdit($ticketId: ID!) {
    installationCompanyInstallationCompanyTicketDetail(ticketId: $ticketId) {
      ...InstallationCompanyInstallationCompanyTicketDetailEdit
    }
  }
  ${InstallationCompanyInstallationCompanyTicketDetailEditFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketDetailEditGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyTicketDetailEditQuery,
  InstallationCompanyInstallationCompanyTicketDetailEditQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketDetailEditDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketDetailDocument = gql`
  query InstallationCompanyInstallationCompanyTicketDetail($ticketId: ID!) {
    installationCompanyInstallationCompanyTicketDetail(ticketId: $ticketId) {
      ...InstallationCompanyInstallationCompanyTicketDetail
    }
  }
  ${InstallationCompanyInstallationCompanyTicketDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketDetailGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyTicketDetailQuery,
  InstallationCompanyInstallationCompanyTicketDetailQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationCompanyTicketListDocument = gql`
  query InstallationCompanyInstallationCompanyTicketList(
    $deviceType: [ID!] = null
    $deviceCreatedBy: [ID!] = null
    $category: [TicketCategoryChoices!] = null
    $isClosed: Boolean = false
    $installationCompanyTechnician: [ID]
    $orderBy: InstallationCompanyTicketOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    installationCompanyInstallationCompanyTicketList(
      deviceType: $deviceType
      deviceCreatedBy: $deviceCreatedBy
      category: $category
      isClosed: $isClosed
      installationCompanyTechnician: $installationCompanyTechnician
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyInstallationCompanyTicketList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyInstallationCompanyTicketListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationCompanyTicketListGQL extends Apollo.Query<
  InstallationCompanyInstallationCompanyTicketListQuery,
  InstallationCompanyInstallationCompanyTicketListQueryVariables
> {
  override document = InstallationCompanyInstallationCompanyTicketListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationNoteDetailDocument = gql`
  query InstallationCompanyInstallationNoteDetail($noteId: ID!) {
    installationCompanyInstallationNoteDetail(noteId: $noteId) {
      ...InstallationNote
    }
  }
  ${InstallationNoteFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationNoteDetailGQL extends Apollo.Query<
  InstallationCompanyInstallationNoteDetailQuery,
  InstallationCompanyInstallationNoteDetailQueryVariables
> {
  override document = InstallationCompanyInstallationNoteDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyInstallationNoteListDocument = gql`
  query InstallationCompanyInstallationNoteList($installationId: ID = null, $page: Int = 1) {
    installationCompanyInstallationNoteList(installationId: $installationId, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyInstallationNoteList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyInstallationNoteListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyInstallationNoteListGQL extends Apollo.Query<
  InstallationCompanyInstallationNoteListQuery,
  InstallationCompanyInstallationNoteListQueryVariables
> {
  override document = InstallationCompanyInstallationNoteListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerSelectItemsDocument = gql`
  query InstallationCompanyManufacturerSelectItems($deviceId: ID = null) {
    installationCompanyManufacturerSelectItems(deviceId: $deviceId) {
      id
      name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerSelectItemsGQL extends Apollo.Query<
  InstallationCompanyManufacturerSelectItemsQuery,
  InstallationCompanyManufacturerSelectItemsQueryVariables
> {
  override document = InstallationCompanyManufacturerSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketCommentDetailDocument = gql`
  query InstallationCompanyManufacturerTicketCommentDetail($commentId: ID!) {
    installationCompanyManufacturerTicketCommentDetail(commentId: $commentId) {
      ...ManufacturerTicketComment
    }
  }
  ${ManufacturerTicketCommentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketCommentDetailGQL extends Apollo.Query<
  InstallationCompanyManufacturerTicketCommentDetailQuery,
  InstallationCompanyManufacturerTicketCommentDetailQueryVariables
> {
  override document = InstallationCompanyManufacturerTicketCommentDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketCommentListDocument = gql`
  query InstallationCompanyManufacturerTicketCommentList($manufacturerTicket: [ID!] = null, $page: Int = 1) {
    installationCompanyManufacturerTicketCommentList(manufacturerTicket: $manufacturerTicket, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...ManufacturerTicketComment
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${ManufacturerTicketCommentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketCommentListGQL extends Apollo.Query<
  InstallationCompanyManufacturerTicketCommentListQuery,
  InstallationCompanyManufacturerTicketCommentListQueryVariables
> {
  override document = InstallationCompanyManufacturerTicketCommentListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketDetailEditDocument = gql`
  query InstallationCompanyManufacturerTicketDetailEdit($ticketId: ID!) {
    installationCompanyManufacturerTicketDetail(ticketId: $ticketId) {
      ...InstallationCompanyManufacturerTicketDetailEdit
    }
  }
  ${InstallationCompanyManufacturerTicketDetailEditFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketDetailEditGQL extends Apollo.Query<
  InstallationCompanyManufacturerTicketDetailEditQuery,
  InstallationCompanyManufacturerTicketDetailEditQueryVariables
> {
  override document = InstallationCompanyManufacturerTicketDetailEditDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketDetailDocument = gql`
  query InstallationCompanyManufacturerTicketDetail($ticketId: ID!) {
    installationCompanyManufacturerTicketDetail(ticketId: $ticketId) {
      ...InstallationCompanyManufacturerTicketDetail
    }
  }
  ${InstallationCompanyManufacturerTicketDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketDetailGQL extends Apollo.Query<
  InstallationCompanyManufacturerTicketDetailQuery,
  InstallationCompanyManufacturerTicketDetailQueryVariables
> {
  override document = InstallationCompanyManufacturerTicketDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyManufacturerTicketListDocument = gql`
  query InstallationCompanyManufacturerTicketList(
    $q: String = ""
    $isClosed: Boolean = false
    $priority: [TicketPriorityChoices!] = null
    $category: [TicketCategoryChoices!] = null
    $device: [ID!] = null
    $assignee: [ManufacturerTicketAssigneeChoices!] = null
    $installationCompanyTechnician: [ID] = null
    $orderBy: ManufacturerTicketOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    installationCompanyManufacturerTicketList(
      isClosed: $isClosed
      q: $q
      priority: $priority
      category: $category
      device: $device
      assignee: $assignee
      installationCompanyTechnician: $installationCompanyTechnician
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyManufacturerTicketList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyManufacturerTicketListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyManufacturerTicketListGQL extends Apollo.Query<
  InstallationCompanyManufacturerTicketListQuery,
  InstallationCompanyManufacturerTicketListQueryVariables
> {
  override document = InstallationCompanyManufacturerTicketListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyPlcEventDetailTicketsDocument = gql`
  query InstallationCompanyPlcEventDetailTickets($plcEventId: ID!) {
    installationCompanyPlcEventDetail(plcEventId: $plcEventId) {
      ...InstallationCompanyPlcEventDetailTickets
    }
  }
  ${InstallationCompanyPlcEventDetailTicketsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyPlcEventDetailTicketsGQL extends Apollo.Query<
  InstallationCompanyPlcEventDetailTicketsQuery,
  InstallationCompanyPlcEventDetailTicketsQueryVariables
> {
  override document = InstallationCompanyPlcEventDetailTicketsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyPlcEventDetailDocument = gql`
  query InstallationCompanyPlcEventDetail($plcEventId: ID!) {
    installationCompanyPlcEventDetail(plcEventId: $plcEventId) {
      ...InstallationCompanyPlcEvent
    }
  }
  ${InstallationCompanyPlcEventFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyPlcEventDetailGQL extends Apollo.Query<
  InstallationCompanyPlcEventDetailQuery,
  InstallationCompanyPlcEventDetailQueryVariables
> {
  override document = InstallationCompanyPlcEventDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyPlcEventListDetailDocument = gql`
  query InstallationCompanyPlcEventListDetail(
    $q: String = ""
    $priority: [DeviceEventPriorityChoices!] = null
    $device: [ID!] = null
    $installationCompanyCustomer: [ID!] = null
    $installationCompanyCustomerSite: [ID!] = null
    $hasTicket: Boolean = null
    $notRead: Boolean = null
    $orderBy: PlcEventOrderingOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    installationCompanyPlcEventList(
      q: $q
      priority: $priority
      device: $device
      installationCompanyCustomer: $installationCompanyCustomer
      installationCompanyCustomerSite: $installationCompanyCustomerSite
      hasTicket: $hasTicket
      notRead: $notRead
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyPlcEventList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyPlcEventListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyPlcEventListDetailGQL extends Apollo.Query<
  InstallationCompanyPlcEventListDetailQuery,
  InstallationCompanyPlcEventListDetailQueryVariables
> {
  override document = InstallationCompanyPlcEventListDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyPlcEventListDocument = gql`
  query InstallationCompanyPlcEventList(
    $q: String = ""
    $priority: [DeviceEventPriorityChoices!] = null
    $deviceType: [ID!] = null
    $installationCompanyCustomer: [ID!] = null
    $installationCompanyCustomerSite: [ID!] = null
    $hasTicket: Boolean = null
    $notRead: Boolean = null
    $orderBy: PlcEventOrderingOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    installationCompanyPlcEventList(
      q: $q
      priority: $priority
      deviceType: $deviceType
      installationCompanyCustomer: $installationCompanyCustomer
      installationCompanyCustomerSite: $installationCompanyCustomerSite
      hasTicket: $hasTicket
      notRead: $notRead
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...InstallationCompanyPlcEventList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${InstallationCompanyPlcEventListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyPlcEventListGQL extends Apollo.Query<
  InstallationCompanyPlcEventListQuery,
  InstallationCompanyPlcEventListQueryVariables
> {
  override document = InstallationCompanyPlcEventListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyPlcFveCurrentDataDocument = gql`
  query InstallationCompanyPlcFveCurrentData($deviceId: ID!) {
    installationCompanyDeviceDetail(deviceId: $deviceId) {
      id
      deviceType {
        id
        mappingJson
      }
    }
    plcFveCurrentData(deviceId: $deviceId) {
      ...PlcFveMetrics
    }
  }
  ${PlcFveMetricsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyPlcFveCurrentDataGQL extends Apollo.Query<
  InstallationCompanyPlcFveCurrentDataQuery,
  InstallationCompanyPlcFveCurrentDataQueryVariables
> {
  override document = InstallationCompanyPlcFveCurrentDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyPlcHeatPumpCurrentDataDocument = gql`
  query InstallationCompanyPlcHeatPumpCurrentData($deviceId: ID!) {
    installationCompanyDeviceDetail(deviceId: $deviceId) {
      id
      deviceType {
        id
        mappingJson
      }
    }
    plcHeatPumpCurrentData(deviceId: $deviceId) {
      ...PlcHeatPumpMetrics
    }
  }
  ${PlcHeatPumpMetricsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyPlcHeatPumpCurrentDataGQL extends Apollo.Query<
  InstallationCompanyPlcHeatPumpCurrentDataQuery,
  InstallationCompanyPlcHeatPumpCurrentDataQueryVariables
> {
  override document = InstallationCompanyPlcHeatPumpCurrentDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyTariffRestrictionInfoDocument = gql`
  query InstallationCompanyTariffRestrictionInfo {
    installationCompanyTariffRestrictionInfo {
      ...InstallationCompanyTariffRestrictionInfo
    }
  }
  ${InstallationCompanyTariffRestrictionInfoFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyTariffRestrictionInfoGQL extends Apollo.Query<
  InstallationCompanyTariffRestrictionInfoQuery,
  InstallationCompanyTariffRestrictionInfoQueryVariables
> {
  override document = InstallationCompanyTariffRestrictionInfoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceCreatedBySelectItemsDocument = gql`
  query ManufacturerDeviceCreatedBySelectItems {
    manufacturerDeviceCreatedBySelectItems {
      ...UserSelectItem
    }
  }
  ${UserSelectItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceCreatedBySelectItemsGQL extends Apollo.Query<
  ManufacturerDeviceCreatedBySelectItemsQuery,
  ManufacturerDeviceCreatedBySelectItemsQueryVariables
> {
  override document = ManufacturerDeviceCreatedBySelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceDetailTicketsDocument = gql`
  query ManufacturerDeviceDetailTickets($deviceId: ID!) {
    manufacturerDeviceDetail(deviceId: $deviceId) {
      ...ManufacturerDeviceDetailTickets
    }
  }
  ${ManufacturerDeviceDetailTicketsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceDetailTicketsGQL extends Apollo.Query<
  ManufacturerDeviceDetailTicketsQuery,
  ManufacturerDeviceDetailTicketsQueryVariables
> {
  override document = ManufacturerDeviceDetailTicketsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceDetailDocument = gql`
  query ManufacturerDeviceDetail($deviceId: ID!) {
    manufacturerDeviceDetail(deviceId: $deviceId) {
      ...ManufacturerDeviceDetail
    }
  }
  ${ManufacturerDeviceDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceDetailGQL extends Apollo.Query<
  ManufacturerDeviceDetailQuery,
  ManufacturerDeviceDetailQueryVariables
> {
  override document = ManufacturerDeviceDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceEventTypeDetailDocument = gql`
  query ManufacturerDeviceEventTypeDetail($deviceEventTypeId: ID!) {
    manufacturerDeviceEventTypeDetail(deviceEventTypeId: $deviceEventTypeId) {
      ...ManufacturerDeviceEventTypeDetail
    }
  }
  ${ManufacturerDeviceEventTypeDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceEventTypeDetailGQL extends Apollo.Query<
  ManufacturerDeviceEventTypeDetailQuery,
  ManufacturerDeviceEventTypeDetailQueryVariables
> {
  override document = ManufacturerDeviceEventTypeDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceEventTypeListDocument = gql`
  query ManufacturerDeviceEventTypeList(
    $q: String = ""
    $priority: [DeviceEventPriorityChoices!] = null
    $deviceType: [ID!]
    $page: Int = 1
  ) {
    manufacturerDeviceEventTypeList(q: $q, priority: $priority, deviceType: $deviceType, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...ManufacturerDeviceEventTypeList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${ManufacturerDeviceEventTypeListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceEventTypeListGQL extends Apollo.Query<
  ManufacturerDeviceEventTypeListQuery,
  ManufacturerDeviceEventTypeListQueryVariables
> {
  override document = ManufacturerDeviceEventTypeListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceListDocument = gql`
  query ManufacturerDeviceList(
    $q: String = ""
    $deviceType: [ID!]
    $deviceCreatedBy: [ID!]
    $state: [DeviceStateChoices!]
    $orderBy: DeviceOrderingChoices = MODIFIED_DESC
    $page: Int = 1
  ) {
    manufacturerDeviceList(
      q: $q
      deviceType: $deviceType
      deviceCreatedBy: $deviceCreatedBy
      state: $state
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...ManufacturerDeviceList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${ManufacturerDeviceListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceListGQL extends Apollo.Query<
  ManufacturerDeviceListQuery,
  ManufacturerDeviceListQueryVariables
> {
  override document = ManufacturerDeviceListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceTypeCreatedBySelectItemsDocument = gql`
  query ManufacturerDeviceTypeCreatedBySelectItems {
    manufacturerDeviceTypeCreatedBySelectItems {
      ...UserSelectItem
    }
  }
  ${UserSelectItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceTypeCreatedBySelectItemsGQL extends Apollo.Query<
  ManufacturerDeviceTypeCreatedBySelectItemsQuery,
  ManufacturerDeviceTypeCreatedBySelectItemsQueryVariables
> {
  override document = ManufacturerDeviceTypeCreatedBySelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceTypeDetailEventDocument = gql`
  query ManufacturerDeviceTypeDetailEvent($deviceTypeId: ID!) {
    manufacturerDeviceTypeDetail(deviceTypeId: $deviceTypeId) {
      ...ManufacturerDeviceTypeDetailEvent
    }
  }
  ${ManufacturerDeviceTypeDetailEventFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceTypeDetailEventGQL extends Apollo.Query<
  ManufacturerDeviceTypeDetailEventQuery,
  ManufacturerDeviceTypeDetailEventQueryVariables
> {
  override document = ManufacturerDeviceTypeDetailEventDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceTypeDetailDocument = gql`
  query ManufacturerDeviceTypeDetail($deviceTypeId: ID!) {
    manufacturerDeviceTypeDetail(deviceTypeId: $deviceTypeId) {
      ...ManufacturerDeviceTypeDetail
    }
  }
  ${ManufacturerDeviceTypeDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceTypeDetailGQL extends Apollo.Query<
  ManufacturerDeviceTypeDetailQuery,
  ManufacturerDeviceTypeDetailQueryVariables
> {
  override document = ManufacturerDeviceTypeDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceTypeListDocument = gql`
  query ManufacturerDeviceTypeList(
    $technology: [DeviceTypeTechnologyChoices!]
    $apiType: [DeviceTypeApiTypeChoices!]
    $createdBy: [ID!]
    $orderBy: DeviceTypeOrderingChoices = NAME_ASC
    $page: Int = 1
  ) {
    manufacturerDeviceTypeList(
      technology: $technology
      apiType: $apiType
      createdBy: $createdBy
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...ManufacturerDeviceTypeList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${ManufacturerDeviceTypeListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceTypeListGQL extends Apollo.Query<
  ManufacturerDeviceTypeListQuery,
  ManufacturerDeviceTypeListQueryVariables
> {
  override document = ManufacturerDeviceTypeListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerDeviceTypeSelectItemsDocument = gql`
  query ManufacturerDeviceTypeSelectItems($q: String = "") {
    manufacturerDeviceTypeSelectItems(q: $q) {
      ...DeviceTypeSelectItem
    }
  }
  ${DeviceTypeSelectItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDeviceTypeSelectItemsGQL extends Apollo.Query<
  ManufacturerDeviceTypeSelectItemsQuery,
  ManufacturerDeviceTypeSelectItemsQueryVariables
> {
  override document = ManufacturerDeviceTypeSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerInstallationCompanySelectItemsTicketsAllowedDocument = gql`
  query ManufacturerInstallationCompanySelectItemsTicketsAllowed($deviceId: ID = null) {
    manufacturerInstallationCompanySelectItems(ticketsAllowed: true, deviceId: $deviceId) {
      ...InstallationCompanySelectItem
    }
  }
  ${InstallationCompanySelectItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerInstallationCompanySelectItemsTicketsAllowedGQL extends Apollo.Query<
  ManufacturerInstallationCompanySelectItemsTicketsAllowedQuery,
  ManufacturerInstallationCompanySelectItemsTicketsAllowedQueryVariables
> {
  override document = ManufacturerInstallationCompanySelectItemsTicketsAllowedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTariffSelectItemsDocument = gql`
  query ManufacturerManufacturerTariffSelectItems {
    manufacturerManufacturerTariffSelectItems {
      ...ManufacturerManufacturerTariffSelectItems
    }
  }
  ${ManufacturerManufacturerTariffSelectItemsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTariffSelectItemsGQL extends Apollo.Query<
  ManufacturerManufacturerTariffSelectItemsQuery,
  ManufacturerManufacturerTariffSelectItemsQueryVariables
> {
  override document = ManufacturerManufacturerTariffSelectItemsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketCommentListDocument = gql`
  query ManufacturerManufacturerTicketCommentList($manufacturerTicket: [ID!] = null, $page: Int = 1) {
    manufacturerManufacturerTicketCommentList(manufacturerTicket: $manufacturerTicket, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...ManufacturerTicketComment
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${ManufacturerTicketCommentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketCommentListGQL extends Apollo.Query<
  ManufacturerManufacturerTicketCommentListQuery,
  ManufacturerManufacturerTicketCommentListQueryVariables
> {
  override document = ManufacturerManufacturerTicketCommentListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketDetailEditDocument = gql`
  query ManufacturerManufacturerTicketDetailEdit($ticketId: ID!) {
    manufacturerManufacturerTicketDetail(ticketId: $ticketId) {
      ...ManufacturerManufacturerTicketDetailEdit
    }
  }
  ${ManufacturerManufacturerTicketDetailEditFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketDetailEditGQL extends Apollo.Query<
  ManufacturerManufacturerTicketDetailEditQuery,
  ManufacturerManufacturerTicketDetailEditQueryVariables
> {
  override document = ManufacturerManufacturerTicketDetailEditDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketDetailDocument = gql`
  query ManufacturerManufacturerTicketDetail($ticketId: ID!) {
    manufacturerManufacturerTicketDetail(ticketId: $ticketId) {
      ...ManufacturerManufacturerTicketDetail
    }
  }
  ${ManufacturerManufacturerTicketDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketDetailGQL extends Apollo.Query<
  ManufacturerManufacturerTicketDetailQuery,
  ManufacturerManufacturerTicketDetailQueryVariables
> {
  override document = ManufacturerManufacturerTicketDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerManufacturerTicketListDocument = gql`
  query ManufacturerManufacturerTicketList(
    $device: [ID!] = null
    $deviceType: [ID!] = null
    $deviceCreatedBy: [ID!] = null
    $isClosed: Boolean = false
    $q: String = ""
    $orderBy: ManufacturerTicketOrderingChoices = CREATED_DESC
    $page: Int = 1
  ) {
    manufacturerManufacturerTicketList(
      device: $device
      deviceType: $deviceType
      deviceCreatedBy: $deviceCreatedBy
      isClosed: $isClosed
      q: $q
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        ...PaginationMeta
      }
      items {
        ...ManufacturerManufacturerTicketList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${ManufacturerManufacturerTicketListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerManufacturerTicketListGQL extends Apollo.Query<
  ManufacturerManufacturerTicketListQuery,
  ManufacturerManufacturerTicketListQueryVariables
> {
  override document = ManufacturerManufacturerTicketListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ManufacturerTariffRestrictionInfoDocument = gql`
  query ManufacturerTariffRestrictionInfo {
    me {
      id
      manufacturer {
        id
        subscription {
          id
          currentPeriodEnd
          isExpired
        }
      }
    }
    manufacturerTariffRestrictionInfo {
      ...ManufacturerTariffRestrictionInfo
    }
  }
  ${ManufacturerTariffRestrictionInfoFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ManufacturerTariffRestrictionInfoGQL extends Apollo.Query<
  ManufacturerTariffRestrictionInfoQuery,
  ManufacturerTariffRestrictionInfoQueryVariables
> {
  override document = ManufacturerTariffRestrictionInfoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CountryListDocument = gql`
  query CountryList {
    countryList {
      ...Country
    }
  }
  ${CountryFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CountryListGQL extends Apollo.Query<CountryListQuery, CountryListQueryVariables> {
  override document = CountryListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeviceMappingSchemaDocument = gql`
  query DeviceMappingSchema {
    deviceMappingSchema
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeviceMappingSchemaGQL extends Apollo.Query<DeviceMappingSchemaQuery, DeviceMappingSchemaQueryVariables> {
  override document = DeviceMappingSchemaDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InstallationCompanyCustomerInvitationDetailDocument = gql`
  query InstallationCompanyCustomerInvitationDetail($installationCompanyCustomerId: ID!, $token: String!) {
    installationCompanyCustomerInvitationDetail(
      installationCompanyCustomerId: $installationCompanyCustomerId
      token: $token
    ) {
      ... on InstallationCompanyCustomerInvitationType {
        ...InstallationCompanyCustomerInvitationDetail
      }
      ... on TokenExpiredResponse {
        ok
      }
      ... on ObjectDoesNotExistsResponse {
        id
      }
    }
  }
  ${InstallationCompanyCustomerInvitationDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InstallationCompanyCustomerInvitationDetailGQL extends Apollo.Query<
  InstallationCompanyCustomerInvitationDetailQuery,
  InstallationCompanyCustomerInvitationDetailQueryVariables
> {
  override document = InstallationCompanyCustomerInvitationDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InvitedUserOrganizationDetailDocument = gql`
  query InvitedUserOrganizationDetail($userActivationInput: UserActivationInput!) {
    invitedUserOrganizationDetail(userActivationInput: $userActivationInput) {
      ... on CustomerType {
        id
        name
      }
      ... on InstallationCompanyType {
        id
        name
      }
      ... on ManufacturerType {
        id
        name
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InvitedUserOrganizationDetailGQL extends Apollo.Query<
  InvitedUserOrganizationDetailQuery,
  InvitedUserOrganizationDetailQueryVariables
> {
  override document = InvitedUserOrganizationDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeAdminDocument = gql`
  query MeAdmin {
    me {
      ...MeAdmin
    }
  }
  ${MeAdminFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeAdminGQL extends Apollo.Query<MeAdminQuery, MeAdminQueryVariables> {
  override document = MeAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeCustomerExportDocument = gql`
  query MeCustomerExport {
    me {
      ...MeCustomerExport
    }
  }
  ${MeCustomerExportFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeCustomerExportGQL extends Apollo.Query<MeCustomerExportQuery, MeCustomerExportQueryVariables> {
  override document = MeCustomerExportDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeCustomerMqttDocument = gql`
  query MeCustomerMqtt {
    me {
      ...MeCustomerMqtt
    }
  }
  ${MeCustomerMqttFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeCustomerMqttGQL extends Apollo.Query<MeCustomerMqttQuery, MeCustomerMqttQueryVariables> {
  override document = MeCustomerMqttDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeCustomerSubscriptionDocument = gql`
  query MeCustomerSubscription {
    me {
      ...MeCustomerSubscription
    }
  }
  ${MeCustomerSubscriptionFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeCustomerSubscriptionGQL extends Apollo.Query<
  MeCustomerSubscriptionQuery,
  MeCustomerSubscriptionQueryVariables
> {
  override document = MeCustomerSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeCustomerDocument = gql`
  query MeCustomer {
    me {
      ...MeCustomer
    }
  }
  ${MeCustomerFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeCustomerGQL extends Apollo.Query<MeCustomerQuery, MeCustomerQueryVariables> {
  override document = MeCustomerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeInstallationCompanySubscriptionDocument = gql`
  query MeInstallationCompanySubscription {
    me {
      ...MeInstallationCompanySubscription
    }
  }
  ${MeInstallationCompanySubscriptionFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeInstallationCompanySubscriptionGQL extends Apollo.Query<
  MeInstallationCompanySubscriptionQuery,
  MeInstallationCompanySubscriptionQueryVariables
> {
  override document = MeInstallationCompanySubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeInstallationCompanyTicketsAllowedDocument = gql`
  query MeInstallationCompanyTicketsAllowed {
    me {
      ...MeInstallationCompanyTicketsAllowed
    }
  }
  ${MeInstallationCompanyTicketsAllowedFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeInstallationCompanyTicketsAllowedGQL extends Apollo.Query<
  MeInstallationCompanyTicketsAllowedQuery,
  MeInstallationCompanyTicketsAllowedQueryVariables
> {
  override document = MeInstallationCompanyTicketsAllowedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeInstallationCompanyDocument = gql`
  query MeInstallationCompany {
    me {
      ...MeInstallationCompany
    }
  }
  ${MeInstallationCompanyFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeInstallationCompanyGQL extends Apollo.Query<
  MeInstallationCompanyQuery,
  MeInstallationCompanyQueryVariables
> {
  override document = MeInstallationCompanyDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeManufacturerDocument = gql`
  query MeManufacturer {
    me {
      ...MeManufacturer
    }
  }
  ${MeManufacturerFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeManufacturerGQL extends Apollo.Query<MeManufacturerQuery, MeManufacturerQueryVariables> {
  override document = MeManufacturerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MeDocument = gql`
  query Me {
    me {
      ...MeUser
    }
  }
  ${MeUserFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
  override document = MeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveBatteryCapacityDocument = gql`
  query ChartFveBatteryCapacity($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: batteryCapacity
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveBatteryCapacityGQL extends Apollo.Query<
  ChartFveBatteryCapacityQuery,
  ChartFveBatteryCapacityQueryVariables
> {
  override document = ChartFveBatteryCapacityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveBatteryCurrentDocument = gql`
  query ChartFveBatteryCurrent($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: batteryCurrent
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveBatteryCurrentGQL extends Apollo.Query<
  ChartFveBatteryCurrentQuery,
  ChartFveBatteryCurrentQueryVariables
> {
  override document = ChartFveBatteryCurrentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveBatteryPowerDocument = gql`
  query ChartFveBatteryPower($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: batteryPower
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveBatteryPowerGQL extends Apollo.Query<
  ChartFveBatteryPowerQuery,
  ChartFveBatteryPowerQueryVariables
> {
  override document = ChartFveBatteryPowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveBatterySocDisconnectActualDocument = gql`
  query ChartFveBatterySocDisconnectActual($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: batterySocDisconnectActual
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveBatterySocDisconnectActualGQL extends Apollo.Query<
  ChartFveBatterySocDisconnectActualQuery,
  ChartFveBatterySocDisconnectActualQueryVariables
> {
  override document = ChartFveBatterySocDisconnectActualDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveBatterySocDisconnectMinimumDocument = gql`
  query ChartFveBatterySocDisconnectMinimum($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: batterySocDisconnectMinimum
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveBatterySocDisconnectMinimumGQL extends Apollo.Query<
  ChartFveBatterySocDisconnectMinimumQuery,
  ChartFveBatterySocDisconnectMinimumQueryVariables
> {
  override document = ChartFveBatterySocDisconnectMinimumDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveBatterySocDisconnectNogridDocument = gql`
  query ChartFveBatterySocDisconnectNogrid($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: batterySocDisconnectNogrid
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveBatterySocDisconnectNogridGQL extends Apollo.Query<
  ChartFveBatterySocDisconnectNogridQuery,
  ChartFveBatterySocDisconnectNogridQueryVariables
> {
  override document = ChartFveBatterySocDisconnectNogridDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveBatterySocDocument = gql`
  query ChartFveBatterySoc($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: batterySoc
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveBatterySocGQL extends Apollo.Query<ChartFveBatterySocQuery, ChartFveBatterySocQueryVariables> {
  override document = ChartFveBatterySocDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveBatteryTemperatureDocument = gql`
  query ChartFveBatteryTemperature($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: batteryTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveBatteryTemperatureGQL extends Apollo.Query<
  ChartFveBatteryTemperatureQuery,
  ChartFveBatteryTemperatureQueryVariables
> {
  override document = ChartFveBatteryTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveBatteryVoltageDocument = gql`
  query ChartFveBatteryVoltage($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: batteryVoltage
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveBatteryVoltageGQL extends Apollo.Query<
  ChartFveBatteryVoltageQuery,
  ChartFveBatteryVoltageQueryVariables
> {
  override document = ChartFveBatteryVoltageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveChargingVoltageDocument = gql`
  query ChartFveChargingVoltage($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: chargingVoltage
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveChargingVoltageGQL extends Apollo.Query<
  ChartFveChargingVoltageQuery,
  ChartFveChargingVoltageQueryVariables
> {
  override document = ChartFveChargingVoltageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveExternalPvPowerDocument = gql`
  query ChartFveExternalPvPower($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: externalPvPower
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveExternalPvPowerGQL extends Apollo.Query<
  ChartFveExternalPvPowerQuery,
  ChartFveExternalPvPowerQueryVariables
> {
  override document = ChartFveExternalPvPowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveGridFeedPowerMaxDocument = gql`
  query ChartFveGridFeedPowerMax($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: gridFeedPowerMax
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveGridFeedPowerMaxGQL extends Apollo.Query<
  ChartFveGridFeedPowerMaxQuery,
  ChartFveGridFeedPowerMaxQueryVariables
> {
  override document = ChartFveGridFeedPowerMaxDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveGridL1PowerDocument = gql`
  query ChartFveGridL1Power($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: gridL1Power
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveGridL1PowerGQL extends Apollo.Query<ChartFveGridL1PowerQuery, ChartFveGridL1PowerQueryVariables> {
  override document = ChartFveGridL1PowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveGridL2PowerDocument = gql`
  query ChartFveGridL2Power($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: gridL2Power
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveGridL2PowerGQL extends Apollo.Query<ChartFveGridL2PowerQuery, ChartFveGridL2PowerQueryVariables> {
  override document = ChartFveGridL2PowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveGridL3PowerDocument = gql`
  query ChartFveGridL3Power($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: gridL3Power
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveGridL3PowerGQL extends Apollo.Query<ChartFveGridL3PowerQuery, ChartFveGridL3PowerQueryVariables> {
  override document = ChartFveGridL3PowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveGridPowerDocument = gql`
  query ChartFveGridPower($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: gridPower
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveGridPowerGQL extends Apollo.Query<ChartFveGridPowerQuery, ChartFveGridPowerQueryVariables> {
  override document = ChartFveGridPowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveInternalTemperatureDocument = gql`
  query ChartFveInternalTemperature($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: internalTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveInternalTemperatureGQL extends Apollo.Query<
  ChartFveInternalTemperatureQuery,
  ChartFveInternalTemperatureQueryVariables
> {
  override document = ChartFveInternalTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveLoadL1PowerDocument = gql`
  query ChartFveLoadL1Power($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: loadL1Power
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveLoadL1PowerGQL extends Apollo.Query<ChartFveLoadL1PowerQuery, ChartFveLoadL1PowerQueryVariables> {
  override document = ChartFveLoadL1PowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveLoadL2PowerDocument = gql`
  query ChartFveLoadL2Power($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: loadL2Power
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveLoadL2PowerGQL extends Apollo.Query<ChartFveLoadL2PowerQuery, ChartFveLoadL2PowerQueryVariables> {
  override document = ChartFveLoadL2PowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveLoadL3PowerDocument = gql`
  query ChartFveLoadL3Power($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: loadL3Power
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveLoadL3PowerGQL extends Apollo.Query<ChartFveLoadL3PowerQuery, ChartFveLoadL3PowerQueryVariables> {
  override document = ChartFveLoadL3PowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveLoadPowerDocument = gql`
  query ChartFveLoadPower($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: loadPower
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveLoadPowerGQL extends Apollo.Query<ChartFveLoadPowerQuery, ChartFveLoadPowerQueryVariables> {
  override document = ChartFveLoadPowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveMaxChargingCurrentDocument = gql`
  query ChartFveMaxChargingCurrent($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: maxChargingCurrent
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveMaxChargingCurrentGQL extends Apollo.Query<
  ChartFveMaxChargingCurrentQuery,
  ChartFveMaxChargingCurrentQueryVariables
> {
  override document = ChartFveMaxChargingCurrentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveMpptPowerDocument = gql`
  query ChartFveMpptPower($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: mpptPower
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveMpptPowerGQL extends Apollo.Query<ChartFveMpptPowerQuery, ChartFveMpptPowerQueryVariables> {
  override document = ChartFveMpptPowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveMppt1PowerDocument = gql`
  query ChartFveMppt1Power($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: mppt1Power
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveMppt1PowerGQL extends Apollo.Query<ChartFveMppt1PowerQuery, ChartFveMppt1PowerQueryVariables> {
  override document = ChartFveMppt1PowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveMppt1VoltageDocument = gql`
  query ChartFveMppt1Voltage($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: mppt1Voltage
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveMppt1VoltageGQL extends Apollo.Query<
  ChartFveMppt1VoltageQuery,
  ChartFveMppt1VoltageQueryVariables
> {
  override document = ChartFveMppt1VoltageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveMppt2PowerDocument = gql`
  query ChartFveMppt2Power($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: mppt2Power
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveMppt2PowerGQL extends Apollo.Query<ChartFveMppt2PowerQuery, ChartFveMppt2PowerQueryVariables> {
  override document = ChartFveMppt2PowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveMppt2VoltageDocument = gql`
  query ChartFveMppt2Voltage($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: mppt2Voltage
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveMppt2VoltageGQL extends Apollo.Query<
  ChartFveMppt2VoltageQuery,
  ChartFveMppt2VoltageQueryVariables
> {
  override document = ChartFveMppt2VoltageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFvePowerGridConsumptionDocument = gql`
  query ChartFvePowerGridConsumption($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: powerGridConsumption
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFvePowerGridConsumptionGQL extends Apollo.Query<
  ChartFvePowerGridConsumptionQuery,
  ChartFvePowerGridConsumptionQueryVariables
> {
  override document = ChartFvePowerGridConsumptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartFveSolarIrradianceDocument = gql`
  query ChartFveSolarIrradiance($deviceId: ID!) {
    chartData: plcFveMiniChartData(deviceId: $deviceId) {
      bucket
      value: solarIrradiance
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartFveSolarIrradianceGQL extends Apollo.Query<
  ChartFveSolarIrradianceQuery,
  ChartFveSolarIrradianceQueryVariables
> {
  override document = ChartFveSolarIrradianceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpBivalentPowerConsumptionDocument = gql`
  query ChartHeatPumpBivalentPowerConsumption($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: bivalentPowerConsumption
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpBivalentPowerConsumptionGQL extends Apollo.Query<
  ChartHeatPumpBivalentPowerConsumptionQuery,
  ChartHeatPumpBivalentPowerConsumptionQueryVariables
> {
  override document = ChartHeatPumpBivalentPowerConsumptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpCompressorTemperatureDocument = gql`
  query ChartHeatPumpCompressorTemperature($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: compressorTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpCompressorTemperatureGQL extends Apollo.Query<
  ChartHeatPumpCompressorTemperatureQuery,
  ChartHeatPumpCompressorTemperatureQueryVariables
> {
  override document = ChartHeatPumpCompressorTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpCondenserTemperatureDocument = gql`
  query ChartHeatPumpCondenserTemperature($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: condenserTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpCondenserTemperatureGQL extends Apollo.Query<
  ChartHeatPumpCondenserTemperatureQuery,
  ChartHeatPumpCondenserTemperatureQueryVariables
> {
  override document = ChartHeatPumpCondenserTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpDhwHysteresisDocument = gql`
  query ChartHeatPumpDhwHysteresis($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: dhwHysteresis
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpDhwHysteresisGQL extends Apollo.Query<
  ChartHeatPumpDhwHysteresisQuery,
  ChartHeatPumpDhwHysteresisQueryVariables
> {
  override document = ChartHeatPumpDhwHysteresisDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpDhwTargetDocument = gql`
  query ChartHeatPumpDhwTarget($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: dhwTarget
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpDhwTargetGQL extends Apollo.Query<
  ChartHeatPumpDhwTargetQuery,
  ChartHeatPumpDhwTargetQueryVariables
> {
  override document = ChartHeatPumpDhwTargetDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpDhwTemperatureDocument = gql`
  query ChartHeatPumpDhwTemperature($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: dhwTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpDhwTemperatureGQL extends Apollo.Query<
  ChartHeatPumpDhwTemperatureQuery,
  ChartHeatPumpDhwTemperatureQueryVariables
> {
  override document = ChartHeatPumpDhwTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpEkvitermPowerDocument = gql`
  query ChartHeatPumpEkvitermPower($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: ekvitermPower
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpEkvitermPowerGQL extends Apollo.Query<
  ChartHeatPumpEkvitermPowerQuery,
  ChartHeatPumpEkvitermPowerQueryVariables
> {
  override document = ChartHeatPumpEkvitermPowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpEvaporatorTemperatureDocument = gql`
  query ChartHeatPumpEvaporatorTemperature($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: evaporatorTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpEvaporatorTemperatureGQL extends Apollo.Query<
  ChartHeatPumpEvaporatorTemperatureQuery,
  ChartHeatPumpEvaporatorTemperatureQueryVariables
> {
  override document = ChartHeatPumpEvaporatorTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpHeatingFactorDocument = gql`
  query ChartHeatPumpHeatingFactor($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: heatingFactor
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpHeatingFactorGQL extends Apollo.Query<
  ChartHeatPumpHeatingFactorQuery,
  ChartHeatPumpHeatingFactorQueryVariables
> {
  override document = ChartHeatPumpHeatingFactorDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpHeatingSystemHysteresisDocument = gql`
  query ChartHeatPumpHeatingSystemHysteresis($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: heatingSystemHysteresis
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpHeatingSystemHysteresisGQL extends Apollo.Query<
  ChartHeatPumpHeatingSystemHysteresisQuery,
  ChartHeatPumpHeatingSystemHysteresisQueryVariables
> {
  override document = ChartHeatPumpHeatingSystemHysteresisDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpHeatingSystemTargetDocument = gql`
  query ChartHeatPumpHeatingSystemTarget($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: heatingSystemTarget
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpHeatingSystemTargetGQL extends Apollo.Query<
  ChartHeatPumpHeatingSystemTargetQuery,
  ChartHeatPumpHeatingSystemTargetQueryVariables
> {
  override document = ChartHeatPumpHeatingSystemTargetDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpHeatingSystemTemperatureDocument = gql`
  query ChartHeatPumpHeatingSystemTemperature($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: heatingSystemTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpHeatingSystemTemperatureGQL extends Apollo.Query<
  ChartHeatPumpHeatingSystemTemperatureQuery,
  ChartHeatPumpHeatingSystemTemperatureQueryVariables
> {
  override document = ChartHeatPumpHeatingSystemTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpIndoorTemperatureDocument = gql`
  query ChartHeatPumpIndoorTemperature($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: indoorTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpIndoorTemperatureGQL extends Apollo.Query<
  ChartHeatPumpIndoorTemperatureQuery,
  ChartHeatPumpIndoorTemperatureQueryVariables
> {
  override document = ChartHeatPumpIndoorTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpInputPowerDocument = gql`
  query ChartHeatPumpInputPower($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: inputPower
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpInputPowerGQL extends Apollo.Query<
  ChartHeatPumpInputPowerQuery,
  ChartHeatPumpInputPowerQueryVariables
> {
  override document = ChartHeatPumpInputPowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpOutdoorTemperatureDocument = gql`
  query ChartHeatPumpOutdoorTemperature($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: outdoorTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpOutdoorTemperatureGQL extends Apollo.Query<
  ChartHeatPumpOutdoorTemperatureQuery,
  ChartHeatPumpOutdoorTemperatureQueryVariables
> {
  override document = ChartHeatPumpOutdoorTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpOutdoorunitTargetPercentageDocument = gql`
  query ChartHeatPumpOutdoorunitTargetPercentage($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: outdoorunitTargetPercentage
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpOutdoorunitTargetPercentageGQL extends Apollo.Query<
  ChartHeatPumpOutdoorunitTargetPercentageQuery,
  ChartHeatPumpOutdoorunitTargetPercentageQueryVariables
> {
  override document = ChartHeatPumpOutdoorunitTargetPercentageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpOutputPowerDocument = gql`
  query ChartHeatPumpOutputPower($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: outputPower
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpOutputPowerGQL extends Apollo.Query<
  ChartHeatPumpOutputPowerQuery,
  ChartHeatPumpOutputPowerQueryVariables
> {
  override document = ChartHeatPumpOutputPowerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpOutputTemperatureDocument = gql`
  query ChartHeatPumpOutputTemperature($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: outputTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpOutputTemperatureGQL extends Apollo.Query<
  ChartHeatPumpOutputTemperatureQuery,
  ChartHeatPumpOutputTemperatureQueryVariables
> {
  override document = ChartHeatPumpOutputTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpTargetIndoorTemperatureDocument = gql`
  query ChartHeatPumpTargetIndoorTemperature($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: targetIndoorTemperature
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpTargetIndoorTemperatureGQL extends Apollo.Query<
  ChartHeatPumpTargetIndoorTemperatureQuery,
  ChartHeatPumpTargetIndoorTemperatureQueryVariables
> {
  override document = ChartHeatPumpTargetIndoorTemperatureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChartHeatPumpWatterFlowDocument = gql`
  query ChartHeatPumpWatterFlow($deviceId: ID!) {
    chartData: plcHeatPumpMiniChartData(deviceId: $deviceId) {
      bucket
      value: watterFlow
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChartHeatPumpWatterFlowGQL extends Apollo.Query<
  ChartHeatPumpWatterFlowQuery,
  ChartHeatPumpWatterFlowQueryVariables
> {
  override document = ChartHeatPumpWatterFlowDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OrganizationUserDetailDocument = gql`
  query OrganizationUserDetail($userId: ID!) {
    organizationUserDetail(userId: $userId) {
      ...OrganizationUserDetail
    }
  }
  ${OrganizationUserDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class OrganizationUserDetailGQL extends Apollo.Query<
  OrganizationUserDetailQuery,
  OrganizationUserDetailQueryVariables
> {
  override document = OrganizationUserDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OrganizationUserListDocument = gql`
  query OrganizationUserList($orderBy: UserOrderingChoices = MODIFIED_DESC, $page: Int = 1) {
    organizationUserList(orderBy: $orderBy, page: $page) {
      meta {
        ...PaginationMeta
      }
      items {
        ...OrganizationUserList
      }
    }
  }
  ${PaginationMetaFragmentDoc}
  ${OrganizationUserListFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class OrganizationUserListGQL extends Apollo.Query<
  OrganizationUserListQuery,
  OrganizationUserListQueryVariables
> {
  override document = OrganizationUserListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlcFveBarChartDataDocument = gql`
  query PlcFveBarChartData($deviceId: ID!, $period: PlcMetricsBarChartPeriodChoices!, $untilDate: Date = null) {
    plcFveBarChartData(deviceId: $deviceId, period: $period, untilDate: $untilDate) {
      ... on PLCFVEBarChartDataList {
        data {
          ...PLCFVEBarChartData
        }
      }
      ... on TariffRestrictionResponse {
        ok
      }
    }
  }
  ${PlcfveBarChartDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PlcFveBarChartDataGQL extends Apollo.Query<PlcFveBarChartDataQuery, PlcFveBarChartDataQueryVariables> {
  override document = PlcFveBarChartDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlcFveDashboardMetricsDocument = gql`
  query PlcFveDashboardMetrics($deviceId: ID!) {
    plcFveMiniChartData(deviceId: $deviceId) {
      ...PlcFveDashboardMetricsChart
    }
    plcFveCurrentData(deviceId: $deviceId) {
      ...PlcFveDashboardMetricsCounts
    }
  }
  ${PlcFveDashboardMetricsChartFragmentDoc}
  ${PlcFveDashboardMetricsCountsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PlcFveDashboardMetricsGQL extends Apollo.Query<
  PlcFveDashboardMetricsQuery,
  PlcFveDashboardMetricsQueryVariables
> {
  override document = PlcFveDashboardMetricsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlcFveLineChartDataDocument = gql`
  query PlcFveLineChartData(
    $deviceId: ID!
    $period: PlcMetricsLineChartPeriodGraphQLChoices!
    $untilDate: Date = null
  ) {
    plcFveLineChartData(deviceId: $deviceId, period: $period, untilDate: $untilDate) {
      ... on PLCFVELineChartDataList {
        data {
          ...PLCFVELineChartData
        }
      }
      ... on TariffRestrictionResponse {
        ok
      }
    }
  }
  ${PlcfveLineChartDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PlcFveLineChartDataGQL extends Apollo.Query<PlcFveLineChartDataQuery, PlcFveLineChartDataQueryVariables> {
  override document = PlcFveLineChartDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlcHeatPumpBarChartDataDocument = gql`
  query PlcHeatPumpBarChartData($deviceId: ID!, $period: PlcMetricsBarChartPeriodChoices!, $untilDate: Date = null) {
    plcHeatPumpBarChartData(deviceId: $deviceId, period: $period, untilDate: $untilDate) {
      ... on PLCHeatPumpBarChartDataList {
        data {
          ...PLCHeatPumpBarChartData
        }
      }
      ... on TariffRestrictionResponse {
        ok
      }
    }
  }
  ${PlcHeatPumpBarChartDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PlcHeatPumpBarChartDataGQL extends Apollo.Query<
  PlcHeatPumpBarChartDataQuery,
  PlcHeatPumpBarChartDataQueryVariables
> {
  override document = PlcHeatPumpBarChartDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlcHeatPumpDashboardMetricsDocument = gql`
  query PlcHeatPumpDashboardMetrics($deviceId: ID!) {
    plcHeatPumpMiniChartData(deviceId: $deviceId) {
      ...PlcHeatPumpDashboardMetricsChart
    }
    plcHeatPumpCurrentData(deviceId: $deviceId) {
      ...PlcHeatPumpDashboardMetricsCounts
    }
  }
  ${PlcHeatPumpDashboardMetricsChartFragmentDoc}
  ${PlcHeatPumpDashboardMetricsCountsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PlcHeatPumpDashboardMetricsGQL extends Apollo.Query<
  PlcHeatPumpDashboardMetricsQuery,
  PlcHeatPumpDashboardMetricsQueryVariables
> {
  override document = PlcHeatPumpDashboardMetricsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PlcHeatPumpLineChartDataDocument = gql`
  query PlcHeatPumpLineChartData(
    $deviceId: ID!
    $period: PlcMetricsLineChartPeriodGraphQLChoices!
    $untilDate: Date = null
  ) {
    plcHeatPumpLineChartData(deviceId: $deviceId, period: $period, untilDate: $untilDate) {
      ... on PLCHeatPumpLineChartDataList {
        data {
          ...PlcHeatPumpLineChartData
        }
      }
      ... on TariffRestrictionResponse {
        ok
      }
    }
  }
  ${PlcHeatPumpLineChartDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PlcHeatPumpLineChartDataGQL extends Apollo.Query<
  PlcHeatPumpLineChartDataQuery,
  PlcHeatPumpLineChartDataQueryVariables
> {
  override document = PlcHeatPumpLineChartDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
