import { Pipe, PipeTransform } from '@angular/core';
import { CalendarDto } from '../dto';

@Pipe({
  name: 'calendarSelectedMonth',
  standalone: true,
})
export class CalendarSelectedMonthPipe implements PipeTransform {
  public transform(days: CalendarDto[]): string {
    return days.find((i) => i.isCurrentMonth)?.date || '';
  }
}
