import { InjectionToken } from '@angular/core';

export interface LanguageSwitchLanguage {
  code: string;
  label: string;
  abbreviation: string;
  flagPath: string;
}

export interface LanguageSwitchConfig {
  languages: LanguageSwitchLanguage[];
}

export const defaultLanguageSwitchConfig: LanguageSwitchConfig = {
  languages: [
    {
      code: 'cs',
      label: 'Česky',
      abbreviation: 'CS',
      flagPath: '',
    },
    {
      code: 'en',
      label: 'English',
      abbreviation: 'EN',
      flagPath: '',
    },
  ],
};

export const LANGUAGE_SWITCH_CONFIG_TOKEN = new InjectionToken<LanguageSwitchConfig>('language-switch-config');
